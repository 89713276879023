import React, { Component } from 'react'
import Header from '../../common/Header'
import { ProjectMenu } from '../common/ProjectMenu'
import { CallsheetComponent } from './callsheetComponent'
import { connect } from 'react-redux'
import { callsheetAction } from '../../../redux/actions/callsheet.action'
import { membersAction } from '../../../redux/actions/members.actions'
import { authentication } from '../../../redux/reducers/authentication.reducer'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { ScrollPanel } from 'primereact/scrollpanel'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import './projectCallsheetScreen.scss'
import { ProgressSpinner } from 'primereact/progressspinner'
import NoFile from '../../../img/nofiles.svg'
import { withTranslation } from 'react-i18next';

class ProjectCallsheetScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectId: null,
            callsheetPackage: {
                address: null,
                date: null,
                callTimes: [{
                    time: null,
                    description: null
                }],
                members: null,
                isFetched: false
            },
            loading: true,
            t: this.props.t
        }
        this.addCallSheet = this.addCallSheet.bind(this)
        this.updateCallSheet = this.updateCallSheet.bind(this)
        this.deleteCallSheet = this.deleteCallSheet.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        this.props.getCallsheet(this.props.match.params.projectId)
        this.props.getMembers(this.props.match.params.projectId)

        this.setState({ projectId: this.props.match.params.projectId })

    }

    static getDerivedStateFromProps(props, state) {
        console.log('state', state)
        if ( props.callsheet.isFetched && props.members.fetched ) {
            return {
                callsheet: props.callsheet.type,
                isFetched: props.callsheet.isFetched,
                loading: false,
                members: props.members.type
            }
        }

        if ( props.callsheet._isFetched || props.callsheet.is_Fetched || props.callsheet.isDeleted ) {
            props.getCallsheet(state.projectId)
            return {
                loading: true,
                isFetched: false
            }
        }
    }

    handleChange(event) {
        let name = event.target.name
        let value = event.target.value

        this.setState({
            callsheetPackage: { ...this.state.callsheetPackage, [name]: value }
        })
    }

    setTime = (idx, value) => {

        const newCallTimes = this.state.callsheetPackage.callTimes.map((callTime, sidx) => {
            if ( idx !== sidx ) return callTime
            return { ...callTime, time: moment(value).format(), description: callTime.description }
        })

        this.setState({
            callsheetPackage: {
                ...this.state.callsheetPackage, callTimes: newCallTimes
            }
        })
    }

    handleCallTimesChange = (idx) => (evt) => {
        const newCallTimes = this.state.callsheetPackage.callTimes.map((callTime, sidx) => {
            if ( idx !== sidx ) return callTime
            return { ...callTime, description: evt.target.value, time: callTime.time }
        })

        this.setState({
            callsheetPackage: {
                ...this.state.callsheetPackage, callTimes: newCallTimes
            }
        })
    }

    addTask = () => {
        this.setState(prevState => ({
            ...prevState,
            callsheetPackage: {
                ...prevState.callsheetPackage,
                callTimes: this.state.callsheetPackage.callTimes.concat([{ time: null, description: null }])
            }
        }))
    }

    addCallSheet(callsheetPackage) {
        this.props.addCallsheet(this.props.match.params.projectId, callsheetPackage)
    }

    updateCallSheet(callsheetId, newCallsheet) {
        this.props.updateCallsheet(this.props.match.params.projectId, callsheetId, newCallsheet)
    }

    deleteCallSheet(callsheetId) {
        console.log('callsheetId', callsheetId)
        this.props.deleteCallsheet(this.props.match.params.projectId, callsheetId)
    }

    render() {

        const addCallsheetPopupFooter = (
            <div className="p-pt-3 p-pb-1 popupFooterContainer">
                <Button className="cancelButton" label={this.state.t('common.cancel')} onClick={() => {
                    this.setState({
                        addCallsheetPopup: false, callsheetPackage: {
                            address: null,
                            date: null,
                            callTimes: [{
                                time: null,
                                description: null
                            }],
                            members: null
                        }
                    })

                    this.setState({ addCallsheetPopup: false })
                }}/>
                <Button className="validationButton" label={this.state.t('callSheet.addCallSheet')} onClick={() => {
                    this.addCallSheet(this.state.callsheetPackage)
                    this.setState({
                        addCallsheetPopup: false, callsheetPackage: {
                            address: null,
                            date: null,
                            callTimes: [{
                                time: null,
                                description: null
                            }],
                            members: null
                        }
                    })
                }}/>
            </div>
        )

        return (
            <div className="p-pb-4">
                <Header/>
                <ProjectMenu activeItemIndex={3}/>
                {this.state.loading ?
                    <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ height: '70vh' }}>
                        <ProgressSpinner className="p-progress-spinner"/>
                    </div> :
                    <>
                        {this.state.isFetched && this.state.callsheet.length > 0 ?
                            (<>
                                    {this.state.callsheet.map((callsheet, i) => (
                                        <CallsheetComponent
                                            index={i}
                                            id={callsheet.id}
                                            date={callsheet.date}
                                            address={callsheet.address}
                                            callTime={callsheet.callTimes}
                                            description={callsheet.description}
                                            projectMembers={callsheet.members}
                                            addCallSheet={this.addCallSheet}
                                            updateCallSheet={this.updateCallSheet}
                                            deleteCallsheet={this.deleteCallSheet}
                                            projectId={this.props.match.params.projectId}
                                        />))}
                                    <div className="p-text-right p-mb-3 pointer addCallsheetContainer"
                                         onClick={() => {this.setState({ addCallsheetPopup: true })}}>
                                        <i className="pi pi-plus p-mr-1"></i>
                                        {this.state.t('callSheet.addField')}
                                    </div>
                                </>
                            )
                            :
                            <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-mx-6 p-my-auto"
                                 style={{ height: '50vh' }}>
                                <img src={NoFile} style={{ width: '5%', marginBottom: "1vh" }}/>
                                <p style={{ color: "#AEAEAE" }}>{this.state.t('callSheet.emptyCallSheet')}</p>
                                <div onClick={() => {
                                    this.setState({ addCallsheetPopup: true })
                                }}>
                                    <i className="pi pi-plus p-mr-1"></i>
                                    {this.state.t('callSheet.addField')}
                                </div>
                            </div>
                        }
                    </>
                }

                <Dialog
                    header={this.state.t('callSheet.addOne')}
                    footer={addCallsheetPopupFooter}
                    visible={this.state.addCallsheetPopup}
                    onHide={() => {
                        const newCallTimes = [{ time: null, description: null }]
                        this.setState({
                            callsheetPackage: { ...this.state.callsheetPackage,
                                date: null,
                                address: null,
                                members: null,
                                callTimes: newCallTimes },
                            addCallsheetPopup: false
                        })
                    }}
                    className="p-d-flex p-flex-column p-jc-around popupContainer">
                    <ScrollPanel>
                        <div className="p-d-flex p-flex-column p-jc-around p-mb-6 scrollPanelContainer">
                            <Calendar placeholder={this.state.t('callSheet.date')}
                                      value={this.state.callsheetPackage.date}
                                      minDate={new Date()}
                                      onChange={(e) => this.setState({
                                          callsheetPackage: {
                                              ...this.state.callsheetPackage,
                                              date: moment(e.value).format()
                                          }
                                      })}/>
                            <div className="p-d-flex p-flex-column p-ai-end">
                                {this.state.callsheetPackage.callTimes.map((calltime, i) => (
                                    <div id="addTask"
                                         className="p-d-flex p-flex-row p-jc-between p-mb-3 p-mt-1 calltimeContainer">
                                        <DatePicker
                                            className="callsheet-input-horaire"
                                            placeholderText={calltime.time === null ? this.state.t('callSheet.hour') : moment(calltime.time).format('LT')}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            selected={this.state.callsheetPackage.callTimes.time}
                                            onChange={value => {this.setTime(i, value)}}
                                            autoComplete="off"
                                        />
                                        <InputText
                                            placeholder={this.state.t('callSheet.description')}
                                            name="description"
                                            value={calltime.description}
                                            onChange={this.handleCallTimesChange(i)}
                                            className="inputDescription"/>
                                    </div>
                                ))}
                                <div onClick={this.addTask} className="pointer">
                                    <i className="pi pi-plus p-mr-1"/>
                                    {this.state.t('callSheet.addTask')}
                                </div>
                            </div>
                            <InputText
                                placeholder={this.state.t('callSheet.rdv')}
                                name="address"
                                value={this.state.callsheetPackage.address}
                                onChange={this.handleChange}/>
                            <MultiSelect
                                className="multiselect"
                                value={this.state.callsheetPackage.members}
                                onChange={(e) => {
                                    this.setState({
                                        callsheetPackage: {
                                            ...this.state.callsheetPackage,
                                            members: e.value
                                        }
                                    })
                                }}
                                options={this.props.members.type}
                                optionLabel="email"
                                placeholder={this.state.t('callSheet.chooseMember')}
                                display="chip"/>
                        </div>
                    </ScrollPanel>
                </Dialog>
            </div>
        )
    }

}

function mapState(state) {
    const { callsheet, members } = state
    const { user } = authentication
    return { user, callsheet, members }
}

const actionCreators = {
    addCallsheet: callsheetAction.addCallsheet,
    getCallsheet: callsheetAction.getCallsheet,
    deleteCallsheet: callsheetAction.deleteCallsheet,
    updateCallsheet: callsheetAction.updateCallsheet,
    getMembers: membersAction.getMembersProject
}

const connectedApp = connect(mapState, actionCreators)(withTranslation()(ProjectCallsheetScreen))
export { connectedApp as ProjectCallsheetScreen }
