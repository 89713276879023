import React, { Component } from "react";
import { connect } from "react-redux";
import { productsActions, sessiondetailsActions } from "../../redux/actions";
import Header from "../common/Header";
import { ScrollPanel } from "primereact/scrollpanel";
import { SketchField, Tools } from "react-sketch";
import { Panel } from "primereact/panel";
import { ColorPicker } from "primereact/colorpicker";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { ToggleButton } from "primereact/togglebutton";
import { withTranslation } from "react-i18next";

class SessionComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: JSON.parse(localStorage.getItem("project")),
      image: null,
      comments: [],
      displayedComments: [],
      imageId: "",
      imageUrl: "",
      productId: 0,
      isChecked: false,
      sketchWidth: "90vw",
      sketchHeight: "90vh",
      removeToolSelected: false,
      availableTools: [
        {
          icon: "icon-Media_ToolboxPen",
          tool: Tools.Pencil,
        },
        {
          icon: "icon-Media_ToolboxEllipse",
          tool: Tools.Circle,
        },
        {
          icon: "icon-Media_Toolbox_Arrow",
          tool: Tools.Line,
        },
        {
          icon: "icon-FileBar_Expand",
          tool: Tools.Select,
        },
        {
          icon: "icon-Media_ToolboxSquare",
          tool: Tools.Rectangle,
        },
      ],

      selectedColor: "#000000",
      selectedTool: Tools.Pencil,
      drawings: [],
      defaultDrawing: [],
      commentPanelVisibility: "block",
      isFetched: false,
      loading: true,
    };

    this.drawComments = this.drawComments.bind(this);
    this.setSelectedTool = this.setSelectedTool.bind(this);
    this.validateImg = this.validateImg.bind(this);
    this.setSelectedColor = this.setSelectedColor.bind(this);
    this.setRemoveTool = this.setRemoveTool.bind(this);
    this.setKeyDown = this.setKeyDown.bind(this);
    this.addComment = this.addComment.bind(this);
    this.hideToolPanel = this.hideToolPanel.bind(this);
    this.updateCommentList = this.updateCommentList.bind(this);
    this.sketchBackgroundRef = React.createRef();
  }
  
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.image &&
      nextProps.image.url &&
      nextProps.image !== this.state.image
    ) {
      this.drawComments(
        nextProps.image.comments,
        `${process.env.REACT_APP_BASE_URL}${nextProps.image.url}`
      );
      this.setState({
        image: nextProps.image,
        comments: nextProps.image.comments,
        displayedComments: nextProps.image.comments.slice(),
        imageUrl: nextProps.image.url,
      });
    }

    if (nextProps.comments) {
      this.setState({
        comments: nextProps.comments,
        displayedComments: nextProps.comments.slice(),
      });
    }
    if (nextProps.isValidate) {
      this.props.getImageOverview(
        this.state.project.projectId,
        this.props.match.params.productId,
        this.props.match.params.imageId
      );
    }

    if (nextProps.isFetched) {
      this.setState({ isFetched: nextProps.isFetched });
    }
    if (nextProps.location && nextProps.location.state) {
      if (nextProps.location.state.displayedMedias) {
        const jsonMedia = JSON.stringify(
          nextProps.location.state.displayedMedias
        );

        sessionStorage.setItem("medias", jsonMedia);
      }

      if (nextProps.location.state.selectedPath) {
        const jsonSelectedPath = JSON.stringify(
          nextProps.location.state.selectedPath
        );

        sessionStorage.setItem("selected", jsonSelectedPath);
      }
    }
    if ( nextProps.location.state.imageId!==undefined &&
      nextProps.location.state.imageId  !==  this.props.match.params.imageId   
    ) {
       this.setState({project:nextProps.location.state.project,displayedComments:[]})

      this.props.getImageOverview(
        nextProps.location.state.projectId,
        nextProps.location.state.productId,
        nextProps.location.state.imageId
      );
    }
  }

  setSelectedTool(selectedTool) {
    this.setState({
      selectedTool: selectedTool,
      commentPanelVisibility: selectedTool == Tools.Select ? "none" : "block",
      removeToolSelected: false,
    });
  }

  setSelectedColor(selectedColor) {
    this.setState({ selectedColor: `#${selectedColor}` });
  }

  setKeyDown(key) {
    if (key.code === "Delete" || key.code === "Backspace") {
      let removeToolSelected = this.state.removeToolSelected;
      if (removeToolSelected) {
        this.removeSelected();
      }
    }
  }

  setRemoveTool() {
    this.setSelectedTool(Tools.Select);
    this.setState({ removeToolSelected: true, commentPanelVisibility: "none" });
  }

  removeSelected() {
    this.sketch.removeSelected();
  }

  validateImg() {
    this.props.validateImg(this.state.project.projectId, this.state.imageId);
  }

  componentDidMount() {
    this.setState({ defaultDrawing: [], drawings: [] });
 
    if (this.props.match.params.productId && this.props.match.params.imageId) {
      this.setState({
        imageId: this.props.match.params.imageId,
        productId: this.props.match.params.productId,
      });
      if (this.props.match.params.projectId) {
        this.props.getImageOverview(
          this.props.match.params.projectId,
          this.props.match.params.productId,
          this.props.match.params.imageId
        );
      } else {
        this.props.getImageOverview(
          this.state.project.projectId,
          this.props.match.params.productId,
          this.props.match.params.imageId
        );
      }
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  }

  componentWillUnmount() {
    const newCommentCount = JSON.stringify(this.state.comments.length);
    sessionStorage.setItem("newCommentCount", newCommentCount);

    const commentImgId = JSON.stringify(this.state.imageId);
    sessionStorage.setItem("commentImgId", commentImgId);
  }

  updateCommentList(comment, status) {
    let displayedComments = this.state.displayedComments;

    if (comment === null) {
      displayedComments = status === true ? this.state.comments.slice() : [];
    } else {
      if (status === true) {
        displayedComments.push(comment);
      } else {
        let commentIndex = displayedComments.indexOf(comment);
        if (commentIndex !== -1) displayedComments.splice(commentIndex, 1);
      }
    }

    this.drawComments(displayedComments, null);
    this.setState({ displayedComments: displayedComments });
  }

  drawComments(comments, imageUrl) {
    let drawings = [];
    if (comments && comments.length > 0) {
      comments.forEach((element) => {
        let pointIds = JSON.parse(element.pointId);
        if (pointIds != null) {
          pointIds.forEach((item) => {
            drawings.push(item);
          });
        }
      });
    }
    let defaultDrawing = {
      objects: drawings,
    };
    this.setState({ defaultDrawing: defaultDrawing });
  }

  addComment() {
    let alldrawings = this.sketch.toJSON().objects.slice();
    let difference =
      this.state.defaultDrawing.objects.length > 0
        ? alldrawings.filter(
            (d) =>
              !this.state.defaultDrawing.objects.some(
                (i) => JSON.stringify(i) === JSON.stringify(d)
              )
          )
        : alldrawings;

    let comment = {
      message: this.state.commentMessage,
      pointId: JSON.stringify(difference),
    };
    this.setState({ defaultDrawing: this.sketch.toJSON().objects.slice() });
    this.props.addComment(
      this.state.project.projectId,
      this.state.productId,
      this.state.imageId,
      comment
    );

    document.getElementById("comment").value = "";
    //this.hideToolPanel()

    setTimeout(() => {
      this.props.getImageOverview(
        this.state.project.projectId,
        this.props.match.params.productId,
        this.props.match.params.imageId
      );
    }, 1000);
    //TODO: optimisation de l'actualisation des commentaires
  }

  hideToolPanel() {
    this.setState({ commentPanelVisibility: "none" });
  }

  validateComment(commentid) {
    this.props.validateComment(this.state.project.projectId, commentid);
  }

  createSketchField = (value) => {
    if (this.sketchBackgroundRef.current) {
      let sketchWidth = this.sketchBackgroundRef.current.offsetWidth;
      let sketchHeight = this.sketchBackgroundRef.current.offsetHeight;

      return (
        <SketchField
          name="sketch"
          ref={(c) => (this.sketch = c)}
          width={sketchWidth}
          height={sketchHeight}
          tool={this.state.selectedTool}
          lineWidth={3}
          defaultValue={value}
          value={value}
          lineColor={this.state.selectedColor}
        />
      );
    }
  };

  render() {
    return (
      <div>
        <Header  projectName={this.state.project.title} projectId={this.state.project.projectId}/>
        {this.state.isFetched ? (
          <div className="p-grid" style={{ height: "90vh" }}>
            <ScrollPanel
              className="p-xl-9 p-lg-9 p-md-12"
              style={{ height: "90vh" }}
            >
              <div
                className="secondary-bg-color p-d-flex p-jc-center img-drawing-container"
                ref={(c) => (this.sketchContainer = c)}
              >
                <div
                  className="p-d-flex p-dir-col p-ai-start secondary-bg-color"
                  style={{
                    position: "fixed",
                    top: "150px",
                    left: "20px",
                    zIndex: "10",
                    border: "solid 2px #181818",
                    borderRadius: "5px",
                    backgroundColor: "#181818",
                  }}
                >
                  <ColorPicker
                    value={this.state.selectedColor}
                    onChange={(e) => this.setSelectedColor(e.value)}
                    className="p-button-icon-only p-button-link p-button p-d-flex"
                  />

                  {this.state.availableTools.map((item, index) => {
                    return (
                      <Button
                        onClick={() => this.setSelectedTool(item.tool)}
                        className="p-button-icon-only p-button-link"
                      >
                        <i className={`${item.icon} icon-2x`} />
                      </Button>
                    );
                  })}
                  <Button
                    onClick={() => this.setRemoveTool()}
                    className="p-button-icon-only p-button-link"
                  >
                    <i className={"icon-Mobile_2Reject icon-2x"} />
                  </Button>
                </div>

                <div
                  ref={this.sketchBackgroundRef}
                  className="p-col p-d-flex p-jc-center p-ai-center img-drawing-box"
                  onKeyDown={(e) => this.setKeyDown(e)}
                  tabIndex="0"
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${this.state.imageUrl})`,
                    height: "calc(100vh - 140px)",
                  }}
                >
                  {this.createSketchField(this.state.defaultDrawing)}
                </div>
                
              </div>
            </ScrollPanel>
            <div className="p-xl-3 p-lg-3 p-md-12">
              <div
                className="p-fluid"
                style={{ display: this.state.commentPanelVisibility }}
              >
                <Panel className="p-col rounded-content">
                  <form className="p-field">
                    <span className="p-float-label">
                      <InputTextarea
                        id="comment"
                        placeholder={this.props.t(
                          "session.commentScreen.yourComment"
                        )}
                        onChange={(event) =>
                          this.setState({
                            commentMessage: event.target.value,
                          })
                        }
                      />
                    </span>
                  </form>
                  <Button
                    className="p-button-rounded"
                    label={this.props.t("common.add")}
                    onClick={() => {
                      this.addComment();
                    }}
                  />
                </Panel>
              </div>
              <div className="p-d-flex p-jc-center panelComment p-dir-col p-col">
                <div className="p-d-flex p-jc-between p-ai-end p-col">
                  <h5>{this.props.t("session.commentScreen.title")}</h5>
                  <ToggleButton
                    onChange={(e) =>
                      this.updateCommentList(null, e.target.value)
                    }
                    checked={this.state.displayedComments.length > 0}
                    onIcon="icon-visibility icon-3x"
                    offIcon="icon-visiblity_off icon-3x"
                    className="visibility-button"
                    onLabel=""
                    offLabel=""
                  />
                </div>
                <ScrollPanel className="p-col-12 commentContainer">
                  <div style={{ height: "58vh" }}>
                    {this.state.comments &&
                      this.state.comments.length > 0 &&
                      this.state.comments.map((item, index) => {
                        return (
                          <Panel className="p-col rounded-content">
                            <div className="p-d-flex p-jc-between comment-box">
                              <div className="p-d-flex p-flex-row p-ai-center">
                                <span className="p-badge p-mr-3">
                                  {item.authorName && item.authorName[0]}
                                </span>
                                <span className="greyColor">
                                  {item.authorName}
                                </span>
                              </div>
                              <span className="p-as-end">
                                <ToggleButton
                                  onChange={(e) =>
                                    this.updateCommentList(item, e.target.value)
                                  }
                                  checked={this.state.displayedComments.includes(
                                    item
                                  )}
                                  onIcon="icon-visibility icon-3x"
                                  offIcon="icon-visiblity_off icon-3x"
                                  className="visibility-button"
                                  onLabel=""
                                  offLabel=""
                                />
                              </span>
                            </div>
                            <div className="p-d-flex">
                              <div className="p-mr-3 p-ml-3">
                                <Checkbox
                                  onChange={() => this.validateComment(item.id)}
                                  checked={item.resolved}
                                />
                              </div>
                              <div className="p-mr-10">{item.comment}</div>
                            </div>
                          </Panel>
                        );
                      })}
                  </div>
                </ScrollPanel>
              </div>
            </div>
          </div>
        ) : this.state.loading ? (
          <div
            className="p-d-flex p-flex-column p-jc-center p-ai-center"
            style={{ height: "90vh" }}
          >
            <ProgressSpinner className="p-progress-spinner" />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { image, isFetched, comments, isValidate } = state.sessiondetails;

  return { image, isFetched, comments, isValidate };
};

const mapDispatchToProps = {
  getImageOverview: sessiondetailsActions.getImageOverview,
  addComment: sessiondetailsActions.addComment,
  validateImg: productsActions.validateImg,
  validateComment: sessiondetailsActions.validateComment,
};

const connectedSessionComments = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SessionComments));
export { connectedSessionComments as SessionComments };
