export const placeConstants = {
    PLACES_ADD_REQUEST: 'PLACES_ADD_REQUEST',
    PLACES_ADD_SUCCESS: 'PLACES_ADD_SUCCESS',
    PLACES_ADD_FAILURE: 'PLACES_ADD_FAILURE',

    GET_PLACES_REQUEST: 'GET_PLACES_REQUEST',
    GET_PLACES_SUCCESS: 'GET_PLACES_SUCCESS',
    GET_PLACES_FAILURE: 'GET_PLACES_FAILURE',

    DELETE_PLACES_REQUEST: 'DELETE_PLACES_REQUEST',
    DELETE_PLACES_SUCCESS: 'DELETE_PLACES_SUCCESS',
    DELETE_PLACES_FAILURE: 'DELETE_PLACES_FAILURE',

    UPDATE_PLACES_REQUEST: 'UPDATE_PLACES_REQUEST',
    UPDATE_PLACES_SUCCESS: 'UPDATE_PLACES_SUCCESS',
    UPDATE_PLACES_FAILURE: 'UPDATE_PLACES_FAILURE',

    VALIDATE_PLACES_REQUEST: 'VALIDATE_PLACES_REQUEST',
    VALIDATE_PLACES_SUCCESS: 'VALIDATE_PLACES_SUCCESS',
    VALIDATE_PLACES_FAILURE: 'VALIDATE_PLACES_FAILURE',

    REJECT_PLACES_REQUEST: 'REJECT_PLACES_REQUEST',
    REJECT_PLACES_SUCCESS: 'REJECT_PLACES_SUCCESS',
    REJECT_PLACES_FAILURE: 'REJECT_PLACES_FAILURE',

    DRAFT_PLACES_REQUEST: 'DRAFT_PLACES_REQUEST',
    DRAFT_PLACES_SUCCESS: 'DRAFT_PLACES_SUCCESS',
    DRAFT_PLACES_FAILURE: 'DRAFT_PLACES_FAILURE',

    DELETE_ALL_PLACES_REQUEST: 'DELETE_ALL_PLACES_REQUEST',
    DELETE_ALL_PLACES_SUCCESS: 'DELETE_ALL_PLACES_SUCCESS',
    DELETE_ALL_PLACES_FAILURE: 'DELETE_ALL_PLACES_FAILURE'
}
