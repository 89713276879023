import React, {Component} from 'react';
import {Tree} from 'primereact/tree'

class ProductTreeview extends Component {

	constructor( props ) {
		super(props)
		this.state = {
			treeview: [],
			expandedRefs: {},
			selectedPath: ''
		}

		this.selectProductByRef = this.selectProductByRef.bind(this);
	}

	componentDidMount() {
		if (JSON.parse(sessionStorage.getItem("expandedRefs"))) {
			const json = sessionStorage.getItem('expandedRefs')
			const expandedRefs = JSON.parse(json)
			this.setState({expandedRefs})
		}
	}

	static toTreeData( tree, parentkey = '' ) {
		let that = this

		return Object.keys(tree).map(title => {
			let key = parentkey + title;

			var o = {label: title, icon: 'icon-File_Folder icon-2x', key: key}

			if (Object.keys(tree[title]).length > 0) {
				o.children = that.toTreeData(tree[title], key + '/')
				o.selectable = o.children.length > 0
				o.leaf = !o.children.length > 0
			}

			return o
		})
	}

	static createTreeView( filePaths ) {
		var tree = {}
		filePaths.forEach(function ( path ) {
			var currentNode = tree
			path.storagePath.split('/').forEach(segment => {
				if (currentNode[segment] === undefined) {
					currentNode[segment] = {}
				}
				currentNode = currentNode[segment]
			})
		})
		return ProductTreeview.toTreeData(tree)
	}

	static getDerivedStateFromProps( props, state ) {
		const json = JSON.stringify(state.expandedRefs)
		sessionStorage.setItem('expandedRefs', json)
		if (state.treeview.length > 0 && state.selectedPath && Object.keys(state.expandedRefs).length === 0) {
			let parentKey
			let mediaSelected
			if (state.selectedPath && state.selectedPath.storagePath) {
				mediaSelected = state.selectedPath.storagePath.split('/')
			} else {
				mediaSelected = state.selectedPath.split('/')
			}
			mediaSelected.map(title => {
				let key = parentKey ? parentKey + title : title;
				state.expandedRefs[key] = true;
				parentKey = key + '/'
			})
		}

		return {
			treeview: ProductTreeview.createTreeView(props.paths, props.selectedPath),
			selectedPath: props.selectedPath
		}
	}

	selectProductByRef( e ) {
		this.props.selectPath(e.node.key);
	}

	render() {
		return (
			<>
				<Tree
					selectionMode="single"
					value={this.state.treeview}
					onSelect={ e => this.selectProductByRef(e)}
					onToggle={ e => this.setState({expandedRefs: e.value})}
					expandedKeys={this.state.expandedRefs}
				/>
			</>
		);
	}
}

export default ProductTreeview;
