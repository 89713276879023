import React, { Component } from 'react'
import { SessionDetailsMenu, SessionHeader } from './SessionDetailsMenu'
import { connect } from 'react-redux'
import { sessiondetailsActions } from '../../redux/actions'
import { Rating } from 'primereact/rating'
import moment from 'moment'
import 'moment/locale/fr'

class SessionMeta extends Component {
    constructor (props) {
        super(props)
        this.state = {
            image: null,
            imageId: '',
            productId: 0,
            project: JSON.parse(localStorage.getItem('project')),
            couleur: [
                { code: 'black', hxd: '#181818' },
                { code: 'red', hxd: '#ff2905' },
                { code: 'orange', hxd: '#f58f17' },
                { code: 'yellow', hxd: '#f5c200' },
                { code: 'green', hxd: '#23ae2b' },
                { code: 'blue', hxd: '#1c7bf5' },
                { code: 'pink', hxd: '#ff1493' },
                { code: 'purple', hxd: '#a455cc' }
            ]
        }
    }

    componentDidMount () {
        if (this.props.match.params.productId && this.props.match.params.imageId) {
            this.setState({ imageId: this.props.match.params.imageId, productId: this.props.match.params.productId })
            this.props.getImageOverview(this.state.project.projectId, this.props.match.params.productId, this.props.match.params.imageId)
        }
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps && nextProps.image)
            this.setState({ image: nextProps.image })
    }

    render () {
        let { image } = this.state
        let rate
        let couleur
        if (image !== null && image.metasData) {
            let rates = image.metasData.map(data => { if (data.name === 'Rating') { return parseInt(data.value) } })
            let couleurs = this.state.image.metasData.map(item => {
                let colour
                if (item.name === 'Label') {
                    console.log('item.value', item.value)
                    this.state.couleur.map(color => {
                        if (color.code === item.value) {
                            return colour = color.hxd
                        }
                    })
                }

                return colour
            })

            let str = rates.join()
            var n = str.search(',')
            if (n === 0) {
                rate = str.slice(-1)
            } else {
                rate = str.slice(0, n)
            }

            let strr = couleurs.join()
            let x = strr.search(',')
            if (x === 0) {
                couleur = strr.slice(1)
            } else {
                couleur = strr.slice(0, x)
            }

        }

        return (
            <>
                {this.state.image && this.state.image.url &&
                <div className="p-dir-col p-m-0" style={{height: "calc(100vh - 80px)"}}>
                    <SessionHeader projectId={this.state.project.projectId} title={this.state.image.productName}/>
                    <div className="p-d-flex p-jc-between">
                        <div className="p-mr-3 p-col-6 p-px-6 p-py-6">
                            <div>
                                <h5><i className="icon-Flag_2Active"/> {image.productName}</h5>
                                <p>Projet : {image.projectName}</p>
                                <p>Création : {moment(image.created).format('LLLL')}</p>
                            </div>
                            <div className='p-mt-6'>
                                <div className="p-d-flex p-p-3 p-ai-start"
                                     style={{ backgroundColor: '#252525', borderRadius: '10px', width: '100%' }}>
                                    <div className='p-mr-2'>
                                        <p className="p-text-nowrap" style={{ color: '#aeaeae', fontSize: '12px' }}>Data
                                            : </p>
                                    </div>
                                    <div className='p-ml-3'>
                                        <p>Couleur : {couleur === '#181818' ?
                                            <div className='p-my-1' style={{
                                                background: couleur,
                                                border: '1px solid white',
                                                width: '24px',
                                                height: '16px',
                                                borderRadius: '20px'
                                            }}/>
                                            :
                                            <div className='p-my-1' style={{
                                                background: couleur,
                                                width: '24px',
                                                height: '16px',
                                                borderRadius: '20px'
                                            }}/>}
                                        </p>
                                        <p>Note : <Rating value={rate} readOnly stars={5} cancel={false}/></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="p-px-2 p-col-6 p-d-flex p-jc-center">
                            <img src={process.env.REACT_APP_BASE_URL + this.state.image.url}
                                 alt={this.state.image.productName}
                                 style={{objectFit: 'cover', maxHeight: 'calc(100vh - 110px)', maxWidth:'100%', height:'auto'}}/>

                        </div>
                    </div>

                </div>
                }
                <SessionDetailsMenu projectId={this.state.project.projectId}
                                    productId={this.state.productId}
                                    imageId={this.state.imageId}/>
            </>
        )
    }

}

const mapStateToProps = state => {
    const { image, isFetched } = state.sessiondetails
    return { image, isFetched }
}

const mapDispatchToProps = {
    getImageOverview: sessiondetailsActions.getImageOverview
}

const connectedSessionMeta = connect(mapStateToProps, mapDispatchToProps)(SessionMeta)
export { connectedSessionMeta as SessionMeta }


