import { placeConstants } from '../constants';
import { placeService } from '../../services';
import { alertActions } from './';


export const placeAction = {
    addPlaces,
    getAllPlaces,
    deletePlace,
    updatePlace,
    validatePlace,
    rejectPlace,
    draftPlace,
    deleteAllPlace
};

function addPlaces(projectId, places){
    return dispatch => {
        dispatch(request(places));
        placeService.placeAdd(projectId, places)
            .then(
                places => {
                    dispatch(success(places))
                    dispatch(alertActions.success('Lieux ajouté avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(places) { return { type: placeConstants.PLACES_ADD_REQUEST, places } }
    function success(places) { return { type: placeConstants.PLACES_ADD_SUCCESS, places } }
    function failure(error) { return { type: placeConstants.PLACES_ADD_FAILURE, error } }

};

function getAllPlaces(projectId) {
    return dispatch => {
        dispatch(request());
        placeService.getPlace(projectId)
            .then(
                places => dispatch(success(places)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: placeConstants.GET_PLACES_REQUEST } }
    function success(places) { return { type: placeConstants.GET_PLACES_SUCCESS, places } }
    function failure(error) { return { type: placeConstants.GET_PLACES_FAILURE, error } }
}

function deletePlace(projectId, id){
    return dispatch => {
        dispatch(request(id))

        placeService.deletePlace(projectId, id)
        .then(
            places => {
                dispatch(success(id))
                dispatch(alertActions.success('Lieux supprimé avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: placeConstants.DELETE_PLACES_REQUEST, id}}
    function success(id) {return {type: placeConstants.DELETE_PLACES_SUCCESS, id}}
    function failure(id, error) {return {type: placeConstants.DELETE_PLACES_FAILURE, id, error}}
}

function updatePlace(projectId, id, place){
    return dispatch => {
        dispatch(request({ place }));
        placeService.updatePlace(projectId, id, place)
        .then(
            places => {
                dispatch(success(place));
                dispatch(alertActions.success('Lieux modifié avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(place) {return {type: placeConstants.UPDATE_PLACES_REQUEST, place}}
    function success(place) {return {type: placeConstants.UPDATE_PLACES_SUCCESS, place}}
    function failure(error) {return {type: placeConstants.UPDATE_PLACES_FAILURE, error}}
}

function validatePlace(projectId, id){
    return dispatch => {
        dispatch(request(id))
        placeService.validatePlace(projectId, id)
        .then(
            place => {
                dispatch(success(id))
                dispatch(alertActions.success('Lieux validé avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: placeConstants.VALIDATE_PLACES_REQUEST, id}}
    function success(id) {return {type: placeConstants.VALIDATE_PLACES_SUCCESS, id}}
    function failure(id, error) {return {type: placeConstants.VALIDATE_PLACES_FAILURE, id, error}}
}

function rejectPlace(projectId, id){
    return dispatch => {
        dispatch(request(id))
        placeService.rejectPlace(projectId, id)
        .then(
            place => {
                dispatch(success(id))
                dispatch(alertActions.success('Lieux rejeté avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: placeConstants.REJECT_PLACES_REQUEST, id}}
    function success(id) {return {type: placeConstants.REJECT_PLACES_SUCCESS, id}}
    function failure(id, error) {return {type: placeConstants.REJECT_PLACES_FAILURE, id, error}}
}

function draftPlace(projectId, id){
    return dispatch => {
        dispatch(request(id))
        placeService.draftPlace(projectId, id)
        .then(
            place => {
                dispatch(success(id))
                dispatch(alertActions.success('Lieux modifiable'));

            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: placeConstants.DRAFT_PLACES_REQUEST, id}}
    function success(id) {return {type: placeConstants.DRAFT_PLACES_SUCCESS, id}}
    function failure(id, error) {return {type: placeConstants.DRAFT_PLACES_FAILURE, id, error}}
}

function deleteAllPlace(projectId, categorieId){
    return dispatch => {
        dispatch(request(categorieId))

        placeService.deleteAllPlace(projectId, categorieId)
            .then(
                places => {
                    dispatch(success(categorieId))
                    dispatch(alertActions.success('Tous les lieux ont été supprimés avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(categorieId) {return {type: placeConstants.DELETE_ALL_PLACES_REQUEST, categorieId}}
    function success(categorieId) {return {type: placeConstants.DELETE_ALL_PLACES_SUCCESS, categorieId}}
    function failure(categorieId, error) {return {type: placeConstants.DELETE_ALL_PLACES_FAILURE, categorieId, error}}
}
