export const productsConstants = {
    PRODUCTS_TYPE_REQUEST: 'PRODUCTS_TYPE_REQUEST',
    PRODUCTS_TYPE_SUCCESS: 'PRODUCTS_TYPE_SUCCESS',
    PRODUCTS_TYPE_FAILURE: 'PRODUCTS_TYPE_FAILURE',
    PRODUCTS_IMAGES_TYPE_REQUEST: 'PRODUCTS_IMAGES_TYPE_REQUEST',
    PRODUCTS_IMAGES_TYPE_SUCCESS: 'PRODUCTS_IMAGES_TYPE_SUCCESS',
    PRODUCTS_IMAGES_TYPE_FAILURE: 'PRODUCTS_IMAGES_TYPE_FAILURE',
    IMAGES_SUBMIT_TYPE_REQUEST: 'IMAGES_SUBMIT_TYPE_REQUEST',
    IMAGES_SUBMIT_TYPE_SUCCESS: 'IMAGES_SUBMIT_TYPE_SUCCESS',
    IMAGES_SUBMIT_TYPE_FAILURE: 'IMAGES_SUBMIT_TYPE_FAILURE',
    CHECK_IMAGES: 'CHECK_IMAGES_REQUEST',
    SUCCESS_CHECK_IMAGES: 'SUCCESS_CHECK_IMAGES',
    FAILURE_CHECK_IMAGES: 'FAILURE_CHECK_IMAGES',
    GET_REFERENCE_REQUEST: 'GET_REFERENCE_REQUEST',
    GET_REFERENCE_SUCCESS: 'GET_REFERENCE_SUCCESS',
    GET_REFERENCE_FAILURE: 'GET_REFERENCE_FAILURE',
    SET_COMMENT_REQUEST: 'SET_COMMENT_REQUEST',
    SET_COMMENT_SUCCESS: 'SET_COMMENT_SUCCESS',
    SET_COMMENT_FAILURE: 'SET_COMMENT_FAILURE',
    SET_COMMENT_REFERENCE_REQUEST: 'SET_COMMENT_REFERENCE_REQUEST',
    SET_COMMENT_REFERENCE_SUCCESS: 'SET_COMMENT_REFERENCE_SUCCESS',
    SET_COMMENT_REFERENCE_FAILURE: 'SET_COMMENT_REFERENCE_FAILURE',
    SET_COMMENT_PROJECT_REQUEST: 'SET_COMMENT_PROJECT_REQUEST',
    SET_COMMENT_PROJECT_SUCCESS: 'SET_COMMENT_PROJECT_SUCCESS',
    SET_COMMENT_PROJECT_FAILURE: 'SET_COMMENT_PROJECT_FAILURE',
    GET_COMMENTS_REQUEST: 'GET_COMMENTS_REQUEST',
    GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
    GET_COMMENTS_FAILURE: 'GET_COMMENTS_FAILURE',
    GET_PROJECT_HISTORY_REQUEST: 'GET_PROJECT_HISTORY_REQUEST',
    GET_PROJECT_HISTORY_SUCCESS: 'GET_PROJECT_HISTORY_SUCCESS',
    GET_PROJECT_HISTORY_FAILURE: 'GET_PROJECT_HISTORY_FAILURE',
    SET_URGENT_REQUEST: 'SET_URGENT_REQUEST',
    SET_URGENT_SUCCESS: 'SET_URGENT_SUCCESS',
    SET_URGENT_FAILURE: 'SET_URGENT_FAILURE',
    SET_PROJECT_VERSION_REQUEST: 'SET_PROJECT_VERSION_REQUEST',
    SET_PROJECT_VERSION_SUCCESS: 'SET_PROJECT_VERSION_SUCCESS',
    SET_PROJECT_VERSION_FAILURE: 'SET_PROJECT_VERSION_FAILURE',
    SET_PRODUCT_ORDER_REQUEST: 'SET_PRODUCT_ORDER_REQUEST',
    SET_PRODUCT_ORDER_SUCCESS: 'SET_PRODUCT_ORDER_SUCCESS',
    SET_PRODUCT_ORDER_FAILURE: 'SET_PRODUCT_ORDER_FAILURE',
    ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
    ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
    GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',
    ALL_IMAGES_SUBMIT_TYPE_REQUEST: 'ALL_IMAGES_SUBMIT_TYPE_REQUEST',
    ALL_IMAGES_SUBMIT_TYPE_SUCCESS: 'ALL_IMAGES_SUBMIT_TYPE_SUCCESS',
    ALL_IMAGES_SUBMIT_TYPE_FAILURE: 'ALL_IMAGES_SUBMIT_TYPE_FAILURE',
    GET_PRODUCT_IMAGES_REQUEST: 'GET_PRODUCT_IMAGES_REQUEST',
    GET_PRODUCT_IMAGES_SUCCESS: 'GET_PRODUCT_IMAGES_SUCCESS',
    GET_PRODUCT_IMAGES_FAILURE: 'GET_PRODUCT_IMAGES_FAILURE',
    SELECT_IMAGES_PRODUCT_REQUEST: 'SELECT_IMAGES_PRODUCT_REQUEST',
    SELECT_IMAGES_PRODUCT_SUCCESS: 'SELECT_IMAGES_PRODUCT_SUCCESS',
    SELECT_IMAGES_PRODUCT_FAILURE: 'SELECT_IMAGES_PRODUCT_FAILURE',
    UNSELECT_IMAGES_PRODUCT_REQUEST: 'UNSELECT_IMAGES_PRODUCT_REQUEST',
    UNSELECT_IMAGES_PRODUCT_SUCCESS: 'UNSELECT_IMAGES_PRODUCT_SUCCESS',
    UNSELECT_IMAGES_PRODUCT_FAILURE: 'UNSELECT_IMAGES_PRODUCT_FAILURE',
    DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
	CHANGE_LABEL_RATE_REQUEST: 'CHANGE_LABEL_RATE_REQUEST',
    CHANGE_LABEL_RATE_SUCCESS: 'CHANGE_LABEL_RATE_SUCCESS',
    CHANGE_LABEL_RATE_FAILURE: 'CHANGE_LABEL_RATE_FAILURE',
    VALIDATE_IMAGES_PRODUCT_REQUEST: 'VALIDATE_IMAGES_PRODUCT_REQUEST',
    VALIDATE_IMAGES_PRODUCT_SUCCESS: 'VALIDATE_IMAGES_PRODUCT_SUCCESS',
    VALIDATE_IMAGES_PRODUCT_FAILURE: 'VALIDATE_IMAGES_PRODUCT_FAILURE',
    REJECT_IMAGES_PRODUCT_REQUEST: 'REJECT_IMAGES_PRODUCT_REQUEST',
    REJECT_IMAGES_PRODUCT_SUCCESS: 'REJECT_IMAGES_PRODUCT_SUCCESS',
    REJECT_IMAGES_PRODUCT_FAILURE: 'REJECT_IMAGES_PRODUCT_FAILURE',
    ZIP_ADD_REQUEST: 'ZIP_ADD_REQUEST',
    ZIP_ADD_SUCCESS: 'ZIP_ADD_SUCCESS',
    ZIP_ADD_FAILURE: 'ZIP_ADD_FAILURE',
    LINK_PRODUCT_REQUEST: 'LINK_PRODUCT_REQUEST',
    LINK_PRODUCT_SUCCESS: 'LINK_PRODUCT_SUCCESS',
    LINK_PRODUCT_FAILURE: 'LINK_PRODUCT_FAILURE',
    GET_LINK_PRODUCT_REQUEST: 'GET_LINK_PRODUCT_REQUEST',
    GET_LINK_PRODUCT_SUCCESS: 'GET_LINK_PRODUCT_SUCCESS',
    GET_LINK_PRODUCT_FAILURE: 'GET_LINK_PRODUCT_FAILURE',
    EXPORT_VALIDATE_IMAGE_REQUEST: 'EXPORT_VALIDATE_IMAGE_REQUEST',
    EXPORT_VALIDATE_IMAGE_SUCCESS: 'EXPORT_VALIDATE_IMAGE_SUCCESS',
    EXPORT_VALIDATE_IMAGE_FAILURE: 'EXPORT_VALIDATE_IMAGE_FAILURE',
    EXPORT_MERCH_REQUEST: 'EXPORT_MERCH_REQUEST',
    EXPORT_MERCH_SUCCESS: 'EXPORT_MERCH_SUCCESS',
    EXPORT_MERCH_FAILURE: 'EXPORT_MERCH_FAILURE',
    GET_SYNC_DROPBOX_REQUEST: 'GET_SYNC_DROPBOX_REQUEST',
    GET_SYNC_DROPBOX_SUCCESS: 'GET_SYNC_DROPBOX_SUCCESS',
    GET_SYNC_DROPBOX_FAILURE: 'GET_SYNC_DROPBOX_FAILURE',
    GET_FOLDERS_REQUEST: 'GET_FOLDERS_REQUEST',
    GET_FOLDERS_SUCCESS: 'GET_FOLDERS_SUCCESS',
    GET_FOLDERS_FAILURE: 'GET_FOLDERS_FAILURE',
  };
