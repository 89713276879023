import React, {Component} from 'react';
import {BreadCrumb} from 'primereact/breadcrumb';

class SessionBreadcrumb extends Component {
	constructor( props ) {
		super(props)
		this.state = {
			items: [],
		}
	}

	buildBreadcrumb( path ) {
		let itemsTitles
		if (path && path.storagePath) {
			itemsTitles = path.storagePath.split('/');
		} else {
			itemsTitles = path.split('/');
		}
		let items = itemsTitles.map(( title, index ) => {
			return {
				label: title,
				command: () => this.loadPath(path.substring(0, path.indexOf(title) + title.length))
			}
		});

		return items;
	}

	loadPath( target ) {
		this.props.selectPath(target);

	}

	render() {
		let items = this.buildBreadcrumb(this.props.path);
		return (
			<>
				{items &&
				<BreadCrumb model={items} className="session-breadcrumb"/>
				}
			</>
		);
	}
}

export default SessionBreadcrumb;
