import React, {Component} from 'react'
import {projectActions} from '../../../redux/actions'
import moment from 'moment';
import {connect} from 'react-redux'
import {Steps} from 'primereact/steps'
import {Toast} from 'primereact/toast'
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {history} from '../../../redux/helpers';
import Dropzone from 'react-dropzone';
import Acrobat from '../../../img/acrobat.svg'
import './createProjectScreen.scss'
import {CreateProjectMember} from './createProjectMember'
import {specialChar} from '../../common/Regex'
import {Dropdown} from 'primereact/dropdown';
import { withTranslation } from 'react-i18next';

class CreateProjectScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
          activeIndex: 0,
          showStepOne: true,
          showStepTwo: false,
          project: {
            title: '',
            startShootingDate: null,
            shootingType: 0,
            uploadDocument: []
          },
          file: [],
          errors: {},
          uploads: '',
          members: [
              {email: "", role: this.props.t('createProject.role.photographer')},
              {email: "", role: this.props.t('createProject.role.photographer')},
              {email: "", role: this.props.t('createProject.role.photographer')},
              {email: "", role: this.props.t('createProject.role.photographer')},
            ],
            types :[],
            loading: false,
            errorInput: false,
            t: this.props.t
        }

      }

    componentDidMount = () => {
        console.log('ici')
        this.props.shootingTypes();
    }

    handleDateChange = date => {
        this.setState({
          project: { ...this.state.project, startShootingDate: date }
        })
      }

    onChange = ( e ) => {
        this.setState({project: {...this.state.project, shootingType: parseInt(e.value)}})
    }

    handleChange = ( e ) => {
        let value = e.target.value
        if (specialChar.test(value)) {
            this.setState({errorInput: true})
        } else {
            this.setState({project: {...this.state.project, title: value.trim()}, errorInput: false})
        }
    }

    static getDerivedStateFromProps( props, state ) {
        if (props.project.isCreated && props.project.project) {
            return {
                project: props.project.project,
                activeIndex: 1
            }
        }
        if (props.project.isCreating) {
            return {loading: true}
        }
        if (props.project.isFetched) {
            return {types: props.project.types}
        }
    }

    onDrop = (file) => {
    let errors = this.state.errors
    if (file.length > 0) {
        errors['uploadDocument'] = "";
        let doc = [];
        let reader = new FileReader();
        reader.onload = (file => {
        return () => {
            // let data = reader.result;
            doc.push({ name: file.name, document: file });
            this.setState({
                project: {
                    ...this.state.project,
                    uploadDocument: doc
                }
            })
        }
        })(file[0]);
        reader.readAsDataURL(file[0]);
    }
    else {
        errors['uploadDocument'] = this.state.t('common.pdfAllowed');
    }
    this.setState({ errors: errors })
    };

    handleSubmitProject = (e) => {
        this.props.stepOne(this.state.project)
    }

    render() {
        const toast = React.createRef()
        const items = [
            {
                label: this.state.t('createProject.project'),
                command: (event) => {
                    toast.current.show({ severity: 'info', summary: this.state.t('createProject.project'), detail: event.item.label });
                }
            },
            {
                label: this.state.t('createProject.members'),
                command: (event) => {
                    toast.current.show({ severity: 'info', summary: this.state.t('createProject.members'), detail: event.item.label });
                }
            }
        ]
        let today = new Date()
        return (
            <div className="p-d-flex p-flex-column p-ai-center p-p-6">
                <Toast ref={toast}></Toast>
                <Steps className="p-steps-item steps" model={items} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({activeIndex: e.index})} />
                <hr className="step-bar"/>
                {this.state.activeIndex === 0 ?
                <div className="p-mt-6 p-p-6 p-d-flex p-flex-column p-jc-around stepOneContainer" >
                    <h4 className="p-text-center">{this.state.t('createProject.project')}</h4>
                    <div>
                        <InputText  type="text" className="stepOneChild" style={this.state.errorInput ? {border: '1px solid #f44435'} : {border: 'none'}} placeholder={this.state.t('createProject.name')} value={this.state.project.title} name="title" onChange={this.handleChange} required />
                        {this.state.errorInput ? <small className="p-error">{this.state.t('createProject.specialChar')}</small> : <></>}
                    </div>
                    <Calendar className="stepOneChild" placeholder={this.state.t('createProject.date')} value={this.state.project.startShootingDate} minDate={today} onChange={(e) => this.handleDateChange(moment(e.value).format())} required />
                    <Dropdown className="shootingTypeSelect p-p-0" value={this.state.project.shootingType} options={this.state.types} optionLabel="name" optionValue="value" onChange={this.onChange} required />
                    <div className="dropzone p-d-flex p-flex-column p-jc-center p-ai-center">
                        <Dropzone onDrop={this.onDrop} multiple={false} accept=".pdf" required>
                            {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p className="p-d-flex p-flex-row p-ai-center"><span><img src={Acrobat} alt="acrobat" className="p-mr-5"></img></span>{this.state.t('createProject.file')}</p>
                                </div>
                            </section>
                            )}
                        </Dropzone>
                        {((this.state.project.uploadDocument.length > 0)) &&
                        <ul className="list-unstyled">
                            <li>
                                {this.state.project.uploadDocument[0].name}
                            </li>
                        </ul>
                    }
                    </div>
                    <div className="p-d-flex p-flex-row p-jc-around p-pt-6 p-pl-6 p-pr-6 p-pb-2 footer">
                        <Button label={this.state.t('common.cancel')} onClick={() => history.push(`/`)}/>
                        <Button loading={this.state.loading} onClick={this.handleSubmitProject} label={this.state.t('createProject.create')} type="submit" />
                    </div>
                </div>
                :<CreateProjectMember project={this.props.project} />}
            </div>
        )
    }
}

function mapState(state) {
    const {project} = state;
    return {project}
  }

  const actionCreators = {
    shootingTypes: projectActions.shootingTypes,
    stepOne: projectActions.stepOne
  };

const connectedProject = (connect(mapState, actionCreators)(withTranslation()(CreateProjectScreen)));
export { connectedProject as CreateProjectScreen };
