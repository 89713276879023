import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "../common/Header";
import {ScrollPanel} from "primereact/scrollpanel";
import {ThreeDotsButton} from "../common/SVGButtons";
import {TieredMenu} from "primereact/tieredmenu";
import {projectActions} from "../../redux/actions/project.actions";

import {Panel} from "primereact/panel";

import {Document, Page, Outline} from "react-pdf";
import {pdfjs} from "react-pdf";

import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Paginator} from "primereact/paginator";

import {groupby} from "../../helpers/arrayHelpers";
import {Checkbox} from "primereact/checkbox";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import moment from "moment";
import {Avatar} from "primereact/avatar";
import {AvatarGroup} from "primereact/avatargroup";
import {OverlayPanel} from "primereact/overlaypanel";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import ReactToPrint, {PrintContextConsumer} from "react-to-print";
import {ProgressSpinner} from "primereact/progressspinner";

class FileDetailsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      project: null,
      comments: [],
      projectId: 0,
      fileId: "",
      pageCount: null,
      pageNumber: 1,
      xPos: 0,
      yPos: 0,
      showCommentPopup: false,
      commentMessage: "",
      members: [],
      isChecked: false,
      showSideBar: false,
    };
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

    this.addComment = this.addComment.bind(this);
    this.changePage = this.changePage.bind(this);
    this.hideCommentPopup = this.hideCommentPopup.bind(this);
    this.showCommentPopup = this.showCommentPopup.bind(this);
    this.formatComments = this.formatComments.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    //  this.validateComment=this.validateComment.bind(this);
    this.PDFdocument = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.projectId && this.props.match.params.fileId) {
      const fileId = this.props.match.params.fileId;
      const projectId = this.props.match.params.projectId;
      this.setState({projectId: projectId, fileId: fileId});
      this.props.getFile(projectId, fileId);
    }
  }

  changePage(currentPage) {
    console.log(currentPage);
    this.setState({pageNumber: currentPage});
  }

  showCommentPopup() {
    this.setState({showCommentPopup: true});
  }
  hideCommentPopup() {
    this.setState({showCommentPopup: false});
  }

  formatComments(comments) {
    this.setState({comments: groupby(comments, "pointId")});

    console.log(groupby(comments, "pointId"));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state !== undefined) {
      if (
        nextProps.location.state.project !== undefined &&
        nextProps.location.state.project.projectId !== this.state.projectId
      ) {
        this.props.getFile(
          nextProps.location.state.project.projectId,
          nextProps.match.params.fileId
        );
        this.setState({
          projectId: nextProps.location.state.project.projectId,
          fileId: nextProps.match.params.fileId,
          project: nextProps.location.state.project,
          file: null,
        });
      }
    }
    console.log("nextProps", nextProps);

    if (nextProps.file) {
      this.setState({file: nextProps.file, loading: false});
      //  this.setState({ comments: nextProps.file.comments });
      this.formatComments(nextProps.file.comments);
    }
    if (nextProps.comments) {
      // this.setState({ comments: nextProps.comments });
      this.formatComments(nextProps.comments);
    }
    if (nextProps.isValidate) {
      this.props.getFile(this.state.projectId, this.state.fileId);
      this.setState({loading: true});
    }
  }

  onDocumentLoadSuccess({numPages}) {
    this.setState({pageCount: numPages});
    console.log("Number of pages", numPages);
  }

  addComment() {
    this.props.addComment(
      this.state.projectId,
      this.state.fileId,
      this.state.commentMessage,
      `Page ${this.state.pageNumber}`
    );
    this.hideCommentPopup();
    setTimeout(() => {
      this.props.getFile(this.state.projectId, this.state.fileId);
    }, 500);
  }

  validateComment(comment) {
    this.props.validateComment(this.state.projectId, comment.id);
  }

  deleteFile(fileId) {
    this.props.deleteDocument(this.state.projectId, fileId);
   }

  downloadFile(fileId) {
    this.props.downloadFile(this.state.projectId, fileId);
   }

  render() {
    let items = [
      {
        label: "Télécharger",
        command: () => this.downloadFile(this.state.fileId),
      },
      {label: "Historique"},
      {label: "Supprimer", command: () => this.deleteFile(this.state.fileId)},
    ];

    let pageList = new Array(this.state.pageCount).fill(this.state.pageCount);

    moment.locale("fr");
    return (
      <div>
        <Header
          projectId={this.state.projectId}
          projectName={this.state.project?.title}
        />
        {this.state.file ? (
          <div className="p-grid p-d-flex">
            {this.state.showSideBar && (
              <div className="p-col-2 p-d-flex p-flex-column p-ai-center p-jc-start">
                <ScrollPanel>
                  <div style={{height: "85vh"}}>
                    <Document
                      ref={el => (this.PDFdocument = el)}
                      file={`data:application/pdf;base64,${this.state.file.thumbnail}`}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                      onItemClick={e => this.onItemClick(e)}
                    >
                      {pageList.map((page, index) => {
                        return (
                          <Page
                            className="p-mb-1 cursor"
                            pageNumber={index + 1}
                            onClick={() =>
                              this.setState({pageNumber: index + 1})
                            }
                          />
                        );
                      })}
                    </Document>
                  </div>
                </ScrollPanel>
              </div>
            )}
            <div className="p-col-8 p-d-flex p-flex-column p-ai-center p-jc-start">
              <div
                style={{width: "90%", marginBottom: "2%"}}
                className="p-d-flex p-jc-between j-ai-start"
              >
                <h5>{this.state.file.fileName}</h5>
              </div>
              <TransformWrapper wheel={false}>
                {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                  <>
                    <TransformComponent>
                      <ScrollPanel>
                        <div style={{height: "85vh"}}>
                          <Document
                            ref={el => (this.PDFdocument = el)}
                            file={`data:application/pdf;base64,${this.state.file.thumbnail}`}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onItemClick={e => this.onItemClick(e)}
                          >
                            <Page
                              className="big-page-size"
                              pageNumber={this.state.pageNumber}
                            />
                          </Document>
                        </div>
                      </ScrollPanel>
                    </TransformComponent>
                    <div className="p-d-flex p-flex-row p-jc-around p-ai-center bottom-panel">
                      <i
                        className="icon-FileBar_Sidebar icon-3x cursor p-mx-3"
                        onClick={() =>
                          this.setState({showSideBar: !this.state.showSideBar})
                        }
                      />
                      <span
                        className="p-d-flex p-flex-row p-jc-between p-ai-center"
                        style={{width: "5vw"}}
                      >
                        {this.state.pageNumber === 1 ? (
                          <i
                            className="icon-Arrow_Open icon-3x cursor dark-bg radius4"
                            style={{transform: "rotate(180deg)"}}
                          />
                        ) : (
                          <i
                            className="icon-Arrow_Open icon-3x cursor dark-bg radius4"
                            style={{transform: "rotate(180deg)"}}
                            onClick={() =>
                              this.setState({
                                pageNumber: this.state.pageNumber - 1,
                              })
                            }
                          />
                        )}
                        <span>
                          {this.state.pageNumber} / {this.state.pageCount}
                        </span>
                        {this.state.pageNumber === this.state.pageCount ? (
                          <i className="icon-Arrow_Open icon-3x cursor dark-bg radius4" />
                        ) : (
                          <i
                            className="icon-Arrow_Open icon-3x cursor dark-bg radius4"
                            onClick={() =>
                              this.setState({
                                pageNumber: this.state.pageNumber + 1,
                              })
                            }
                          />
                        )}
                      </span>
                      <div className="p-d-flex p-flex-row p-jc-around p-ai-center">
                        <div className="p-d-flex p-ai-center p-jc-around file-zoom-panel">
                          <span
                            className="file-zoom-minus"
                            onClick={zoomOut}
                          ></span>
                          <span
                            className="file-zoom-plus"
                            onClick={zoomIn}
                          ></span>
                        </div>
                        <i
                          className="icon-FileBar_Expand icon-3x p-mx-5 cursor"
                          onClick={resetTransform}
                        />
                        <ReactToPrint content={() => this.PDFdocument}>
                          <PrintContextConsumer>
                            {({handlePrint}) => (
                              <i
                                className="icon-FileBar_Print icon-3x p-mx-3 cursor"
                                onClick={handlePrint}
                              />
                            )}
                          </PrintContextConsumer>
                        </ReactToPrint>
                      </div>
                    </div>
                  </>
                )}
              </TransformWrapper>
            </div>
            <div
              className={
                this.state.showSideBar ? "p-col-2" : "p-col-4 p-pl-6 p-10"
              }
            >
              <div className="comment-panel">
                <div className="p-d-flex p-flex-wrap p-jc-between">
                  <div className="col-8">
                    <div style={{color: "#DEDEDE", fontSize: "13px"}}>
                      {this.state.file.fileName}
                    </div>
                    <div className="greyColor" style={{fontSize: "12px"}}>
                      {this.state.file.size} ko
                    </div>
                  </div>
                  <div className="col-4 p-d-flex">
                    <i
                      className="icon-Action_Comment icon-3x dark-bg radius4 p-mr-2"
                      onClick={() => this.showCommentPopup()}
                    />
                    <TieredMenu
                      model={items}
                      popup
                      ref={el => (this.menu = el)}
                    />
                    <i
                      className="icon-Action_Open icon-3x dark-bg radius4"
                      onClick={event => this.menu.toggle(event)}
                    ></i>
                  </div>
                </div>
                <div
                  className="greyColor p-mt-4 p-mb-5"
                  style={{fontSize: "12px"}}
                >
                  <div>
                    Le {moment(this.state.file.created).format("L")} à{" "}
                    {moment(this.state.file.created).format("LT")}
                  </div>
                </div>

                {this.state.file.comments.map(item => {
                  return (
                    <ScrollPanel>
                      <div className="p-d-flex p-flex-row p-ai-center comment-box">
                        <span className="p-badge p-mr-3">
                          {item.authorName && item.authorName[0]}
                        </span>
                        <span className="p-mr-3 greyColor">{item.pointId}</span>
                        <span>{item.comment}</span>
                      </div>
                    </ScrollPanel>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="p-d-flex p-flex-column p-jc-center p-ai-center"
            style={{height: "60vh"}}
          >
            <ProgressSpinner className="p-progress-spinner" />
          </div>
        )}
        <Dialog
          modal={false}
          visible={this.state.showCommentPopup}
          style={{width: "25vw"}}
          onHide={() => this.hideCommentPopup()}
          header={`Ajouter un commentaire a la page ${this.state.pageNumber}`}
        >
          <div className="p-fluid">
            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  id="comment"
                  placeholder="Votre commentaire"
                  onChange={event =>
                    this.setState({
                      commentMessage: event.target.value,
                    })
                  }
                ></InputTextarea>
              </span>
            </div>
            <Button
              className="p-button-rounded comment-dialog-button"
              label="Ajouter"
              onClick={() => this.addComment()}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {file, comments, isValidate} = state.project;
  return {file, comments, isValidate};
};

const mapDispatchToProps = {
  getProject: projectActions.getProject,
  getFile: projectActions.getFile,
  addComment: projectActions.addComment,
  validateComment: projectActions.validateComment,
  deleteDocument: projectActions.deleteDocument,
  downloadFile: projectActions.downloadFile,
};

const connectedFile = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileDetailsScreen);
export {connectedFile as FileDetailsScreen};
