import React, { Component } from 'react';
import { Card } from 'primereact/card';
import { history } from '../../../redux/helpers';
import Moment from 'react-moment';
import LogoMini from '../../../img/mini-logo.svg'
import { LazyImage } from '../../common/LazyImage'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from "primereact/dialog";
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';

class ProjectCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            product: null,
            openProjectDialog: false,
            t: this.props.t
        }
        this.op = React.createRef();
        this.onHide = this.onHide.bind(this);
        this.submitDeleteProject = this.submitDeleteProject.bind(this);
    }
    submitDeleteProject(e) {
        e.stopPropagation();
        this.props.deleteProject(this.props.project.projectId);
    }

    cardClick(e) {
        localStorage.setItem('project', JSON.stringify(this.props.project));
        history.push('/projects/' + this.props.project.projectId);
    }
    onHide() {
        this.setState({
            openProjectDialog: false
        });
    }
    render() {
        const headerImg = <LazyImage imageKey={this.props.project.projectId} src={this.props.project.thumbUrl} alt={this.props.project.title} style={{ maxHeight: '177px', height:'200px' ,objectFit: "cover", objectPosition: 'center top', borderRadius: "10px 10px 0 0" }} />
        const headerLogo = (<div className="p-d-flex p-jc-center p-ai-start default-card-img-container">
            <img src={LogoMini} className="default-card-img p-mt-5" />
        </div>)
        const dialogFooter = (
            <>
                <Button
                    label={this.state.t('common.no')}
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.onHide}
                />
                <Button
                    label={this.state.t('common.yes')}
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.submitDeleteProject}
                />
            </>
        );

        return (
            <>
                <Dialog
                    visible={this.state.openProjectDialog}
                    header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                    modal
                    className="p-fluid"
                    footer={dialogFooter}
                    onHide={this.onHide}
                >
                    <div className="p-grid" >
                        <i
                            className="pi pi-exclamation-triangle p-mr-3 p-col-fixed  p-col-align-center"
                            style={{ fontSize: "3rem" }}
                        />
                        <div className="p-col">
                            <p className="p-text-nowrap">{this.state.t('dashboardScreen.projectCard.warning')}</p>
                            <p className="p-text-nowrap" >{this.state.t('dashboardScreen.projectCard.warningQuestion')}</p>
                        </div>

                    </div>
                </Dialog>
                <div style={{ maxWidth: '200px' }} key={this.props.index} onClick={(e) => this.cardClick(e)} data-tip data-for={this.props.project.needToBeSynchronized ? 'sync' : '' }>
                    <Card className="project-card" style={{position: 'relative'}}
                        header={this.props.project.thumbUrl !== null ? headerImg : headerLogo}>

                        <div className=" p-grid project-title">
                            <div className='p-col-9 p-text-nowrap p-text-truncate'>
                                {this.props.project.title}
                            </div>
                            <div className='p-col-3'>

                                <Button type="button" icon='pi icon-Action_Open icon-2x' className="p-button-text p-p-0" onClick={(e) => { e.stopPropagation(); this.op.current.toggle(e); }}></Button>
                                <OverlayPanel ref={this.op}  >
                                    <div className="p-d-flex p-flex-column">
                                        <Button className="p-button p-button-text" label={this.state.t('common.delete')} onClick={(e) => { e.stopPropagation(); this.setState({ openProjectDialog: true }); }}></Button>
                                    </div>
                                </OverlayPanel>
                            </div>



                        </div>
                        <div className="project-date">
                            <Moment format="DD/MM/YYYY" >{this.props.project.startShootingDate}</Moment>

                        </div>
                        <div className={this.props.project.needToBeSynchronized ? "project-shoot project-shoot-warning p-text-nowrap p-text-truncate" : "project-shoot p-text-nowrap p-text-truncate"}>
                            <span className="project-shoot-value" style={{ width: "2rem" }}>{this.state.t('dashboardScreen.projectCard.shootType') + this.props.project.shootingTypeLabel}</span>
                        </div>
                        {this.props.project.needToBeSynchronized ?
                            <div className="p-m-0 p-px-1" style={{position: 'absolute', bottom: '7px', right: "7px", zIndex: 100}}>
                                <i className="pi pi-exclamation-triangle" style={{color: '#FF6022'}} />
                            </div>
                            :
                            <></>}
                    </Card>
                </div>
                <ReactTooltip id='sync' place="bottom" type="warning" effect="solid" backgroundColor='#FF6022'>
                    <span>{this.state.t('dashboardScreen.projectCard.noSync')}</span>
                </ReactTooltip>


            </>
        );
    }
}

export default withTranslation()(ProjectCard)
