import React, {Component} from "react";
import Header from "../common/Header";
import {Activities} from "./Activities";
import {ProgressStats} from './ProgressStats'
import {ProjectListing} from "../project/listing/ProjectListing";
import {ScrollPanel} from "primereact/scrollpanel";
import {Button} from 'primereact/button';
import {ReactComponent as ScanIcon} from '../../img/scan.svg';
import {ReactComponent as CatalogIcon} from '../../img/catalogue-1.svg';
import {history} from '../../redux/helpers/store';
import {Dialog} from 'primereact/dialog';
import {ScanProducts} from '../catalog/ScanProducts';
import {isTablet} from 'react-device-detect'
import { withTranslation } from 'react-i18next';

class DashboardScreen extends Component {

  constructor(props)
  {
    super(props) ;
    this.state = {
      showScanCatalog:false
    }

    this.catalogButtonClicked = this.catalogButtonClicked.bind(this) ;
    this.showScanCatalog = this.showScanCatalog.bind(this) ;
    this.hideScanCatalog = this.hideScanCatalog.bind(this) ;
  }

  catalogButtonClicked()
  {
    history.push('/catalog')
  }

  showScanCatalog()
  {
    this.setState({showScanCatalog : true})
  }

  hideScanCatalog()
  {
    this.setState({showScanCatalog : false})
  }


  render() {
    const { t } = this.props;
    sessionStorage.clear();
    localStorage.removeItem('project')
    return (
      <div>
        <Header/>
        <ScrollPanel className="sf-scrollpanel">
          <div className="p-d-flex">
            <div className="p-col">
              <h3>{t('dashboardScreen.title')}</h3>
            </div>
          </div>

          <div className="p-d-flex p-mt-6 p-flex-row">
            <div className="p-col-2">
              <Button onClick={()=> this.showScanCatalog()} className="p-d-flex p-flex-column p-jc-center p-ai-center p-button-icon-only" style={{width:"100%", borderRadius: '10px'}}>
                <ScanIcon className="p-mt-2"/>
                <h5 className="project-title">{t('dashboardScreen.scan')}</h5>
              </Button>
            </div>

            <div className="p-col-2">
              <Button className="p-d-flex p-flex-column p-jc-center p-ai-center p-button-icon-only" style={{width:"100%", borderRadius: '10px'}} onClick={() => this.catalogButtonClicked()}>
                <CatalogIcon className="p-mt-2"/>
                <h5 className="project-title">{t('dashboardScreen.catalog')}</h5>
              </Button>
            </div>
          </div>

          <div className="p-d-flex p-mt-6">
            <div className="p-col">
              <h5>{t('dashboardScreen.projects')}</h5>
              <ProjectListing/>
            </div>
          </div>
          <div className="p-d-flex p-mt-6">
            <div className="p-col-4">
              <Activities/>
            </div>
            <div className="p-col-4">
              <ProgressStats/>
            </div>
          </div>
        </ScrollPanel>
        <Dialog
          position='top' header={t('dashboardScreen.scann.title')}
          className="secondary-bg-color"
          visible={this.state.showScanCatalog}
          onHide={this.hideScanCatalog}
          style={isTablet ? { width: '50vw' , marginTop:"50px"} : { width: '30vw' , marginTop:"50px"}}
        >
          <ScanProducts onCancel={this.hideScanCatalog}/>
          </Dialog>
      </div>
    );
  }
}

export default withTranslation()(DashboardScreen);
