import { callsheetService } from '../../services'
import { callsheetConstants } from '../constants'
import {alertActions} from './'

export const callsheetAction = {
    addCallsheet,
    getCallsheet,
    deleteCallsheet,
    updateCallsheet,
    downloadCallsheet
}

function addCallsheet(projectId, callsheet){
    return dispatch => {
        dispatch(request(callsheet));
        callsheetService.addCallsheet(projectId, callsheet)
        .then(
            callsheet => {
                dispatch(success(callsheet))
                dispatch(alertActions.success('Callsheet ajoutée avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(callsheet) {return { type: callsheetConstants.CALLSHEET_ADD_REQUEST, callsheet}}
    function success(callsheet) {return { type: callsheetConstants.CALLSHEET_ADD_SUCCESS, callsheet}}
    function failure(error) {return {type: callsheetConstants.CALLSHEET_ADD_FAILURE, error}}
}

function getCallsheet(projectId){
    return dispatch => {
        dispatch(request());
        callsheetService.getCallsheet(projectId)
        .then(
            callsheet => dispatch(success(callsheet)),
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request() { return { type: callsheetConstants.GET_CALLSHEET_REQUEST } }
    function success(callsheet) { return { type: callsheetConstants.GET_CALLSHEET_SUCCESS, callsheet } }
    function failure(error) { return { type: callsheetConstants.GET_CALLSHEET_FAILURE, error } }
}

function deleteCallsheet(projectId, callsheetItemId){
    return dispatch => {
        dispatch(request(callsheetItemId))

        callsheetService.deleteCallsheet(projectId, callsheetItemId)
        .then(
            callsheet => {
                dispatch(success(callsheetItemId))
                dispatch(alertActions.success('Callsheet supprimée avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(callsheetItemId) {return {type: callsheetConstants.DELETE_CALLSHEET_REQUEST, callsheetItemId}}
    function success(callsheetItemId) {return {type: callsheetConstants.DELETE_CALLSHEET_SUCCESS, callsheetItemId}}
    function failure(callsheetItemId, error) {return {type: callsheetConstants.DELETE_CALLSHEET_FAILURE, callsheetItemId, error}}
}

function updateCallsheet(projectId, callsheetItemId, callsheet){
    return dispatch => {
        dispatch(request({ callsheet }));
        callsheetService.updateCallsheet(projectId, callsheetItemId, callsheet)
        .then(
            callsheets => {
                dispatch(success(callsheets));
                dispatch(alertActions.success('Callsheet modifiée avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(callsheet) {return {type: callsheetConstants.UPDATE_CALLSHEET_REQUEST, callsheet}}
    function success(callsheet) {return {type: callsheetConstants.UPDATE_CALLSHEET_SUCCESS, callsheet}}
    function failure(error) {return {type: callsheetConstants.UPDATE_CALLSHEET_FAILURE, error}}
}

function downloadCallsheet (projectId) {
    return dispatch => {
        dispatch(request())
        callsheetService.downloadCallsheet(projectId)
            .then(
	            callsheets => {
                    dispatch(success(callsheets))
                    dispatch(alertActions.success("Callsheet téléchargée avec succès"))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request () { return { type: callsheetConstants.DOWNLOAD_CALLSHEET_REQUEST } }

    function success (callsheets) { return { type: callsheetConstants.DOWNLOAD_CALLSHEET_SUCCESS, callsheets } }

    function failure (error) { return { type: callsheetConstants.DOWNLOAD_CALLSHEET_FAILURE, error } }
}

