import React, {Component} from 'react';
import {Timeline} from 'primereact/timeline';
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {connect} from 'react-redux';
import {activitiesActions} from '../../redux/actions';
import moment from 'moment'
import 'moment/locale/fr';
import {ProgressSpinner} from 'primereact/progressspinner'
import {history} from '../../redux/helpers/store';
import { withTranslation } from 'react-i18next';

class Activities extends Component {

	constructor( props ) {
		super(props);
		this.state = {
			activities: [],
			open: false,
			showMore: false,
			textButton: "Afficher plus  ▼",
			fetched: false,
		}
	}

	componentDidMount() {
		this.props.getAllActivities()
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.activities.isFetched && props.activities.types.length > 0) {
			return {
				activities: props.activities.types,
				fetched: props.isFetched
			}
		}
		return state
	}

	customizedMarker = ( item ) => {
		if (item.message) {
			let iconLetter = item.message.charAt(0);
			const randomColor = Math.floor(Math.random()*16777215).toString(16);
			return (
				<span className="custom-marker"
				      style={iconLetter !== " " ?{backgroundColor: `#${randomColor}`} : {backgroundColor: '##5c5c5c'}}
				>
                {iconLetter}
            </span>
			);
		}
	};

	customizeContent = (item) => {
		let url;
		let state = {}
		if(item.objectType === 'Image'){
			url = `/projects/${item.projectId}/session/products/${item.productId}/images/${item.objectId}/comments`
			state = {productId: item.productId, projectId: item.projectId, imageId: item.objectId}
		}
		return(
			<div>
				<p onClick={() =>  history.push(url, state)} style={{cursor: 'pointer'}}>{item.message}</p>
			</div>
		)
	}

	customizeOposite = (item, position = 'dialog') => {
		return (
			<div style={position === "dash" ? {width: '65px'} : {width: '100px'}}>
				<small >{moment(item.created).startOf('day').fromNow()}</small>
			</div>
		)
	}

	showAllActivities() {
		let state = {showMore: true};
		this.setState(state);
	}

	hideActivitiesDialog() {
		let state = {showMore: false};
		this.setState(state);
	}

	render() {
		const {t} = this.props;
		const footer = <div className="p-d-flex p-jc-end">
			<Button label={t('dashboardScreen.activity.button')} className="p-button-link p-button-sm" onClick={() => this.showAllActivities()}/>
		</div>;
		return (
			<div>
				<h5>{t('dashboardScreen.activity.title')}</h5>
				{this.state.fetched ?
					<ProgressSpinner className="small-progress-spinner p-d-flex p-flex-row p-jc-center p-ai-center"/> :
					<Panel header=" " className="p-pt-4">
						<Timeline value={this.state.activities.slice(0, 3)}
							      opposite={item => this.customizeOposite(item, 'dash')}
							      content={item  => this.customizeContent((item)) }
							      marker={( item ) => this.customizedMarker(item)}/>
						{footer}
					</Panel>}

				<Dialog position='top' header={t('dashboardScreen.activity.popupTitle')} visible={this.state.showMore}
				        style={{width: '50vw'}} onHide={() => this.hideActivitiesDialog()}
				        closeOnEscape dismissableMask>
					<h5>{t('dashboardScreen.activity.today')}</h5>
					<Timeline
						value={this.state.activities.filter(act => moment(act.created).format('l') === moment().format('l'))}
						opposite={item => this.customizeOposite((item))}
						content={item  => this.customizeContent((item)) }
						marker={( item ) => this.customizedMarker(item)}/>
					<h5>{t('dashboardScreen.activity.yesterday')}</h5>
					<Timeline
						value={this.state.activities.filter(act => moment(act.created).format('l') === moment().subtract(1, 'days').format('l'))}
						opposite={item => this.customizeOposite((item))}
						content={item  => this.customizeContent((item)) }
						marker={( item ) => this.customizedMarker(item)}/>
					<h5>{t('dashboardScreen.activity.revious')}</h5>
					<Timeline
						value={this.state.activities.filter(act => moment(act.created).format('l') <= moment().subtract(2, 'days').format('l'))}
						// align="left"
						opposite={item => this.customizeOposite((item))}
						content={item  => this.customizeContent((item)) }
						marker={( item ) => this.customizedMarker(item)}/>
				</Dialog>
			</div>
		);
	}
}


function mapState( state ) {
	const {users, authentication, activities} = state;
	const {user} = authentication;
	return {user, users, activities};
}

const actionCreator = {
	getAllActivities: activitiesActions.getAllActivities
};

const connectedActivities = connect(mapState, actionCreator)(withTranslation()(Activities));
export {connectedActivities as Activities};
