import React, { Component } from 'react';
import {ReactComponent as Logo } from '../../logoRedirect.svg'

class MobileRedirect extends Component {
    render() {
        return (
            <div>
            <div className={'redirect-logo'}>
              <Logo />
            </div>
            <div className={'redirect-title'}>
            
                On dirait que vous utilisez un appareil mobile.
            
            </div>
            <div className={'redirect-content'}>
            
            Shooting flow n’est pas encore disponible 
en version mobile. Pour accéder à toutes les fonctionnalités veuillez vous connecter sur un ordinateur.
        
        </div>
            </div>
        );
    }
}

export default MobileRedirect;