export const contributorConstants = {
    CONTRIBUTORS_ADD_REQUEST: 'CONTRIBUTORS_ADD_REQUEST',
    CONTRIBUTORS_ADD_SUCCESS: 'CONTRIBUTORS_ADD_SUCCESS',
    CONTRIBUTORS_ADD_FAILURE: 'CONTRIBUTORS_ADD_FAILURE',

    GET_CONTRIBUTORS_REQUEST: 'GET_CONTRIBUTORS_REQUEST',
    GET_CONTRIBUTORS_SUCCESS: 'GET_CONTRIBUTORS_SUCCESS',
    GET_CONTRIBUTORS_FAILURE: 'GET_CONTRIBUTORS_FAILURE',

    DELETE_CONTRIBUTORS_REQUEST: 'DELETE_CONTRIBUTORS_REQUEST',
    DELETE_CONTRIBUTORS_SUCCESS: 'DELETE_CONTRIBUTORS_SUCCESS',
    DELETE_CONTRIBUTORS_FAILURE: 'DELETE_CONTRIBUTORS_FAILURE',

    UPDATE_CONTRIBUTORS_REQUEST: 'UPDATE_CONTRIBUTORS_REQUEST',
    UPDATE_CONTRIBUTORS_SUCCESS: 'UPDATE_CONTRIBUTORS_SUCCESS',
    UPDATE_CONTRIBUTORS_FAILURE: 'UPDATE_CONTRIBUTORS_FAILURE',

    VALIDATE_CONTRIBUTORS_REQUEST: 'VALIDATE_CONTRIBUTORS_REQUEST',
    VALIDATE_CONTRIBUTORS_SUCCESS: 'VALIDATE_CONTRIBUTORS_SUCCESS',
    VALIDATE_CONTRIBUTORS_FAILURE: 'VALIDATE_CONTRIBUTORS_FAILURE',

    REJECT_CONTRIBUTORS_REQUEST: 'REJECT_CONTRIBUTORS_REQUEST',
    REJECT_CONTRIBUTORS_SUCCESS: 'REJECT_CONTRIBUTORS_SUCCESS',
    REJECT_CONTRIBUTORS_FAILURE: 'REJECT_CONTRIBUTORS_FAILURE',

    DRAFT_CONTRIBUTORS_REQUEST: 'DRAFT_CONTRIBUTORS_REQUEST',
    DRAFT_CONTRIBUTORS_SUCCESS: 'DRAFT_CONTRIBUTORS_SUCCESS',
    DRAFT_CONTRIBUTORS_FAILURE: 'DRAFT_CONTRIBUTORS_FAILURE',

    DELETE_ALL_CONTRIBUTORS_REQUEST: 'DELETE_ALL_CONTRIBUTORS_REQUEST',
    DELETE_ALL_CONTRIBUTORS_SUCCESS: 'DELETE_ALL_CONTRIBUTORS_SUCCESS',
    DELETE_ALL_CONTRIBUTORS_FAILURE: 'DELETE_ALL_CONTRIBUTORS_FAILURE',

    IMPORT_CONTRIBUTORS_REQUEST: 'IMPORT_CONTRIBUTORS_REQUEST',
    IMPORT_CONTRIBUTORS_SUCCESS: 'IMPORT_CONTRIBUTORS_SUCCESS',
    IMPORT_CONTRIBUTORS_FAILURE: 'IMPORT_CONTRIBUTORS_FAILURE',

    IMPORT_FILE_CONTRIBUTORS_REQUEST: 'IMPORT_FILE_CONTRIBUTORS_REQUEST',
    IMPORT_FILE_CONTRIBUTORS_SUCCESS: 'IMPORT_FILE_CONTRIBUTORS_SUCCESS',
    IMPORT_FILE_CONTRIBUTORS_FAILURE: 'IMPORT_FILE_CONTRIBUTORS_FAILURE',
}
