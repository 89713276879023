export const tasksConstants = {
    GET_TASKS_REQUEST: 'GET_TASKS_REQUEST',
    GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS',
    GET_TASKS_FAILURE: 'GET_TASKS_FAILURE',

    ADD_TASKS_REQUEST: 'ADD_TASKS_REQUEST',
    ADD_TASKS_SUCCESS: 'ADD_TASKS_SUCCESS',
    ADD_TASKS_FAILURE: 'ADD_TASKS_FAILURE',

    UPDATE_TASKS_REQUEST: 'UPDATE_TASKS_REQUEST',
    UPDATE_TASKS_SUCCESS: 'UPDATE_TASKS_SUCCESS',
    UPDATE_TASKS_FAILURE: 'UPDATE_TASKS_FAILURE',

    CHANGE_STATUS_TASKS_REQUEST: 'CHANGE_STATUS_TASKS_REQUEST',
    CHANGE_STATUS_TASKS_SUCCESS: 'CHANGE_STATUS_TASKS_SUCCESS',
    CHANGE_STATUS_TASKS_FAILURE: 'CHANGE_STATUS_TASKS_FAILURE',

    COMMENT_TASK_REQUEST: 'COMMENT_TASK_REQUEST',
    COMMENT_TASK_SUCCESS: 'COMMENT_TASK_SUCCESS',
    COMMENT_TASK_FAILURE: 'COMMENT_TASK_FAILURE',

    ADD_CHECKLIST_REQUEST: 'ADD_CHECKLIST_REQUEST',
    ADD_CHECKLIST_SUCCESS: 'ADD_CHECKLIST_SUCCESS',
    ADD_CHECKLIST_FAILURE: 'ADD_CHECKLIST_FAILURE',

    VALIDATE_CHECKLIST_REQUEST: 'VALIDATE_CHECKLIST_REQUEST',
    VALIDATE_CHECKLIST_SUCCESS: 'VALIDATE_CHECKLIST_SUCCESS',
    VALIDATE_CHECKLIST_FAILURE: 'VALIDATE_CHECKLIST_FAILURE'

}
