import {alertActions} from '.';
import {mediasConstants} from '../constants';
import {mediasService} from '../../services';

export const mediasActions = {
    getBoard,
    DownloadMedias,
    changeRefMer
};
function changeRefMer(projectId,mediasByProducts,newRefId,newRef) {
  return dispatch => {
      dispatch(request());
      mediasService.changeRefMer(projectId,mediasByProducts,newRefId)
          .then(
              products => {dispatch(success(products))
                dispatch(alertActions.success("Les images ont été déplacé sous " +newRef))},
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              });
            };   
 function request() {return {type: mediasConstants.CHANGE_REF_MER_REQUEST}}
 function success(products) {return {type: mediasConstants.CHANGE_REF_MER_SUCCESS, products}}
  function failure(error) {return {type: mediasConstants.CHANGE_REF_MER_FAILURE, error}}
}

function getBoard( projectId, imageIds ) {
    return dispatch => {
        dispatch(request(imageIds));
        mediasService.getBoard(projectId, imageIds)
            .then(
                board => dispatch(success(board)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }

    function request( imageIds ) {return {type: mediasConstants.MEDIAS_GET_BOARD_REQUEST, imageIds}}
    function success( board ) {return {type: mediasConstants.MEDIAS_GET_BOARD_SUCCESS, board}}
    function failure( imageIds, error ) {return {type: mediasConstants.MEDIAS_GET_BOARD_FAILURE, imageIds, error}}
}
  function DownloadMedias(projectId, imageIds){
    return dispatch => {
      dispatch(request(imageIds));
      mediasService.DownloadMedias(projectId, imageIds)
      .then(
        zip => dispatch(success(zip)),
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
    }
    function request(imageIds) {return {type: mediasConstants.MEDIAS_DOWNLOADZIP_REQUEST, imageIds}}
    function success(zip) {return {type: mediasConstants.MEDIAS_DOWNLOADZIP_SUCCESS, zip}}
    function failure(imageIds, error) {return {type: mediasConstants.MEDIAS_DOWNLOADZIP_FAILURE, imageIds, error}}
  }