import React, {Component} from 'react';
import Header from '../common/Header';
import {Panel} from 'primereact/panel';
import {connect} from 'react-redux';
import {MultiSelect} from 'primereact/multiselect';
import {Dropdown} from 'primereact/dropdown';
import {alertActions, membersAction, settingAction} from '../../redux/actions'
import {Button} from 'primereact/button'
import {ProgressSpinner} from 'primereact/progressspinner'
import {Divider} from 'primereact/divider';
import { withTranslation } from 'react-i18next';

class NotificationScreen extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			members: [],
			type: [],
			setting: [{notificationTypeId: null, emails: []}],
			isSettingFetched: false,
			loading: false
		}
	}

	componentDidMount() {
		this.props.getMembers()
		this.props.getType()
		setTimeout(() => this.props.getNotif(), 800)
	}

	static getDerivedStateFromProps( props, state ) {
		if(props.isFetchingAdd){
			return {loading: true}
		}

		if (props.members && props.types && props.fetched && props.isTypeFetched) {
			let emptyChoise = [{name: props.t('parameter.chooseType'), value: null}]
			let type = emptyChoise.concat(props.types)
			return {
				members: props.members,
				type: type
			}
		}

		if (props.settings && props.isSettingFetched && !state.isSettingFetched ) {
			if (props.settings.length === 0){
				let row = [{notificationTypeId: null, emails: []}]
				return {
					setting: row,
					isSettingFetched: props.isSettingFetched,
				}
			} else {
				return {
					setting: props.settings,
					isSettingFetched: props.isSettingFetched,
				}
			}
		}

		if(!props.isFetchingAdd){
			return {loading: false}
		}

		return state
	}

	addLigne = () => {
		this.setState(( prevState ) => ({
			setting: [...prevState.setting, {notificationTypeId: null, emails: []},
			]
		}))
	}

	onSubmit = () => {
		let setting = this.state.setting
		let sending = false
		setting.map(( row, i ) => {
			if (row.emails.length == 0) {
				this.props.warningAlert(this.props.t('parameter.addMember') + " " + (i + 1))
				return sending = false
			} else if (row.notificationTypeId === null || row.notificationTypeId.length == 0) {
				this.props.warningAlert(this.props.t('parameter.addType') + " " + (i + 1))
				return sending = false
			} else {
				return sending = true
			}
		})

		const map = new Map(setting.map(({notificationTypeId, emails}) => [notificationTypeId, {notificationTypeId, emails: [] }]));
		for (let {notificationTypeId, emails} of setting) map.get(notificationTypeId).emails.push(...[emails].flat());
		let result = [...map.values()]

		if (sending) {
			this.props.addNotif(result)
			this.setState({
				setting: result
			})
		}
	}

	render() {
		return (
			<div>
				<Header/>
				<div className="p-grid p-justify-center">
					{this.state.isSettingFetched ?
						<Panel header={this.props.t('parameter.notifParams')}
						       className="p-col-6 p-my-6 ">
								{this.state.setting.map(( obj, index ) => {
									let onChangeMember = ( e ) => {
										let settiing = this.state.setting
										settiing[index].emails = e.value
										this.setState({setting: settiing})
									}
									let onChangeNotif = ( e ) => {
										let settiing = this.state.setting
										settiing[index].notificationTypeId = e.value
										this.setState({setting: settiing})
									}
									let deleteNotif = () => {
										let settiing = this.state.setting
										settiing.splice(index, 1)
										this.setState({setting: settiing})
									}
									return (
                                        <div className='p-grid p-align-center p-mt-2'>
										<MultiSelect
											placeholder={this.props.t('parameter.chooseMember')}
											className='p-col-6 p-mr-2'
											display="chip"
											optionLabel="email"
											optionValue='email'
											value={obj.emails}
											options={this.state.members}
											onChange={onChangeMember}
											style={{ padding: '0'}}
											filter
											disabled={this.state.loading}
										/>
										<Dropdown
											placeholder={this.props.t('parameter.chooseType')}
											className='p-col p-mr-2'
											display="chip"
											optionLabel="name"
											optionValue='value'
											value={obj.notificationTypeId}
											options={this.state.type}
											onChange={onChangeNotif}
											style={{padding: '0'}}
											disabled={this.state.loading}
										/>
										<Button icon="icon-Close icon-2x"
										        className="p-button-rounded p-button-text p-mt-2"
										        onClick={deleteNotif}
										/>
									</div>)
								})
								}
							<div className="p-d-flex p-jc-end">
								<Button
									className="p-mt-2 p-button-text"
									onClick={() => this.addLigne()}
									disabled={this.state.loading}>{this.props.t('parameter.addLigne')}</Button>
							</div>
							<Divider className="p-mt-3"/>
							<div className="p-d-flex p-flex-column p-jc-center p-ai-end p-pt-4">
								<Button loading={this.state.loading}
								        label={this.props.t('common.valid')}
								        className="p-px-4 validationButton"
								        onClick={this.onSubmit}/>
							</div>
						</Panel>
						:
						<div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: '70vh'}}>
							<ProgressSpinner className="p-progress-spinner"/>
						</div>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ( state ) => {
	const {settings, types, isSettingFetched, isTypeFetched, isFetchingAdd, isFetchedAdd} = state.company
	const {members, fetched} = state.members

	return {settings, types, members, isSettingFetched, isTypeFetched, fetched, isFetchingAdd, isFetchedAdd}
}

const actionNotification = {
	warningAlert: alertActions.warning,
	getMembers: membersAction.getMembers,
	getNotif: settingAction.getNotificationSettings,
	getType: settingAction.getNotificationType,
	addNotif: settingAction.addNotificationSetting
}

const connectedApp = connect(mapStateToProps, actionNotification)(withTranslation()(NotificationScreen))
export {connectedApp as NotificationScreen}
