import { commonService } from './common.service';
export const callsheetService = {
    addCallsheet,
    getCallsheet,
    deleteCallsheet,
    updateCallsheet,
    downloadCallsheet
}

function addCallsheet(projectId, callsheet) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(callsheet)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/callsheet`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}


function getCallsheet(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/callsheet`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteCallsheet(projectId, callsheetItemId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/callsheet/${callsheetItemId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function updateCallsheet(projectId, callsheetItemId, placeField) {
   
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(placeField)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/callsheet/${callsheetItemId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function downloadCallsheet(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/CallSheet/download`, requestOptions)
        .then(commonService.handleFileResponse.bind(this))
}