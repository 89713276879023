export const projectConstants = {
  SHOOTING_TYPE_REQUEST: 'SHOOTING_TYPE_REQUEST',
  SHOOTING_TYPE_SUCCESS: 'SHOOTING_TYPE_SUCCESS',
  SHOOTING_TYPE_FAILURE: 'SHOOTING_TYPE_FAILURE',

  CREATE_FOLDER_RAW_SUCCESS: 'CREATE_FOLDER_RAW_SUCCESS',
  CREATE_FOLDER_RAW_REQUEST: 'CREATE_FOLDER_RAW_REQUEST',
  CREATE_FOLDER_RAW_FAILURE: 'CREATE_FOLDER_RAW_FAILURE',

  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',

  STEP_ONE_REQUEST: 'STEP_ONE_REQUEST',
  STEP_ONE_SUCCESS: 'STEP_ONE_SUCCESS',
  STEP_ONE_FAILURE: 'STEP_ONE_FAILURE',

  MEMBER_ROLES_REQUEST: 'MEMBER_ROLES_REQUEST',
  MEMBER_ROLES_SUCCESS: 'MEMBER_ROLES_SUCCESS',
  MEMBER_ROLES_FAILURE: 'MEMBER_ROLES_FAILURE',

  MEMBER_ADD_REQUEST: 'MEMBER_ADD_REQUEST',
  MEMBER_ADD_SUCCESS: 'MEMBER_ADD_SUCCESS',
  MEMBER_ADD_FAILURE: 'MEMBER_ADD_FAILURE',

  GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE',


  GET_PROJECT_DETAILS_REQUEST: 'GET_PROJECT_DETAILS_REQUEST',
  GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
  GET_PROJECT_DETAILS_FAILURE: 'GET_PROJECT_DETAILS_FAILURE',

  PROJECT_UPDATE_REQUEST: 'PROJECT_UPDATE_REQUEST',
  PROJECT_UPDATE_SUCCESS: 'PROJECT_UPDATE_SUCCESS',
  PROJECT_UPDATE_FAILURE: 'PROJECT_UPDATE_FAILURE',

  GET_PROJECT_FILES_REQUEST: 'GET_PROJECT_FILES_REQUEST',
  GET_PROJECT_FILES_SUCCESS: 'GET_PROJECT_FILES_SUCCESS',
  GET_PROJECT_FILES_FAILURE: 'GET_PROJECT_FILES_FAILURE',

  GET_FILE_CATEGORIES_REQUEST: 'GET_FILE_CATEGORIES_REQUEST',
  GET_FILE_CATEGORIES_SUCCESS: 'GET_FILE_CATEGORIES_SUCCESS',
  GET_FILE_CATEGORIES_FAILURE: 'GET_FILE_CATEGORIES_FAILURE',

  ADD_DOCUMENT_REQUEST: 'ADD_DOCUMENT_REQUEST',
  ADD_DOCUMENT_SUCCESS: 'ADD_DOCUMENT_SUCCESS',
  ADD_DOCUMENT_FAILURE: 'ADD_DOCUMENT_FAILURE',

  DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',

  REPLACE_DOCUMENT_REQUEST: 'REPLACE_DOCUMENT_REQUEST',
  REPLACE_DOCUMENT_SUCCESS: 'REPLACE_DOCUMENT_SUCCESS',
  REPLACE_DOCUMENT_FAILURE: 'REPLACE_DOCUMENT_FAILURE',

  UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',

  GET_FILE_DETAILS_REQUEST: 'GET_FILE_DETAILS_REQUEST',
  GET_FILE_DETAILS_SUCCESS: 'GET_FILE_DETAILS_SUCCESS',
  GET_FILE_DETAILS_FAILURE: 'GET_FILE_DETAILS_FAILURE',

  DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE: 'DOWNLOAD_FILE_FAILURE',

  ADD_FILE_COMMENT_REQUEST: 'ADD_FILE_COMMENT_REQUEST',
  ADD_FILE_COMMENT_SUCCESS: 'ADD_FILE_COMMENT_SUCCESS',
  ADD_FILE_COMMENT_FAILURE: 'ADD_FILE_COMMENT_FAILURE',

  VALIDATE_COMMENT_REQUEST: 'VALIDATE_COMMENT_REQUEST',
  VALIDATE_COMMENT_SUCCESS: 'VALIDATE_COMMENT_SUCCESS',
  VALIDATE_COMMENT_FAILURE: 'VALIDATE_COMMENT_FAILURE',

  DELETE_MEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
  DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',

  GET_STATISTIC_REQUEST: 'GET_STATISTIC_REQUEST',
  GET_STATISTIC_SUCCESS: 'GET_STATISTIC_SUCCESS',
  GET_STATISTIC_FAILURE: 'GET_STATISTIC_FAILURE'
};
