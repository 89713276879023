import { tasksService } from '../../services'
import { tasksConstants } from '../constants'
import {alertActions} from './'

export const tasksAction = {
    getTasks,
    addTasks,
    updateTask,
    changeStatusTask,
    commentTask,
    addCheckList,
    validateChecklist
}

function getTasks(projectId){
    return dispatch => {
        dispatch(request());
        tasksService.getTasks(projectId)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.GET_TASKS_REQUEST}}
    function success(tasks) {return { type: tasksConstants.GET_TASKS_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.GET_TASKS_FAILURE, error}}

}

function addTasks(projectId, task){
    return dispatch => {
        dispatch(request());
        tasksService.addTasks(projectId, task)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.ADD_TASKS_REQUEST}}
    function success(tasks) {return { type: tasksConstants.ADD_TASKS_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.ADD_TASKS_FAILURE, error}}

}

function updateTask(projectId, taskId, task){
    return dispatch => {
        dispatch(request());
        tasksService.updateTask(projectId, taskId, task)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.UPDATE_TASKS_REQUEST}}
    function success(tasks) {return { type: tasksConstants.UPDATE_TASKS_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.UPDATE_TASKS_FAILURE, error}}

}

function changeStatusTask(projectId, taskId, status){
    return dispatch => {
        dispatch(request());
        tasksService.changeStatusTask(projectId, taskId, status)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.CHANGE_STATUS_TASKS_REQUEST}}
    function success(tasks) {return { type: tasksConstants.CHANGE_STATUS_TASKS_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.CHANGE_STATUS_TASKS_FAILURE, error}}

}

function commentTask(projectId, taskId, comment){
    return dispatch => {
        dispatch(request());
        tasksService.commentsTask(projectId, taskId, comment)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.COMMENT_TASK_REQUEST}}
    function success(tasks) {return { type: tasksConstants.COMMENT_TASK_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.COMMENT_TASK_FAILURE, error}}

}

function addCheckList(projectId, taskId, checklist){
    return dispatch => {
        dispatch(request());
        tasksService.addChecklist(projectId, taskId, checklist)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.ADD_CHECKLIST_REQUEST}}
    function success(tasks) {return { type: tasksConstants.ADD_CHECKLIST_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.ADD_CHECKLIST_FAILURE, error}}

}

function validateChecklist(projectId, taskId, itemId){
    return dispatch => {
        dispatch(request());
        tasksService.validateChecklist(projectId, taskId, itemId)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return { type: tasksConstants.VALIDATE_CHECKLIST_REQUEST}}
    function success(tasks) {return { type: tasksConstants.VALIDATE_CHECKLIST_SUCCESS, tasks}}
    function failure(error) {return {type: tasksConstants.VALIDATE_CHECKLIST_FAILURE, error}}

}
