 
import { commonService } from './common.service';

export const placeService = {
    placeAdd,
    getPlace,
    deletePlace,
    updatePlace,
    validatePlace,
    rejectPlace,
    draftPlace,
    deleteAllPlace
};

function placeAdd (projectId, place){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(place)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}
 
function getPlace(projectId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + user.auth.auth_token }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function deletePlace(projectId, placeId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'DELETE',
      headers: {'Content-Tupe': 'application/json',
                  'Authorization': 'Bearer ' + user.auth.auth_token }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/${placeId}`, requestOptions)
    .then(commonService.handleResponse.bind(this))
  }

  function updatePlace(projectId, placeId ,placeField) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      },
      body: JSON.stringify(placeField)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/${placeId}`, requestOptions)
      .then(commonService.handleResponse.bind(this))
  }

  function validatePlace(projectId, placeId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
      return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/${placeId}/Validate`, requestOptions)
      .then(commonService.handleResponse.bind(this))
  }


  function rejectPlace(projectId, placeId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/${placeId}/Reject`, requestOptions)
    .then(commonService.handleResponse.bind(this))
  }

  function draftPlace(projectId, placeId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/${placeId}/draft`, requestOptions)
    .then(commonService.handleResponse.bind(this))
  }

function deleteAllPlace(projectId, categoryId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Tupe': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/places/categories/${categoryId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
