import { commonService } from './common.service';
export const contributorService = {
    contributorAdd,
    getContributor,
    deleteContributor,
    updateContributor,
    validateContributor,
    rejectContributor,
    draftContributor,
    deleteAllContributors,
    importContributors,
    attachFilesContributors
}

function contributorAdd (projectId, contributors) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(contributors)
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

 
function getContributor (projectId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteContributor (projectId, contributorId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Tupe': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function updateContributor (projectId, contributorId, contributorField) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(contributorField)
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function validateContributor (projectId, contributorId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}/Validate`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function rejectContributor (projectId, contributorId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}/Reject`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function draftContributor (projectId, contributorId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}/draft`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteAllContributors (projectId) {
    let user = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Tupe': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function importContributors (projectId, file) {
    let user = JSON.parse(localStorage.getItem('user'))
    let formdata = new FormData()
    formdata.append('file', file[0].document, file[0].name)
    console.log('formdata', formdata)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: formdata
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/Import`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function attachFilesContributors (projectId, contributorId, EmailBody) {
    let user = JSON.parse(localStorage.getItem('user'))
    var formdata = new FormData()
    console.log('file', EmailBody)
    formdata.append('files', EmailBody.files[0].document, EmailBody.files[0].name)
    if (EmailBody.files[1]) {formdata.append('files', EmailBody.files[1].document, EmailBody.files[1].name)}
    console.log('file.files[0].name', EmailBody.files[0].name)
    formdata.append('message', EmailBody.message)
    for (var pair of formdata.entries()) {
        console.log(pair[0] + ', ' + pair[1])
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: formdata
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/contributors/${contributorId}/AttachFiles`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
