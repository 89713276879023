import { commonService } from './common.service';


export const productsService = {
    submitProducts,
    getImages,
    submitSelectedImages,
    uploadAllImages,
    checkImages,
    getReference,
    setComment,
    setCommentReference,
    setCommentProject,
    getComments,
    getSessionHistory,
    setUrgent,
    setVersion,
    setOrder,
    addReference,
    getProduct,
    getImageProduct,
    submitSelectedImagesProduct,
    unselectImage,
    deleteImage,
    synchronizeSession,
    changeLabelOrRate,
    validateImg,
    rejectImg,
    validateImages,
    uploadRetouchZip,
    selectedImagesProductBool,
    linkProduct,
    getProductLinked,
    exportValideImage,
    getFolder,
    generateBarCodes,
    exportMerch
};
function generateBarCodes(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
     };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/barcodes`, requestOptions)
        .then(commonService.handleFileResponse.bind(this))
}
function validateImages(projectId, imageIds) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },

        body: JSON.stringify(imageIds)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/Validate`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function submitProducts(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/import`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getProduct(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getComments(projectId, productId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getReference(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/session/products`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setComment(type, comment, projectId, id, pointId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify({
            "comment": comment,
            "pointId": pointId
        })
    };
    if (type === "images")
        return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/${type}/${id}/comment`, requestOptions)
            .then(commonService.handleResponse.bind(this))
    else
        return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/${type}/${id}/comment/${imageId}`, requestOptions)
            .then(commonService.handleResponse.bind(this))

}

function setCommentReference(projectId, productId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify({
            "comment": comment
        })
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/comment`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setCommentProject(projectId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify({
            "comment": comment
        })
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/Comment`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getImages(id, path) {
    let user = JSON.parse(localStorage.getItem('user'));
   
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }, 
        body: JSON.stringify({
            "path" : path
        }) 
        
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/session/images-for-path`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getImageProduct(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/medias/product/${productId}/all`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function submitSelectedImages(id, images) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(Object.keys(images))
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/medias/selected`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function submitSelectedImagesProduct(id, productId, images) {

    let user = JSON.parse(localStorage.getItem('user'));
    console.log(images);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(images)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/medias/product/${productId}/select`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}


function uploadAllImages(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/medias/select-all`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function checkImages(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/medias/check`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
 
function getSessionHistory(projectId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}/versions`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setVersion(projectId, imageId, versionId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}/versions/${versionId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function unselectImage(projectId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}/select`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setUrgent(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/urgent`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setOrder(projectId, imageId, order) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}/reorder/${order}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function addReference(projectId, product, file) {
    let user = JSON.parse(localStorage.getItem('user'));
    let formdata = new FormData();

    formdata.append("product", JSON.stringify(product));
    if (file[0] != null) {
        formdata.append("file", file[0].document, file[0].name);
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
        },
        body: formdata
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteImage(projectId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptionsFile = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}`, requestOptionsFile)
        .then(commonService.handleResponse.bind(this))
}

function synchronizeSession(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/sync`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function changeLabelOrRate(projectId, productId, imageId, value) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(value)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/metadata`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function validateImg(projectId, productId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json",
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/validate`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function rejectImg(projectId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json",
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/images/${imageId}/reject`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function uploadRetouchZip(projectId, zip) {
    let user = JSON.parse(localStorage.getItem('user'));
    let formdata = new FormData();
    formdata.append("file", zip[0].document, zip[0].name);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: formdata
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/medias/uploadZip`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function selectedImagesProductBool(id, images, bool) {

    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(images)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/medias/select/${bool}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function linkProduct(projectId, productId, imageId, list) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(list)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/visibleproducts`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getProductLinked(projectId, productId, imageId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/visibleProducts`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function exportValideImage(id){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/session/export`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function exportMerch(id){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token,
            "Content-Type": "application/json"
        },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${id}/session/merch-export`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getFolder(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/folders`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
