import { commonService } from './common.service';


export const searchService = {
  getFilters,
  searchAdvancedProducts,
};

function getFilters() {
  let user = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.auth.auth_token,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/search/filters`,
    requestOptions
  ).then(commonService.handleResponse.bind(this));
}

function searchAdvancedProducts(filters) {
  let user = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.auth.auth_token,
    },
    body: JSON.stringify(filters),
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/api/search/advanced`,
    requestOptions
  ).then(commonService.handleResponse.bind(this));
}

 