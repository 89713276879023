import { catalogService } from '../../services'
import { catalogConstants } from '../constants'
import { alertActions } from './'

export const catalogAction = {
    getProducts,
    addCatalog,
    deleteCatalog,
    setShootedProduct,
    linkProduct,
    searchCatalog,
    getFullCatalog,
    setInventoryStatus,
    addProductsToProject,
    generateBarCodes,
    printShootList,
    addManuallyProduct,
    findCatalog,
    showImg,
    getLinkedproducts,
    getProduct
}
function getLinkedproducts(projectId,productId) {
    return dispatch => {
        dispatch(request());
        catalogService.getLinkedproducts(projectId,productId)
            .then(
                linkedProducts => dispatch(success(linkedProducts)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: catalogConstants.GET_LINKED_PRODUCTS_REQUEST } }
    function success(linkedProducts) { return { type: catalogConstants.GET_LINKED_PRODUCTS_SUCCESS, linkedProducts } }
    function failure(error) { return { type: catalogConstants.GET_LINKED_PRODUCTS_FAILURE, error } }
}
function getProducts(projectId,dtoShoot) {
    return dispatch => {
        dispatch(request());
        catalogService.getProducts(projectId,dtoShoot)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: catalogConstants.GET_CATALOG_REQUEST } }
    function success(catalogs) { return { type: catalogConstants.GET_CATALOG_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.GET_CATALOG_FAILURE, error } }
}
function getProduct(productId) {
    return dispatch => {
        dispatch(request());
        catalogService.getProduct(productId)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: catalogConstants.GET_PRODUCT_REQUEST } }
    function success(product) { return { type: catalogConstants.GET_PRODUCT_SUCCESS, product } }
    function failure(error) { return { type: catalogConstants.GET_PRODUCT_FAILURE, error } }
}
function addCatalog(projectId, catalogs) {
    return dispatch => {
        dispatch(request(catalogs));
        catalogService.addCatalog(projectId, catalogs)
            .then(
                catalogs => {
                    dispatch(success(catalogs))
                    dispatch(alertActions.success("L'import du catalogue a été lancé. Vous serez notifié quand il sera terminé"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(catalogs) { return { type: catalogConstants.CATALOG_ADD_REQUEST, catalogs } }
    function success(catalogs) { return { type: catalogConstants.CATALOG_ADD_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.CATALOG_ADD_FAILURE, error } }

};

function deleteCatalog(projectId, id) {
    return dispatch => {
        dispatch(request(id))

        catalogService.deleteCatalog(projectId, id)
            .then(
                catalogs => dispatch(success(id)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(id) { return { type: catalogConstants.DELETE_CATALOG_REQUEST, id } }
    function success(id) { return { type: catalogConstants.DELETE_CATALOG_SUCCESS, id } }
    function failure(id, error) { return { type: catalogConstants.DELETE_CATALOG_FAILURE, id, error } }

}


function setShootedProduct(projectId, id) {
    return dispatch => {
        dispatch(request(id))

        catalogService.setShootedProduct(projectId, id)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(id) { return { type: catalogConstants.SWITCH_CATALOG_REQUEST, id } }
    function success(catalogs) { return { type: catalogConstants.SWITCH_CATALOG_SUCCESS, catalogs } }
    function failure(id, error) { return { type: catalogConstants.SWITCH_CATALOG_FAILURE, id, error } }

}

function linkProduct(projectId, id, selectedProdduct) {
    return dispatch => {
        dispatch(request(id))
        catalogService.linkProduct(projectId, id, selectedProdduct)
            .then(
                catalogs => {
                    dispatch(success(selectedProdduct))
                    dispatch(alertActions.success('Les produits ont été associés à la référence'))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.LINK_CATALOG_REQUEST } }
    function success(catalogs) { return { type: catalogConstants.LINK_CATALOG_SUCCESS, catalogs } }
    function failure(catalogs, error) { return { type: catalogConstants.LINK_CATALOG_FAILURE, catalogs, error } }

}
function findCatalog(q, p = 1, projectId = 0) {
    return dispatch => {
        dispatch(request(q))
        catalogService.findProduct(q, p, projectId)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(q) { return { type: catalogConstants.FIND_CATALOG_REQUEST, q } }
    function success(catalogs) { return { type: catalogConstants.FIND_CATALOG_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.FIND_CATALOG_FAILURE, error } }
}
function searchCatalog(q, p = 1, projectId = 0) {
    return dispatch => {
        dispatch(request(q))
        catalogService.searchProduct(q, p, projectId)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(q) { return { type: catalogConstants.SEARCH_CATALOG_REQUEST, q } }
    function success(catalogs) { return { type: catalogConstants.SEARCH_CATALOG_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.SEARCH_CATALOG_FAILURE, error } }
}

function getFullCatalog(currentPage, perPage, search = '') {
    return dispatch => {
        dispatch(request())
        catalogService.getFullCatalog(currentPage, perPage, search)
            .then(
                catalog => dispatch(success(catalog)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.FULL_CATALOG_REQUEST } }
    function success(catalog) { return { type: catalogConstants.FULL_CATALOG_SUCCESS, catalog } }
    function failure(error) { return { type: catalogConstants.FULL_CATALOG_FAILURE, error } }
}
function setInventoryStatus(status, selectedProducts) {
    return dispatch => {
        dispatch(request())
        catalogService.setInventoryStatus(status, selectedProducts)
            .then(
                () => {
                    dispatch(success());
                    dispatch(alertActions.success("Produit scanné"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.INVENTORY_STATUS_REQUEST } }
    function success() { return { type: catalogConstants.INVENTORY_STATUS_SUCCESS } }
    function failure(error) { return { type: catalogConstants.INVENTORY_STATUS_FAILURE, error } }
}

function addProductsToProject(status, productsRefs) {
    return dispatch => {
        dispatch(request())
        catalogService.addProductsToProject(status, productsRefs)
            .then(
                () => dispatch(success()),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.ADD_PRODUCTS_PROJECT_REQUEST } }
    function success() { return { type: catalogConstants.ADD_PRODUCTS_PROJECT_SUCCESS } }
    function failure(error) { return { type: catalogConstants.ADD_PRODUCTS_PROJECT_FAILURE, error } }
}
function generateBarCodes(productsRefs,projectId=0) {
    return dispatch => {
        dispatch(request())
        catalogService.generateBarCodes(productsRefs,projectId)
            .then(
                () => dispatch(success()),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.PRODUCTS_BARCODE_REQUEST } }
    function success() { return { type: catalogConstants.PRODUCTS_BARCODE_SUCCESS } }
    function failure(error) { return { type: catalogConstants.PRODUCTS_BARCODE_FAILURE, error } }
}

function printShootList(projectId) {
    return dispatch => {
        dispatch(request());
        catalogService.printShootList(projectId)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: catalogConstants.PRINT_CATALOG_REQUEST } }
    function success(catalogs) { return { type: catalogConstants.PRINT_CATALOG_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.PRINT_CATALOG_FAILURE, error } }
}

function addManuallyProduct(projectId, product) {
    return dispatch => {
        dispatch(request());
        catalogService.addManuallyProduct(projectId, product)
            .then(
                catalogs => {
                    dispatch(success(catalogs))
                    dispatch(alertActions.success("Le produit a été ajouté avec succès"))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }
    function request() { return { type: catalogConstants.ADD_MANUALLY_REQUEST } }
    function success(catalogs) { return { type: catalogConstants.ADD_MANUALLY_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.ADD_MANUALLY_FAILURE, error } }
}

function showImg(productId) {
    return dispatch => {
        dispatch(request());
        catalogService.showImg(productId)
            .then(
                catalogs => dispatch(success(catalogs)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request() { return { type: catalogConstants.GET_IMAGE_PRODUCT_REQUEST } }
    function success(catalogs) { return { type: catalogConstants.GET_IMAGE_PRODUCT_SUCCESS, catalogs } }
    function failure(error) { return { type: catalogConstants.GET_IMAGE_PRODUCT_FAILURE, error } }
}
