import { membersConstants } from '../constants'

export function members(state={}, action){
    switch(action.type){
        case membersConstants.GET_MEMBERS_PROJECT_REQUEST:
            return Object.assign({}, { isFetching: true });
        case membersConstants.GET_MEMBERS_PROJECT_SUCCESS:
            return Object.assign({}, { type: action.members, fetched: true});
        case membersConstants.GET_MEMBERS_PROJECT_FAILURE:
            return {}

        case membersConstants.GET_MEMBERS_REQUEST:
            return Object.assign({}, { isFetching: true });
        case membersConstants.GET_MEMBERS_SUCCESS:
            return Object.assign({}, { members: action.members, fetched: true});
        case membersConstants.GET_MEMBERS_FAILURE:
            return {}

        default:
            return state
    }
}