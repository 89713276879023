import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from 'primereact/autocomplete';
import { catalogAction } from '../../redux/actions'
import { LazyImage } from "./LazyImage";
import ReactTooltip from "react-tooltip";
import { Skeleton } from 'primereact/skeleton';
import { ProjectProductsScreen } from '../project/projectLists/ProjectProductsScreen';

class CustomAutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputSearch: '',
			displayedProducts: [],
			lazyLoading: false,
			currentPage: null,
			totalCount: null,
			showLoader: true,
			isSearched: false,
			projectId:0,

		}
		this.onLazyLoad = this.onLazyLoad.bind(this);
		this.itemTemplate = this.itemTemplate.bind(this);
		this.isStringNullOrWhiteSpace = this.isStringNullOrWhiteSpace.bind(this);
		this.onValueChange = this.onValueChange.bind(this);

	}

	onValueChange(e) {
		if (this.isStringNullOrWhiteSpace(e)) {
			this.setState({ displayedProducts: [] })
		}
		this.setState({ inputSearch: e })
		this.props.onChange(e);

	}

	onLazyLoad(event) {
		if (event.first > 0 &&
			event.first < event.last &&
			event.last == this.state.displayedProducts.length &&
			this.state.displayedProducts.length > 0 &&
			event.last < this.state.totalCount) {
			this.setState({ lazyLoading: true, showLoader: true });
			this.findCatalog(this.state.inputSearch, this.state.currentPage + 1)
		}

	}

	findCatalog = (event, page = 1) => {
		if (event.toLowerCase() !== '') {
			this.props.findCatalog(event.toLowerCase(), page,this.state.projectId)
			if (page == 1)
				this.setState({ isSearched: false })
		}
	}
	isStringNullOrWhiteSpace(value) {
		return value === undefined
			|| value === null
			|| typeof value !== 'string'
			|| value.match(/^ *$/) !== null;
	}

	static getDerivedStateFromProps(props, state) {
		 
		if(props.projectId!==undefined && props.projectId!==state.projectId)
		{
			return {projectId:props.projectId}
		}
		if (props.value === "") {
			return {
				displayedProducts: []
			}
		}
		if (props.isSearched) {
			if (!state.isSearched && props.type.currentPage === 1) {
				return {
					displayedProducts: props.type.result,
					currentPage: props.type.currentPage,
					lazyLoading: false,
					showLoader: false,
					totalCount: props.type.totalCount,
					isSearched: props.isSearched
				}
			}
			else if (props.type.currentPage !== 1 && props.type.currentPage !== state.currentPage) {
				return {
					displayedProducts: state.displayedProducts.concat(props.type.result),
					totalCount: props.type.totalCount,
					currentPage: props.type.currentPage,
					lazyLoading: false,
					showLoader: false,
					isSearched: props.isSearched
				}
			}
		}


		return state;
	}
	itemTemplate = (item) => {
		return (
			<div className="p-grid p-align-center">
				<div className="p-col-3" data-tip data-for={`tooltip-${item.reference}`}>
					<LazyImage key={`img_${item.reference}`} src={item.thumbnail} alt={item.product} style={{ height: '50px' }} />
				</div>
				<div className="p-col-9">{item.product}</div>
				{item.properties ?
					<ReactTooltip id={`tooltip-${item.reference}`} place="right" type="dark" effect="float">
						{item.properties.map(row => <p>{row.name} : {row.value}</p>)}
					</ReactTooltip>
					:
					<></>
				}
			</div>
		)
	}
	render() {


		return (
			<AutoComplete
				suggestions={this.state.displayedProducts}
				value={this.props.value === "" ? this.state.inputSearch : this.props.value}
				onChange={(e) => { this.onValueChange(e.value) }}
				placeholder={this.props.placeholder}
				onSelect={(e) => { this.props.onSelect(e.value); this.setState({ inputSearch: '' }) }}
				completeMethod={(e) => this.findCatalog(e.query)}
				onClear={() => this.setState({ inputSearch: '' })}
				itemTemplate={this.itemTemplate}
				readOnly={this.props.readOnly}
				delay={500}
				className={this.props.className}
				style={this.props.style}
				virtualScrollerOptions={{
					lazy: true
					, onLazyLoad: this.onLazyLoad
					, itemSize: 50
					, showLoader: this.state.showLoader
					, loading: this.state.lazyLoading
					, delay: 250
				}}
			/>
		);
	}
}

const mapStateToProps = state => {
	const { isSearched, type, isSearching } = state.catalog
	return { isSearched, type, isSearching }
}
const actionCreators = {
	findCatalog: catalogAction.findCatalog,
}
const connectedCatalogScreen = connect(mapStateToProps, actionCreators)(CustomAutoComplete)
export { connectedCatalogScreen as CustomAutoComplete }
