import { productsConstants } from '../constants';
const INITIAL_STATE = {
  projectImages: []
}

export function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case productsConstants.PROJECTS_TYPE_REQUEST:
      return Object.assign({}, { isFetching: true });
    case productsConstants.PROJECTS_TYPE_SUCCESS:
      return Object.assign({}, { isFetched: true, types: action.shootingTypes });
    case productsConstants.PROJECTS_TYPE_FAILURE:
      return {}

    case productsConstants.IMAGES_SUBMIT_TYPE_REQUEST:
      return Object.assign({}, { isImageSubmitting: true });
    case productsConstants.IMAGES_SUBMIT_TYPE_SUCCESS:
      return Object.assign({}, { isImageSubmitting: false, isImageSubmitted: true });
    case productsConstants.IMAGES_SUBMIT_TYPE_FAILURE:
      return Object.assign({}, { isImageSubmitted: false });
    case productsConstants.PRODUCTS_IMAGES_TYPE_FAILURE:
      return {}
    case productsConstants.ALL_IMAGES_SUBMIT_TYPE_REQUEST:
      return Object.assign({}, { isImageSubmitting: true });
    case productsConstants.ALL_IMAGES_SUBMIT_TYPE_SUCCESS:
      return Object.assign({}, { isImageSubmitting: false, isImageSubmitted: true });
    case productsConstants.ALL_IMAGES_SUBMIT_TYPE_FAILURE:
      return Object.assign({}, { isImageSubmitted: false });
    case productsConstants.CHECK_IMAGES:
      return Object.assign({}, { isImageChecking: true });
    case productsConstants.SUCCESS_CHECK_IMAGES:
      return Object.assign({}, { isImageChecking: false, isImageChecked: true, pendingImageProducts: action.products });
    case productsConstants.FAILURE_CHECK_IMAGES:
      return Object.assign({}, { isImageChecking: false, isImageChecked: false });
    case productsConstants.GET_REFERENCE_REQUEST:
      return Object.assign({}, { isFecthing: true });
    case productsConstants.GET_REFERENCE_SUCCESS:
      return Object.assign({}, { isFetched: true, products: action.products });
    case productsConstants.GET_REFERENCE_FAILURE:
      return {};
    case productsConstants.GET_COMMENTS_REQUEST:
      return Object.assign({}, { isCommentFetching: true });
    case productsConstants.GET_COMMENTS_SUCCESS:
      return Object.assign({}, { isCommentFetched: true, comments: action.comments });
    case productsConstants.GET_COMMENTS_FAILURE:
      return Object.assign({}, { isImageSubmitting: true });
    case productsConstants.SET_COMMENT_REQUEST:
      return Object.assign({}, { isCreating: true });
    case productsConstants.SET_COMMENT_SUCCESS:
      return Object.assign({}, { isCommentCreated: true, comments: action.comments });
    case productsConstants.SET_COMMENT_FAILURE:
      return Object.assign({}, { isCommentCreated: false });
    case productsConstants.SET_COMMENT_REFERENCE_REQUEST:
      return Object.assign({}, { isCreating: true });
    case productsConstants.SET_COMMENT_REFERENCE_SUCCESS:
      return Object.assign({}, { isCommentCreated: true, comments: action.comments });
    case productsConstants.SET_COMMENT_REFERENCE_FAILURE:
      return Object.assign({}, { isCommentCreated: false });
    case productsConstants.SET_COMMENT_PROJECT_REQUEST:
      return Object.assign({}, { isCreating: true });
    case productsConstants.SET_COMMENT_PROJECT_SUCCESS:
      return Object.assign({}, { isCommentCreated: true, comments: action.comments });
    case productsConstants.SET_COMMENT_PROJECT_FAILURE:
      return Object.assign({}, { isCommentCreated: false });
    case productsConstants.GET_PROJECT_HISTORY_REQUEST:
      return Object.assign({}, { isHistoryFetching: true });
    case productsConstants.GET_PROJECT_HISTORY_SUCCESS:
      return Object.assign({}, { isHistoryFetched: true, productHistory: action.productHistory });
    case productsConstants.GET_PROJECT_HISTORY_FAILURE:
      return Object.assign({}, { isHistoryFetched: false });
    case productsConstants.SET_URGENT_REQUEST:
      return Object.assign({}, { isCreatingUrgent: true });
    case productsConstants.SET_URGENT_SUCCESS:
      return Object.assign({}, { urgentCreated: true });
    case productsConstants.SET_URGENT_FAILURE:
      return Object.assign({}, { urgentCreated: false });
    case productsConstants.SET_PROJECT_VERSION_REQUEST:
      return Object.assign({}, { isCreatingVersion: true });
    case productsConstants.SET_PROJECT_VERSION_SUCCESS:
      return Object.assign({}, { versionCreated: true, productHistory: action.productHistory });
    case productsConstants.SET_PROJECT_VERSION_FAILURE:
      return Object.assign({}, { urgentCreated: false });
    case productsConstants.SET_PRODUCT_ORDER_REQUEST:
      return Object.assign({}, { reordering: true });
    case productsConstants.SET_PRODUCT_ORDER_SUCCESS:
      return Object.assign({}, { reordered: true });
    case productsConstants.SET_PRODUCT_ORDER_FAILURE:
      return Object.assign({}, { reordered: false });
    case productsConstants.ADD_PRODUCT_REQUEST:
      return Object.assign({}, { isFetching: true });
    case productsConstants.ADD_PRODUCT_SUCCESS:
      return Object.assign({}, { isFetched: true });
    case productsConstants.ADD_PRODUCT_FAILURE:
      return {};
    case productsConstants.GET_PRODUCT_REQUEST:
      return Object.assign({}, { reordering: true });
    case productsConstants.GET_PRODUCT_SUCCESS:
      return Object.assign({}, { productFetched: true, product: action.product });
    case productsConstants.GET_PRODUCT_FAILURE:
      return {};
    case productsConstants.GET_PRODUCT_IMAGES_REQUEST:
      return Object.assign({}, { isImageFetched: true });
    case productsConstants.GET_PRODUCT_IMAGES_SUCCESS:
      return Object.assign({}, { isImageFetching: true, productImages: action.productImages });
    case productsConstants.GET_PRODUCT_IMAGES_FAILURE:
      return {};
    case productsConstants.SELECT_IMAGES_PRODUCT_REQUEST:
      return Object.assign({}, { isImageSelectedFetched: true });
    case productsConstants.SELECT_IMAGES_PRODUCT_SUCCESS:
      return Object.assign({}, { isImageSelectedFetching: true, images: action.images });
    case productsConstants.SELECT_IMAGES_PRODUCT_FAILURE:
      return {};
    case productsConstants.UNSELECT_IMAGES_PRODUCT_REQUEST:
      return Object.assign({}, { isUnselectFetched: true });
    case productsConstants.UNSELECT_IMAGES_PRODUCT_SUCCESS:
      return Object.assign({}, { isUnselectFetching: true });
    case productsConstants.UNSELECT_IMAGES_PRODUCT_FAILURE:
      return {};
    case productsConstants.DELETE_PRODUCT_REQUEST:
      return Object.assign({}, { isFetching: true });
    case productsConstants.DELETE_PRODUCT_SUCCESS:
      return Object.assign({}, { isFetched: true });
    case productsConstants.DELETE_PRODUCT_FAILURE:
      return {};
    case productsConstants.CHANGE_LABEL_RATE_REQUEST:
      return Object.assign({}, { isRateOrLabelFetched: true })
    case productsConstants.CHANGE_LABEL_RATE_SUCCESS:
      return Object.assign({}, { isRateOrLabelFetching: true });
    case productsConstants.CHANGE_LABEL_RATE_FAILURE:
      return {};
    case productsConstants.VALIDATE_IMAGES_PRODUCT_REQUEST:
      return Object.assign({}, { isFetching: true })
    case productsConstants.VALIDATE_IMAGES_PRODUCT_SUCCESS:
      return Object.assign({}, { isImageValidated: true });
    case productsConstants.VALIDATE_IMAGES_PRODUCT_FAILURE:
      return {};
    case productsConstants.REJECT_IMAGES_PRODUCT_REQUEST:
      return Object.assign({}, { isFetching: true })
    case productsConstants.REJECT_IMAGES_PRODUCT_SUCCESS:
      return Object.assign({}, { isImageRejected: true });
    case productsConstants.REJECT_IMAGES_PRODUCT_FAILURE:
      return {};
    case productsConstants.ZIP_ADD_REQUEST:
      return Object.assign({}, { isFetching: true });
    case productsConstants.ZIP_ADD_SUCCESS:
      return Object.assign({}, { isFetchedZip: true, zip: action.zip });
    case productsConstants.ZIP_ADD_FAILURE:
      return {};
    case productsConstants.LINK_PRODUCT_REQUEST:
      return Object.assign({}, { isFetching: true })
    case productsConstants.LINK_PRODUCT_SUCCESS:
      return Object.assign({}, { linkProductIsFetched: true, product: action.product })
    case productsConstants.LINK_PRODUCT_FAILURE:
      return {}

    case productsConstants.GET_LINK_PRODUCT_REQUEST:
      return Object.assign({}, { isProductLinkedFecthing: true })
    case productsConstants.GET_LINK_PRODUCT_SUCCESS:
      return Object.assign({}, { isProductLinkedFecthing: false, isProductLinkedFetched: true, product: action.product })
    case productsConstants.GET_LINK_PRODUCT_FAILURE:
      return Object.assign({}, { isProductLinkedFecthing: false, product: {} })

    case productsConstants.EXPORT_VALIDATE_IMAGE_REQUEST:
      return Object.assign({}, { exportImageRequest: true });
    case productsConstants.EXPORT_VALIDATE_IMAGE_SUCCESS:
      return Object.assign({}, { exportImageSuccess: true });
    case productsConstants.EXPORT_VALIDATE_IMAGE_FAILURE:
      return {};

    case productsConstants.EXPORT_MERCH_REQUEST:
      return Object.assign({}, { exportMerchRequest: true });
    case productsConstants.EXPORT_MERCH_SUCCESS:
      return Object.assign({}, { exportMerchSuccess: true });
    case productsConstants.EXPORT_MERCH_FAILURE:
      return {};

    case productsConstants.GET_SYNC_DROPBOX_REQUEST:
      return Object.assign({}, { isSynchronizing: true });
    case productsConstants.GET_SYNC_DROPBOX_SUCCESS:
      return Object.assign({}, { isSynchronized: true })
    case productsConstants.GET_SYNC_DROPBOX_FAILURE:
      return Object.assign({}, {isSynchronizing: false});

      return {}
    //GET IMAGES BY PATH 
    case productsConstants.PRODUCTS_IMAGES_TYPE_REQUEST:
      return Object.assign({}, { isCreating: true });
    case productsConstants.PRODUCTS_IMAGES_TYPE_SUCCESS:
      return Object.assign({}, { isFetched: true, products: action.productImages });
    case productsConstants.PRODUCTS_IMAGES_TYPE_FAILURE:
      return {};
      // GET FOLDERS
    case productsConstants.GET_FOLDERS_REQUEST:
      return Object.assign({}, { isFolderFetching: true });
    case productsConstants.GET_FOLDERS_SUCCESS:
      return Object.assign({}, { isFolderFetched: true, folder: action.path });
    case productsConstants.GET_FOLDERS_FAILURE:
      return {};

    default:
      return state
  }
}
