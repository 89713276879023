import React, {Component} from 'react';
import { Panel } from 'primereact/panel';
import { Carousel } from 'primereact/carousel';
import { Knob } from 'primereact/knob';
import { connect } from 'react-redux';
import { projectActions } from '../../redux/actions';
import {isTablet} from 'react-device-detect'
import { withTranslation } from 'react-i18next';

class ProgressStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetched: false,
			stats: []
		}
	}

	componentDidMount(){
		this.props.getProjectStatistics()
	}

	static getDerivedStateFromProps (props, state) {
		if(props.isStatsFetched){
			return {
				stats: props.stats,
				isFetched: props.isStatsFetched
			}
		}
	}

	itemTemplate = (car) => {
		const {t}= this.props;
		var commented = car.countCommentedMedias
		var drafted = car.countDraftedMedias
		var validated = car.countValidatedMedias
		var waiting = car.countWaitingForValidationMedias
		var total = commented+drafted+validated+waiting
		var percentage = Math.floor((validated/total) * 100)
		return (<div>
			<h5>{car.projectTitle}( {total  + t('dashboardScreen.progress.image')})</h5>
			<div className='p-grid p-justify-center p-align-center'>

				<Knob value={isNaN(percentage)? 0 : percentage} readOnly textColor="#FFF"
				rangeColor="#252525"
				 valueColor="#1D40C8" size={isTablet ? 110 : 190}
				 strokeWidth={5} valueTemplate={"{value}%"}/>
				<div className={isTablet ? "p-mt-3 p-col-12" : "p-ml-3"}>
					<p>{validated + t('dashboardScreen.progress.validated')}</p>
					<p>{waiting + t('dashboardScreen.progress.waiting')}</p>
					<p>{drafted + t('dashboardScreen.progress.drafted')}</p>
					<p>{commented + t('dashboardScreen.progress.commented')}</p>
				</div>
			</div>
		</div>)
	}

	render() {
		const {t}= this.props;
		return (
			<>
				<h5>{t('dashboardScreen.progress.title')}</h5>
				<Panel header=" " className="p-pt-4">
					{this.state.isFetched &&( <Carousel value={this.state.stats} itemTemplate={this.itemTemplate} circular />)}
				</Panel>

			</>
		);
	}
}

function mapState(state) {
	const { isStatsFetched, stats} = state.project

	return {isStatsFetched, stats}
}

const actionCreator = {
	getProjectStatistics: projectActions.getProjectStatistics
};

const connectedActivities = connect(mapState, actionCreator)(withTranslation()(ProgressStats));
export { connectedActivities as ProgressStats };
