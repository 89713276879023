import React from "react";
import { useDispatch } from "react-redux";
import { store } from "../redux/helpers/store";
import { hubActions } from "../redux/actions/notifications.actions";
import { hubConstants } from "../redux/constants";
const signalR = require("@microsoft/signalr");
let user = JSON.parse(localStorage.getItem("user"));

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_BASE_URL}/Hub`, {
    useDefaultPath: false,
    transport: signalR.HttpTransportType.LongPolling,
    accessTokenFactory: () => user.auth.auth_token,
  })
  .configureLogging(signalR.LogLevel.Trace)
  .withAutomaticReconnect() // to automatically attempt to reconnect if the connection is lost. By default, the client will wait 0, 2, 10 and 30 seconds respectively before trying up to 4 reconnect attempts.
  .build();
connection.on("ReceiveNotification", (result) => {
  console.info("Yessss, I just received a notification!!!", result);  
hubActions.pushNotification()
 
});
if (connection.state == signalR.HubConnectionState.Disconnected) {
  connection
    .start()
    .then(() =>
      console.log(
        "SignalR Connected with connectionId ",
        connection.connectionId
      )
    )
    .catch((err) => console.log(`Error: ${err}`));
}

export const NotificationContext = React.createContext(connection);
