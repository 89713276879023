import React, { Component } from 'react';
import './callsheetComponent.scss'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { ScrollPanel } from 'primereact/scrollpanel'
import { connect } from 'react-redux'
import { membersAction, callsheetAction } from '../../../redux/actions'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { OverlayPanel } from 'primereact/overlaypanel';
import { withTranslation } from 'react-i18next';

class CallsheetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateCallsheetPopup: false,
            loading: false,
            callsheetPackage: {
                address: this.props.address,
                date: this.props.date,
                callTimes: this.props.callTime,
                members: this.props.projectMembers
            },
            t: this.props.t
        }
        this.handleChangeUpdate = this.handleChangeUpdate.bind(this)
        this.downloadCallSheet = this.downloadCallSheet.bind(this)
        this.op = React.createRef()
    }

    componentDidMount() {
        this.props.getMembers(this.props.projectId)
    }

    static getDerivedStateFromProps(props, state) {
        if ( props.members && Object.keys(props.members).length > 0 && props.isFetched ) {
            return { members: props.members }
        }
        if ( props.callsheet.isCallsheetFetching ) {
            return { loading: true }
        }
        if ( props.callsheet.isCallsheetFetched ) {
            return { loading: false }
        }
    }

    handleChangeUpdate(event) {
        let name = event.target.name
        let value = event.target.value

        this.setState({
            callsheetPackage: { ...this.state.callsheetPackage, [name]: value }
        })
    }

    addTask = () => {
        this.setState(prevState => ({
            ...prevState,
            callsheetPackage: {
                ...prevState.callsheetPackage,
                callTimes: this.state.callsheetPackage.callTimes.concat([{ time: null, description: null }])
            }
        }))
    }

    setTime = (idx, value) => {

        const newCallTimes = this.state.callsheetPackage.callTimes.map((callTime, sidx) => {
            if ( idx !== sidx ) return callTime;
            return { ...callTime, time: moment(value).format(), description: callTime.description };
        });

        this.setState({
            callsheetPackage: {
                ...this.state.callsheetPackage,
                callTimes: newCallTimes
            }
        })
    }

    handleCallTimesChange = (idx) => (evt) => {
        const newCallTimes = this.state.callsheetPackage.callTimes.map((callTime, sidx) => {
            if ( idx !== sidx ) return callTime;
            return { ...callTime, description: evt.target.value, time: callTime.time };
        });

        this.setState({
            callsheetPackage: {
                ...this.state.callsheetPackage, callTimes: newCallTimes
            }
        })
    }

    downloadCallSheet() {
        this.props.downloadCallsheet(this.props.projectId)
    }


    render() {

        const updateCallsheetPopupFooter = (
            <div className="p-pt-3 p-pb-1 popupFooterContainer">
                <Button className="cancelButton" label={this.state.t('common.cancel')} onClick={() => {
                    this.setState({
                        updateCallsheetPopup: false, callsheetPackage: {
                            address: this.props.address,
                            date: this.props.date,
                            callTimes: [],
                            members: this.props.projectMembers
                        }
                    })
                }}/>
                <Button className="validationButton" label={this.state.t('callSheet.modifyCallSheet')} onClick={() => {
                    this.props.updateCallSheet(this.props.id, this.state.callsheetPackage)
                    this.setState({
                        updateCallsheetPopup: false, callsheetPackage: {
                            address: this.props.address,
                            date: this.props.date,
                            callTimes: [],
                            members: this.props.projectMembers
                        }
                    })
                }}/>
            </div>
        );

        return (
            <>
                <div style={{ width: "48%", height: "25vh" }} className="p-d-flex p-flex-column p-mb-5">
                    <div className="callsheetContainer p-p-4 p-mt-6 p-mb-3" key={this.props.id}>
                        <div className="callsheetTime">{moment(this.props.date).format('LL')}</div>
                        <div className="p-d-flex p-flex-column p-jc-between callsheetBody">
                            <ul className="p-m-0 p-p-0">
                                {this.props.callTime.map((call, i) => (
                                    <li key={call + ' ' + i}
                                        style={{ listStyleType: "none" }}>{moment(call.time).format('H[h]mm')} : {call.description}</li>
                                ))}
                            </ul>
                            <div>Rendez-vous {this.props.address}</div>
                            <p>{this.props.projectMembers.map((member) => (
                                member.name
                            )).join(' - ')}</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button type="button" icon='pi icon-Action_Open icon-2x' className="p-button-text p-p-0"
                                    onClick={(e) => this.op.current.toggle(e)}></Button>
                            <OverlayPanel ref={this.op}>
                                <div className="p-d-flex p-flex-column">
                                    <Button className="p-button p-button-text" label={this.state.t('common.modify')}
                                            onClick={() => {this.setState({ updateCallsheetPopup: true })}}></Button>
                                    <Button className="p-button p-button-text" label={this.state.t('common.delete')}
                                            onClick={() => {this.props.deleteCallsheet(this.props.id)}}></Button>
                                </div>
                            </OverlayPanel>
                        </div>
                    </div>
                </div>

                <Dialog header={this.state.t('callSheet.modifyCallSheet')} footer={updateCallsheetPopupFooter}
                        visible={this.state.updateCallsheetPopup}
                        onHide={() => {
                            this.setState({
                                updateCallsheetPopup: false, callsheetPackage: {
                                    address: this.props.address,
                                    date: this.props.date,
                                    callTimes: [],
                                    members: this.props.projectMembers
                                }
                            })
                        }}
                        className="p-d-flex p-flex-column p-jc-between popupContainer">
                    <ScrollPanel>
                        <div className="p-d-flex p-flex-column p-jc-around p-mb-1 scrollPanelContainer">
                            <Calendar value={new Date(this.props.date)} minDate={new Date()}
                                      onChange={(e) => this.setState({
                                          callsheetPackage: {
                                              ...this.state.callsheetPackage,
                                              date: moment(e.value).format()
                                          }
                                      })}></Calendar>
                            <div className="p-d-flex p-flex-column p-ai-end">
                                {this.state.callsheetPackage.callTimes.map((call, i) => {
                                    return <div id="addTask"
                                                className="p-d-flex p-flex-row p-jc-between p-mt-3 p-mb-3 calltimeContainer">
                                        <DatePicker
                                            className="callsheet-input-horaire"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            placeholderText={moment(call.time).format('LT')}
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            selected={this.state.callsheetPackage.callTimes.time}
                                            onChange={value => {
                                                this.setTime(i, value)
                                            }}
                                            autoComplete="off"
                                        />
                                        <InputText placeholder="Description" name="description"
                                                   defaultValue={call.description}
                                                   onChange={this.handleCallTimesChange(i)}
                                                   className="inputDescription"/>
                                    </div>
                                })}
                                <div onClick={this.addTask} className="pointer">
                                    <i className="pi pi-plus p-mr-1"></i>
                                    {this.state.t('callSheet.addTask')}
                                </div>
                            </div>
                            <InputText placeholder={this.state.t('callSheet.rdv') + " " + this.props.address}
                                       name="address" value={this.state.callsheetPackage.address}
                                       onChange={this.handleChangeUpdate}/>

                            {/* !!! BUG = valeur multiselect n'affiche pas noms des membres déjà sélectionnés mais les prends en compte dans update => A REGLER !!! */}

                            <MultiSelect
                                className="multiselect"
                                value={this.state.callsheetPackage.members}
                                onChange={(e) => this.setState({
                                    callsheetPackage: {
                                        ...this.state.callsheetPackage,
                                        members: e.value
                                    }
                                })}
                                options={this.props.members.type}
                                optionLabel="email"
                                display="chip"/>
                        </div>
                    </ScrollPanel>
                </Dialog>
                <Button icon="icon-Session_Download icon-5x" className="download-button" loading={this.state.loading}
                        onClick={this.downloadCallSheet}/>
            </>
        )
    }
}

function mapState(state) {
    const { members, callsheet } = state;
    return { members, callsheet }
}

const actionCreators = {
    getMembers: membersAction.getMembers,
    downloadCallsheet: callsheetAction.downloadCallsheet
}

const connectedApp = connect(mapState, actionCreators)(withTranslation()(CallsheetComponent));
export { connectedApp as CallsheetComponent };
