import {materialConstants} from '../constants';

export function material(state = {}, action){
    switch(action.type) {
        case materialConstants.MATERIALS_ADD_REQUEST:
            return Object.assign({}, { isFetching: true });
        case materialConstants.MATERIALS_ADD_SUCCESS:
            return Object.assign({}, { type: action.material, _isFetched: true });
        case materialConstants.MATERIALS_ADD_FAILURE:
            return {}

        case materialConstants.GET_MATERIALS_REQUEST:
            return Object.assign({}, { isFetching: true });
        case materialConstants.GET_MATERIALS_SUCCESS:
            return Object.assign({}, { type: action.material, isFetched: true});
        case materialConstants.GET_MATERIALS_FAILURE:
            return {}

        case materialConstants.UPDATE_MATERIALS_REQUEST:
            return Object.assign({}, {isFetching: true});
        case materialConstants.UPDATE_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, fetched: true});
        case materialConstants.UPDATE_MATERIALS_FAILURE:
            return {}

        case materialConstants.DELETE_MATERIALS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case materialConstants.DELETE_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, isDeleted: true});
        case materialConstants.DELETE_MATERIALS_FAILURE:
            return {}

        case materialConstants.VALIDATE_MATERIALS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case materialConstants.VALIDATE_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, isValidate: true});
        case materialConstants.VALIDATE_MATERIALS_FAILURE:
            return {}

        case materialConstants.REJECT_MATERIALS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case materialConstants.REJECT_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, isRejected: true});
        case materialConstants.REJECT_MATERIALS_FAILURE:
            return {}

        case materialConstants.DRAFT_MATERIALS_REQUEST:
            return Object.assign({}, {isDrafting: true});
        case materialConstants.DRAFT_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, isDrafted: true});
        case materialConstants.DRAFT_MATERIALS_FAILURE:
            return {}

        case materialConstants.DELETE_ALL_MATERIALS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case materialConstants.DELETE_ALL_MATERIALS_SUCCESS:
            return Object.assign({}, {type: action.material, isAllDeleted: true});
        case materialConstants.DELETE_ALL_MATERIALS_FAILURE:
            return {}

        default:
            return state
    }
}
