import React, {Component} from 'react';
import Header from '../../common/Header';
import {ProjectMenu} from '../common/ProjectMenu';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProjectFileCard} from './ProjectFileCard';
import {Button} from 'primereact/button';
import {alertActions, projectActions} from '../../../redux/actions'
import {connect} from 'react-redux';
import {Dialog} from 'primereact/dialog'
import ReactDropzone from "react-dropzone";
import {ReactComponent as PDFImg} from '../../../img/pdf.svg';
import {ProgressSpinner} from 'primereact/progressspinner'
import NoFile from '../../../img/nofiles.svg'
import { withTranslation } from 'react-i18next';

class ProjectFilesScreen extends Component {

	constructor( props ) {
		super(props)
		this.state = {
			project: {
				files: [],
			},
			isFetched: false,
			isFetching: false,
			showAddFileDialog: false,
			showReplaceFileDialog: false,
			fileCategory: null,
			loading: true,
			t: this.props.t
		}

		this.showAddFileDialog = this.showAddFileDialog.bind(this);
		this.hideAddFileDialog = this.hideAddFileDialog.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
	}

	componentDidMount() {
		if (this.props.match.params.projectId) {
			this.props.getProjectFiles(this.props.match.params.projectId)
			this.setState({projectId: this.props.match.params.projectId})
		}
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.isFetched && props.project !== state.project && props.project.files !== undefined) {
			return {
				project: props.project,
				isFetched: props.isFetched,
				loading: false
			}
		}

		if (props.isFetching) {
			return {
				isFetched: false,
				loading: true
			}
		}

		if (props.isSuccessFetched) {
			props.getProjectFiles(state.projectId)
			return {loading: true}
		}

		if (props.isDeleteFetched) {
			props.getProjectFiles(state.projectId)
			return {loading: true}
		}

		if (props.isDowloadFetched) {
			props.getProjectFiles(state.projectId)
			return {loading: true}
		}

		return state
	}


	showAddFileDialog() {
		this.setState({showAddFileDialog: true});
	}

	hideAddFileDialog() {
		this.setState({showAddFileDialog: false});
	}

	uploadFile( acceptedFiles ) {
		console.log("acceptedFiles" + acceptedFiles);
		if (acceptedFiles !== null && acceptedFiles.length > 0) {
			this.props.addDocument(this.state.projectId, acceptedFiles[0], 1)
			this.hideAddFileDialog();
			this.setState({
				loading: true
			})
			// return setTimeout(
			//     () => window.location.reload(),
			//     500
			//   );
		}

	}

	deleteFile( fileId ) {
		this.props.deleteDocument(this.state.projectId, fileId);
		this.setState({
			loading: true
		})
	}

	downloadFile( fileId ) {
		this.props.downloadFile(this.state.projectId, fileId);
		this.setState({
			loading: true
		})
	}

	render() {

		return (
			<div>
				<Header/>

				<ScrollPanel className="sf-scrollpanel">
					<ProjectMenu activeItemIndex={1}/>

					<div className="p-d-flex p-ai-center p-mt-6 p-mb-3 p-jc-between">
						<h5>{this.state.t('projectFile.title')}</h5>
						{this.state.project.files.length > 0 ?
						<Button className="p-button-rounded" label={this.state.t('projectFile.addFile')}
						        onClick={() => this.showAddFileDialog()}/>
							: <></>}
					</div>
					{this.state.loading ?
						<div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "60vh"}}>
							<ProgressSpinner className="p-progress-spinner"/>
						</div> :
						this.state.isFetched && this.state.project && this.state.project.files && this.state.project.files.length > 0 ?
							<div className="p-grid">
								{this.state.project.files.map(( file, index ) => {
									return <div className="p-col-12 p-md-6 p-lg-2 p-px-3">
										<ProjectFileCard
											file={file}
											projectId={this.state.projectId}
											deleteClicked={() => this.deleteFile(file.id)}
											downloadClicked={() => this.downloadFile(file.id)}
											// deleteClick = {() => this.deleteFile(file.id)}
											// replaceClick = {() => this.replaceFile(file.id)}
											// downloadClick = {() => this.downloadFile(file.id)}
										/>
									</div>
								})}
							</div> :
							<div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "60vh"}}>
								<img src={NoFile} style={{width: "5%", marginBottom: "1vh"}}/>
								<p style={{color: "#AEAEAE"}}>{this.state.t('projectFile.noFile')}</p>
								<div style={{cursor: 'pointer'}} onClick={() => this.showAddFileDialog()}>
									<i className="pi pi-plus p-mr-1"></i>
									{this.state.t('projectFile.addFile')}
								</div>
							</div>}
				</ScrollPanel>

				<Dialog position='center' header={this.state.t('projectFile.addFile')}
				        visible={this.state.showAddFileDialog}
				        style={{width: '30vw'}}
				        onHide={() => this.hideAddFileDialog()}>
					<div className="p-d-flex p-jc-center">
						<ReactDropzone onDrop={acceptedFiles => this.uploadFile(acceptedFiles)} multiple={false}
						               accept="application/pdf">
							{( {getRootProps, getInputProps} ) => (
								<section className="sf-dropzone">
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<p>
											<PDFImg/>&nbsp;
											{this.state.t('projectFile.uploadFile')}
										</p>
									</div>
								</section>
							)}
						</ReactDropzone>
					</div>
				</Dialog>

			</div>
		);
	}
}

const mapStateToProps = state => {
	const {project} = state;
	return project;
}

const mapDispatchToProps = {
	getProjectFiles: projectActions.getProjectFiles,
	getFileCategories: projectActions.getFileCategories,
	addDocument: projectActions.addDocument,
	deleteDocument: projectActions.deleteDocument,
	downloadFile: projectActions.downloadFile,
	successWarning: alertActions.success
}

const connectedProject = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectFilesScreen));
export {connectedProject as ProjectFilesScreen};
