import React, { Component } from 'react'
import { TabMenu } from 'primereact/tabmenu';
import {history} from '../../redux/helpers'
import { withTranslation } from 'react-i18next';

class TasksMenu extends Component {
    constructor(props) {
        super(props);
        let project = JSON.parse(localStorage.getItem('project'));
        this.state = {
            items: [
                { label: this.props.t('task.menuProd'), command: () => { history.push(`/projects/${project.projectId}/tasks`); } },
                { label: this.props.t('task.menuRetouch'), command: () => { history.push(`/projects/${project.projectId}/retouch`); } }
            ]
        };
    }

    render () {
        return (
            <div className="p-d-flex">
                <div className="p-col">
                    <TabMenu model={this.state.items} activeIndex={this.props.activeItemIndex} />
                </div>
            </div>
        )
    }
}

export default withTranslation()(TasksMenu)
