import React, {Component} from 'react'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {ScrollPanel} from 'primereact/scrollpanel'
import {Dropdown} from 'primereact/dropdown';
import {alertActions, projectActions} from '../../../redux/actions'
import {connect} from 'react-redux';
import InputEmail from '../../common/InputEmail'
import { Dialog } from 'primereact/dialog'
import { withTranslation } from 'react-i18next';

class Member extends Component {

	constructor( props ) {
		super(props)
		this.state = {
			members: [],
			projectId: null,
			memberRoles: [],
			inputError: false,
			t: this.props.t
		}
	}

	componentDidMount = () => {
		this.props.getMemberRoles()
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.memberRoles && Object.keys(props.memberRoles).length > 0) {
			return {memberRoles: props.memberRoles}
		}
		if(props.members && props.projectId && state.projectId === null) {
			return {
				members: props.members,
				projectId: props.projectId,
			}
		}
		return state
	}

	cancelClicked = () => {
		this.setState({
			members: [],
			projectId: null,
			inputError: false,
		})
		this.props.cancel(this.state.oldMembers)
	}

	validateClicked = () => {
		let {members} = this.state
		const membre = members.filter(membre => membre.email === '')
		const role = members.filter(membre => membre.role === '')
		if (membre.length > 0) {
			this.props.warningAlert(this.state.t('toast.emptyEmail'));
		} else if (role.length > 0) {
			this.props.warningAlert(this.state.t('toast.emptyRole'));
		}else if (this.state.inputError){
			this.props.warningAlert(this.state.t('toast.errorInput'));
		} else {
			this.props.validate(this.state.members)
		}
	}

	addMember() {
		this.setState(( prevState ) => ({
			members: [...prevState.members, {name: '', email: '', role: ''}]
		}))

	}

	deleteMember( id ) {
		if (id != null)
			this.props.delete(id)
	}

	render() {
		return (
			<Dialog position='center' header="Inviter des membres"
			        visible={this.props.visible}
			        onHide={() => this.cancelClicked()}>
				<div className="p-d-flex p-flex-column">
					<ScrollPanel>
						<div className="p-d-flex p-jc-center p-ai-stretch p-fluid p-flex-column p-pt-3"
						     style={{width: '100%', maxHeight: '600px'}}>
							{this.state.members && this.state.members.map(( member, index ) => {
								var updateName = ( e ) => {
									var members = this.state.members
									members[index].name = e.target.value
									this.setState({members: members})
								}
								var updateEmail = ( value ) => {
									var members = this.state.members
									members[index].email = value
									this.setState({members: members})
								}
								var updateRole = ( e ) => {
									var members = this.state.members
									members[index].role = e.target.value
									this.setState({members: members})
								}
								var deleteMember = () => {
									var members = this.state.members
									members.splice(index, 1)
									this.setState({members: members})
								}
								return (
									<div className="p-formgroup-inline p-fluid p-grid">
										<div className="p-field p-col">
											<InputText
												value={member.name}
												type="text"
												placeholder={this.state.t('common.name')}
												onChange={updateName}
											/>
										</div>
										<div className="p-field p-col">
											<InputEmail
												value={member.email}
												placeholder={this.state.t('common.email')}
												onChange={updateEmail}
												isError={bool => this.setState({inputError: bool})}
												required
											/>
										</div>
										<div className="p-field p-col">
											<Dropdown value={member.role}
											          options={this.state.memberRoles}
											          optionLabel='name'
											          optionValue="name"
											          placeholder={this.state.t('common.role')}
											          onChange={updateRole}
											/>
										</div>
										<div className="p-col-1">
											<Button icon="icon-Close icon-2x"
											        className="p-button-rounded p-button-text"
											        onClick={() => {
												        deleteMember();
												        this.deleteMember(member.userId)
											        }}/>
										</div>
									</div>
								)
							})}
						</div>
					</ScrollPanel>

					<div className="p-grid p-d-flex p-jc-start">
						<div className="col p-mb-1">
							<Button className="p-button-link" onClick={() => this.addMember()}>{this.state.t('projectDetails.addMember')}</Button>
						</div>
					</div>
					<div className="p-grid p-d-flex p-jc-end">
						<div className="p-col p-text-right">
							<Button className="p-button-rounded p-button-outlined"
							        onClick={() => this.cancelClicked()}>{this.state.t('common.cancel')}</Button>

							<Button className="p-button-rounded p-ml-3"
							        onClick={() => this.validateClicked()}
							        type="submit">{this.state.t('common.valid')}</Button>
						</div>
					</div>
				</div>
			</Dialog>
		)
	}
}

const mapStateToProps = state => {
	const {memberRoles} = state.project

	return {memberRoles}
}

const mapDispatchToProps = {
	getMemberRoles: projectActions.getMemberRoles,
	warningAlert: alertActions.warning

}

const connectedProject = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Member))
export {connectedProject as Member}

