import { commonService } from './common.service';

export const activitiesService = {
  getAllActivities
};

function getAllActivities() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    },

  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/activities`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}