import React, {Component} from 'react'
import {CustomAutoComplete} from '../../common/CustomAutoComplete'
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import Dropzone from "react-dropzone";
import {Button} from "primereact/button";
import {DownloadCloudButton} from "../../common/SVGButtons";
import moment from 'moment';
import {LazyImage} from '../../common/LazyImage'
import {isTablet} from 'react-device-detect'
import { withTranslation } from 'react-i18next';

class AddProduct extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			newProductRef: "",
			newProductProd: "",
			thumbnail: "",
			availabilityDate: null,
			uploadDocument: [],
			existingProduct: false,
			errors: {},
			filteredProducts: null,
			t: this.props.t
		};
		this.onChange = this.onChange.bind(this)
		this.searchProductRef = this.searchProductRef.bind(this)
		this.searchProductName = this.searchProductName.bind(this)
		this.selectProduct = this.selectProduct.bind(this)
	}

	onChange( e ) {
		this.setState(prevState => ({
			newProduct: {
				...prevState.newProduct,
				name: e.target.value
			}
		}))
	}

	onDrop = ( file ) => {
		let errors = this.state.errors
		if (file.length > 0) {
			errors['uploadDocument'] = "";
			let doc = [];
			let reader = new FileReader();
			reader.onload = (file => {
				return () => {
					// let data = reader.result;
					doc.push({name: file.name, document: file});
					this.setState({uploadDocument: doc})
				}
			})(file[0]);
			reader.readAsDataURL(file[0]);
		} else {
			errors['uploadDocument'] = this.state.t('common.pdfAllowed');
		}
		this.setState({errors: errors})
	};

	searchProductRef( event ) {
		setTimeout(() => {
			let filteredProducts;
			if (!event.query.trim().length) {
				filteredProducts = [...this.props.catalog];
			} else {
				filteredProducts = this.props.catalog.filter(( product ) => {
					return product.reference.toLowerCase().startsWith(event.query.toLowerCase());
				});
			}
			this.setState({filteredProducts: filteredProducts});
		}, 250);
	}

	searchProductName = event => {
		setTimeout(() => {
			let filteredProducts;
			if (!event.query.trim().length) {
				filteredProducts = [...this.props.catalog];
			} else {
				filteredProducts = this.props.catalog.filter(( product ) => {
					return product.product.toLowerCase().startsWith(event.query.toLowerCase());
				});
			}
			this.setState({filteredProducts: filteredProducts});
		}, 250);
	}


	selectProduct = ( e ) => {
		let availabilityDate
		if (e.availabilityDate === "A cadencer") {
			availabilityDate = ""
		} else {
			availabilityDate = new Date(e.availabilityDate)
		}
		this.setState({
			existingProduct: true,
			newProductRef: e.reference,
			newProductProd: e.product,
			availabilityDate: availabilityDate,
			thumbnail: e.thumbnail
		})

	}

	onAddProduct = () => {
		let {newProductRef, newProductProd, availabilityDate, uploadDocument} = this.state
		let newProduct = []
		if (availabilityDate !== null)
			if (availabilityDate.length > 0)
				availabilityDate = moment(availabilityDate).format()
		if (uploadDocument.length === 0) {
			newProduct = [
				[{
					Reference: newProductRef,
					Title: newProductProd,
					AvailibiltyDate: availabilityDate,
				}]]
		} else {
			newProduct = [
				[{
					Reference: newProductRef,
					Title: newProductProd,
					AvailibiltyDate: availabilityDate,
				}],
				uploadDocument
			]

		}
		this.props.addProduct(newProduct)
		this.hideDialog()
	}

	hideDialog = () => {
		this.setState({
			newProductRef: "",
			newProductProd: "",
			thumbnail: "",
			availabilityDate: null,
			uploadDocument: [],
			existingProduct: false,
			errors: {},
			filteredProducts: null
		})
		this.props.hideDialog()
	}

	render() {

		const dialogFooter = (
			<div className="p-d-flex p-jc-end p-mt-6">
	            <span className="p-d-flex">
		            <Button
			            label={this.state.t('projectList.shootingList.reset')}
			            icon="pi pi-refresh"
			            className="p-button-text"
			            onClick={() => this.setState({
				            newProductRef: '',
				            newProductProd: '',
				            availabilityDate: null,
				            existingProduct: false
			            })}
		            />
	                <Button
		                label={this.state.t('common.cancel')}
		                icon="pi pi-times"
		                className="p-button-text"
		                onClick={this.props.hideDialog}
	                />
	                <Button
		                label={this.state.t('common.add')}
		                icon="pi pi-check"
		                className="p-button-text"
		                onClick={this.onAddProduct}
	                />
	            </span>
			</div>
		)

		return (
			<Dialog
				visible={this.props.openDialog}
				style={isTablet ? {width: '60vw'} : {width: '30vw'}}
				header={this.state.t('catalog.dialog.productAdd')}
				footer={dialogFooter}
				modal
				position="center"
				className="p-fluid"
				onHide={this.hideDialog}>
				<div className="p-d-flex p-flex-column p-mt-3" style={{height: "80%"}}>
					<CustomAutoComplete
						className="p-mb-6"
						value={this.state.newProductRef}
						placeholder={this.state.t('catalog.dialog.productRef')}
						field="reference"
						onSelect={e => this.selectProduct(e)}
						onChange={e => this.setState({newProductRef: e})}
						readOnly={this.state.existingProduct ? true : false}
					/>
					<CustomAutoComplete
						className="p-mb-6"
						value={this.state.newProductProd}
						placeholder={this.state.t('catalog.dialog.productName')}
						field="reference"
						onSelect={e => this.selectProduct(e)}
						onChange={e => this.setState({newProductProd: e})}
						readOnly={this.state.existingProduct ? true : false}
					/>
					<Calendar
						className="p-mb-6"
						placeholder={this.state.t('catalog.dialog.productDate')}
						dateFormat="dd/mm/yy"
						value={this.state.availabilityDate}
						onChange={e => {
							this.setState({availabilityDate: e.value})
						}}
						disabled={this.state.existingProduct ? true : false}
					/>
					{this.state.existingProduct ?

						(<div className="p-d-flex p-jc-center p-ai-center">
							<LazyImage src={this.state.thumbnail} alt={this.state.newProductProd}
							           style={{maxWidth: '100%', maxHeight: '100%'}}/>
						</div>)

						:
						(<div className="p-d-flex p-flex-column p-jc-center p-ai-center dropzone-file">
							<Dropzone onDrop={this.onDrop} multiple={false} accept="image/jpeg, image/png" required>
								{( {getRootProps, getInputProps} ) => (
									<section>
										<div {...getRootProps()}>
											<input {...getInputProps()} />
											<span><DownloadCloudButton title={this.state.t('catalog.dialog.dragDrop')}/></span>
										</div>
									</section>
								)}
							</Dropzone>
							{this.state.uploadDocument.length > 0 &&
							<ul className="list-unstyled">
								<li>
									{this.state.uploadDocument[0].name}
								</li>
							</ul>
							}
						</div>)}
				</div>
			</Dialog>
		)
	}
}

export default withTranslation()(AddProduct)
