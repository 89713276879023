import React, { Component } from 'react';
var Barcode = require('react-barcode');


class CustomBarcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            barcode: '',
        };
    }

    componentDidMount() {
        this.setState({ barcode: this.props.value });
    }
    render() {
        return (
                <Barcode  value={this.state.barcode}  width={1}  fontSize={12}  height={50} format="CODE128" />
        )
    }
}
export default CustomBarcode;