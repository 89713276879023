import { sessiondetailsConstants } from '../constants';
import { sessiondetailsService } from '../../services';
import {alertActions} from './alert.actions'
export const sessiondetailsActions = {
    getImageOverview,
	addComment,
    getImageHistory,
    changeImageHistory,
    validateComment
  };

  function getImageOverview(projectId, productId, imageId) {
    return dispatch => {
      dispatch(request());
      sessiondetailsService.getImageOverview(projectId, productId, imageId)
        .then(
          image => dispatch(success(image)),
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
    };

    function request() { return { type: sessiondetailsConstants.IMAGE_DETAILS_REQUEST } }
    function success(image) { return { type: sessiondetailsConstants.IMAGE_DETAILS_SUCCESS, image } }
    function failure(error) { return { type: sessiondetailsConstants.IMAGE_DETAILS_FAILURE, error } }
  }
  function validateComment(projecttId, commentId) {
    return dispatch => {
      dispatch(request());
      sessiondetailsService.validateComment(projecttId, commentId)
        .then(
          comments => {
            dispatch(success(comments))
            dispatch(alertActions.success('Le commentaire a été validé'));
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
    };
    function request() { return { type: sessiondetailsConstants.VALIDATE_COMMENT_REQUEST } }
    function success(comments) { return { type: sessiondetailsConstants.VALIDATE_COMMENT_SUCCESS, comments } }
    function failure(error) { return { type: sessiondetailsConstants.VALIDATE_COMMENT_FAILURE, error } }
  }
  function addComment(projecttId, productId, imageId, comment) {
    return dispatch => {
      dispatch(request());
      sessiondetailsService.addImageComment(projecttId, productId, imageId, comment)
        .then(
          image => {
            dispatch(success(image))
            dispatch(alertActions.success('Le commentaire a été ajouté'));
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
    };

    function request() { return { type: sessiondetailsConstants.ADD_COMMENT_REQUEST } }
    function success(image) { return { type: sessiondetailsConstants.ADD_COMMENT_SUCCESS, image } }
    function failure(error) { return { type: sessiondetailsConstants.ADD_COMMENT_FAILURE, error } }
}

 function getImageHistory(projectId, productId, imageId) {
    return dispatch => {
      dispatch(request());
      sessiondetailsService.getImageHistory(projectId, productId, imageId)
        .then(
          historyImage => dispatch(success(historyImage)),
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
    };

    function request() { return { type: sessiondetailsConstants.IMAGE_HISTORY_REQUEST } }
    function success(historyImage) { return { type: sessiondetailsConstants.IMAGE_HISTORY_SUCCESS, historyImage } }
    function failure(error) { return { type: sessiondetailsConstants.IMAGE_HISTORY_FAILURE, error } }
  }

  function changeImageHistory(projectId, productId, imageId, versionId) {
    return dispatch => {
      dispatch(request());
      sessiondetailsService.changeImageHistory(projectId, productId, imageId, versionId)
        .then(
          historyImage => dispatch(success(historyImage)),
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
    };

    function request() { return { type: sessiondetailsConstants.IMAGE_HISTORY_CHANGE_REQUEST } }
    function success(historyImage) { return { type: sessiondetailsConstants.IMAGE_HISTORY_CHANGE_SUCCESS, historyImage } }
    function failure(error) { return { type: sessiondetailsConstants.IMAGE_HISTORY_CHANGE_FAILURE, error } }
  }
