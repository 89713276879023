import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authentication } from './authentication.reducer';
import { activities } from './activities.reducer';
import { alert } from './alert.reducer';
import { project } from './project.reducer';
import { catalog } from './catalog.reducer';
import { contributors } from './contributors.reducer';
import { place } from './places.reducer';
import { material } from './material.reducer';
import { sessiondetails } from './sessiondetails.reducer';
import { products } from './products.reducer';
import { callsheet } from './callsheet.reducer';
import { members } from './members.reducer'
import { company } from './settings.reducer'
import { tasks } from './tasks.reducer'
import { search } from './search.reducer'
import { media } from './medias.reducer';
import { notifications } from './notifications.reducer';

import { user } from './user.reducer';
const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    authentication,
    alert,
    user,
    activities,
    project,
    catalog,
    contributors,
    place,
    material,
    sessiondetails,
    products,
    callsheet,
    members,
    company,
    tasks,
    search,
    media,
    notifications
})
export default createRootReducer;
