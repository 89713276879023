import React, { Component } from "react";
import { Button } from 'primereact/button';
import { Divider } from "primereact/divider";
import { withTranslation } from 'react-i18next';

class SessionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medias: []
        }
        this.UnCheckMedias = this.UnCheckMedias.bind(this);
        this.UpdateSelectMedias = this.UpdateSelectMedias.bind(this);
        this.validateMedias = this.validateMedias.bind(this);
        this.getBoard = this.getBoard.bind(this);
        this.showCommentProjectPopup = this.showCommentProjectPopup.bind(this);
        this.showchangeRefPopup = this.showchangeRefPopup.bind(this);
        this.exportValideImage = this.exportValideImage.bind(this);
        this.exportMerch = this.exportMerch.bind(this);
        this.showLinkedPopup = this.showLinkedPopup.bind(this);
        this.syncButtonClicked = this.syncButtonClicked.bind(this);
        this.showZipPopup = this.showZipPopup.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.medias) {
            return {
                medias: props.medias,
                isDownloadFetching: props.isDownloadFetching,
                isSynchronizing: props.isSynchronizing,
                exportImageRequest: props.exportImageRequest,
                needToBeSynchronized: props.needToBeSynchronized
            }
        }
        return state
    }
    syncButtonClicked() {
        this.props.syncButtonClicked();
    }
    exportValideImage() {
        this.props.exportValideImage();
    }
    exportMerch() {
        this.props.exportMerch();
    }
    showZipPopup() {
        this.props.showZipPopup()
    }
    showLinkedPopup() {
        this.props.showLinkedPopup();
    }
    showchangeRefPopup() {
        this.props.showchangeRefPopup();
    }
    showCommentProjectPopup() {
        this.props.showCommentProjectPopup();
    }
    getBoard() {
        this.props.getBoard();
    }
    validateMedias() {
        this.props.validateMedias();
    }
    UpdateSelectMedias(isSelected) {
        this.props.UpdateSelectMedias(isSelected);
    }
    UnCheckMedias() {
        this.props.UnCheckMedias(this.state.medias);
    }
    render() {
        const {t} = this.props;

        return (
            <div>
                <div className="p-d-flex p-jc-start p-flex-column">
                    {this.state.medias.length > 0 ?
                        (
                            <div>
                                <div className="p-grid p-align-center" >
                                    <div className="p-col" style={{ padding: "8%" }}>
                                       <span > {this.state.medias.length + " "+ t('session.images')}</span>
                                           </div>
                                    <div className="p-col-2 p-md-2">
                                        <Button icon="pi pi-times"
                                                className="p-button-rounded p-button-danger session-button p-button-text"
                                                onClick={() => this.UnCheckMedias()} />
                                    </div>
                                </div>
                                <Button
                                    className="p-button-text session-button p-mb-1"
                                    label={t('session.addSelection')}
                                    icon="icon-Session_Select icon-3x"
                                    onClick={() => this.UpdateSelectMedias(true)}
                                />

                                <Button
                                    className="p-button-text session-button p-mb-1"
                                    label={t('session.removeSelection')}
                                    icon="icon-Session_Deselect icon-3x"
                                    onClick={() => this.UpdateSelectMedias(false)}
                                />

                                <Button
                                    className="p-button-text session-button p-mb-1"
                                    label={t('session.validImg')}
                                    icon="icon-State_4Valid icon-3x"
                                    onClick={() => this.validateMedias()} />
                                <Button
                                    className="p-button-text session-button p-mb-1"
                                    label={t('session.dlBoard')}
                                    icon="icon-Session_Download icon-3x"
                                    onClick={() => this.getBoard()}
                                    loading={this.props.isDownloadFetching ? true : false}
                                />
                                <Button
                                    className="p-button-text session-button p-mb-1"
                                    label={t('session.retouch')}
                                    icon="icon-State_2Retouching icon-3x"
                                    onClick={() => {
                                        this.showCommentProjectPopup();
                                    }}
                                />
                                <div className="p-d-flex p-jc-start p-flex-column">
                                    <Button
                                        className="p-button-text session-button"
                                        label={t('session.move')}
                                        onClick={() => this.showchangeRefPopup()}
                                        icon="icon-ChangeRef icon-3x"
                                    />
                                </div>

                                <Divider className="p-my-3" />
                            </div>) : (<></>)}
                    <Button
                        className={this.state.needToBeSynchronized ? "p-button-text session-button p-mb-1" : "p-button-text session-button p-mb-1"}
                        label={t('session.sync')}
                        icon={this.state.needToBeSynchronized ? "pi pi-exclamation-triangle" : "icon-Session_Synchronize icon-3x"}
                        onClick={() => this.syncButtonClicked()}
                        loading={this.props.isSynchronizing}
                        style={this.state.needToBeSynchronized ?  {border: '1px solid #FF6022'} : {}}
                    />
                    <Button
                        className="p-button-text session-button"
                        label={t('session.ulRetouch')}
                        icon="icon-Upload icon-3x"
                        onClick={() => this.showZipPopup()}
                    />

                    <Button
                        className="p-button-text session-button"
                        label={t('session.exportImgValid')}
                        icon="icon-Action_Import icon-3x"
                        onClick={() => this.exportValideImage()}
                        loading={this.props.exportImageRequest ? true : false}
                    />
                    <Button
                        className="p-button-text session-button"
                        label={t('session.exportToMerch')}
                        icon="icon-File_Folder icon-3x"
                        onClick={() => this.exportMerch()}
                        loading={this.props.exportMerchRequest ? true : false}
                    />
                </div>


                {this.state.medias.length === 1 ? (
                    <>
                        <Divider className="p-my-3" />
                        <div className="p-d-flex p-jc-start p-flex-column">
                            <Button
                                className="p-button-text session-button"
                                label={t('session.linkProduct')}
                                icon="icon-Session_Liens icon-3x"
                                onClick={() => this.showLinkedPopup()}
                            />
                            {/*<Button*/}
                            {/*    className="p-button-text session-button"*/}
                            {/*    label="Historique"*/}
                            {/*    icon="icon-Media_History icon-3x"*/}
                            {/*/>*/}
                        </div>
                    </>
                ) : (
                    <></>
                )}


            </div>

        )
    }
}
export default withTranslation()(SessionMenu);
