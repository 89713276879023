import React, { Component } from 'react';
import { connect } from "react-redux";
import { alertActions, catalogAction } from "../../../redux/actions";
import { Dialog } from "primereact/dialog";
import { CustomAutoComplete } from '../../common/CustomAutoComplete'
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { LazyImage } from "../../common/LazyImage";
import {ProgressSpinner} from 'primereact/progressspinner';
import { withTranslation } from 'react-i18next';

class LinkedProductDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchCatalog: '',
			linkedProducts:[],
			productId: '',
			projectId: '',
			open: false,
			logo: false,
			isLoading:false,
			t: this.props.t
		}
		this.validSelectProduct = this.validSelectProduct.bind(this);
		this.onHide = this.onHide.bind(this);
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.state.open && this.state.productId !== prevState.productId) {
			this.props.getLinkedproducts(this.state.projectId, this.state.productId)
			this.setState({linkedProducts:[],isLoading:true})
		}
	}
	static getDerivedStateFromProps(props, state) {
		if (props.productId !== state.productId && props.open) {
			return {
				productId: props.productId,
				projectId: props.projectId,
				open: props.open
			}
		}
		if (props.catalog.isLinkedProductsFetched && state.isLoading) {
			return {
				linkedProducts: props.catalog.linkedProducts,
				isLoading:false,
			}
		}
		return state
	}

	unlinkProduct(referencedProductId) {
		let linkedProducts = this.state.linkedProducts.filter((item) => item.id !== referencedProductId);
		this.setState({ linkedProducts: linkedProducts });
	}

	selectSearch(selectedProduct) {
		let linkedProducts = this.state.linkedProducts;
		let existing = linkedProducts.filter((item) => { return (item.id === selectedProduct.id) });
		if (existing.length > 0 || this.state.productId == selectedProduct.id)
			this.props.warningAlert("Produit existant")
		else
			linkedProducts.unshift(selectedProduct)
		this.setState({ linkedProducts: linkedProducts, searchCatalog: '' })
	}

	validSelectProduct() {
		let { linkedProducts, projectId, productId } = this.state;

		if (productId > 0) {
			let table = [];
			linkedProducts.map((product) => {
				table.push(product.id);
			});
			this.props.linkProduct(projectId, productId, table);
		}

		this.onHide();
		this.props.hide(productId, linkedProducts.length)

	}

	onHide() {
		this.setState({
			open: false,
			searchCatalog: '',
			linkedProducts: [],
			productId: '',
			projectId: '',
		})

	}

	render() {
		const associatedProductFooter = (
			<>
				<Button
					label={this.state.t('common.cancel')}
					icon="pi pi-times"
					className="p-button-text"
					onClick={() =>{ this.onHide(); this.props.hide(); }}
				/>
				<Button
					label={this.state.t('projectList.shootingList.validSelection')}
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.validSelectProduct}
				/>
			</>
		);

		return (
			<Dialog
				visible={this.state.open}
				style={{ width: "50vw" }}
				header={this.state.t('projectList.shootingList.associateRef')}
				modal
				className="p-fluid"
				footer={associatedProductFooter}
				onHide={() => { this.onHide(); this.props.hide(); }}
			>
				<div className="p-d-flex p-flex-column">
					<span className="p-input-icon-left p-my-3">
						<i className="pi pi-search" />
						<CustomAutoComplete
							placeholder={this.state.t('projectList.shootingList.filter')}
							value={this.state.searchCatalog}
							onChange={(e) => this.setState({ searchCatalog: e })}
							onSelect={e => this.selectSearch(e)}
						/>
					</span>
					<div className="p-d-flex p-flex-column p-jc-between p-mt-5">
						<h5>
							{this.state.linkedProducts ? this.state.linkedProducts.length : "0"}{" " + this.state.t('projectList.shootingList.associatesRef')}
						</h5>
						<div className="p-grid">
							{this.state.linkedProducts && !this.state.isLoading ?
								this.state.linkedProducts.map((prod, index) => {
									return (
										<div key={prod.id} className="p-col-12 p-md-6 p-lg-3">
											<Card key={index} className=" p-mb-3 catalog" style={{ maxHeight: '432px' }}>
												<div className='p-d-flex p-dir-col p-jc-between'>
													<LazyImage
														key={prod.id}
														src={prod.thumbnail}
														alt={prod.product}
														style={{
															borderTopRightRadius: "10px",
															borderTopLeftRadius: "10px",
															width: "100%",
															height: '100%',
															objectFit: 'cover',
															maxHeight: '373px'
														}}
													/>
													<span className="p-m-0 p-col-fixed p-d-flex p-jc-center p-ai-center">
														<p className="p-m-0"> {prod.product}</p>
														<Button
															icon="pi pi-minus-circle"
															onClick={() => this.unlinkProduct(prod.id)}
															className="p-button-text p-button-rounded p-button-sm p-m-0"
														/>
													</span>
												</div>
											</Card>
										</div>
									);
								})
							:
							<div className="p-d-flex p-justify-center p-ai-center p-my-6" style={{ maxHeight: 'calc(100vh - 110px)' }}>
							<ProgressSpinner strokeWidth="3"/>
						</div>}
						</div>
					</div>
				</div>
			</Dialog>
		);
	}
}

const mapStateToProps = state => {
	const { catalog } = state;
	return { catalog };
}

const actionLinkedProduct = {	 
	getLinkedproducts: catalogAction.getLinkedproducts,
	linkProduct: catalogAction.linkProduct,
	warningAlert: alertActions.warning,
}

const connectedLinkedProduct = connect(mapStateToProps, actionLinkedProduct)(withTranslation()(LinkedProductDialog))
export { connectedLinkedProduct as LinkedProductDialog };
