import React, {Component} from "react";
import {MiniImageCard} from "./ImageCard";
import {ScrollPanel} from "primereact/scrollpanel";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {LazyImage} from '../common/LazyImage'
import { withTranslation } from 'react-i18next';

class ImageOverview extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			selected: [],
			medias: [],
			zoom: 1
		};
		this.selectMedia = this.selectMedia.bind(this);
		this.setImageMeta = this.setImageMeta.bind(this);
	}

	static getDerivedStateFromProps( props, state ) {
		console.log('props.selected ImageOverview', props.selected)
		console.log('props.medias ImageOverview', props.medias)
		if (props.medias && props.selected) {
			return {
				medias: props.medias,
				selected: props.selected,
			}
		}
		return state
	}

	selectMedia( m, e ) {
		this.props.selectMedia(m, e);
	}

	setImageMeta( value ) {
		this.props.setImageMeta(value);
	}

	render() {
		const {t} = this.props;
		return (
			<div className="p-dir-col">
				<div className="p-d-flex p-jc-center p-py-4" style={{height: "calc(85vh - 350px)"}}>
					{this.state.selected &&
					this.state.selected.length < 4 ?
						<TransformWrapper>
							{( {zoomIn, zoomOut, resetTransform, ...rest} ) => (
								<>
									<TransformComponent>
										<div className="p-d-flex" style={{height: "calc(85vh - 350px)"}}>
											{this.state.selected.map(( m ) => {
												return (
													<div className="p-col p-d-flex p-jc-center" key={`key-${m.id}`}>
														<LazyImage
															key={`imgKey-${m.id}`}
															imageKey={`imageKey-${m.id}`}
															src={m.url}
															style={{width: "auto", maxWidth: "100%", height: "auto", maxHeight: "100%", objectFit: 'contain',}}
															alt={m.ref}
															loader
														/>
													</div>
												);
											})}
										</div>
									</TransformComponent>
									<div className="p-d-flex p-ai-center p-px-3 p-py-2 zoom-panel">
										<span className="zoom-minus" onClick={zoomOut}/>
										<span className="zoompercent">{`${parseInt((this.state.zoom * 100))}%`}</span>
										<span className="zoom-plus" onClick={zoomIn}/>
									</div>
								</>)}
						</TransformWrapper>
						:
						this.state.selected &&
						this.state.selected.length >= 4 ?
							(<TransformWrapper>
								{( {zoomIn, zoomOut, resetTransform, ...rest} ) => (
									<>
										<TransformComponent>
											<div className="p-d-flex"
											     style={{height: "calc(85vh - 350px)"}}>
												{this.state.selected.slice(0, 3).map(( m ) => {
													return (
														<div className="p-col p-d-flex p-jc-center">
															<LazyImage
																key={`imgKey-${m.id}`}
																imageKey={`imageKey-${m.id}`}
																src={m.url}
																alt={m.title}
																style={{
																	width: "auto",
																	maxWidth: "100%",
																	height: "auto",
																	maxHeight: "100%",
																	objectFit: 'contain'
																}}
																loader
															/>
														</div>
													);
												})
												}
												<div className="p-col p-d-flex p-ai-center">
													<span className="image-placeholder p-d-flex p-jc-center p-ai-center"> + {this.state.selected.length - 3 + " " + t('session.otherImg')}</span>
												</div>
											</div>
										</TransformComponent>
										<div className="p-d-flex p-ai-center p-px-3 p-py-2 zoom-panel">
											<span className="zoom-minus" onClick={zoomOut}></span>
											<span
												className="zoompercent">{`${parseInt((this.state.zoom * 100))}%`}</span>
											<span className="zoom-plus" onClick={zoomIn}></span>
										</div>
									</>)}
							</TransformWrapper>)
							: <></>
					}
				</div>
				<div className="p-col-fixed p-mt-3" style={{height: "280px"}}>
					<ScrollPanel style={{width: "100%", height: "100%"}}>
						<div className="p-d-flex p-flex-xl-nowrap p-flex-lg-nowrap p-flex-md-wrap">
							{this.state.medias &&
							this.state.medias.map(( m ) => {
								let selected = this.state.selected.includes(m);
								return (
									<div className="p-col-2 p-xl-2 p-lg-2 p-md-4">
										<MiniImageCard media={m}
										               key={`imageKey-${m.id}`}
										               selected={selected}
										               setImageMeta={( v ) => this.setImageMeta(v)}
										               selectMedia={( i, e ) => this.selectMedia(i, e)}
										/>
									</div>
								);
							})}
						</div>
					</ScrollPanel>
				</div>
			</div>
		);
	}
}

export default withTranslation()(ImageOverview);
