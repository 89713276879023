import { activitiesConstants } from '../constants';
import { activitiesService } from '../../services';
import { alertActions } from './';

export const activitiesActions = {
    getAllActivities,
};

function getAllActivities(){
    return dispatch => {
        dispatch(request());
        activitiesService.getAllActivities()
            .then(
                activities => dispatch(success(activities)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: activitiesConstants.GETALL_ACTIVITIES_REQUEST } }
    function success(activities) { return { type: activitiesConstants.GETALL_ACTIVITIES_SUCCESS, activities } }
    function failure(error) { return { type: activitiesConstants.GETALL_ACTIVITIES_FAILURE, error } }
}