import { userConstants } from '../constants';
import { userService } from '../../services';
import { alertActions } from './';
import { history } from './../helpers/store'

export const userActions = {
  login,
  socialLogin,
  logout,
  register,
  getAll,
  delete: _delete,
  forgetPassword,
  resetPassword,
  changePassword,
  updateUserProfile,
  getUser,
  confirmEmail,
  createPassword
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    userService.login(username, password)
      .then(
        user => {
          dispatch(success(user));
          history.push('/');
          dispatch(alertActions.success('Connexion réussie'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function socialLogin(provider, token) {
  return dispatch => {
    dispatch(request({ provider }));
    userService.socialLogin(provider, token)
      .then(
        user => {
          dispatch(success(user));
          history.push('/');
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function forgetPassword(email) {
  return dispatch => {
    dispatch(request({ email }));
    userService.forgetPassword(email)
      .then(
        user => {
          dispatch(success(email));
          dispatch(alertActions.success('Nous avons envoyés les instructions pour créer un nouveau mot de passe à votre adresse e-mail'));
          history.push('/login')
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }
  function request(email) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, email } }
  function success(email) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, email } }
  function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}

function resetPassword(password) {
  return dispatch => {
    dispatch(request({ password }));
    userService.resetPassword(password)
      .then(
        user => {
          dispatch(success(password));
          dispatch(alertActions.success('Votre mot de passe a été enregistré'));
            history.push('/login')
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }
  function request(password) { return { type: userConstants.RESET_PASSWORD_REQUEST, password } }
  function success(password) { return { type: userConstants.RESET_PASSWORD_SUCCESS, password } }
  function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function changePassword(user) {
  return dispatch => {
    dispatch(request({ user }));
    userService.changePassword(user)
      .then(
        user => {
          dispatch(success(user));
          history.push('/login');
          dispatch(alertActions.success('Votre mot de passe est mis à jour'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  }
  function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
  function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
  function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user)
      .then(
        user => {
          dispatch(success());
          history.push('/');
          dispatch(alertActions.success('Inscription réussie'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };
  function request() { return { type: userConstants.GETALL_REQUEST } }
  function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
  function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id)
      .then(
        user => dispatch(success(id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
  function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function getUser() {
  return dispatch => {
    dispatch(request());
    userService.getUser()
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error.toString()))
      );
  };
  function request() { return { type: userConstants.GET_USER_REQUEST } }
  function success(user) { return { type: userConstants.GET_USER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error } }
}

function updateUserProfile(user) {
  return dispatch => {
    dispatch(request({ user }));
    userService.updateProfile(user)
      .then(
        user => {
          dispatch(success(user));
          dispatch(alertActions.success('Profil d\'utilisateur mis à jour'));        
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user) { return { type: userConstants.UPDATE_PROFILE_REQUEST, user } }
  function success(user) { return { type: userConstants.UPDATE_PROFILE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.UPDATE_PROFILE_FAILURE, error } }
}

function confirmEmail (email, token) {
  return dispatch => {
    dispatch(request());
    userService.confirmEmail(email, token)
    .then(
      user => dispatch(success(user)),
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {return {type: userConstants.CONFIRM_EMAIL_REQUEST}}
  function success(user){return {type: userConstants.CONFIRM_EMAIL_SUCCESS, user}}
  function failure(error) {return {type: userConstants.CONFIRM_EMAIL_FAILURE, error}}
}

function createPassword(password){
  return dispatch => {
    dispatch(request());
    userService.createPassword(password)
    .then(
      user => {
        dispatch(success(user));
        history.push('/login');
      },
      error => dispatch(failure(error.toString()))
    );
  };

  function request() {return {type: userConstants.CREATE_PASSWORD_REQUEST}}
  function success(user){return {type: userConstants.CREATE_PASSWORD_SUCCESS, user}}
  function failure(error) {return {type: userConstants.CREATE_PASSWORD_FAILURE, error}}
}
