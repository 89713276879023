import { commonService } from './common.service';

export const materialService = {
    materialAdd,
    getMaterial,
    deleteMaterial,
    updateMaterial,
    validateMaterial,
    rejectMaterial,
    draftMaterial,
    deleteAllMaterial
}

function materialAdd(projectId, material) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(material)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}


function getMaterial(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteMaterial(projectId, materialId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials/${materialId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function updateMaterial(projectId, materialId, materialField) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(materialField)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials/${materialId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function validateMaterial(projectId, materialId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials/${materialId}/validate`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function rejectMaterial(projectId, materialId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials/${materialId}/reject`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function draftMaterial(projectId, materialId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials/${materialId}/draft`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteAllMaterial(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/materials`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
