import { hubConstants } from "../constants";

export function notifications(state = {}, action) {
 
  switch (action.type) {
    case hubConstants.GET_NOTIFICATIONS_HISTORY_REQUEST:
      return Object.assign({}, { isFetching: true });
    case hubConstants.GET_NOTIFICATIONS_HISTORY_SUCCESS:
      return Object.assign({}, { isFetched: true, type: action.notifications });
    case hubConstants.GET_NOTIFICATIONS_HISTORY_FAILURE:

    case hubConstants.MARK_AS_READ_REQUEST:
      return Object.assign({}, { markAsReadIsFetching: true });
    case hubConstants.MARK_AS_READ_SUCCESS:
      return Object.assign({}, { markAsReadIsFetched: true });
    case hubConstants.NOTIFICATIONS_PUSHED:
       
      return Object.assign({}, { isNotifPushed: true });
    case hubConstants.MARK_AS_READ_FAILURE:
    default:
      return state;
  }
}
