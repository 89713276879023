import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
 import {Divider} from "primereact/divider";
import {LazyImage} from "../../common/LazyImage";
import {CustomAutoComplete} from "../../common/CustomAutoComplete";
import { withTranslation } from 'react-i18next';
import { Panel } from 'primereact/panel'

class ChangeMainProductDialog extends Component {
	constructor() {
		super();
		this.state = {
			productfolders: [],
			newRef: null,
			productfolderSelected: {},
			inputSearch:''
		}
	}
 
	static getDerivedStateFromProps( props, state ) {
		console.log('props', props)
		if (props.productfolders) {
			return {productfolders: props.productfolders}
		}
		return state
	}

	OnchangeRefMer = ( product ) => {
		this.setState({inputSearch: ''})
		if (product == undefined) {
			this.setState({productfolderSelected: {}, newRef: null});
		} else {
			this.setState({productfolderSelected: product});
			
			this.setState({newRef: product});
		}
	};

	render() {
		let footerChangeRefPopup = (
			<>
				<Divider className="p-mb-4"/>
				<Button
					label={this.props.t('common.cancel')}
					className="cancelButton"
					onClick={() => this.props.onHide()}
				/>
				<Button
					label={this.props.t('common.valid')}
					className="validationButton"
					disabled={this.state.disabled}
					onClick={() => this.props.changeRefMer(this.state.newRef)}
				/>
			</>
		); 

		return (
			<Dialog
				visible={this.props.showchangeRefPopup}
				closable={true}
				header={this.props.t('session.dialog.changeRef')}
				footer={footerChangeRefPopup}
				onHide={() => this.props.onHide()}
				style={{width: "50vw", maxWidth: "1923px"}}
				modal
			>
				<CustomAutoComplete
                            placeholder={this.props.t('session.dialog.selectProduct')}
                            value={this.state.inputSearch}
							style={{width: "30vw"}}
                            onChange={ e  => this.setState({inputSearch: e})}
                            onSelect={ e => this.OnchangeRefMer(e)}
							projectId={this.props.projectId}
							
                        />
							{this.state.newRef!==null ?
				( 
				 <Panel className="p-my-3 rounded-content panel-sm">
                                <div className="p-grid p-align-center p-my-auto p-mx-1">
                                    <div className="p-col p-py-auto">
                                        <LazyImage key={`product_${this.state.newRef.reference}`}  style={{ height: '50px' }} src={this.state.newRef.thumbnail}/>
                                    </div>
                                    <p className="p-col">{this.state.newRef.product}</p>
                                    <p className="p-col">{this.state.newRef.barcode}</p>
                                         <div className="p-col">
                                      <Button
                                        icon="pi pi-trash"
                                        style={{color: "white", backgroundColor: "transparent",}}
                                        className="p-button-icon-only p-button-sm p-py-1 p-ml-4 p-mr-3"
                                        onClick={() => this.setState({newRef:null})}
                                      />
                                    </div>
                                </div>
                                
                            </Panel>
				):<></>}
			</Dialog>
		);
	}
}

export default withTranslation()(ChangeMainProductDialog);
