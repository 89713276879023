import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {isTablet} from 'react-device-detect'
import {Button} from "primereact/button";
import {InputText} from 'primereact/inputtext';
import {Calendar} from "primereact/calendar";
import Dropzone from "react-dropzone";
import {DownloadCloudButton} from "../common/SVGButtons";
import { withTranslation } from 'react-i18next';

class AddProductToCatalog extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			productTitle: '',
			productRef: '',
			availabilityDate: null,
			uploadDocument: [],
			errors: {},
			t: this.props.t
		}
	}

	onHide = () => {
		this.setState({
			productTitle: '',
			productRef: '',
			availabilityDate: null,
			uploadDocument: [],
			errors: {},
		})
		this.props.hideDialog()
	}

	onDrop = ( file ) => {
		let errors = this.state.errors
		if (file.length > 0) {
			errors['uploadDocument'] = "";
			let doc = [];
			let reader = new FileReader();
			reader.onload = (file => {
				return () => {
					// let data = reader.result;
					doc.push({name: file.name, document: file});
					this.setState({uploadDocument: doc})
				}
			})(file[0]);
			reader.readAsDataURL(file[0]);
		} else {
			errors['uploadDocument'] = this.state.t('common.pdfAllowed');
		}
		this.setState({errors: errors})
	};

	onAddProduct = () => {
		let {productTitle, productRef, availabilityDate, uploadDocument} = this.state
		let newProduct = [
			[{
				Reference: productRef,
				Title: productTitle,
				AvailibiltyDate: availabilityDate,
			}],
			uploadDocument
		]

		this.props.submitClicked(newProduct)
		this.onHide()
	}

	render() {
		const dialogFooter = (
			<div className="p-d-flex p-jc-end p-mt-6">
	            <span className="p-d-flex">
	                <Button
		                label={this.state.t('common.cancel')}
		                icon="pi pi-times"
		                className="p-button-text"
		                onClick={this.onHide}
	                />
	                <Button
		                label={this.state.t('common.add')}
		                icon="pi pi-check"
		                className="p-button-text"
		                onClick={this.onAddProduct}
	                />
	            </span>
			</div>
		)

		return (
			<Dialog
				visible={this.props.openDialog}
				style={isTablet ? {width: '60vw'} : {width: '30vw'}}
				header={this.state.t('catalog.dialog.productAdd')}
				footer={dialogFooter}
				modal
				position="center"
				className="p-fluid"
				onHide={this.onHide}
			>
				<div>
					<InputText className="p-mb-6" placeholder={this.state.t('catalog.dialog.productName')}
					           value={this.state.productTitle}
					           onChange={e => this.setState({productTitle: e.target.value})}/>

					<InputText className="p-mb-6" placeholder={this.state.t('catalog.dialog.productRef')}
					           value={this.state.productRef}
					           onChange={e => this.setState({productRef: e.target.value})}/>

					<Calendar className="p-mb-6"
					          placeholder={this.state.t('catalog.dialog.productDate')}
					          dateFormat="dd/mm/yy"
					          value={this.state.availabilityDate}
					          onChange={e => this.setState({availabilityDate: e.value})}/>

					<div className="p-d-flex p-flex-column p-jc-center p-ai-center dropzone-file">
						<Dropzone onDrop={this.onDrop} multiple={false} accept="image/jpeg, image/png" required>
							{( {getRootProps, getInputProps} ) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<span><DownloadCloudButton title={this.state.t('catalog.dialog.dragDrop')}/></span>
									</div>
								</section>
							)}
						</Dropzone>
						{this.state.uploadDocument.length > 0 &&
						<ul className="list-unstyled">
							<li>
								{this.state.uploadDocument[0].name}
							</li>
						</ul>
						}
					</div>
				</div>
			</Dialog>
		);
	}
}

export default withTranslation()(AddProductToCatalog);
