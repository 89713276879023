import { userConstants } from '../constants';

let loggedInUser = JSON.parse(localStorage.getItem('user'));

const initial = loggedInUser ? { loggedIn: true, user: loggedInUser } : {};

export function user(state = initial, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return Object.assign({}, { isLoging: true });
    case userConstants.LOGIN_FAILURE:
      return Object.assign({}, { isFailed: true });
    case userConstants.REGISTER_REQUEST:
      return Object.assign({}, { isRegisting: true });

    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    case userConstants.UPDATE_PROFILE_REQUEST:
      return {
        requesting: true
      };
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, { isFetched: true, user: action.user });
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        status: false
      };
    case userConstants.GET_USER_REQUEST:
      return Object.assign({}, { isFetching: true });
    case userConstants.GET_USER_SUCCESS:
      return Object.assign({}, { isFetched: true, user: action.user });
    case userConstants.GET_USER_FAILURE:

    case userConstants.CONFIRM_EMAIL_REQUEST:
      return Object.assign({}, {isFetching: true});
    case userConstants.CONFIRM_EMAIL_SUCCESS:
      return Object.assign({}, { isFetched: true, user: action.user});
    case userConstants.CONFIRM_EMAIL_FAILURE:
      return {}

    case userConstants.CREATE_PASSWORD_REQUEST:
      return Object.assign({}, {isFetching: true});
    case userConstants.CREATE_PASSWORD_SUCCESS:
      return Object.assign({}, { isFetched: true, user: action.user});
    case userConstants.CREATE_PASSWORD_FAILURE:
      return {}

    default:
      let loggedInUser = JSON.parse(localStorage.getItem('user'));
      //const initial = loggedInUser ? { loggedIn: true, user: loggedInUser } : {};
      return  loggedInUser ? { loggedIn: true, user: loggedInUser } : {};
  }
}
