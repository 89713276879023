import {tasksConstants} from '../constants'

export function tasks(state= {}, action){
    switch (action.type){
        case tasksConstants.GET_TASKS_REQUEST:
            return Object.assign({}, {isFetching: true});
        case tasksConstants.GET_TASKS_SUCCESS:
            return Object.assign({}, {type: action.tasks, isFetched: true});
        case tasksConstants.GET_TASKS_FAILURE:
            return {}

        case tasksConstants.ADD_TASKS_REQUEST:
            return Object.assign({}, { _isFetching: true });
        case tasksConstants.ADD_TASKS_SUCCESS:
            return Object.assign({}, {type: action.tasks, _isFetched: true});
        case tasksConstants.ADD_TASKS_FAILURE:
            return {}

        case tasksConstants.UPDATE_TASKS_REQUEST:
            return Object.assign({}, {isUpdating: true})
        case tasksConstants.UPDATE_TASKS_SUCCESS:
            return Object.assign({}, {type: action.tasks, isUpdated: true});
        case tasksConstants.UPDATE_TASKS_FAILURE:
            return {}

        case tasksConstants.CHANGE_STATUS_TASKS_REQUEST:
            return Object.assign({}, {isStatusChanging: true});
        case tasksConstants.CHANGE_STATUS_TASKS_SUCCESS:
            return Object.assign({}, {type: action.tasks, isStatusChanged: true});
        case tasksConstants.CHANGE_STATUS_TASKS_FAILURE:
            return {}

        case tasksConstants.COMMENT_TASK_REQUEST:
            return Object.assign({}, {isCommentFetching: true});
        case tasksConstants.COMMENT_TASK_SUCCESS:
            return Object.assign({}, {type: action.tasks, isCommentFetched: true});
        case tasksConstants.COMMENT_TASK_FAILURE:
            return {}

        case tasksConstants.ADD_CHECKLIST_REQUEST:
            return Object.assign({}, {isChecklistFetching: true});
        case tasksConstants.ADD_CHECKLIST_SUCCESS:
            return Object.assign({}, {type: action.tasks, isChecklistFetched: true});
        case tasksConstants.ADD_CHECKLIST_FAILURE:
            return {}

        case tasksConstants.VALIDATE_CHECKLIST_REQUEST:
            return Object.assign({}, {isValidateFetching: true});
        case tasksConstants.VALIDATE_CHECKLIST_SUCCESS:
            return Object.assign({}, {type: action.tasks, isValidateFetched: true});
        case tasksConstants.VALIDATE_CHECKLIST_FAILURE:
            return {}

        default:
            return state
    }
}
