import React, { Component } from 'react'
import { SessionDetailsMenu, SessionHeader } from './SessionDetailsMenu'
import { connect } from 'react-redux'
import { catalogAction, sessiondetailsActions } from '../../redux/actions'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { ScrollPanel } from 'primereact/scrollpanel'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import {LazyImage} from '../common/LazyImage'

class SessionLinkedProducts extends Component {
    constructor (props) {
        super(props)
        this.state = {
            image: null,
            imageId: '',
            productId: 0,
            project: JSON.parse(localStorage.getItem('project')),
            globalFilter: '',
            linkedProducts: []
        }
        this.unlinkProduct = this.unlinkProduct.bind(this)
    }

    componentDidMount () {
        if (this.props.match.params.productId && this.props.match.params.imageId) {
            this.setState({ imageId: this.props.match.params.imageId, productId: this.props.match.params.productId })
            this.props.getImageOverview(this.state.project.projectId, this.props.match.params.productId, this.props.match.params.imageId)
            this.props.getCatalog(this.state.project.projectId)
        }
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps && nextProps.sessiondetails.image)
            this.setState({ image: nextProps.sessiondetails.image })
        if (nextProps && nextProps.catalog.isFetched) {
            let x = nextProps.catalog.type
            let products = x.filter(products => products.id == this.props.match.params.productId)
            this.setState({catalog: x, product: products[0]})
            if (products[0].linkedProducts) {
                console.log('if linkedProducts')
                let table = []
                const iterator = products[0].linkedProducts.values();
                for (const value of iterator) {
                    x.map(catalog => {
                        if (catalog.id === value) {
                            table.push(catalog)
                        }
                    })
                }
                this.setState({
                    linkedProducts: table,
                })
            }
        }
    }

    imageTemplate(rowData) {
        return  <LazyImage
        key={rowData.id}
        src={rowData.thumbnail}
        alt={rowData.product}
        className="product-image"
        style={{height: '56px', width: 'auto'}}
      />
     
    }

    unlinkProduct(catalogId) {
        this.setState(prevState => {
            const linkedProducts = prevState.linkedProducts.filter(cat => cat.id !== catalogId);
            console.log('catalog', linkedProducts)
            return { linkedProducts };
        })
    }

    render () {

        return (
            <div className="p-dir-col p-d-flex p-ai-center">
                {this.state.image && this.state.product &&
                <div style={{ height: 'calc(100vh - 80px)'}}>
                    <SessionHeader projectId={this.state.project.projectId} title={this.state.image.productName}/>
                    <div className="p-d-flex p-jc-between">
                        <div className="p-mr-3 p-col-6">
                            <span className='p-input-icon-left p-mt-1 p-mb-3'>
                              <i className="pi pi-search"/>
                              <InputText id='username' type="search"
                                         onInput={(e) => this.setState({ globalFilter: e.target.value })}
                                         placeholder="Filtrer par nom ou par référence"/>
                            </span>
                            <DataTable value={this.state.catalog}
                                       sortField="lier" sortOrder={1}
                                       paginator
                                       globalFilter={this.state.globalFilter}
                                       rows={5}
                                       multiSortMeta={this.state.linkedProducts}
                                       selection={this.state.linkedProducts}
                                       onSelectionChange={e => {
                                           console.log('e.target', e)
                                           this.setState({ linkedProducts: e.value })
                                           let table = [];
                                           e.value.map(product => {
                                               if (product.id) {
                                                   table.push(product.id)
                                               } else {
                                                   table.push(product)
                                               }
                                           })
                                           this.props.linkProduct(this.state.project.projectId, this.state.productId, table)
                                       }}
                                       dataKey="id"
                            >
                                <Column header='Image' body={this.imageTemplate}/>
                                <Column field='product' header='Name' sortable/>
                                <Column field='product' header='Référence' sortable/>
                                <Column selectionMode="multiple" header="Lier" headerStyle={{ width: '3em' }}/>
                            </DataTable>
                            <div className="p-d-flex p-flex-column p-jc-between p-my-3">
                                <h5>{this.state.linkedProducts ? this.state.linkedProducts.length : "0"} références associées</h5>
                                <ScrollPanel style={{ height: '200px', width: '656px' }} >
                                    <div style={{ height: '100%', width: '200%' }} className="p-d-flex p-flex-row p-flex-nowrap p-ai-start">
                                        {this.state.linkedProducts && this.state.linkedProducts.map((catalog, index) => {
                                            return <Card
                                                // title={catalog.product}
                                                style={{ width: '176px', height: '176px', borderRadius: '10px' }}
                                                header={<img style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px', maxHeight: '118px', objectFit: 'cover' }} src={catalog.thumbnail} alt={catalog.product} />}
                                                // footer={<Button icon='pi pi-minus-circle' onClick={() => this.unlinkProduct(catalog.id)} className="p-button-text p-button-rounded p-button-sm p-m-0" />}
                                                className="p-mx-2 catalog">
                                              <span className='p-m-0 p-d-flex p-jc-center p-ai-center'>
                                                <p className="p-m-0"> {catalog.product} </p><Button icon='pi pi-minus-circle' onClick={() => this.unlinkProduct(catalog.id)} className="p-button-text p-button-rounded p-button-sm p-m-0" />
                                              </span>
                                            </Card>
                                        })}
                                    </div>
                                </ScrollPanel>
                            </div>
                        </div>
                        <div className="p-px-2 p-col-6 p-d-flex p-jc-center">
                            <img src={process.env.REACT_APP_BASE_URL + this.state.image.url}
                                 alt={this.state.image.productName}
                                 style={{ objectFit: 'cover', maxHeight: 'calc(100vh - 110px)', maxWidth:'100%', height:'auto'}}/>
                        </div>
                    </div>

                </div>
                }
                <SessionDetailsMenu projectId={this.state.project.projectId}
                                    productId={this.state.productId}
                                    imageId={this.state.imageId}/>

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { sessiondetails, catalog } = state
    return { sessiondetails, catalog }
}

const mapDispatchToProps = {
    getImageOverview: sessiondetailsActions.getImageOverview,
    getCatalog: catalogAction.getCatalog,
    linkProduct: catalogAction.linkProduct
}

const connectedSessionLinkedProduct = connect(mapStateToProps, mapDispatchToProps)(SessionLinkedProducts)
export { connectedSessionLinkedProduct as SessionLinkedProducts }
