import './layout/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import './App.css'
import './layout/layout.scss'
import 'primeicons/primeicons.css'
import React from 'react'
import { connect } from "react-redux";
import { isMobileOnly, isTablet } from 'react-device-detect'
import { alertActions } from './redux/actions'
import { history } from './redux/helpers/store'
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobileRedirect from './components/mobile-redirect/MobileRedirect'
import {ScrollPanel} from 'primereact/scrollpanel'
import {addLocale, locale} from 'primereact/api';
import { withTranslation } from 'react-i18next';

import { NotificationContext } from './DependencyInjection/NotificationContext';
 

class App extends React.Component {
	constructor(props) {
		super(props)
 		this.state = {
			option: {
				position: "top-left",
				autoClose: 3000,
				hideProgressBar: true,
				toastId: 'toastId',
				closeButton: true,
				transition: Bounce
			}
		}

		history.listen((location, action) => {
			this.props.clearAlerts()
		})
	}

	notify = (alert) => {
		switch (alert.style) {
			case 'success':
				return toast.success(alert.message, this.state.option)
			case 'warn':
				return toast.warn(alert.message, this.state.option)
			case "error":
				return toast.error(alert.message, this.state.option)
			default:
				toast(alert.message, this.state.option)
		}
	}

	render() {
		addLocale('fr', {
			firstDayOfWeek: 1,
			dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
			dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
			dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
			monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
			monthNamesShort: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
			today: 'Aujourd\'hui',
			clear: 'Nettoyer',
			dateFormat: 'dd/mm/yy',
			weekHeader: 'Sem'
		});
		locale('fr');

		const { alert } = this.props

		if (alert.message) {
			this.notify(alert)
		}



		if (isMobileOnly) {
			return (
				<div className='layout-static'>
					<MobileRedirect />
				</div>
			)
		}
		else {
			return (
				<>
					<NotificationContext.Provider value={NotificationContext}> 					
					<div className="flex-container">
						<ScrollPanel style={{ height: '100vh' }}>
							<div className='p-grid p-mx-0'>
								<div className='p-col-12'>
									<ToastContainer limite={1} />
									{this.props.children}
								</div>
							</div>
						</ScrollPanel>
					</div>
					</NotificationContext.Provider></>

			)
		}
	}
}

function mapState(state) {
	const { alert } = state
	return { alert }
}

const actionCreators = { clearAlerts: alertActions.clear }

const connectedApp = withTranslation()(connect(mapState, actionCreators)(App))
export {connectedApp as App}
