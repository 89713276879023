export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',


    LOGOUT: 'USERS_LOGOUT',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'USER_UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'USER_UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'USER_UPDATE_PROFILE_FAILURE',

    CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
    CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',

    CREATE_PASSWORD_REQUEST: 'CREATE_PASSWORD_REQUEST',
    CREATE_PASSWORD_SUCCESS: 'CREATE_PASSWORD_SUCCESS',
    CREATE_PASSWORD_FAILURE: 'CREATE_PASSWORD_FAILURE'
};
