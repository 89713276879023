import { sessiondetailsConstants } from '../constants';
export function sessiondetails(state = {}, action) {
    switch (action.type) {
      case sessiondetailsConstants.IMAGE_DETAILS_REQUEST:
        return Object.assign({}, { image: {...state.image}, isFetching: true });
      case sessiondetailsConstants.IMAGE_DETAILS_SUCCESS:
        return Object.assign({}, { image: action.image, isFetched: true });
      case sessiondetailsConstants.IMAGE_DETAILS_FAILURE:
        return {}

      case sessiondetailsConstants.IMAGE_HISTORY_REQUEST:
        return Object.assign({}, {image: {...state.image}, isFetching: true});
      case sessiondetailsConstants.IMAGE_HISTORY_SUCCESS:
        return Object.assign({}, {image: {...state.image}, type: action.historyImage, isHistoryFetched: true});
      case sessiondetailsConstants.IMAGE_HISTORY_FAILURE:
        return {}

      case sessiondetailsConstants.IMAGE_HISTORY_CHANGE_REQUEST:
        return Object.assign({}, { isFetching: true });
      case sessiondetailsConstants.IMAGE_HISTORY_CHANGE_SUCCESS:
        return Object.assign({}, {type: action.historyImage, isSend: true});
      case sessiondetailsConstants.IMAGE_HISTORY_CHANGE_FAILURE:
        return {}
      
      case sessiondetailsConstants.ADD_COMMENT_REQUEST:
        return Object.assign({}, { image: { ...state.image}, isFetching: true });
      case sessiondetailsConstants.ADD_COMMENT_SUCCESS:
        return Object.assign({}, { image: action.image, isFetched: true });
      case sessiondetailsConstants.ADD_COMMENT_FAILURE:
        return {}
        case sessiondetailsConstants.VALIDATE_COMMENT_REQUEST:
          return Object.assign({}, { image: { ...state.image}, isFetching: true });
        case sessiondetailsConstants.VALIDATE_COMMENT_SUCCESS:
          return Object.assign({}, { image: action.image, isValidate: true });
        case sessiondetailsConstants.VALIDATE_COMMENT_FAILURE:
          return {}
      default:
        return state
        }
      }
      
      
