import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import Dropzone from "react-dropzone";
import {alertActions, productsActions} from "../../../redux/actions";
import Acrobat from "../../../img/acrobat.svg";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import { withTranslation } from 'react-i18next';

class UploadRetouchDialog extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			uploadDocument: {},
			errors: [],
			isFetchedZip: true
		}

		this.uploadZipRetouch = this.uploadZipRetouch.bind(this);
		this.closeDialog = this.closeDialog.bind(this)
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.isFetchedZip && state.isFetchedZip) {
			if (props.zip.haveWarning) props.alertWarning(props.zip.message);
			else props.alertSuccess(props.zip.message);
			return {isFetchedZip: false};
		}
		return state
	}

	onDrop = ( file ) => {
		let errors = this.state.errors;
		if (file.length > 0) {
			errors["uploadDocument"] = "";
			let doc = [];
			let reader = new FileReader();
			reader.onload = (( file ) => {
				return () => {

					doc.push({name: file.name, document: file});
					this.setState({uploadDocument: doc});
				};
			})(file[0]);
			reader.readAsDataURL(file[0]);
		} else {
			errors["uploadDocument"] = this.props.t('common.zipAllowed');
		}
		this.setState({errors: errors});
	};

	uploadZipRetouch() {
		if (this.state.uploadDocument.length > 0) {
			this.props.uploadRetouchZip(
				this.props.projectId,
				this.state.uploadDocument
			);
			this.closeDialog()
		} else {
			this.state.errors["uploadDocument"] = this.props.t('toast.zipRequired');
		}
	}

	closeDialog() {
		this.setState({
			uploadDocument: {},
			errors: []
		})
		this.props.onHide()
	}

	render() {
		let footerZipPopup = (
			<div>
				<Button
					label={this.props.t('common.cancel')}
					className="cancelButton"
					onClick={this.closeDialog}
				/>
				<Button
					label={this.props.t('common.upload')}
					className="validationButton"
					onClick={this.uploadZipRetouch}
				/>
			</div>
		);

		return (
			<Dialog
				modal={false}
				visible={this.props.showZipPopup}
				closable={false}
				header={this.props.t('session.dialog.uploadRetouch')}
				footer={footerZipPopup}
				onHide={this.closeDialog}
			>
				<div className="dropzone p-d-flex p-flex-column p-jc-center p-ai-center">
					<Dropzone
						onDrop={this.onDrop}
						multiple={false}
						accept=".zip"
						required
					>
						{( {getRootProps, getInputProps} ) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p className="p-d-flex p-flex-row p-ai-center">
				                      <span>
				                        <img src={Acrobat} alt="acrobat" className="p-mr-5"/>
				                      </span>
										<span>
                                            {" " + this.props.t('session.dialog.uploadZip')} <br/>{" "}
											{this.state.errors
												? this.state.errors["uploadDocument"]
												: this.props.t('session.dialog.uploadZip')}
                                         </span>
									</p>
								</div>
							</section>
						)}
					</Dropzone>
					{this.state.uploadDocument.length > 0 && (
						<ul className="list-unstyled">
							<li>{this.state.uploadDocument[0].name}</li>
						</ul>
					)}
				</div>
			</Dialog>
		);
	}
}

function mapState( state ) {
	const {isFetchedZip, zip} = state.products;

	return {isFetchedZip, zip};
}

const actionDialog = {
	uploadRetouchZip: productsActions.uploadRetouchZip,
	alertSuccess: alertActions.success,
	alertWarning: alertActions.warning,
}

const connectedUploadRetouchDialog = connect(mapState, actionDialog)(withTranslation()(UploadRetouchDialog));
export {connectedUploadRetouchDialog as UploadRetouchDialog};
