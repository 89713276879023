import React, {Component} from "react";
import {connect} from "react-redux";
import {productsActions} from "../../redux/actions";
import {ScrollPanel} from "primereact/scrollpanel";
import {ImageCard} from "./ImageCard";
import ImageOverview from "./ImageOverview";
import ImageOverviewHeader from "./ImageOverviewHeader";
import {Button} from "primereact/button";
import SessionFooter from "./SessionFooter";
import {isTablet} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {ProgressSpinner} from "primereact/progressspinner";

class ImageContainer extends Component {
  constructor() {
    super();
    this.state = {
      displayedMedias: [],
      selectedMedias: [],
      projectId: null,
    };

    this.updateImageMeta = this.updateImageMeta.bind(this);
    this.addToSelectedMedias = this.addToSelectedMedias.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.displayedMedias &&
      props.selectedMediasSession &&
      props.projectId &&
      props.paths
    ) {
      return {
        displayedMedias: props.displayedMedias,
        selectedMedias: state.selectedMedias
          ? state.selectedMedias
          : props.selectedMediasSession,
        projectId: props.projectId,
        paths: props.paths,
      };
    }
    return state;
  }

  addToSelectedMedias(media, keypress) {
    let selectedMedias = this.state.selectedMedias;
    var index = selectedMedias.indexOf(media);
    if (index > -1) {
      selectedMedias.splice(index, 1);
    } else if (index === -1 && !keypress) {
      selectedMedias = [media];
    } else if (index === -1 && keypress) {
      selectedMedias.push(media);
    }
    if (selectedMedias.length == 0) {
      this.setState({isOverviewMode: false});
    } else {
      this.setState({isOverviewMode: true});
    }
    this.setState({selectedMedias: selectedMedias});
  }

  updateImageMeta(media) {
    let rate =
      media.metasData.length > 0
        ? media.metasData.filter(m => m.name == "Rating").length > 0
          ? media.metasData.filter(m => m.name == "Rating")[0].value
          : 0
        : 0;
    let label =
      media.metasData.length > 0
        ? media.metasData.filter(m => m.name == "Label").length > 0
          ? media.metasData.filter(m => m.name == "Label")[0].value
          : ""
        : "";
    let obj = [
      {
        name: "Rating",
        value: rate.toString(),
      },
      {
        name: "Label",
        value: label,
      },
    ];
    media.metasData = obj;
    this.props.changeLabelOrRate(
      this.state.projectId,
      media.productId,
      media.id,
      obj
    );
  }

  compareValues(order) {
    return function innerSort(a, b) {
      let varA;
      let varB;
      if (order !== "rate") {
        varA = a.title.toUpperCase();
        varB = b.title.toUpperCase();
      } else {
        varA = a.metasData.find(meta => meta.name === "Rating")?.value;
        varB = b.metasData.find(meta => meta.name === "Rating")?.value;
      }

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order !== "asc" ? comparison * -1 : comparison;
    };
  }

  sortMedias = e => {
    let {displayedMedias} = this.state;
    displayedMedias.sort(this.compareValues(e));
    this.setState({displayedMedias});
  };

  render() {
    return (
      <div className="p-col p-xl-9 p-lg-9 p-md-12 secondary-bg-color p-mr-xl-0 p-mr-lg-0 p-mr-md-2">
        {this.state.displayedMedias &&
        this.state.displayedMedias.length > 0 &&
        !this.state.isOverviewMode ? (
          <div className="p-grid p-dir-col">
            <ScrollPanel
              className="p-col"
              style={isTablet ? {height: "70vh"} : {height: "83vh"}}
            >
              <div className="p-grid">
                {this.state.displayedMedias.map(media => {
                  return (
                    <div className={isTablet ? "p-col-4" : "p-col-3"}>
                      <ImageCard
                        media={media}
                        selectMedia={m => this.addToSelectedMedias(m)}
                        setImageMeta={m => this.updateImageMeta(m)}
                        selectionButtonClicked={m =>
                          this.props.addToSelection(m)
                        }
                        validationButtonClicked={(m, s) =>
                          this.props.validateMedia(m, s)
                        }
                        commentButtonClicked={m =>
                          this.props.commentButtonClicked(m)
                        }
                        setCheckedmedia={m => this.props.updateCheckedMedias(m)}
                        showLinkedPopup={m => this.props.showLinkedPopup(m)}
                      />
                    </div>
                  );
                })}
              </div>
            </ScrollPanel>
            <SessionFooter
              className="p-col"
              displayedMediasLength={this.state.displayedMedias.length}
              selectedMediasLength={this.props.selectedMediasLength}
              sortMedias={this.sortMedias}
              setSelectAllMedias={p => this.props.setSelectAllMedias(p)}
            />
          </div>
        ) : this.state.displayedMedias &&
          this.state.selectedMedias !== undefined &&
          this.state.selectedMedias.length > 0 ? (
          <div
            className="p-d-flex p-dir-col p-jc-between"
            style={{height: "85vh"}}
          >
            {this.state.isOverviewMode &&
              this.state.selectedMedias.length === 1 && (
                <div className="p-col-fixed" style={{height: "40px"}}>
                  <ImageOverviewHeader
                    media={this.state.selectedMedias[0]}
                    commentButtonClicked={m =>
                      this.props.commentButtonClicked(m)
                    }
                    selectionButtonClicked={m => this.props.addToSelection(m)}
                    validationButtonClicked={m => this.props.validateMedia(m)}
                    setImageMeta={m => this.updateImageMeta(m)}
                    showLinkedPopup={m => this.props.showLinkedPopup(m)}
                  />
                </div>
              )}
            <ImageOverview
              selected={this.state.selectedMedias}
              medias={this.state.displayedMedias}
              selectMedia={(m, e) => this.addToSelectedMedias(m, e)}
              setImageMeta={m => this.updateImageMeta(m)}
            />
          </div>
        ) : (this.state.displayedMedias &&
            this.state.displayedMedias.length === 0) ||
          this.state.products.length === 0 ? (
          !Object.values(this.props.filters).every(a => a.length === 0) ? (
            <div className="p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
              <h5>{this.props.t("session.noImgFilter")}</h5>
            </div>
          ) : this.state.paths.length === 0 ? (
            <div className="image-placeholder p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
              {this.props.t("session.noImgSync")}
              <Button
                className="p-button-rounded p-mt-6"
                onClick={() => this.props.syncButtonClicked()}
                loading={this.props.isSynchronizing ? true : false}
                label={this.props.t("session.sync")}
              />
            </div>
          ) : this.props.isFetched ? (
            <div className="p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
              <ProgressSpinner className="p-progress-spinner" />
            </div>
          ) : (
            <div className="p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
              <h5>{this.props.t("session.noImg")}</h5>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const {isRateOrLabelFetching, isImageValidated} = state.products;

  return {isRateOrLabelFetching, isImageValidated};
}

const actionCreators = {
  changeLabelOrRate: productsActions.changeLabelOrRate,
  validateImg: productsActions.validateImg,
  selectedImagesBool: productsActions.selectedImagesProductBool,
};

const connectedSessionScreen = connect(
  mapState,
  actionCreators
)(withTranslation()(ImageContainer));
export {connectedSessionScreen as ImageContainer};
