import React, { Component } from "react";
import Header from "../common/Header";
import Moment from "react-moment";
import moment from "moment";
import "./notificationHistory.scss";
import { hubActions } from "../../redux/actions/notifications.actions";
import { connect } from "react-redux";
import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";
import { history } from "../../redux/helpers";
import { VirtualScroller } from "primereact/virtualscroller";
import { ProgressSpinner } from "primereact/progressspinner";
class NotificationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReceivedData: [],
      showLoader: true,
      lazyLoading: false,
      currentPage: 1,
      totalCount: null,
      isFetched: false,
    };
    this.markAsRead = this.markAsRead.bind(this);
    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.redirectToNotificationTarget =
      this.redirectToNotificationTarget.bind(this);
  }

  componentDidMount() {
    let dtoShoot = { currentPage: 1, perPage: 10 };
    this.props.getNotificationHistory(dtoShoot);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.notifications.isFetched &&
      props.notifications.type.result.length > 0 &&
      !state.isFetched &&
      props.notifications.type.currentPage == 1
    ) {
      return {
        ReceivedData: props.notifications.type.result,
        currentPage: props.notifications.type.currentPage,
        totalCount: props.notifications.type.totalCount,
        isFetched: props.notifications.isFetched,
        lazyLoading: false,
        showLoader: false,
      };
    }
    if (
      props.notifications.isFetched &&
      props.notifications.type.currentPage > 1 &&
      props.notifications.type.currentPage !== state.currentPage
    ) {
      return {
        ReceivedData: state.ReceivedData.concat(
          props.notifications.type.result
        ),
        currentPage: props.notifications.type.currentPage,
        totalCount: props.notifications.type.totalCount,
        isFetched: props.notifications.isFetched,
        lazyLoading: false,
        showLoader: false,
      };
    }
    return state;
  }
  onLazyLoad(event) {
    if (
      event.first > 0 &&
      event.first < event.last &&
      event.last == this.state.ReceivedData.length &&
      this.state.ReceivedData.length > 0 &&
      event.last < this.state.totalCount
    ) {
      let dto = { currentPage: this.state.currentPage + 1, perPage: 10 };
      console.log(event);
      console.log(dto);
      this.props.getNotificationHistory(dto);
      this.setState({ lazyLoading: true, showLoader: true });
    }
  }
  markAsRead(notificationId) {
    this.setState({ notificationId: notificationId });
    let notifications = this.state.ReceivedData;
    let notification = notifications.filter((n) => n.id == notificationId)[0];
    if (!notification.isRead) this.props.markAsRead(notificationId);
    let storedProject = JSON.parse(localStorage.getItem("project"));
    if (notification.projectId > 0) {
      if (
        storedProject == null ||
        storedProject.projectId !== notification.projectId
      ) {
        let projects = JSON.parse(localStorage.getItem("projects"));
        if (projects.length > 0) {
          let notificationProject = projects.filter(
            (n) => n.projectId == notification.projectId
          )[0];
          localStorage.setItem("project", JSON.stringify(notificationProject));
        }
      }
    }
    this.redirectToNotificationTarget(notification);
  }
  redirectToNotificationTarget(notification) {
    switch (notification.objectType) {
      case "Catalog": {
        history.push(`/catalog`);
        break;
      }
      case "Image": {
        history.push(
          `/projects/${notification.projectId}/session/products/${notification.productId}/images/${notification.objectId}/comments`
        );
        break;
      }
      case ("Sync", "Export", "ExportMerch"): {
        history.push(`/projects/${notification.projectId}/session`, {
          fromNotif: true,
        });
        break;
      }
      case "ListShoot": {
        history.push(`/projects/${notification.projectId}/lists/products`);
        break;
      }
      case "File": {
        history.push(
          `/projects/${notification.projectId}/files/${notification.objectId}`
        );
        break;
      }
      default:
        break;
    }
  }
  render() {
    const itemTemplate = (option) => {
      let iconLetter = option.message.charAt(0);
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return (
        <div style={{ margin: "1rem", cursor: "pointer" }}>
          <div className="p-grid ">
            <div className="p-col-2">
              <span
                className="custom-marker"
                style={
                  iconLetter !== " "
                    ? { backgroundColor: `#${randomColor}` }
                    : { backgroundColor: "##5c5c5c" }
                }
              >
                {iconLetter}
              </span>
            </div>
            <div className="p-col-9">
              <div> {option.message}</div>
              <div
                style={
                  !option.isRead ? { color: "#90caf9" } : { color: "#adadad" }
                }
              >
                {" "}
                <span> Il y a </span>
                <Moment fromNow ago locale="Fr">
                  {option.created}
                </Moment>
              </div>
            </div>
            <div className="p-col">
              {!option.isRead ? (
                <Badge severity="info" className="p-badge-info"></Badge>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
    };
    return (
      <>
        <Header />
        {this.state.isFetched && this.state.totalCount > 0 ? (
          <>
            <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
              <Panel
                header="Historique de notifications"
                className="p-grid p-my-6 p-dir-col"
                style={{ width: "40vw", height: "100%" }}
              >
                <VirtualScroller
                  items={this.state.ReceivedData}
                  className="p-virtualscroller-loader"
                  itemTemplate={itemTemplate}
                  onChange={(e) => this.markAsRead(e.value)}
                  onLazyLoad={(e) => this.onLazyLoad(e)}
                  loading={this.state.lazyLoading}
                  showLoader={this.state.showLoader}
                  orientation="vertical"
                  style={{ height: "40vh" }}
                  lazy
                  delay={250}
                  itemSize={50}
                />
              </Panel>
            </div>
          </>
        ) : (
          <div
            className="p-d-flex p-flex-column p-jc-center p-ai-center"
            style={{ height: "60vh" }}
          >
            <ProgressSpinner className="p-progress-spinner" />
          </div>
        )}
      </>
    );
  }
}
function mapState(state) {
  const { notifications, markAsReadIsFetched } = state;
  return { notifications, markAsReadIsFetched };
}

const actionCreator = {
  getNotificationHistory: hubActions.getNotificationHistory,
  markAsRead: hubActions.markAsRead,
};
const connectedNotifications = connect(
  mapState,
  actionCreator
)(NotificationHistory);
export { connectedNotifications as NotificationHistory };
