import { placeConstants } from '../constants';

export function place(state = {}, action){
    switch(action.type) {
        case placeConstants.PLACES_ADD_REQUEST:
            return Object.assign({}, { isFetching: true });
        case placeConstants.PLACES_ADD_SUCCESS:
            return Object.assign({}, { type: action.places, _isFetched: true });
        case placeConstants.PLACES_ADD_FAILURE:
            return {}

        case placeConstants.GET_PLACES_REQUEST:
            return Object.assign({}, { isFetching: true });
        case placeConstants.GET_PLACES_SUCCESS:
            return Object.assign({}, { type: action.places, isFetched: true});
        case placeConstants.GET_PLACES_FAILURE:
            return {}

        case placeConstants.UPDATE_PLACES_REQUEST:
            return Object.assign({}, {isFetching: true});
        case placeConstants.UPDATE_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, fetched: true});
        case placeConstants.UPDATE_PLACES_FAILURE:
            return {}

        case placeConstants.DELETE_PLACES_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case placeConstants.DELETE_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, isDeleted: true});
        case placeConstants.DELETE_PLACES_FAILURE:
            return {}

        case placeConstants.VALIDATE_PLACES_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case placeConstants.VALIDATE_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, isValidate: true});
        case placeConstants.VALIDATE_PLACES_FAILURE:
            return {}

        case placeConstants.REJECT_PLACES_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case placeConstants.REJECT_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, isRejected: true});
        case placeConstants.REJECT_PLACES_FAILURE:
            return {}

        case placeConstants.DRAFT_PLACES_REQUEST:
            return Object.assign({}, {isDrafting: true});
        case placeConstants.DRAFT_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, isDrafted: true});
        case placeConstants.DRAFT_PLACES_FAILURE:
            return {}

        case placeConstants.DELETE_ALL_PLACES_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case placeConstants.DELETE_ALL_PLACES_SUCCESS:
            return Object.assign({}, {type: action.place, isAllDeleted: true});
        case placeConstants.DELETE_ALL_PLACES_FAILURE:
            return {}
        default:
            return state
    }
}
