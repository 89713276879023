import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userActions, alertActions} from '../../redux/actions';
import {Panel} from 'primereact/panel';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';
import {emailRegex} from '../common/Regex'
import { withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			email: ''
		}
	}

	submitEmail = ( event ) => {
		const { t } = this.props;
		event.preventDefault()
		if(emailRegex.test(this.state.email)){
			this.props.forgetPassword(this.state.email);
		}
		else if (!emailRegex.test(this.state.email)){
			this.props.warning(t('toast.invalidEmail'))

		}
		else {
			this.props.warning(t('toast.emptyEmail'))
		}
	}

	handleKeyPress = ( event ) => {
		const { t } = this.props;
		if (event.key === 'Enter') {
			event.preventDefault()
			if(emailRegex.test(this.state.email)){
				this.props.forgetPassword(this.state.email);
			}
			else if (!emailRegex.test(this.state.email)){
				this.props.warning(t('toast.invalidEmail'))

			}
			else {
				this.props.warning(t('toast.emptyEmail'))
			}
		}
	}


	render() {
		const { t } = this.props;

		return (
			<div className="p-grid p-d-flex authentication-container p-jc-center p-ai-center">
				<div className="p-col-4 p-sm-12 p-md-6 p-lg-4">
					<Panel header={t('authentication.common.forgot')} className="p-pt-4">
						<p className="p-text-center">{t('authentication.forgotPassword.instruction')}</p>
						<div className="p-d-flex p-flex-column p-mt-5 p-jc-between">
							<span className="p-float-label p-my-3">
								<InputText id="email"
								           value={this.state.email}
								           onChange={( e ) => this.setState({email: e.target.value})}
								           style={{width: '100%'}}
								           onKeyPress={this.handleKeyPress}
								/>
								<label htmlFor="email">{t('common.email')}</label>
							</span>
							<Button onClick={this.submitEmail} className="p-my-3 p-button-rounded p-as-end"
							        style={{width: '30%'}} label={t('common.valid')}/>
							<Link className="p-as-center p-mt-5 link" to="/login">{t('authentication.forgotPassword.back')}</Link>
						</div>
					</Panel>
				</div>
			</div>
		);
	}
}

function mapState( state ) {
	const {authentication, user} = state;
	return {authentication, user};
}

const actionCreators = {
	forgetPassword: userActions.forgetPassword,
	warning: alertActions.warning
};

const connectedLoginPage = (connect(mapState, actionCreators)(withTranslation()(ForgotPassword)));
export {connectedLoginPage as ForgotPassword};
