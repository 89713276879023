import React, { Component } from 'react'
import Header from '../common/Header'
import TasksMenu from './TasksMenu'
import { connect } from 'react-redux'
import { projectActions, tasksAction } from '../../redux/actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace'
import { Dropdown } from 'primereact/dropdown'
import { Avatar } from 'primereact/avatar'
import { ProgressBar } from 'primereact/progressbar'
import { Checkbox } from 'primereact/checkbox'
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectRetouch extends Component {
    constructor (props) {
        super(props)
        this.state = {
            projectId: null,
            user: '',
            toDo: [],
            inProgress: [],
            done: [],
            members: [],
            selectedMembers: '',
            newItemInput: false,
            newSelectedInput: false,
            newToDo: '',
            newInProgress: '',
            openDialog: false,
            itemDialog: {},
            comment: '',
            addElement: '',
            memberChoise: null,
            isFetched: false,
            loading: true
        }
        this.onDragEnd = this.onDragEnd.bind(this)
        this.move = this.move.bind(this)
        this.getList = this.getList.bind(this)
        this.addItems = this.addItems.bind(this)
        this.onDropdownChange = this.onDropdownChange.bind(this)
        this.onHide = this.onHide.bind(this)
    }

    componentDidMount () {
        let projectId = this.props.match.params.projectId
        let user = JSON.parse(localStorage.getItem('user'))
        if (projectId)
            this.setState({ projectId: projectId, user: user.profile.name })
        this.props.getTasks(projectId)
        this.props.getProject(projectId)
    }

	static getDerivedStateFromProps(props, state){

        const toDoStatus = 0
        const inProgressStatus = 2
        const doneStatus = 4

		if (props.type && props.isFetched) {
			let tasks = props.type.filter(task => task.taskTypeLabel === 'Retouch' && task.taskType === 1)
			let toDo = tasks.filter(task => task.status === toDoStatus)
			let inProgress = tasks.filter(task => task.status === inProgressStatus)
			let done = tasks.filter(task => task.status === doneStatus)
			return {
				tasks: tasks,
                toDo: toDo,
                inProgress: inProgress,
                done: done,
				isFetched: props.isFetched
			}
		}

		if(props._isFetched){
			if (props.type.status === toDoStatus){
				let toDo = [...state.toDo];
                toDo[toDo.length - 1] = props.type;
				return {toDo}
			}
			if(props.type.status === inProgressStatus){
				let inProgress = [...state.inProgress];
                inProgress[inProgress.length - 1] = props.type;
				return {inProgress}
			}
		}

		if (props.project && props.project.isFetched && props.project.project.members !== state.members) {
			return { members: props.project.project.members }
		}

		if (props.isChecklistFetched){
			let itemDialog = state.itemDialog
			let tasks = state.tasks
			itemDialog.checkListItems = props.type

			let founfIdx = tasks.findIndex(y => y.id == itemDialog.id)
			tasks[founfIdx].checkListItems = props.type

			if(itemDialog.status === toDoStatus){
				let toDo = [...state.toDo];
				let foundIndex = toDo.findIndex(x => x.id == itemDialog.id)
                toDo[foundIndex].checkListItems = props.type
				return {toDo}
			}

			if(itemDialog.status === inProgressStatus){
				let inProgress = [...state.inProgress];
				let foundIndex = inProgress.findIndex(x => x.id == itemDialog.id)
                inProgress[foundIndex].checkListItems = props.type
				return {inProgress}
			}

			return {itemDialog, tasks}
		}

		return state
	}

    id2List = {
        toDo: 'toDo',
        inProgress: 'inProgress',
        done: 'done'
    }

    toDo = 'toDo'
    inProgress = 'inProgress'
    done = 'done'

    toDoStatus = 0
    inProgressStatus = 2
    doneStatus = 4

    getList = id => {return this.state[this.id2List[id]]}

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    move = (source, destination, droppableSource, droppableDestination) => {

        const sourceClone = Array.from(source)
        const destClone = Array.from(destination)
        const [removed] = sourceClone.splice(droppableSource.index, 1)

        destClone.splice(droppableDestination.index, 0, removed)

        const result = {}
        result[droppableSource.droppableId] = sourceClone
        result[droppableDestination.droppableId] = destClone

        return result
    }

    onDragEnd = result => {
        const { source, destination } = result
        let id = result.draggableId

        // dropped outside the list
        if (!destination) {
            return
        }

        if (source.droppableId === destination.droppableId) {
            const items = this.reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            )

            let state = { toDo: items }

            if (source.droppableId === this.inProgress) {
                state = { inProgress: items }
            }

            if (source.droppableId === this.done) {
                state = { done: items }
            }

            this.setState(state)
        } else {

            let tableSource = this.getList(source.droppableId)
            let tableDestination = this.getList(destination.droppableId)

            const result = this.move(
                tableSource,
                tableDestination,
                source,
                destination
            )

            if (result.toDo) {
                this.setState({ toDo: result.toDo })
                this.props.changeStatusTask(this.state.projectId, id, this.toDoStatus)
            }
            if (result.inProgress) {
                this.setState({ inProgress: result.inProgress })
                this.props.changeStatusTask(this.state.projectId, id, this.inProgressStatus)
            }
            if (result.done) {
                this.setState({ done: result.done })
                this.props.changeStatusTask(this.state.projectId, id, this.doneStatus)
            }

        }
    }

    addItems = (e, etat) => {
        e.preventDefault()

        let item
        let table
        let status

        if (etat === this.toDo) {
            item = this.state.newToDo
            table = this.state.toDo
            status = this.toDoStatus
        } else if (etat === this.inProgress) {
            item = this.state.newInProgress
            table = this.state.inProgress
            status = this.inProgressStatus
        }

        let newObj = {
            id: 'new-' + item.replace(/\s/g, '-'),
            title: item,
            status: status
        }

        let newObject = {
            title: item,
            status: status,
            taskTypeLabel: 'Retouch',
            taskType: 1
        }

        table.push(newObj)

        if (etat === this.toDo) {
            this.props.addTasks(this.state.projectId, newObject)
            this.setState({
                toDo: table,
                newSelectedInput: false,
                newToDo: ''
            })
        } else if (etat === this.inProgress) {
            this.props.addTasks(this.state.projectId, newObject)
            this.setState({
                inProgress: table,
                newSelectedInput: false,
                newInProgress: '',
            })
        }
        this.onHide()
    }

    onDropdownChange = (e) => {
        let { itemDialog } = this.state
        itemDialog.assignedToUserId = e.value.userId
        itemDialog.assignedTo = e.value.name
        this.setState({
            itemDialog: itemDialog,
            memberChoise: e.value
        })
    }

    openDialog = (item) => {
        let { members } = this.state
        if (item.assignedTo !== null) {
            let membre = members.filter(member => member.name == item.assignedTo)
            this.setState({
                memberChoise: membre[0]
            })
        }

        this.setState({
            openDialog: true,
            itemDialog: item
        })

    }

    onHide = () => {
        this.setState({
            openDialog: false,
            itemDialog: {},
            comment: '',
            newItemInput: false,
            newSelectedInput: false,
            addElement: '',
            memberChoise: null
        })
    }

    onValidate = (e) => {
        e.preventDefault()
        let { projectId, itemDialog, tasks } = this.state
        const checkListItems = itemDialog.checkListItems
        const taskComments = itemDialog.taskComments
        itemDialog.checkListItems = []
        itemDialog.taskComments = []
        this.props.updateTask(projectId, itemDialog.id, itemDialog)
        itemDialog.checkListItems = checkListItems
        itemDialog.taskComments = taskComments

        let index = this.state.toDo.findIndex(item => item.id === itemDialog.id)

        if (itemDialog.status === this.toDoStatus) {
            this.setState(({ toDo }) => ({
                toDo: [
                    ...toDo.slice(0, index),
                    itemDialog,
                    ...toDo.slice(index + 1)
                ]
            }))
        } else if (itemDialog.status === this.inProgress) {
            this.setState(({ inProgress }) => ({
                inProgress: [
                    ...inProgress.slice(0, index),
                    itemDialog,
                    ...inProgress.slice(index + 1)
                ]
            }))
        } else if (itemDialog.status === this.doneStatus) {
            this.setState(({ done }) => ({
                done: [
                    ...done.slice(0, index),
                    itemDialog,
                    ...done.slice(index + 1)
                ]
            }))
        }
        this.onHide()
    }

    addComment = (e) => {
        e.preventDefault()

        let { projectId, comment, itemDialog } = this.state
        let obj = {}
        obj.comment = comment
        this.props.commentTask(projectId, itemDialog.id, obj)
        itemDialog.taskComments.push(obj)
        this.setState({ comment: '' })
    }

    numberChecked = () => {
        if (this.state.itemDialog.checkListItems) {
            let table = this.state.itemDialog.checkListItems.filter(check => check.resolved === true).length
            let all = this.state.itemDialog.checkListItems.length
            return (table * 100) / all
        }
    }

    checkedList = (e, check) => {
        let { projectId, itemDialog } = this.state
        let todo = itemDialog.checkListItems.filter(p => p === check)
        const index = itemDialog.checkListItems.indexOf(check)

        if (index !== -1) {
            todo[0].resolved = !todo[0].resolved
            // itemDialog.checkListItems[index] = todo
            this.setState({
                ...this.state,
                itemDialog: {
                    ...itemDialog,
                    checkListItems: [
                        ...this.state.itemDialog.checkListItems.slice(0, index),
                        Object.assign({}, this.state.itemDialog.checkListItems[index], todo[0]),
                        ...this.state.itemDialog.checkListItems.slice(index + 1)
                    ]
                }
            })
            this.props.validateChecklist(projectId, itemDialog.id, check.id)
        }

    }

    addElement = (e) => {
        e.preventDefault()
        let { projectId, itemDialog, addElement } = this.state
        let check = {
            resolved: false,
            description: addElement
        }
        this.props.addCheckList(projectId, itemDialog.id, check)
        this.setState({
            ...this.state,
            itemDialog: {
                ...itemDialog,
                checkListItems: [
                    ...this.state.itemDialog.checkListItems,
                    check,
                ]
            },
            addElement: ''
        })
    }

    render () {
        const {t} = this.props;

        let header = (
            <div>
                <Inplace closable>
                    <InplaceDisplay>
                        {this.state.itemDialog.title ? this.state.itemDialog.title : 'Unknown'}
                    </InplaceDisplay>
                    <InplaceContent>
                        <InputText value={this.state.itemDialog.title}
                                   onChange={(e) => this.setState(({ itemDialog }) => ({
                                       itemDialog: {
                                           ...itemDialog,
                                           title: e.target.value
                                       }
                                   }))}
                        />
                    </InplaceContent>
                </Inplace>
            </div>
        )

        let footer = (
            <div>
                <Button label={t('common.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.onHide}/>
                <Button label={t('common.add')} icon='pi pi-check' className="p-button-text" onClick={this.onValidate}/>
            </div>
        )

        return (
            <div>
                <Header/>
                <TasksMenu activeItemIndex="1"/>
                {this.state.isFetched ? <div className="p-m-4 p-grid p-align-start">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="p-col-3" style={{ height: '90vh' }}>
                            <Droppable droppableId="toDo">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={{ height: '90vh' }}>
                                        <h5>{t('task.toDo')}</h5>
                                        {this.state.toDo && this.state.toDo.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (<div
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="p-p-4 p-my-3" style={{
                                                                width: '80%',
                                                                backgroundColor: '#252525',
                                                                borderRadius: '10px'
                                                            }}
                                                                 onClick={() => this.openDialog(item)}>
                                                                {item.title}
                                                            </div>
                                                        </div>)
                                                    }}

                                                </Draggable>)
                                        })}
                                        {provided.placeholder}
                                        {this.state.newItemInput ?
                                            <>
                                                <InputText value={this.state.newToDo}
                                                           required
                                                           onChange={(e) => this.setState({ newToDo: e.target.value })}
                                                           onKeyPress={e => {
	                                                           if(e.key === 'Enter'){
		                                                           this.addItems(e, this.toDo)
	                                                           }
                                                           }}
                                                           placeholder={t('task.nameTask')}/>
                                                <div className='p-as-end p-mt-3'>
                                                    <Button label={t('common.cancel')}
                                                            className="p-button-rounded p-button-sm p-mr-2"
                                                            onClick={this.onHide}/>
                                                    <Button label={t('common.add')} className="p-button-rounded p-button-sm"
                                                            onClick={(e) => this.addItems(e, this.toDo)}/>

                                                </div>
                                            </>
                                            :
                                            <Button label={t('common.add')} icon="pi pi-plus" className="p-button-text"
                                                    onClick={() => this.setState({ newItemInput: true })}/>
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <Divider style={{ height: '80vh', color: '#252525' }} className="p-col-1" layout="vertical"/>
                        <div className="p-col-3" style={{ height: '90vh' }}>
                            <Droppable droppableId="inProgress">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
	                                    style={{ height: '90vh' }}
                                    >
                                        <h5>{t('task.inProgress')}</h5>
                                        {this.state.inProgress && this.state.inProgress.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (<div
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="p-p-4 p-my-3" style={{
                                                                width: '80%',
                                                                backgroundColor: '#252525',
                                                                borderRadius: '10px'
                                                            }}
                                                                 onClick={() => this.openDialog(item)}>
                                                                {item.title}
                                                            </div>
                                                        </div>)
                                                    }}
                                                </Draggable>)
                                        })}
                                        {provided.placeholder}
                                        {this.state.newSelectedInput ?
                                            <>
                                                <InputText value={this.state.newInProgress}
                                                           required
                                                           onChange={(e) => this.setState({ newInProgress: e.target.value })}
                                                           onKeyPress={e => {
	                                                           if(e.key === 'Enter'){
		                                                           this.addItems(e, this.inProgress)
	                                                           }
                                                           }}
                                                           placeholder={t('task.nameTask')} />
                                                <div className='p-as-end p-mt-3'>
                                                    <Button label={t('common.cancel')}
                                                            className="p-button-rounded p-button-sm p-mr-2"
                                                            onClick={this.onHide}/>
                                                    <Button label={t('common.add')} className="p-button-rounded p-button-sm"
                                                            onClick={(e) => this.addItems(e, this.inProgress)}/>

                                                </div>
                                            </>
                                            :
                                            <Button label={t('common.add')} icon="pi pi-plus" className="p-button-text"
                                                    onClick={() => this.setState({ newSelectedInput: true })}/>
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <Divider style={{ height: '80vh', color: '#252525' }} className="p-col-1" layout="vertical"/>
                        <div className="p-col-3" style={{ height: '90vh' }}>
                            <Droppable droppableId="done">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                         style={{ height: '90vh' }}
                                    >
                                        <h5>{t('task.done')}</h5>
                                        {this.state.done && this.state.done.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (<div
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="p-p-4 p-my-3" style={{
                                                                width: '80%',
                                                                backgroundColor: '#252525',
                                                                borderRadius: '10px'
                                                            }}
                                                                 onClick={() => this.openDialog(item)}>
                                                                {item.title}
                                                            </div>
                                                        </div>)
                                                    }}
                                                </Draggable>)
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>


                    <Dialog header={header} footer={footer} visible={this.state.openDialog}
                            className="p-p-2"
                            style={{ width: '50vw', height: 'auto' }} onHide={this.onHide}>
                        <div className='p-mb-6'>
                            <h5>{t('projectDetails.members')}</h5>
                            <Dropdown value={this.state.memberChoise}
                                      optionLabel='name'
                                      options={this.state.members}
                                      onChange={this.onDropdownChange}
                                      placeholder={t('task.designateMember')}/>
                        </div>
                        <div className='p-my-6'>
                            <h5>{t('task.detail')}</h5>
                            <InputText value={this.state.itemDialog.description}
                                       style={{ width: '100%' }}
                                       placeholder="Ajouter une description"
                                       onChange={(e) => this.setState(({ itemDialog }) => ({
                                           itemDialog: {
                                               ...itemDialog,
                                               description: e.target.value
                                           }
                                       }))}/>
                        </div>

                        <div className='p-my-6'>
                            <h5>{t('sessionScreen.comment.title')}</h5>
                            <form onSubmit={this.addComment} className="p-mb-2">
                                <InputText value={this.state.comment} style={{ width: '100%' }}
                                           onChange={(e) => this.setState({ comment: e.target.value })}/>
                            </form>
                            {this.state.itemDialog.taskComments && this.state.itemDialog.taskComments.map(comment => {
                                    return (<div className="p-my-5">
                                            {comment.author ?
                                                <>
                                                    <p className="p-mb-1">{comment.author} : </p>
                                                    <div>
                                                        <Avatar label={comment.author.charAt(0)} className="p-mr-2"
                                                                style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                                                                shape="circle"/>
                                                        <p className='p-p-3' style={{
                                                            backgroundColor: '#323232',
                                                            borderRadius: '20px',
                                                            display: 'inline-block'
                                                        }}>{comment.comment}</p>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <p className="p-mb-1">{this.state.user} : </p>
                                                    <div>
                                                        <Avatar label={this.state.user.charAt(0)} className="p-mr-2"
                                                                style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                                                                shape="circle"/>
                                                        <p className='p-p-3' style={{
                                                            backgroundColor: '#323232',
                                                            borderRadius: '20px',
                                                            display: 'inline-block'
                                                        }}>{comment.comment}</p>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    )
                                }
                            )}
                        </div>

                        <div className='p-my-6'>
                            <h5>{t('task.toDoTitle')}</h5>
                            <ProgressBar className="p-mb-2" value={this.numberChecked()}/>
                            {this.state.itemDialog.checkListItems && this.state.itemDialog.checkListItems.map((check, index) => {
                                return (<div className="p-my-3 p-d-flex">
                                    <Checkbox checked={check.resolved} onChange={(e) => this.checkedList(e, check)}/>
                                    <p className="p-ml-2">{check.description}</p>
                                </div>)
                            })}
                            <Inplace className='p-mt-6' closable>
                                <InplaceDisplay>
                                    <i className="pi pi-plus"/> {t('task.addElement')}
                                </InplaceDisplay>
                                <InplaceContent>
                                        <InputText value={this.state.addElement}
                                                   onChange={e => this.setState({ addElement: e.target.value })}
                                                   onKeyPress={e => {
	                                                   if (e.key === 'Enter') {
		                                                   this.addElement(e)
	                                                   }
                                                   }}
                                                   autoFocus/>
                                </InplaceContent>
                            </Inplace>
                        </div>

                    </Dialog>
                </div> :
                <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "90vh"}}>
                    <ProgressSpinner className="p-progress-spinner" />
                </div> }
            </div>
        )
    }
}

const mapStateToProps = state => {
	const {  isFetched, type, _isFetched, isChecklistFetched } = state.tasks
	const { project } = state
	return { project, isFetched, type, _isFetched, isChecklistFetched }
}

const mapDispatchToProps = {
    getTasks: tasksAction.getTasks,
    addTasks: tasksAction.addTasks,
    updateTask: tasksAction.updateTask,
    changeStatusTask: tasksAction.changeStatusTask,
    commentTask: tasksAction.commentTask,
    addCheckList: tasksAction.addCheckList,
    validateChecklist: tasksAction.validateChecklist,
    getProject: projectActions.getProject,
}

const connectedRetouch = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectRetouch))
export { connectedRetouch as ProjectRetouch }
