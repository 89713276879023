import { contributorConstants } from '../constants';
import { contributorService } from '../../services';
import { alertActions } from '.';


export const contributorAction = {
    addContributors,
    getAllContributors,
    deleteContributor,
    updateContributor,
    validateContributor,
    rejectContributor,
    draftContributor,
    deleteAllContributor,
    importContributor,
    attachFilesContributors
};

function addContributors(projectId, contributors){
    return dispatch => {
        dispatch(request(contributors));
        contributorService.contributorAdd(projectId, contributors)
            .then(
                contributors => {
                    dispatch(success(contributors))
                    dispatch(alertActions.success('Intervenant ajouté avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(contributors) { return { type: contributorConstants.CONTRIBUTORS_ADD_REQUEST, contributors } }
    function success(contributors) { return { type: contributorConstants.CONTRIBUTORS_ADD_SUCCESS, contributors } }
    function failure(error) { return { type: contributorConstants.CONTRIBUTORS_ADD_FAILURE, error } }

};

function getAllContributors(projectId) {
    return dispatch => {
        dispatch(request());
        contributorService.getContributor(projectId)
            .then(
                contributors => dispatch(success(contributors)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: contributorConstants.GET_CONTRIBUTORS_REQUEST } }
    function success(contributors) { return { type: contributorConstants.GET_CONTRIBUTORS_SUCCESS, contributors } }
    function failure(error) { return { type: contributorConstants.GET_CONTRIBUTORS_FAILURE, error } }
}

function deleteContributor(projectId, id){
    return dispatch => {
        dispatch(request(id))

        contributorService.deleteContributor(projectId, id)
        .then(
            contributors => {
                dispatch(success(id))
                dispatch(alertActions.success('Intervenant supprimé avec succès'));

            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: contributorConstants.DELETE_CONTRIBUTORS_REQUEST, id}}
    function success(id) {return {type: contributorConstants.DELETE_CONTRIBUTORS_SUCCESS, id}}
    function failure(id, error) {return {type: contributorConstants.DELETE_CONTRIBUTORS_FAILURE, id, error}}
}

function updateContributor(projectId, id, contributor){
    return dispatch => {
        dispatch(request({ contributor }));
        contributorService.updateContributor(projectId, id, contributor)
        .then(
            contributors => {
                dispatch(success(contributors));
                dispatch(alertActions.success('Intervenant modifié avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(contributor) {return {type: contributorConstants.UPDATE_CONTRIBUTORS_REQUEST, contributor}}
    function success(contributor) {return {type: contributorConstants.UPDATE_CONTRIBUTORS_SUCCESS, contributor}}
    function failure(error) {return {type: contributorConstants.UPDATE_CONTRIBUTORS_FAILURE, error}}
}

function validateContributor(projectId, id){
    return dispatch => {
        dispatch(request(id))
        contributorService.validateContributor(projectId, id)
        .then(
            contributors => {
                dispatch(success(id))
                dispatch(alertActions.success('Intervenant validé avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: contributorConstants.VALIDATE_CONTRIBUTORS_REQUEST, id}}
    function success(id) {return {type: contributorConstants.VALIDATE_CONTRIBUTORS_SUCCESS, id}}
    function failure(id, error) {return {type: contributorConstants.VALIDATE_CONTRIBUTORS_FAILURE, id, error}}
}

function rejectContributor(projectId, id){
    return dispatch => {
        dispatch(request(id))
        contributorService.rejectContributor(projectId, id)
        .then(
            contributors => {
                dispatch(success(id))
                dispatch(alertActions.success('Intervenant rejeté avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: contributorConstants.REJECT_CONTRIBUTORS_REQUEST, id}}
    function success(id) {return {type: contributorConstants.REJECT_CONTRIBUTORS_SUCCESS, id}}
    function failure(id, error) {return {type: contributorConstants.REJECT_CONTRIBUTORS_FAILURE, id, error}}
}

function draftContributor(projectId, id){
    return dispatch => {
        dispatch(request(id))
        contributorService.draftContributor(projectId, id)
        .then(
            contributors => {
                dispatch(success(id))
                dispatch(alertActions.success('Intervenant modifiable'));

            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: contributorConstants.DRAFT_CONTRIBUTORS_REQUEST, id}}
    function success(id) {return {type: contributorConstants.DRAFT_CONTRIBUTORS_SUCCESS, id}}
    function failure(id, error) {return {type: contributorConstants.DRAFT_CONTRIBUTORS_FAILURE, id, error}}
}

function deleteAllContributor(projectId){
    return dispatch => {
        dispatch(request())

        contributorService.deleteAllContributors(projectId)
        .then(
            contributors => {
                dispatch(success())
                dispatch(alertActions.success('Tous les intervenants ont été supprimés avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request() {return {type: contributorConstants.DELETE_ALL_CONTRIBUTORS_REQUEST}}
    function success() {return {type: contributorConstants.DELETE_ALL_CONTRIBUTORS_SUCCESS}}
    function failure( error) {return {type: contributorConstants.DELETE_ALL_CONTRIBUTORS_FAILURE, error}}
}

function importContributor(projectId, file){
    return dispatch => {
        dispatch(request(file))
        contributorService.importContributors(projectId, file)
            .then(
                contributors => dispatch(success(file)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function request(file) {return { type: contributorConstants.IMPORT_CONTRIBUTORS_REQUEST, file }}
    function success(file) {return { type: contributorConstants.IMPORT_CONTRIBUTORS_SUCCESS, file }}
    function failure(error) {return { type: contributorConstants.IMPORT_CONTRIBUTORS_FAILURE, error }}
}

function attachFilesContributors(projectId, contributorId, file){
    return dispatch => {
        dispatch(request(file))
        contributorService.attachFilesContributors(projectId, contributorId, file)
            .then(
                contributors => {
                    dispatch(success(file))
                    dispatch(alertActions.success('Tous les intervenants sont importés avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(file) {return { type: contributorConstants.IMPORT_FILE_CONTRIBUTORS_REQUEST, file }}
    function success(file) {return { type: contributorConstants.IMPORT_FILE_CONTRIBUTORS_SUCCESS, file }}
    function failure(error) {return {type: contributorConstants.IMPORT_FILE_CONTRIBUTORS_FAILURE, error}}
}
