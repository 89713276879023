import {productsConstants} from '../constants';
import {projectConstants} from '../constants';
import {productsService} from '../../services';
import {projectService} from '../../services';
import { catalogConstants } from '../constants'
import {alertActions} from './';

export const productsActions = {
    submitproducts,
    getImages,
    submitSelectedImages,
    getReference,
    setComments,
    setCommentReference,
    setCommentProject,
    getComments,
    getProject,
    getSessionHistory,
    setUrgent,
    setVersion,
    setOrder,
    getProduct,
    uploadAllImages,
    addReference,
    getImageProduct,
    submitSelectedImagesProduct,
    unselectImage,
    deleteImage,
    synchronizeSession,
    changeLabelOrRate,
    validateImg,
    rejectImg,
    validateImages,
    uploadRetouchZip,
    selectedImagesProductBool,
    linkProduct,
    getProductLinked,
    exportValideImage,
    getFolder,
    generateBarCodes,
    exportMerch
};
function generateBarCodes(projectId) {
    return dispatch => {
        dispatch(request())
        productsService.generateBarCodes(projectId)
            .then(
                () => dispatch(success()),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: catalogConstants.PRODUCTS_BARCODE_REQUEST } }
    function success() { return { type: catalogConstants.PRODUCTS_BARCODE_SUCCESS } }
    function failure(error) { return { type: catalogConstants.PRODUCTS_BARCODE_FAILURE, error } }
}
function setUrgent(projectId, productId) {
    return dispatch => {
        dispatch(request());
        productsService.setUrgent(projectId, productId)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_URGENT_REQUEST}
    }

    function success(product) {
        return {type: productsConstants.SET_URGENT_SUCCESS, product}
    }

    function failure(error) {
        return {type: productsConstants.SET_URGENT_FAILURE, error}
    }
}

function submitproducts(file) {
    return dispatch => {
        dispatch(request());
        productsService.submitproducts()
            .then(
                products => dispatch(success(products)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.PRODUCTS_TYPE_REQUEST}
    }

    function success(products) {
        return {type: productsConstants.PRODUCTS_TYPE_SUCCESS, products}
    }

    function failure(error) {
        return {type: productsConstants.PRODUCTS_TYPE_FAILURE, error}
    }
}

function validateImages(projectId, imageIds){
  return dispatch => {
    dispatch(request(imageIds));
    productsService.validateImages(projectId, imageIds)
    .then(
      product => {
        dispatch(alertActions.success('La validation des images a été modifiées'));
        dispatch(success(product))},
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    )
  }
  function request(imageIds) {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_REQUEST, imageIds}}
  function success(product) {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_SUCCESS, product}}
  function failure(imageIds, error) {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_FAILURE, imageIds, error}}
}

function uploadAllImages(id) {
    let projecId = id
    return dispatch => {
        dispatch(requestAllImagesSubmit());
        productsService.uploadAllImages(id)
            .then
            (function () {
                    dispatch(successImagesSubmit());
                    dispatch(requestCheckImages(projecId));
                    productsService.checkImages(projecId)
                        .then(
                            products => dispatch(successCheckImages(products)),
                            error => {
                                dispatch(failureCheckImages(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function requestAllImagesSubmit() {
        return {type: productsConstants.ALL_IMAGES_SUBMIT_TYPE_REQUEST}
    }

    function successImagesSubmit(images) {
        return {type: productsConstants.ALL_IMAGES_SUBMIT_TYPE_SUCCESS, images}
    }

    function failure(error) {
        return {type: productsConstants.ALL_IMAGES_SUBMIT_TYPE_FAILURE, error}
    }

    function requestCheckImages(id) {
        return {type: productsConstants.CHECK_IMAGES, id}
    }

    function successCheckImages(products) {
        return {type: productsConstants.SUCCESS_CHECK_IMAGES, products}
    }

    function failureCheckImages(products) {
        return {type: productsConstants.FAILURE_CHECK_IMAGES, products}
    }
}

function submitSelectedImages(id, images) {
    let projecId = id
    return dispatch => {
        dispatch(request());
        productsService.submitSelectedImages(id, images)
            .then
            (function () {
                    dispatch(successImagesSubmit(images));
                    dispatch(requestCheckImages(projecId));
                    productsService.checkImages(projecId)
                        .then(
                            products => dispatch(successCheckImages(products)),
                            error => {
                                dispatch(failureCheckImages(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.IMAGES_SUBMIT_TYPE_REQUEST}
    }

    function successImagesSubmit(images) {
        return {type: productsConstants.IMAGES_SUBMIT_TYPE_SUCCESS, images}
    }

    function failure(error) {
        return {type: productsConstants.IMAGES_SUBMIT_TYPE_FAILURE, error}
    }

    function requestCheckImages(id) {
        return {type: productsConstants.CHECK_IMAGES, id}
    }

    function successCheckImages(products) {
        return {type: productsConstants.SUCCESS_CHECK_IMAGES, products}
    }

    function failureCheckImages(products) {
        return {type: productsConstants.FAILURE_CHECK_IMAGES, products}
    }

}

function submitSelectedImagesProduct(id, productId, images) {
    return dispatch => {
        dispatch(request());
        productsService.submitSelectedImagesProduct(id, productId, images)
            .then(
                images => dispatch(success(images)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SELECT_IMAGES_PRODUCT_REQUEST}
    }

    function success(images) {
        return {type: productsConstants.SELECT_IMAGES_PRODUCT_SUCCESS, images}
    }

    function failure(error) {
        return {type: productsConstants.SELECT_IMAGES_PRODUCT_FAILURE, error}
    }

}

function unselectImage(projectId, imageId) {
    return dispatch => {
        dispatch(request(imageId))

        productsService.unselectImage(projectId, imageId)
            .then(
                product => dispatch(success(imageId)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(imageId) {
        return {type: productsConstants.UNSELECT_IMAGES_PRODUCT_REQUEST, imageId}
    }

    function success(imageId) {
        return {type: productsConstants.UNSELECT_IMAGES_PRODUCT_SUCCESS, imageId}
    }

    function failure(imageId, error) {
        return {type: productsConstants.UNSELECT_IMAGES_PRODUCT_FAILURE, imageId, error}
    }
}

function getImages(id, path) {
    return dispatch => {
        dispatch(request());
        productsService.getImages(id, path)
            .then(
                productImages => dispatch(success(productImages)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.PRODUCTS_IMAGES_TYPE_REQUEST}}
    function success(productImages) {return {type: productsConstants.PRODUCTS_IMAGES_TYPE_SUCCESS, productImages}}
    function failure(error) {return {type: productsConstants.PRODUCTS_IMAGES_TYPE_FAILURE, error}}
}

function getImageProduct(projectId, productId) {
    return dispatch => {
        dispatch(request());
        productsService.getImageProduct(projectId, productId)
            .then(
                productImages => dispatch(success(productImages)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.GET_PRODUCT_IMAGES_REQUEST}
    }

    function success(productImages) {
        return {type: productsConstants.GET_PRODUCT_IMAGES_SUCCESS, productImages}
    }

    function failure(error) {
        return {type: productsConstants.GET_PRODUCT_IMAGES_FAILURE, error}
    }
}

function getReference(id) {
    return dispatch => {
        dispatch(request());
        productsService.getReference(id)
            .then(
                products => dispatch(success(products)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.GET_REFERENCE_REQUEST}}
    function success(products) {return {type: productsConstants.GET_REFERENCE_SUCCESS, products}}
    function failure(error) {return {type: productsConstants.GET_REFERENCE_FAILURE, error}}
}

function setComments(type, comment, projecttId, id, pointId, imageId) {
    return dispatch => {
        dispatch(request());
        productsService.setComment(type, comment, projecttId, id, pointId, imageId)
            .then(
                comments => dispatch(success(comments)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_COMMENT_REQUEST}
    }

    function success(comments) {
        return {type: productsConstants.SET_COMMENT_SUCCESS, comments}
    }

    function failure(error) {
        return {type: productsConstants.SET_COMMENT_FAILURE, error}
    }
}

function setCommentReference(projectId, productId, comment) {
    return dispatch => {
        dispatch(request());
        productsService.setCommentReference(projectId, productId, comment)
            .then(
                comments => dispatch(success(comments)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_COMMENT_REFERENCE_REQUEST}
    }

    function success(comments) {
        return {type: productsConstants.SET_COMMENT_REFERENCE_SUCCESS, comments}
    }

    function failure(error) {
        return {type: productsConstants.SET_COMMENT_REFERENCE_FAILURE, error}
    }
}

function setCommentProject(projectId, comment) {
    return dispatch => {
        dispatch(request());
        productsService.setCommentProject(projectId, comment)
            .then(
                comments => {
                    dispatch(success(comments))
                    dispatch(alertActions.success('Retouch launched successfully'));
                },

                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_COMMENT_PROJECT_REQUEST}
    }

    function success(comments) {
        return {type: productsConstants.SET_COMMENT_PROJECT_SUCCESS, comments}
    }

    function failure(error) {
        return {type: productsConstants.SET_COMMENT_PROJECT_SUCCESS, error}
    }
}

function getComments(imageId, productId, projecttId) {
   
    return dispatch => {
        dispatch(request());
        productsService.getComments(imageId, productId, projecttId)
            .then(
                comments => dispatch(success(comments)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.GET_COMMENTS_REQUEST}
    }

    function success(comments) {
        return {type: productsConstants.GET_COMMENTS_SUCCESS, comments}
    }

    function failure(error) {
        return {type: productsConstants.GET_COMMENTS_FAILURE, error}
    }
}

function getProject(id) {
    return dispatch => {
        dispatch(request());
        projectService.getProject(id)
            .then(project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                });
    };

    function request() {
        return {type: projectConstants.GET_PROJECT_DETAILS_REQUEST}
    }

    function success(project) {
        return {type: projectConstants.GET_PROJECT_DETAILS_SUCCESS, project}
    }

    function failure(error) {
        return {type: projectConstants.GET_PROJECT_DETAILS_FAILURE, error}
    }
}

function synchronizeSession(projectId) {
    return dispatch => {
        dispatch(request());
        productsService.synchronizeSession(projectId)
            .then(
                products => {
                    dispatch(success(products));
                    dispatch(alertActions.success("La synchronisation des images avec Dropbox a été lancée. Vous serez notifié quand elle sera terminée"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                })
 
    };

    function request() {return {type: productsConstants.GET_SYNC_DROPBOX_REQUEST}}
    function success(products) {return {type: productsConstants.GET_SYNC_DROPBOX_SUCCESS, products}}
    function failure(error) {return {type: productsConstants.GET_SYNC_DROPBOX_FAILURE, error}}
}

function getSessionHistory(projectId, imageId) {
    return dispatch => {
        dispatch(request());
        productsService.getSessionHistory(projectId, imageId)
            .then(
                productHistory => dispatch(success(productHistory)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.GET_PROJECT_HISTORY_REQUEST}}
    function success(productHistory) {return {type: productsConstants.GET_PROJECT_HISTORY_SUCCESS, productHistory}}
    function failure(error) {return {type: productsConstants.GET_PROJECT_HISTORY_FAILURE, error}}
}

function setVersion(projectId, imageId, version) {
    return dispatch => {
        dispatch(request());
        productsService.setVersion(projectId, imageId, version)
            .then(
                productHistory => dispatch(success(productHistory)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_PROJECT_VERSION_REQUEST}
    }

    function success(productHistory) {
        return {type: productsConstants.SET_PROJECT_VERSION_SUCCESS, productHistory}
    }

    function failure(error) {
        return {type: productsConstants.SET_PROJECT_VERSION_FAILURE, error}
    }
}

function setOrder(projectId, imageId, order) {
    return dispatch => {
        dispatch(request());
        productsService.setOrder(projectId, imageId, order)
            .then(
                products => dispatch(success(products)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.SET_PRODUCT_ORDER_SUCCESS}
    }

    function success(products) {
        return {type: productsConstants.SET_PRODUCT_ORDER_SUCCESS, products}
    }

    function failure(error) {
        return {type: productsConstants.SET_PRODUCT_ORDER_FAILURE, error}
    }
}

function addReference(projectId, product, file) {
    return dispatch => {
        dispatch(request());
        productsService.addReference(projectId, product, file)
            .then(
                products => dispatch(success(products)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.ADD_PRODUCT_REQUEST}
    }

    function success(products) {
        return {type: productsConstants.ADD_PRODUCT_SUCCESS, products}
    }

    function failure(error) {
        return {type: productsConstants.ADD_PRODUCT_FAILURE, error}
    }
}

function getProduct(projectId, productId) {
    return dispatch => {
        dispatch(request());
        productsService.getProduct(projectId, productId)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.GET_PRODUCT_REQUEST}
    }

    function success(product) {
        return {type: productsConstants.GET_PRODUCT_SUCCESS, product}
    }

    function failure(error) {
        return {type: productsConstants.GET_PRODUCT_FAILURE, error}
    }
}

function deleteImage(projectId, imageId) {
    return dispatch => {
        dispatch(request(imageId));
        productsService.deleteImage(projectId, imageId)
            .then(
                product => dispatch(success(imageId)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }

    function request(imageId) {
        return {type: productsConstants.DELETE_PRODUCT_REQUEST, imageId}
    }

    function success(imageId) {
        return {type: productsConstants.DELETE_PRODUCT_SUCCESS, imageId}
    }

    function failure(imageId, error) {
        return {type: productsConstants.DELETE_PRODUCT_FAILURE, imageId, error}
    }
}

function changeLabelOrRate(projectId, productId, imageId, value) {
    return dispatch => {
        dispatch(request());
        productsService.changeLabelOrRate(projectId, productId, imageId, value)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {type: productsConstants.CHANGE_LABEL_RATE_REQUEST}
    }

    function success(product) {
        return {type: productsConstants.CHANGE_LABEL_RATE_SUCCESS, product}
    }

    function failure(error) {
        return {type: productsConstants.CHANGE_LABEL_RATE_FAILURE, error}
    }
}

function validateImg(projectId, productId, imageId) {
    return dispatch => {
        dispatch(request());
        productsService.validateImg(projectId, productId, imageId)
            .then(
                product => {
                  dispatch(alertActions.success('La validation de l\'image a été modifiée'));
                   dispatch(success(product));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_REQUEST}}
    function success(product) {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_SUCCESS, product}}
    function failure(error) {return {type: productsConstants.VALIDATE_IMAGES_PRODUCT_FAILURE, error}}
}

function rejectImg(projectId, imageId) {
    return dispatch => {
        dispatch(request());
        productsService.rejectImg(projectId, imageId)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.REJECT_IMAGES_PRODUCT_REQUEST}}

    function success(product) {return {type: productsConstants.REJECT_IMAGES_PRODUCT_SUCCESS, product}}

    function failure(error) {return {type: productsConstants.REJECT_IMAGES_PRODUCT_FAILURE, error}}
}

function uploadRetouchZip(projectId, zip) {
    return dispatch => {
        dispatch(request());
        productsService.uploadRetouchZip(projectId, zip)
            .then(
                zip => dispatch(success(zip)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(zip) {return {type: productsConstants.ZIP_ADD_REQUEST, zip}}
    function success(zip) {return {type: productsConstants.ZIP_ADD_SUCCESS, zip}}
    function failure(error) {return {type: productsConstants.ZIP_ADD_FAILURE, error}}

};

function selectedImagesProductBool(id, images, bool) {
    return dispatch => {
        dispatch(request());
        productsService.selectedImagesProductBool(id, images, bool)
            .then(
                images =>{
                  dispatch(alertActions.success('La sélection des images a été modifiées'));
                  dispatch(success(images))},
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.SELECT_IMAGES_PRODUCT_REQUEST}}
    function success(images) {return {type: productsConstants.SELECT_IMAGES_PRODUCT_SUCCESS, images}}
    function failure(error) {return {type: productsConstants.SELECT_IMAGES_PRODUCT_FAILURE, error}}

}

function linkProduct(projectId, productId, imageId, list) {
    return dispatch => {
        dispatch(request());
        productsService.linkProduct(projectId, productId, imageId, list)
            .then(
                product => {
                    dispatch(success(product))
                    dispatch(alertActions.success("Produit visible ajouté"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.LINK_PRODUCT_REQUEST}}
    function success(product) {return {type: productsConstants.LINK_PRODUCT_SUCCESS, product}}
    function failure(error) {return {type: productsConstants.LINK_PRODUCT_FAILURE, error}}
}

function getProductLinked(projectId, productId, imageId) {
    return dispatch => {
        dispatch(request());
        productsService.getProductLinked(projectId, productId, imageId)
            .then(
                product => dispatch(success(product)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.GET_LINK_PRODUCT_REQUEST}}
    function success(product) {return {type: productsConstants.GET_LINK_PRODUCT_SUCCESS, product}}
    function failure(error) {return {type: productsConstants.GET_LINK_PRODUCT_FAILURE, error}}
}

function exportValideImage(id){
    return dispatch => {
        dispatch(request());
        productsService.exportValideImage(id)
            .then(
                image => {
                    dispatch(success(image))
                    dispatch(alertActions.success("La tâche d'export des images a été lancée. Vous serez notifié quand elle sera terminée"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.EXPORT_VALIDATE_IMAGE_REQUEST}}
    function success(image) {return {type: productsConstants.EXPORT_VALIDATE_IMAGE_SUCCESS, image}}
    function failure(error) {return {type: productsConstants.EXPORT_VALIDATE_IMAGE_FAILURE, error}}
}

function exportMerch(id){
    return dispatch => {
        dispatch(request());
        productsService.exportMerch(id)
            .then(
                image => {
                    dispatch(success(image))
                    dispatch(alertActions.success("La tâche d'export vers le dossier merch a été lancée. vous serez notifié quand elle sera terminée"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.EXPORT_MERCH_REQUEST}}
    function success(image) {return {type: productsConstants.EXPORT_MERCH_SUCCESS, image}}
    function failure(error) {return {type: productsConstants.EXPORT_MERCH_FAILURE, error}}
}

function getFolder(id) {
    return dispatch => {
        dispatch(request());
        productsService.getFolder(id)
            .then(
                path => dispatch(success(path)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {return {type: productsConstants.GET_FOLDERS_REQUEST}}
    function success(path) {return {type: productsConstants.GET_FOLDERS_SUCCESS, path}}
    function failure(error) {return {type: productsConstants.GET_FOLDERS_FAILURE, error}}
}
