import React, {Component} from 'react';
import {TabMenu} from 'primereact/tabmenu';
import {Menu} from 'primereact/menu';
import {Button} from 'primereact/button';
import {history} from '../../../redux/helpers';
import {isTablet} from 'react-device-detect'
import { withTranslation } from 'react-i18next';

class ProjectMenuTop extends Component {
    constructor(props) {
        super(props);
        let project = JSON.parse(localStorage.getItem('project'));
        this.state = {
            items: [
                { label: this.props.t('projectMenu.info'), command: () => { history.push(`/projects/${project.projectId}`); } },
                { label: this.props.t('projectMenu.files'), command: () => { history.push(`/projects/${project.projectId}/files`); } },
                { label: this.props.t('projectMenu.list'), command: () => { history.push(`/projects/${project.projectId}/lists/products`); } },
                { label: this.props.t('projectMenu.callSheet'), command: () => { history.push(`/projects/${project.projectId}/callsheet`); } }
            ]
        };
    }

    render() {
        return (
            <div className="p-d-flex">
                <div className="p-col">
                    <TabMenu model={this.state.items} activeIndex={this.props.activeItemIndex} />
                </div>
            </div>
        );
    }
}

export const ProjectMenu = withTranslation()(ProjectMenuTop)

class ProjectListingsMenuLeft extends Component {

    constructor(props) {
        super(props);
        let project = JSON.parse(localStorage.getItem('project'));
        this.state = {
            items: [
                { label: this.props.t('projectMenu.shootingList'), command: () => { history.push(`/projects/${project.projectId}/lists/products`); } },
                { label: this.props.t('projectMenu.contributor'), command: () => { history.push(`/projects/${project.projectId}/lists/contributors`); } },
                { label: this.props.t('projectMenu.place'), command: () => { history.push(`/projects/${project.projectId}/lists/places`); } },
                { label: this.props.t('projectMenu.product'), command: () => { history.push(`/projects/${project.projectId}/lists/materials`); } }
            ]
        };
    }

    render() {
        if (isTablet) {
            return (<>
                <Menu model={this.state.items} popup ref={el => this.menu = el} id="popup_menu"/>
                <Button icon="pi pi-bars" onClick={( event ) => this.menu.toggle(event)}
                        aria-controls="popup_menu" aria-haspopup/>
            </>)
        } else {
            return (
                <div className="p-col">
                    <Menu model={this.state.items}/>
                </div>
            );
        }

    }
}

export const ProjectListingsMenu = withTranslation()(ProjectListingsMenuLeft)

