import React, {Component} from "react";
import {connect} from "react-redux";
import {alertActions, catalogAction, projectActions} from "../../redux/actions";
import {Dropdown} from "primereact/dropdown";
import {Button} from 'primereact/button'
import { withTranslation } from 'react-i18next';

class AddProductsToProject extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			products: this.props.products,
			selectedProject: null,
			projects: [],
			newProjectTitle: "",
			t: this.props.t
		};
		this.choseProjectClicked = this.choseProjectClicked.bind(this);
		this.cancelButtonClicked = this.cancelButtonClicked.bind(this);
		this.updateProjectTitle = this.updateProjectTitle.bind(this);
	}

	componentDidMount() {
		this.props.getProjects();
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.isFetched) {
			if (props.projects !== state.projects)
				return {projects: props.projects};
		}
		return state;
	}

	choseProjectClicked( value ) {
		this.setState({selectedProject: value});
	}

	cancelButtonClicked() {
		this.props.cancelClicked();
	}

	submitButtonClicked() {
		if (this.state.selectedProject !== null && this.state.products.length > 0) {
			this.props.addProductsToProject(this.state.selectedProject.projectId, this.state.products.map(p => p.reference));
			this.props.alertSuccess(this.state.t('toast.productAdd'))
			this.props.submitClicked();
		} else {
			this.props.alertWarning(this.state.t('toast.noSelect'))
		}

	}

	updateProjectTitle( value ) {
		this.setState({newProjectTitle: value});
	}

	render() {
		return (
			<div className="p-d-flex p-flex-column">
				<Dropdown
					value={this.state.selectedProject}
					options={this.state.projects}
					placeholder={this.state.t('catalog.dialog.choose')}
					optionLabel="title"
					onChange={( e ) => {
						this.choseProjectClicked(e.value);
					}}
				/>

				<div className="p-d-flex p-jc-end p-mt-3">
					<Button
						label={this.state.t('common.cancel')}
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => this.cancelButtonClicked()}
					/>
					<Button
						label={this.state.t('common.add')}
						icon="pi pi-check"
						className="p-button-text"
						onClick={() => this.submitButtonClicked()}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ( state ) => {
	const {projects, isFetched} = state.project;
	return {projects, isFetched};
};

const actionCreators = {
	getProjects: projectActions.getAllProjects,
	createProject: projectActions.stepOne,
	addProductsToProject: catalogAction.addProductsToProject,
	alertWarning: alertActions.warning,
	alertSuccess:alertActions.success
};

const connectedCatalogScreen = connect(mapStateToProps, actionCreators)(withTranslation()(AddProductsToProject));
export {connectedCatalogScreen as AddProductsToProject};
