export const sessiondetailsConstants = {

IMAGE_DETAILS_REQUEST : 'IMAGE_DETAILS_REQUEST', 
IMAGE_DETAILS_SUCCESS : 'IMAGE_DETAILS_SUCCESS',
IMAGE_DETAILS_FAILURE : 'IMAGE_DETAILS_FAILURE',

IMAGE_HISTORY_REQUEST : 'IMAGE_HISTORY_REQUEST', 
IMAGE_HISTORY_SUCCESS : 'IMAGE_HISTORY_SUCCESS',
IMAGE_HISTORY_FAILURE : 'IMAGE_HISTORY_FAILURE',

IMAGE_HISTORY_CHANGE_REQUEST : 'IMAGE_HISTORY_CHANGE_REQUEST', 
IMAGE_HISTORY_CHANGE_SUCCESS : 'IMAGE_HISTORY_CHANGE_SUCCESS',
IMAGE_HISTORY_CHANGE_FAILURE : 'IMAGE_HISTORY_CHANGE_FAILURE',

ADD_COMMENT_REQUEST : 'ADD_COMMENT_REQUEST', 
ADD_COMMENT_SUCCESS : 'ADD_COMMENT_SUCCESS',
ADD_COMMENT_FAILURE : 'ADD_COMMENT_FAILURE',

VALIDATE_COMMENT_REQUEST:'VALIDATE_COMMENT_REQUEST',
VALIDATE_COMMENT_SUCCESS:'VALIDATE_COMMENT_SUCCESS',
VALIDATE_COMMENT_FAILURE:'VALIDATE_COMMENT_FAILURE'
}
