import React, { Component } from 'react'
import Header from '../common/Header'
import { connect } from 'react-redux'
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import './searchScreen.scss';
import { catalogAction } from '../../redux/actions';
import { LazyImage } from '../common/LazyImage'
import ReactTooltip from 'react-tooltip';
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class SearchScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            catalog: {
                result: [],
                totalCount: null,
                currentPage: null
            },
            selectedProduct: {
                isSelect: false,
                product: null
            },
            first: 0,
            second: 0,
            minN: 0,
            maxN: 10,
            openDialog: false,
            publicUrl: '',
            loading: false,
            associatedProducts: [],
            medias: []
        }

        this.onPageChange = this.onPageChange.bind(this)
        this.onPageChange2 = this.onPageChange2.bind(this)
        this.onProductClick = this.onProductClick.bind(this)
    }

    componentDidMount() {
        this.setState({ search: this.props.match.params.search })
        this.props.searchCatalog(this.props.match.params.search)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.search !== state.search) {
            props.searchCatalog(props.match.params.search)
            return { search: props.match.params.search }
        }

        if (props.catalog.isFetching) {
            return {
                loading: true
            }
        }

        if (props.catalog.isSearched && props.catalog.type) {
            if (state.catalog.result !== props.catalog.type.result) {
                return {
                    loading: false,
                    catalog: props.catalog.type,
                    second: 0,
                    minN: 0,
                    maxN: 10
                }
            }
        }

        if(props.catalog.isProductFetched) {
            return {
                associatedProducts:props.catalog.product.products,
                medias:props.catalog.product.medias
            }
        }
        return state
    }


    onProductClick(productId) {
        this.setState({associatedProducts:[],medias:[],publicUrl:''})
        this.props.getProduct(productId);

    }

    onImageClick(url) {
        this.setState({
            openDialog: true,
            publicUrl: url
        })
    }

    onPageChange(e) {
        this.setState({
            first: e.first,
            selectedProduct: {
                isSelect: false,
                product: null
            },
            second: 0,
            minN: 0,
            maxN: 10
        });
        this.props.searchCatalog(this.state.search, e.page + 1)
    }

    onPageChange2(e) {
        this.setState({
            second: e.first,
            minN: e.page * e.rows,
            maxN: (e.page * e.rows) + 10
        });
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <Header />
                <div className="p-col-12">

                    <h5 className='p-text-lowercase p-mb-3'>{this.state.catalog.totalCount + " " + t('search.nbResult') + " '" + this.state.search+ "'"}</h5>
                    {!this.state.loading ?
                        <div className='p-my-3 p-d-flex p-flex-wrap p-jc-start p-ac-start'>
                            {this.state.catalog.result && this.state.catalog.result.map((product, i) => {
                                return (
                                    <div key={i} className='p-mr-5 p-mb-5' style={{ maxWidth: '200px' }} data-tip
                                        data-for={`tooltip-${product.reference}`}>
                                        <LazyImage key={'img' + i}
                                            className="img"
                                            onClick={() => this.onProductClick(product.id)}
                                            src={product.thumbnail}
                                            alt={product.product}
                                        />
                                        <p key={'p' + i} className="p-mt-1">{product.product}</p>
                                        {product.properties ?
                                            <ReactTooltip id={`tooltip-${product.reference}`} place="right"
                                                type="dark" effect="float">
                                                {product.properties.map(item => <p>{item.name} : {item.value}</p>)}
                                            </ReactTooltip>
                                            :
                                            <></>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ height: "60vh" }}>
                            <ProgressSpinner className="p-progress-spinner" />
                        </div>
                    }
                    {this.state.catalog.totalCount > 10 ?
                        <Paginator style={{ background: 'none' }} first={this.state.first} rows={10}
                            onPageChange={(e) => this.onPageChange(e)}
                            totalRecords={this.state.catalog.totalCount}/>
                        : <></>}
                    {this.state.associatedProducts && this.state.associatedProducts.length > 0 ?
                        <div className='p-d-flex p-flex-column p-p-6 p-mt-3' style={{
                            backgroundColor: '#252525',
                            marginLeft: '-3.8rem',
                            marginRight: '-3.8rem'
                        }}>
                            <div className="p-d-flex p-ai-center p-ml-1">
                                <i className="icon-Link icon-2x"/><p className="p-ml-2">{t('search.associateRef')}</p>
                            </div>
                            <div className='p-d-flex p-flex-column p-flex-md-row p-flex-wrap p-pb-2'>
                                {this.state.associatedProducts && this.state.associatedProducts.slice(this.state.minN, this.state.maxN).map((item, i) => {
                                    return (
                                        <div key={i} className='p-mr-4 p-ml-1 p-mt-3'
                                            style={{ position: 'relative', maxWidth: '200px', }}>
                                            <LazyImage imageKey={'img' + i}
                                                className="img"
                                                src={item.thumbnail} alt={item.product} />
                                            <p key={'p' + i} className="p-mt-1">{item.product}
                                                 </p>
                                        </div>
                                    )
                                })}
                            </div>
                            {this.state.associatedProducts.length > 10 ?
                                <Paginator style={{ background: 'none' }} first={this.state.second} rows={10}
                                    onPageChange={(e) => this.onPageChange2(e)}
                                    totalRecords={this.state.associatedProducts.length}/>
                                : <></>}
                        </div>
                        : <></>
                    }
                    {this.state.medias && this.state.medias.length > 0 ?
                        <div className='p-d-flex p-flex-column  p-py-4'>
                            <div className="p-d-flex p-ai-baseline">
                                <i className="pi pi-eye"/><p className="p-ml-2">{t('search.visibleRef')}</p>
                            </div>
                            <div className='p-d-flex p-flex-column p-flex-md-row p-pb-2'>
                                {this.state.medias && this.state.medias.map((item, i) => {
                                    return (
                                        <div key={i} className='p-ml-5 p-mt-3'
                                            style={{ position: 'relative', maxWidth: '200px', }}>
                                            <img key={'img' + i}
                                                className="img"
                                                src={`data:image/jpeg;base64,${item.thumbnail}`}
                                                alt={item.title}
                                                onClick={() => this.onImageClick(item.url)}
                                            />
                                            <p key={'p' + i} className="p-mt-1">{item.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : <></>
                    }
                    <Dialog modal={false}
                        closable
                        visible={this.state.openDialog}
                        onHide={() => this.setState({ openDialog: false, publicUrl: '' })}
                        style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                    >
                        <img src={process.env.REACT_APP_BASE_URL+ this.state.publicUrl}
                            //alt={this.state.selectedProduct.product}
                            style={{ maxWidth: '80vw', maxHeight: '80vh' }}
                        />
                    </Dialog>
                </div>
            </>
        )
    }
}

function mapState(state) {
    const { catalog } = state
    return { catalog }
}

const actionSearch = {
    searchCatalog: catalogAction.searchCatalog,
    getProduct: catalogAction.getProduct
}

const connectApp = connect(mapState, actionSearch)(withTranslation()(SearchScreen))
export { connectApp as SearchScreen }
