import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'primereact/button'
import { ReactComponent as ReceivedIcon } from '../../img/receptionne.svg'
import { ReactComponent as InShootIcon } from '../../img/shoot.svg'
import { ReactComponent as ReturnedIcon } from '../../img/retourne.svg'
import { ReactComponent as BorrowIcon } from '../../img/borrow.svg'
import { Card } from 'primereact/card'
import { Panel } from 'primereact/panel'
import { Divider } from 'primereact/divider'
import { catalogAction, alertActions } from '../../redux/actions';
import { CustomAutoComplete } from '../common/CustomAutoComplete';
import {LazyImage} from '../common/LazyImage'
import { InputTextarea } from 'primereact/inputtextarea';
import { ToggleButton } from 'primereact/togglebutton';
import { withTranslation } from 'react-i18next';

class ScanProducts extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedProducts: [],
            selectedStatus: 1,
            displayedProducts: [],
            inputSearch: '',
            openDescription: false,
            t: this.props.t
        }

        this.statusCardClicked = this.statusCardClicked.bind(this)
        this.searchProduct = this.searchProduct.bind(this)
        this.setSelectedProduct = this.setSelectedProduct.bind(this)
        this.removeFromSelectedProducts = this.removeFromSelectedProducts.bind(this)
        this.validateCommandCicked = this.validateCommandCicked.bind(this)
        this.cancelCommandCicked = this.cancelCommandCicked.bind(this)
        this.isStringNullOrWhiteSpace = this.isStringNullOrWhiteSpace.bind(this)
    }

    searchProduct (event) {
        if (event.query.toLowerCase() !== '') {
            this.props.searchCatalog(event.query.toLowerCase(), 1)
        }
    }

    cancelCommandCicked () {
        this.props.onCancel()
    }

    isStringNullOrWhiteSpace(value) {
        return value === undefined
            || value === null
            || typeof value !== 'string'
            || value.match(/^ *$/) !== null;
    }

    validateCommandCicked () {
        let {selectedProducts} = this.state
        if( selectedProducts.length > 0) {
            let selectedProductIds = selectedProducts.map(prd => {
                let o = {}
                o.ProductId = prd.id
                if (prd.description && !this.isStringNullOrWhiteSpace(prd.description))
                    o.Description = prd.description

                return o
            })
            this.props.setInventoryStatus(this.state.selectedStatus, selectedProductIds)
            this.props.onCancel()
        } else {
            this.props.warningWarning(this.state.t('toast.noSelect'))
        }

    }

    statusCardClicked (status) {
        this.setState({ selectedStatus: status })
    }

    setSelectedProduct (value) {
        value.openDescription = false
        value.description = ''
        let selectedProducts = this.state.selectedProducts
        selectedProducts.push(value)
        this.setState({ selectedProducts: selectedProducts, inputSearch: '' })
    }

    removeFromSelectedProducts (prd) {
        let selectedProducts = this.state.selectedProducts
        let index = selectedProducts.indexOf(prd)
        if (index !== -1) {
	        selectedProducts.splice(index, 1)
            this.setState({ selectedProducts: selectedProducts })
        }
    }

    static getDerivedStateFromProps (props, state) {
        if (props.isSearched) {
            return { displayedProducts: props.type.result }
        }
    }

    render () {

        return (
            <>
                <div className="p-d-flex p-jc-start p-flex-wrap p-flex-md-column p-flex-sm-column p-flex-lg-row">
                    <div className="p-col p-sm-12 p-lg-6" onClick={() => this.statusCardClicked(1)}>
                        <Card style={{width: '100%', backgroundColor: '#323232', border: this.state.selectedStatus == 1 ? `solid 1px white` : 'none',}}>
                            <div className="p-d-flex p-dir-col p-jc-center p-ai-center">
                                <ReceivedIcon style={{ height: '30px' }}/>
                                <h5 className="project-title">{this.state.t('dashboardScreen.scann.received')}</h5>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col p-sm-12 p-lg-6" onClick={() => this.statusCardClicked(2)}>
                        <Card style={{width: '100%', backgroundColor: '#323232', border: this.state.selectedStatus == 2 ? `solid 1px white` : 'none',}}>
                            <div className="p-d-flex p-dir-col p-jc-center p-ai-center">
                                <InShootIcon style={{ height: '30px' }}/>
                                <h5 className="project-title">{this.state.t('dashboardScreen.scann.shoot')}</h5>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col p-sm-12 p-lg-6" onClick={() => this.statusCardClicked(3)}>
                        <Card style={{width: '100%', backgroundColor: '#323232', border: this.state.selectedStatus == 3 ? `solid 1px white` : 'none',}}>
                            <div className="p-d-flex p-dir-col p-jc-center p-ai-center">
                                <ReturnedIcon style={{ height: '30px' }}/>
                                <h5 className="project-title">{this.state.t('dashboardScreen.scann.return')}</h5>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col p-sm-12 p-lg-6" onClick={() => this.statusCardClicked(4)}>
                        <Card style={{width: '100%', backgroundColor: '#323232', border: this.state.selectedStatus == 4 ? `solid 1px white` : 'none',}}>
                            <div className="p-d-flex p-dir-col p-jc-center p-ai-center">
                                <BorrowIcon style={{ height: '30px' }}/>
                                <h5 className="project-title">{this.state.t('dashboardScreen.scann.borrow')}</h5>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="p-fluid p-mt-6">
                    <h5 className="project-title">{this.state.t('dashboardScreen.scann.code')}</h5>
                    <div className="p-field p-col-12">
                        <CustomAutoComplete
                            placeholder={this.state.t('common.filter')}
                            value={this.state.inputSearch}
                            onChange={ e  => this.setState({inputSearch: e})}
                            onSelect={ e => this.setSelectedProduct(e)}
                        />
                    </div>
                </div>

                <div className="p-fluid p-mt-6">
                    {this.state.selectedProducts &&
                    this.state.selectedProducts.map((item, i) => {
                        return (
                            <Panel className="p-my-3 rounded-content panel-sm">
                                <div className="p-grid p-align-center p-my-auto p-mx-1">
                                    <div className="p-col p-py-auto">
                                        <LazyImage key={`product_${item.reference}`}  style={{ height: '50px' }} src={item.thumbnail}/>
                                    </div>
                                    <p className="p-col">{item.product}</p>
                                    <p className="p-col">{item.barcode}</p>
                                    <div className="p-col">
                                        <ToggleButton
                                            checked={item.openDescription}
                                            onLabel=""
                                            tooltip={this.state.t('dashboardScreen.scann.description')}
                                            offLabel=""
                                            className="p-button-text"
                                            onIcon='pi pi-angle-up'
                                            offIcon="pi pi-angle-down"
                                            onChange={e => {
                                                item.openDescription = e.value
                                                const newItem = Object.assign(this.state.selectedProducts, {...item,
                                                    openDescription: e.value
                                                });
                                                this.setState({selectedProducts: newItem})
                                            }}
                                        />
                                    </div>
                                    <div className="p-col">
                                      <Button
                                        icon="pi pi-trash"
                                        style={{color: "white", backgroundColor: "transparent",}}
                                        className="p-button-icon-only p-button-sm p-py-1 p-ml-4 p-mr-3"
                                        onClick={() => this.removeFromSelectedProducts(item)}
                                      />
                                    </div>
                                </div>
                                {item.openDescription ? (
                                    <div className='p-col'>
                                        <InputTextarea
                                            rows={5}
                                            cols={30}
                                            autoResize
                                            placeholder={this.state.t('dashboardScreen.scann.descriptionOpt')}
                                            value={item.description}
                                            onChange={e => {
                                                item.description = e.target.value
                                                const newItem = Object.assign(this.state.selectedProducts, {...item,
                                                    description: e.target.value
                                                });
                                                this.setState({selectedProducts: newItem})
                                            }}
                                        />
                                    </div>
                                ) : <></>}
                            </Panel>
                        )
                    })}
                </div>
                <div className="p-fluid p-mt-6">
                    <Divider/>
                    <div className="p-mt-3 p-d-flex p-jc-end">
                        <Button onClick={() => this.cancelCommandCicked()} className="p-button-rounded p-mx-2" style={{ width: 'auto' }}>
                            {this.state.t('common.cancel')}
                        </Button>
                        <Button onClick={() => this.validateCommandCicked()} className="p-button-rounded" style={{ width: 'auto' }}>
                            {this.state.t('common.valid')}
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { isSearched, type } = state.catalog
    return { isSearched, type }
}

const actionCreators = {
    searchCatalog: catalogAction.searchCatalog,
    setInventoryStatus: catalogAction.setInventoryStatus,
    successWarning: alertActions.success,
    warningWarning: alertActions.warning
}

const connectedCatalogScreen = connect(mapStateToProps, actionCreators)(withTranslation()(ScanProducts))
export { connectedCatalogScreen as ScanProducts }
