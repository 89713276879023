import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext'
import {emailRegex} from './Regex'

class InputEmail extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			email : '',
			errorInput: false,
			type: ''
		}
	}

	static getDerivedStateFromProps( props, state ){
		if (props.value && state.email === ''){
			return {
				email: props.value
			}
		}
		return state
	}

	onChange = (e) => {
		this.setState({email: e.target.value, errorInput: false, type: ''})
		this.props.onChange(e.target.value)
	}

	onBlur = () => {
		let {email, errorInput} = this.state
		if(!emailRegex.test(email) && email.length > 0){
			this.setState({errorInput: true, type: 'regex'})
		} else if(this.props.required && email.length === 0){
			this.setState({errorInput: true, type: 'empty'})
		}
		this.props.isError(errorInput)
	}

	render() {
		if(this.state.errorInput){
			this.emailInput.focus();
		}
		return (
			<>
				<InputText
					ref={(input) => { this.emailInput = input; }}
					value={this.props.value ? this.props.value : this.state.email}
					className={this.props.className}
					placeholder={this.props.placeholder}
					onChange={e => this.onChange(e)}
					style={this.state.errorInput ? {...this.props.style, border: '1px solid #f44435'} : {...this.props.style, border: 'none'}}
					onBlur={this.onBlur}
				/>
				{this.state.errorInput && this.state.type === 'regex' ? <small className="p-error p-d-block">L'email n'est pas conforme. <br/> Ex: example@mail.com</small> : this.state.errorInput && this.state.type === 'empty' ? <small className="p-error p-d-block">L'email est requis</small> : <></>}
			</>
		);
	}
}

export default InputEmail;