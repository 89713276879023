import { contributorConstants } from '../constants';

export function contributors(state = {}, action){
    switch(action.type) {
        case contributorConstants.CONTRIBUTORS_ADD_REQUEST:
            return Object.assign({}, { isFetching: true });
        case contributorConstants.CONTRIBUTORS_ADD_SUCCESS:
            return Object.assign({}, { type: action.contributors, isAddFetched: true });
        case contributorConstants.CONTRIBUTORS_ADD_FAILURE:
            return {}

        case contributorConstants.GET_CONTRIBUTORS_REQUEST:
            return Object.assign({}, { isFetching: true });
        case contributorConstants.GET_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, { type: action.contributors, isFetched: true});
        case contributorConstants.GET_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.UPDATE_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isFetching: true});
        case contributorConstants.UPDATE_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isUpdateFetched: true});
        case contributorConstants.UPDATE_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.DELETE_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case contributorConstants.DELETE_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isDeleted: true});
        case contributorConstants.DELETE_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.VALIDATE_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case contributorConstants.VALIDATE_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isValidate: true});
        case contributorConstants.VALIDATE_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.REJECT_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case contributorConstants.REJECT_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isRejected: true});
        case contributorConstants.REJECT_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.DRAFT_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isDrafting: true});
        case contributorConstants.DRAFT_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isDrafted: true});
        case contributorConstants.DRAFT_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.DELETE_ALL_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isDeleting: true});
        case contributorConstants.DELETE_ALL_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isAllDeleted: true});
        case contributorConstants.DELETE_ALL_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.IMPORT_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isImporting: true})
        case contributorConstants.IMPORT_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isImported: true});
        case contributorConstants.IMPORT_CONTRIBUTORS_FAILURE:
            return {}

        case contributorConstants.IMPORT_FILE_CONTRIBUTORS_REQUEST:
            return Object.assign({}, {isFileImporting: true})
        case contributorConstants.IMPORT_FILE_CONTRIBUTORS_SUCCESS:
            return Object.assign({}, {type: action.contributor, isFileImported: true})
        case contributorConstants.IMPORT_FILE_CONTRIBUTORS_FAILURE:
            return {}

        default:
            return state
    }
}
