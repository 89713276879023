import { commonService } from './common.service';
import { history } from './../redux/helpers/store';
export const hubService = {
 getNotificationHistory, markAsRead
};

function getNotificationHistory(dto) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
   body :JSON.stringify(dto)
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/notifications/notifications-history`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}
function markAsRead(notificationId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/notifications/${notificationId}/read`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}



