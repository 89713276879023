import {searchConstants} from '../constants';
import {searchService} from '../../services';
import {alertActions} from '.';

export const searchActions = {
    getFilters,
    searchAdvancedProducts
};


function searchAdvancedProducts(filters){
    console.log('filters', filters)
    return dispatch => {
        dispatch(request());
        searchService.searchAdvancedProducts(filters)
            .then(
                searchResult => {
                    dispatch(success(searchResult))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: searchConstants.ADVANCED_SEARCH_REQUEST } }
    function success(searchResult) { return { type: searchConstants.ADVANCED_SEARCH_SUCCESS, searchResult : searchResult } }
    function failure(error) { return { type: searchConstants.ADVANCED_SEARCH_FAILURE, error } }

};

function getFilters(){
    return dispatch => {
        dispatch(request());
        searchService.getFilters()
            .then(
                filters => {
                    dispatch(success(filters))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: searchConstants.GET_FILTERS_REQUEST } }
    function success(filters) { return { type: searchConstants.GET_FILTERS_SUCCESS, filters : filters } }
    function failure(error) { return { type: searchConstants.GET_FILTERS_FAILURE, error } }

};
