import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { userActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class RegisterScreen extends Component {
    constructor (props) {
        super(props)
        this.state = {
            user: {
                email: '',
                password: '',
            },
            hidden: true,
            errors: {
                email: '',
                password: ''
            },
            loading: false
        }
    }

    static getDerivedStateFromProps (props, state){
        if(props.user.isRegisting){
            return {loading: props.user.isRegisting}
        }
    }

      handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ user:{ ...this.state.user, [name]: value }})

        this.setState({ errors, [name]: value });
      }

      toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
      }

      handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.email == null) {
          return this.setState({ errors: { ...this.state.errors, email: "Email can't be blank" } })
        }
        if (this.state.password === null){
          return this.setState({ errors: { ...this.state.errors, password: "Password can't be blank" } })
        }

          const { user } = this.state
          return this.props.register(user);

      }

      handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          if (this.state.email == null) {
            return this.setState({ errors: { ...this.state.errors, email: "Email can't be blank" } })
          }
          if (this.state.password === null){
          return this.setState({ errors: { ...this.state.errors, password: "Password can't be blank" } })
          }

            const { user } = this.state
            return this.props.register(user);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="p-grid p-d-flex authentication-container p-jc-center p-ai-center">
                <div className="p-col-4">
                    <Panel header={t('authentication.common.register')} className="p-pt-4">
                        <p className="p-d-flex p-jc-center p-mb-6">{t('authentication.registerPage.question')}<br/>
                        <Link className="p-ml-1 link" to="/login"> {t('authentication.common.login')}</Link>
                        </p>
                        <form className="p-fluid" onSubmit={this.handleSubmit} onKeyPress={this.handleKeyPress}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText keyfilter="email" value={this.state.user.email} name="email" id="email" onChange={this.handleChange} required />
                                    <label htmlFor="username">{t('common.email')}</label>
                                </span>
                            </div>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Password feedback={false} value={this.state.user.password} name="password" id="password"  onChange={this.handleChange} required />
                                    <label htmlFor="password">{t('common.password')}</label>
                                </span>
                            </div>
                            <Button loading={this.state.loading} type="submit" className="p-button-rounded" label={t('authentication.registerPage.bouton')} />
                        </form>
                    </Panel>
                </div>

            </div>
        );
    }
}

function mapState(state) {
    const { authentication, user } = state;
    return { authentication, user };
  }
  const actionCreators = {
    register: userActions.register
  }

  const connectedRegisterPage = (connect(mapState, actionCreators)(withTranslation()(RegisterScreen)));
  export { connectedRegisterPage as RegisterScreen };
