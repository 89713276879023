import { settingService } from '../../services';
import { settingConstants } from '../constants';
import { alertActions } from './';
import { history } from './../helpers/store'

export const settingAction = {
    addCompany,
    getCompany,
    getKey,
    getNotificationSettings,
    getNotificationType,
    addNotificationSetting
};

function addCompany(setting){
    return dispatch => {
        dispatch(request(setting));
        settingService.addCompany(setting)
            .then(
                setting => {
                    dispatch(success(setting))
                    dispatch(alertActions.success('Votre compte a bien été créé'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Erreur identifiant'));
                }
            );
    }

    function request(setting) { return { type: settingConstants.ADD_COMPANY_REQUEST, setting } }
    function success(setting) { return { type: settingConstants.ADD_COMPANY_SUCCESS, setting } }
    function failure(error) { return { type: settingConstants.ADD_COMPANY_FAILURE, error } }

}

function getCompany(){
    return dispatch => {
        dispatch(request());
        settingService.getCompany()
            .then(
                company => dispatch(success(company)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: settingConstants.GET_COMPANY_REQUEST } }
    function success(company) { return { type: settingConstants.GET_COMPANY_SUCCESS, company } }
    function failure(error) { return { type: settingConstants.GET_COMPANY_FAILURE, error } }
}

function getKey(){
    return dispatch => {
        dispatch(request());
        settingService.getKey()
            .then(
                key => dispatch(success(key)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: settingConstants.GET_KEY_REQUEST } }
    function success(key) { return { type: settingConstants.GET_KEY_SUCCESS, key } }
    function failure(error) { return { type: settingConstants.GET_KEY_FAILURE, error } }
}

function getNotificationSettings(){
    return dispatch => {
        dispatch(request());
        settingService.getNotificationSettings()
            .then(
                settings => dispatch(success(settings)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: settingConstants.GET_NOTIFICATION_SETTING_REQUEST } }
    function success(settings) { return { type: settingConstants.GET_NOTIFICATION_SETTING_SUCCESS, settings } }
    function failure(error) { return { type: settingConstants.GET_NOTIFICATION_SETTING_FAILURE, error } }
}

function getNotificationType(){
    return dispatch => {
        dispatch(request());
        settingService.getNotificationType()
            .then(
                types => dispatch(success(types)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: settingConstants.GET_NOTIFICATION_TYPE_REQUEST } }
    function success(types) { return { type: settingConstants.GET_NOTIFICATION_TYPE_SUCCESS, types } }
    function failure(error) { return { type: settingConstants.GET_NOTIFICATION_TYPE_FAILURE, error } }
}

function addNotificationSetting(setting){

    return dispatch => {
        dispatch(request());
        settingService.addNotificationSetting(setting)
            .then(
                settings => {
                    dispatch(success(settings))
                    dispatch(alertActions.success("Les paramètres de notifications ont été sauvegardés"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: settingConstants.ADD_NOTIFICATION_SETTING_REQUEST } }
    function success(settings) { return { type: settingConstants.ADD_NOTIFICATION_SETTING_SUCCESS, settings } }
    function failure(error) { return { type: settingConstants.ADD_NOTIFICATION_SETTING_FAILURE, error } }
}