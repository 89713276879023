import React, { Component } from "react";
import Header from "../common/Header";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { SearchPanel } from "./SearchPanel";
import { AddProductsToProject } from "./AddProductsToProject";
import AddProductToCatalog from "./AddProductToCatalog";
import { DownloadCloudButton } from "../common/SVGButtons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { alertActions, catalogAction, searchActions, } from "../../redux/actions";
import { LazyImage } from "../common/LazyImage";
import { Paginator } from "primereact/paginator";
import ReactTooltip from 'react-tooltip';
import CustomBarcode from "./CustomBarcode";
import { allQty, allQtyMaj, barCode, remainsDeliver } from '../common/ConstanteCommon';
import { ReactComponent as LookIcon } from '../../img/Action_Look.svg'
import { withTranslation } from 'react-i18next';
import { DialogImg } from "../project/projectLists/DialogImg";
import {isTablet} from 'react-device-detect'

class CatalogScreen extends Component {
	constructor(props) {
		super(props);
		this.searchPanel = React.createRef();
		const {t} = this.props;
		this.state = {
			catalog: [],
			filters: [],
			isFetched: false,
			displayedProducts: [],
			showCatalogDialog: false,
			showAddProductsDialog: false,
			currentPage: 1,
			perPage: 10,
			totalCount: 0,
			errors: {},
			columns: [
				{ header: t('catalog.columns.look'), field: 'hasLook' },
				{ header: t('catalog.columns.product'), field: 'product' },
				{ header: t('catalog.columns.barcode'), field: 'barcode' },
				{ field: "reference", header: t('catalog.columns.reference') },
				{ field: "imagesCount", header: t('catalog.columns.imagesCount') },
				{ field: "availabilityDate", header: t('catalog.columns.availabilityDate') },
				{ field: "inventoryStatus", header: t('catalog.columns.inventoryStatus') },
				{ header: t('catalog.columns.quantity'), field: "properties" },
			],
			selectedColumns: [],
			selectedProducts: [],
			boundColumns: false,
			uploadDocument: null,
			isCatalogFetching: false,
			showAddProdDialog: false,
			order: 0,
			sortField: null,
			sortOrder: 0,
			orderBy: 0,
			orderType: 0,
			loadingTable: false,
			dialogImgPrd: {},
			openDialogImg: false,
			t: this.props.t
		};
		this.showCatalogDialog = this.showCatalogDialog.bind(this);
		this.showAddProductsDialog = this.showAddProductsDialog.bind(this);
		this.hideCatalogDialog = this.hideCatalogDialog.bind(this);
		this.hideAddProductsDialog = this.hideAddProductsDialog.bind(this);
		this.imageTemplate = this.imageTemplate.bind(this);
		this.barcodeTemplate = this.barcodeTemplate.bind(this);
		this.qtyTemplate = this.qtyTemplate.bind(this);
		this.onColumnToggle = this.onColumnToggle.bind(this);
		this.setSelectedProducts = this.setSelectedProducts.bind(this);
		this.addProductsToProjectClicked = this.addProductsToProjectClicked.bind(this);
		this.searchProducts = this.searchProducts.bind(this);
		this.generateBarcodeButtonClicked = this.generateBarcodeButtonClicked.bind(this);
		this.onSubmitFile = this.onSubmitFile.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.hideAddProdDialog = this.hideAddProdDialog.bind(this);
		this.sortTable = this.sortTable.bind(this);
	}
	componentDidMount() {
		let filters = { currentPage: 1 };
		this.searchProducts(filters);
		this.setState({ filters: filters });
		//	this.setState({filters: filters});
	}
	/* SEARCH REGION */
	searchProducts(filters) {
		let formattedFilters = {};
		if (filters !== undefined) {
			formattedFilters = this.formatFilters(filters);
		}
		formattedFilters.currentPage = filters.currentPage
			? filters.currentPage
			: 1;
		formattedFilters.perPage = this.state.perPage;
		formattedFilters.orderType = this.state.orderType;
		formattedFilters.orderBy = this.state.orderBy;
		this.props.searchAdvancedProducts(formattedFilters);
		this.setState({ filters: filters, loadingTable: true });
	}

	handlePageChange(e) {
		let filters = this.state.filters;
		filters.currentPage = e.page + 1;
		this.setState({ selectedProducts: [] })
		this.searchProducts(filters);
	}

	clearFilters(filters) {
		filters.currentPage = 1;
		this.searchProducts(filters);
		this.setState({ filters: filters });
	}

	formatFilters(filters) {
		let formattedFilters = {};
		let additionalFilters = [];
		if (filters && filters.additionalFilters) {
			for (const [key, value] of Object.entries(filters.additionalFilters)) {
				additionalFilters.push({ filterName: key, values: value });
			}
			formattedFilters.additionalFilters = additionalFilters;
			formattedFilters.keyword = filters.keyword;

		}
		return formattedFilters;
	}
	/* END SEARCH REGION */
	showCatalogDialog() {
		this.setState({ showCatalogDialog: true });
	}
	showAddProductsDialog() {
		this.setState({ showAddProductsDialog: true });
	}
	showAddProdDialog() {
		this.setState({ showAddProdDialog: true });
	}

	static getDerivedStateFromProps(props, state) {
		if (props.isFetched && props.catalog) {
			let columns = state.columns;
			let displayedColumns =
				state.selectedColumns.length > 0
					? state.selectedColumns
					: columns;
			if (!state.boundColumns) {
				if (
					props.catalog &&
					props.catalog.result &&
					props.catalog.result.length > 0
				) {
					let newColumns = [];
					let prd = props.catalog.result.filter(item => item.properties?.length > 0)[0];
					if (prd !== null && prd !== undefined) {
						newColumns = prd.properties?.map((k) => {
							return {
								field: k.name,
								header: k.name[0].toUpperCase() + k.name.slice(1),
								body: item => {
									let tab = item.properties?.filter(p => p.name === k.name);
									if (tab !== undefined && tab.length > 0)
										return <>{tab[0].value}</>
									else
										return <></>
								},
							};
						});
						columns = columns.concat(newColumns);
						//TODO : Changer le code mis en dure
						displayedColumns = displayedColumns.filter((col) => {
							return (
								col.field != 'reference' && col.field != 'inventoryStatus'
							)
						});
					}
				}
			}
			if (props.catalog && props.catalog.result.length > 0) {
				return {
					catalog: props.catalog.result,
					displayedProducts: props.catalog.result,
					selectedColumns: displayedColumns,
					boundColumns: true,
					columns: columns,
					isFetched: props.isFetched,
					currentPage: props.catalog.currentPage,
					totalCount: props.catalog.totalCount,
					totalQueryCount: props.catalog.totalQueryCount,
					loadingTable: false,
				};
			} else {
				return {
					catalog: [],
					displayedProducts: [],
					selectedColumns: displayedColumns,
					boundColumns: true,
					columns: columns,
					isFetched: props.isFetched,
					currentPage: props.catalog.currentPage,
					totalCount: props.catalog.totalCount,
					totalQueryCount: props.catalog.totalQueryCount,
					loadingTable: false,
				};
			}
			// return {columns: columns, isFetched :props.isFetched }
		}
		if (props.isCatalogFetching) {
			return {
				isCatalogFetching: props.isCatalogFetching
			}
		}
		if (props.isCatalogFetched) {
			return {
				isCatalogFetching: false
			}
		}
		return state;
	}
	onDrop = (file) => {
		let errors = this.state.errors;
		if (file.length > 0) {
			errors["uploadDocument"] = "";
			let doc = [];
			let reader = new FileReader();
			reader.onload = ((file) => {
				return () => {
					doc.push({ name: file.name, document: file });
					this.setState({
						uploadDocument: doc,
					});
				};
			})(file[0]);
			reader.readAsDataURL(file[0]);
		} else {
			errors["uploadDocument"] = "Only csv, xls,xlxs allowed";
		}
		this.setState({ errors: errors });
	};
	onSubmitFile() {
		let { uploadDocument } = this.state;
		this.props.addCatalog(0, uploadDocument);
		this.hideCatalogDialog();
	}
	generateBarcodeButtonClicked() {
		if (this.state.selectedProducts && this.state.selectedProducts.length > 0) {
			this.props.generateBarCodes(
				this.state.selectedProducts.map((p) => p.reference)
			);
		} else {
			this.props.alertWarning(this.state.t("toast.noSelect"));
		}
	}
	hideCatalogDialog() {
		this.setState({ showCatalogDialog: false });
	}
	hideAddProductsDialog() {
		this.setState({ showAddProductsDialog: false });
	}
	hideAddProdDialog() {
		this.setState({ showAddProdDialog: false });
	}
	qtyTemplate(data) {
		var otherColsTodisplay = data.properties?.filter(col => col.name.includes(remainsDeliver) || col.name.includes(allQty))
		if (otherColsTodisplay?.length > 0) {
			return otherColsTodisplay.map(prop => {
				return <p>{prop.name.charAt(0).toUpperCase() + prop.name.slice(1)} : {prop.value}</p>
			})
		} else {
			return (<div>
				<p>{this.state.t('catalog.quantity.buy')}</p>
				<p>{this.state.t('catalog.quantity.integrated')}</p>
				<p>{this.state.t('catalog.quantity.all')}</p>
			</div>)
		}
	}
	barcodeTemplate(data) {
		return (
			<CustomBarcode
				value={data.barcode}
				key={data.id} />
		);
	}
	imageTemplate(rowData) {
		return (
			<div className="p-grid p-dir-col" data-tip={rowData.properties?.length > 0 ? true : false} data-for={rowData.properties?.length > 0 ? `tooltip-${rowData.reference}` : ''}>
				<LazyImage
					key={rowData.id}
					src={rowData.thumbnail}
					alt={rowData.product}
					className="product-image p-col"
					style={{ maxHeight: '125px', height: 'auto', maxWidth: '100px', width: 'auto', boxShadow: 'none' }}
				/>
				<p className="p-col" style={{ marginTop: "5px", maxHeight: '55px' }}>{rowData.product}</p>
				{rowData.properties?.length > 0 ?
					<ReactTooltip id={`tooltip-${rowData.reference}`} place="right" type="dark" effect="float">
						{rowData.properties.map(item => <p>{item.name} : {item.value}</p>)}
					</ReactTooltip>
				: <></>}
			</div>
		);

	}
	onColumnToggle(event) {
		this.setState({ selectedColumns: event.value });
	}
	setSelectedProducts(selectedProducts) {
		this.setState({ selectedProducts: selectedProducts });
	}
	addProductsToProjectClicked() {
		this.props.addProductsToProject(this.state.selectedProducts);
	}
	addProdInCatalog = prod => {
		this.props.addProduct(0, prod);
	}

	sortTable = e => {
		let filters = this.state.filters;
		if (e.sortField === 'properties')
			filters.orderBy = 1;
		if (e.sortField === 'availabilityDate')
			filters.orderBy = 2;
		if (e.sortField === 'inventoryStatus')
			filters.orderBy = 3;
		if (e.sortField === 'imagesCount')
			filters.orderBy = 6;
		if (e.sortField === 'couleur')
			filters.orderBy = 5;
		if (e.sortField === 'hasImages')
			filters.orderBy = 7;
		if (e.sortField === 'reference')
			filters.orderBy = 8;
		if (e.sortField === 'hasLook')
			filters.orderBy = 10;
		filters.orderType = e.sortOrder;
		filters.currentPage = filters.currentPage
			? filters.currentPage
			: 1;
		filters.perPage = this.state.perPage;
		let obj = {
			currentPage: filters.currentPage,
			perPage: filters.perPage,
			keyword: filters.keyword,
			orderBy: filters.orderBy,
			orderType: filters.orderType
		}
		this.props.searchAdvancedProducts(obj);

		this.setState({
			filters: filters,
			currentPage: 1,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
			orderBy: filters.orderBy,
			orderType: filters.orderType,
			loadingTable: true
		})

	}

	inventoryHistoryTemplate(data) {
		if (data.inventoryStatus) {
			return (
				<div data-tip data-for={`tooltip-${data.id}`}>
					<div> {data.inventoryStatus.status} </div>
					<ReactTooltip id={`tooltip-${data.id}`} place="right" type="dark" effect="float">
						{data.inventoryStatus.productDescription != null ?
							<p>Description : {data.inventoryStatus.productDescription}</p> : <> <p>Description :
								N/C </p> </>}
					</ReactTooltip>
				</div>
			);
		} else {
			return (<></>)
		}
	}
	lookTemplate = rowData => {
		if (rowData.hasLook) {
			return (
				<div>
					<LookIcon />
				</div>
			)
		}
	}
	render() {
		const importProductFooter = (
			<>
				<Button
					label={this.state.t('common.cancel')}
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideCatalogDialog}
				/>
				<Button
					label={this.state.t('common.add')}
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.onSubmitFile}
				/>
			</>
		);
		return (
			<div>
				<Header />
				<div className="p-grid p-mt-6">
					<div className="p-col-2 md:col-6 lg:col-6">
						<SearchPanel
							onSearchButtonClicked={(filters) => this.searchProducts(filters)}
							onResetButtonClicked={(filters) => this.clearFilters(filters)}
						/>
					</div>
					<div className="p-col-10 md:col-12 lg:col-12">
						{this.state.isFetched ? (
							this.state.totalCount > 0 ? (
								<>
									<div className="p-d-flex p-ai-center">
										<div className="p-col-6 p-d-flex p-ai-center p-mr-2">
											<MultiSelect
												value={this.state.selectedColumns}
												options={this.state.columns}
												optionLabel="header"
												fixedPlaceholder={true}
												placeholder={this.state.selectedColumns.length + this.state.t('catalog.multiselect')}
												onChange={(e) => this.onColumnToggle(e)}
												style={{ width: "20em" }}
											/>
											<h5 className="p-ml-2">
												{this.state.t('catalog.count')} <b className="p-ml-2">{this.state.totalCount || 0} </b>
											</h5>
										</div>
										<div className="p-col p-d-flex p-jc-end">
											<Button
												className="p-mr-2 p-button"
												loading={this.state.isCatalogFetching}
												onClick={() => this.showCatalogDialog()}
												icon='icon-Action_Download icon-2x'
											/>
											<Button
												className="p-mr-2 p-button"
												onClick={() => this.showAddProductsDialog()}
												icon="icon-Action_ icon-2x"
											/>
											<Button
												className="p-mr-2 p-button"
												onClick={() => this.showAddProdDialog()}
												icon="icon-Action_NewFile icon-2x"
											/>
											<Button
												className={isTablet ? "p-mr-2 p-button" : "p-button-outlined card-button p-button"}
												label={isTablet ? '' : this.state.t('catalog.barcode')}
												icon={isTablet ? "svgIcon-Barcode" : ''}
												onClick={() => this.generateBarcodeButtonClicked()}
											/>

										</div>
									</div>
									<div className="p-d-flex">
										<DataTable
											ref={(el) => { this.dt = el; }}
											value={this.state.displayedProducts}
											emptyMessage={this.state.t('catalog.emptyTable')}
											dataKey="id"
											selection={this.state.selectedProducts}
											selectionMode="checkbox"
											onSelectionChange={(e) => { this.setSelectedProducts(e.value); }}
											removableSort
											scrollable
											scrollDirection="both"
											frozenWidth="50%"
											// scrollHeight="70vh"
											onSort={(e) => this.sortTable(e)}
											sortField={this.state.sortField}
											loading={this.state.loadingTable}
											sortOrder={this.state.sortOrder}
										>
											<Column
												selectionMode="multiple"
												headerStyle={{ width: "3rem", height: '49px' }}
												frozen
												style={{ height: '222px', flexGrow: 1, flexBasis: "3rem" }}
											/>
											{this.state.selectedColumns &&
												this.state.selectedColumns.map((col, i) => {
													if (col.header === "Produit")
														return (
															<Column
																body={(data) => this.imageTemplate(data)}
																key={`${col.field}-${i}`}
																field={col.field}
																header={col.header}
																frozen
																headerStyle={{ height: '49px', width: '250px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: '250px' }}
															/>
														);
													//BarCode
													else if (col.header.indexOf(barCode) !== -1) {
														return (
															<Column
																body={(data) => this.barcodeTemplate(data)}
																key={`${col.header}-${i}`}
																field={col.field}
																header={col.header}
																frozen
																headerStyle={{ height: '49px', width: '200px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: "200px" }}
															/>
														);
													}
													//Nombre d'image
													else if(col.field === 'imagesCount'){
														return (
															<Column
																body={data => {
																	console.log('dataaaa', data)
																		return(
																			<p onClick={() => this.setState({
																		openDialogImg: true,
																		dialogImgPrd: data
																	})}
																		style={{ cursor: 'pointer' }}>{data.imagesCount}</p>)
																}
																}
																key={`${col.field}-${i}`}
																field={col.field}
																sortable
																header={col.header}
																headerStyle={{ height: '49px', width: '100px' }}
																style={{
																	height: '250px',
																	flexGrow: 1,
																	flexBasis: "100px"
																}}
															/>
														)
													}
													// Quantité
													else if (col.header.indexOf(allQtyMaj) !== -1) {
														return (
															<Column
																body={data => this.qtyTemplate(data)}
																key={`${col.field}-${i}`}
																field={col.field}
																header={col.header}
																sortable
																headerStyle={{ height: '49px', width: '200px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: "200px" }}
															/>
														);
													}
													else if (col.field === "inventoryStatus") {
														return (
															<Column
																body={(data) => this.inventoryHistoryTemplate(data)}
																key={`${col.field}-${i}`}
																field={col.field}
																sortable
																header={col.header}
																headerStyle={{ height: '49px', width: '150px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: "100px" }}
															/>
														);
													} //Look
													else if (col.field === 'hasLook') {
														return (
															<Column
																body={data => this.lookTemplate(data)}
																key={`${col.field}-${i}`}
																field={col.field}
																sortable
																header={col.header}
																headerStyle={{ height: '49px', width: '100px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: "100px" }}
																frozen
															/>
														)
													} else {
														return (
															<Column
																key={`${col.field}-${i}`}
																field={col.field}
																header={col.header}
																body={col.body}
																sortable
																headerStyle={{ height: '49px', width: '160px' }}
																style={{ height: '250px', flexGrow: 1, flexBasis: "160px" }}
															/>
														);
													}
												}
												)}
										</DataTable>
									</div>
									<div>
										<Paginator
											totalRecords={this.state.totalQueryCount}
											currentPage={this.state.currentPage}
											rows={this.state.perPage}
											first={(this.state.currentPage - 1) * this.state.perPage}
											onPageChange={(e) => this.handlePageChange(e)}
										/>
									</div>
								</>
							) : (
								<div className="image-placeholder p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
									{this.state.t('catalog.emptyProduct')}
									<Button
										className="p-button-rounded p-mt-6"
										onClick={() => this.showCatalogDialog()}
										label={this.state.t('catalog.importList')}
									/>
								</div>
							)
						) : (
							<div
								className="p-d-flex p-flex-column p-jc-center p-ai-center"
								style={{ height: "60vh" }}
							>
								<ProgressSpinner className="p-progress-spinner" />
							</div>
						)}
					</div>
				</div>
				<Dialog
					position="center"
					header={this.state.t('catalog.addPorduct')}
					visible={this.state.showAddProductsDialog}
					onHide={this.hideAddProductsDialog}
					style={{ width: "30vw" }}
				>
					<AddProductsToProject
						products={this.state.selectedProducts}
						cancelClicked={this.hideAddProductsDialog}
						submitClicked={this.hideAddProductsDialog}
					/>
				</Dialog>
				<AddProductToCatalog
					openDialog={this.state.showAddProdDialog}
					hideDialog={this.hideAddProdDialog}
					submitClicked={p => this.addProdInCatalog(p)}
				/>
				<Dialog
					position="center"
					header={this.state.t('catalog.importCatalog')}
					visible={this.state.showCatalogDialog}
					onHide={this.hideCatalogDialog}
					style={{ width: "30vw" }}
					footer={importProductFooter}
				>
					<div className="p-d-flex p-flex-column p-jc-center p-ai-center dropzone-file">
						<Dropzone
							onDrop={this.onDrop}
							multiple={false}
							accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<DownloadCloudButton title={this.state.t('common.dragDrop')} />
										<span>{this.state.t('catalog.importFormat')}</span>
									</div>
								</section>
							)}
						</Dropzone>
						<div className="error">{this.state.errors.uploadDocument}</div>
						{this.state.uploadDocument && (
							<div className="p-mt-5">{this.state.uploadDocument[0].name}</div>
						)}
					</div>
				</Dialog>

				{/* Popup pour Aficher toutes les images */}
				<DialogImg
					open={this.state.openDialogImg}
					dialogImgPrd={this.state.dialogImgPrd}
					onHide={() => this.setState({openDialogImg: false, dialogImgPrd: {}})}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	const { catalog, isFetched } = state.search;
	const { isCatalogFetching, isCatalogFetched } = state.catalog;
	return { catalog, isFetched, isCatalogFetching, isCatalogFetched };
};
const actionCreators = {
	generateBarCodes: catalogAction.generateBarCodes,
	alertSuccess: alertActions.success,
	alertWarning: alertActions.warning,
	addCatalog: catalogAction.addCatalog,
	searchAdvancedProducts: searchActions.searchAdvancedProducts,
	addProduct: catalogAction.addManuallyProduct,
};
const connectedCatalogScreen = connect(mapStateToProps, actionCreators)(withTranslation()(CatalogScreen));
export { connectedCatalogScreen as CatalogScreen };
