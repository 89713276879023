import React, { Component } from 'react'
import { SessionDetailsMenu, SessionHeader } from './SessionDetailsMenu';
import { connect } from 'react-redux';
import { sessiondetailsActions } from '../../redux/actions'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment'
import 'moment/locale/fr'

class SessionHistory extends Component {
    constructor(props){
        super(props)
        this.state = {
            isFetched: false,
            historyImage: null,
            projectId: 0,
            imageId: '',
            openDialog: false,
        }

        this.openHistoryDialog = this.openHistoryDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.changeVersion = this.changeVersion.bind(this)
    }

    componentDidMount() {
        if ( this.props.match.params.imageId) {
            let project = JSON.parse(localStorage.getItem('project'))
            this.setState({ imageId: this.props.match.params.imageId, projectId: project.projectId, productId: this.props.match.params.productId });
            this.props.getImageOverview(project.projectId, this.props.match.params.productId, this.props.match.params.imageId)
            this.props.getImageHistory(project.projectId, this.props.match.params.productId, this.props.match.params.imageId)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.image !== this.props.image && this.props.image !== undefined && this.props.image !== null){
            this.setState({
                image: this.props.image
            })
        }
        if(prevProps.type !== this.props.type && this.props.type !== undefined && this.props.type !== null){
            this.setState({
                historyImage: this.props.type,
                isFetched: this.props.isHistoryFetched,
            })
        }
        if(prevProps.isSend !== this.props.isSend && this.props.isSend === true){
            this.setState({
                isSend: this.props.isSend
            })
            this.props.getImageHistory(this.state.projectId, this.state.imageId)
        }
    }

    openHistoryDialog(id){
        this.setState({
            openDialog: true,
            versionId: id
        })
    }

    onHide(){
        this.setState({
            openDialog: false,
            versionId: null
        })
    }

    changeVersion(){
        let {projectId, productId, imageId, versionId} = this.state
        this.props.changeImageHistory(projectId, productId, imageId, versionId)
        setTimeout(() => {
            this.onHide()
        }, 300);
    }

    render() {
        const changeDialogFooter = (
            <>
                <Button label="Non" icon="pi pi-times" className="p-button-text"
                        onClick={this.onHide}/>
                <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={this.changeVersion}/>
            </>
        )

        return (
            <div>
                <div style={{height: 'calc(100vh - 80px)'}}>
                    {this.state.image && <SessionHeader projectId={this.state.projectId} title={this.state.productName}/>}
                    <div className="p-d-flex p-jc-between">
                        <div className="p-mr-3">
                            <h5 className="p-my-3">Historique des versions</h5>
                            {this.state.historyImage && this.state.historyImage.map((image, i) => {
                                return (
                                <div className="p-p-3 p-mt-2" key={i} style={{backgroundColor: '#252525', borderRadius: '10px', width: '100%'}} onClick={() => this.openHistoryDialog(image.id)}>
                                    <p key={'p'+i} style={{ fontSize: '12px'}}>{moment(image.created).format('lll')}</p>
                                    <p key={'p2'+i}>{image.versions}</p>
                                </div>
                                )
                            })}
                        </div>
                        <div className="p-ml-3 p-d-flex p-jc-center" style={{ maxWidth: 'calc(50vw - 15px)', maxHeight: "calc(100vh - 100px)" }}>
                        {this.state.historyImage &&
                            <img src={this.state.historyImage[0].thumbnail} style={{objectFit: 'cover', width:'100%'}} alt={this.state.historyImage[0].projectName}/>
                        }
                        </div>
                        <Dialog header="Version précédente" visible={this.state.openDialog} style={{ width: '35vw' }} footer={changeDialogFooter} onHide={this.onHide}>
                            <i className="icon-Media_History icon-4x p-mr-3"/>
                            <span>Êtes-vous sur de vouloir revenir à cette version ?</span>
                        </Dialog>
                    </div>
                </div>
                <SessionDetailsMenu projectId={this.state.projectId} imageId={this.state.imageId} productId={this.state.productId} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { type, image, isFetched, isHistoryFetched, isSend } = state.sessiondetails;
    return { type, image, isFetched, isHistoryFetched, isSend };
}

const mapDispatchToProps = {
    getImageOverview: sessiondetailsActions.getImageOverview,
    getImageHistory: sessiondetailsActions.getImageHistory,
    changeImageHistory: sessiondetailsActions.changeImageHistory,

}

const connectedSessionHistory = connect(mapStateToProps, mapDispatchToProps)(SessionHistory);
export { connectedSessionHistory as SessionHistory };
