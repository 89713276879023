export const catalogConstants = {
    GET_CATALOG_REQUEST: 'GET_CATALOG_REQUEST',
    GET_CATALOG_SUCCESS: 'GET_CATALOG_SUCCESS',
    GET_CATALOG_FAILURE: 'GET_CATALOG_FAILURE',
    
    GET_PRODUCT_REQUEST:'GET_PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS:'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE:'GET_PRODUCT_FAILURE',


    CATALOG_ADD_REQUEST: 'CATALOG_ADD_REQUEST',
    CATALOG_ADD_SUCCESS: 'CATALOG_ADD_SUCCESS',
    CATALOG_ADD_FAILURE: 'CATALOG_ADD_FAILURE',

    DELETE_CATALOG_REQUEST: 'DELETE_CATALOG_REQUEST',
    DELETE_CATALOG_SUCCESS: 'DELETE_CATALOG_SUCCESS',
    DELETE_CATALOG_FAILURE: 'DELETE_CATALOG_FAILURE',

    SWITCH_CATALOG_REQUEST: 'SWITCH_CATALOG_REQUEST',
    SWITCH_CATALOG_SUCCESS: 'SWITCH_CATALOG_SUCCESS',
    SWITCH_CATALOG_FAILURE: 'SWITCH_CATALOG_FAILURE',

    LINK_CATALOG_REQUEST: 'LINK_CATALOG_REQUEST',
    LINK_CATALOG_SUCCESS: 'LINK_CATALOG_SUCCESS',
    LINK_CATALOG_FAILURE: 'LINK_CATALOG_FAILURE',
    GET_LINKED_PRODUCTS_REQUEST:'GET_LINKED_PRODUCTS_REQUEST',
    GET_LINKED_PRODUCTS_SUCCESS:'GET_LINKED_PRODUCTS_SUCCESS',
    GET_LINKED_PRODUCTS_FAILURE:'GET_LINKED_PRODUCTS_FAILURE',
    SEARCH_CATALOG_REQUEST: 'SEARCH_CATALOG_REQUEST',
    SEARCH_CATALOG_SUCCESS: 'SEARCH_CATALOG_SUCCESS',
    SEARCH_CATALOG_FAILURE: 'SEARCH_CATALOG_FAILURE',

    FIND_CATALOG_REQUEST: 'FIND_CATALOG_REQUEST',
    FIND_CATALOG_SUCCESS: 'FIND_CATALOG_SUCCESS',
    FIND_CATALOG_FAILURE: 'FIND_CATALOG_FAILURE',

    FULL_CATALOG_REQUEST: 'FULL_CATALOG_REQUEST',
    FULL_CATALOG_SUCCESS: 'FULL_CATALOG_SUCCESS',
    FULL_CATALOG_FAILURE: 'FULL_CATALOG_FAILURE',

    INVENTORY_STATUS_REQUEST : 'INVENTORY_STATUS_REQUEST',
    INVENTORY_STATUS_SUCCESS : 'INVENTORY_STATUS_SUCCESS',
    INVENTORY_STATUS_FAILURE : 'INVENTORY_STATUS_FAILURE',

    ADD_PRODUCTS_PROJECT_REQUEST : 'ADD_PRODUCTS_PROJECT_REQUEST',
    ADD_PRODUCTS_PROJECT_SUCCESS : 'ADD_PRODUCTS_PROJECT_SUCCESS',
    ADD_PRODUCTS_PROJECT_FAILURE : 'ADD_PRODUCTS_PROJECT_FAILURE',

    PRODUCTS_BARCODE_REQUEST : 'PRODUCTS_BARCODE_REQUEST',
    PRODUCTS_BARCODE_SUCCESS : 'PRODUCTS_BARCODE_SUCCESS',
    PRODUCTS_BARCODE_FAILURE : 'PRODUCTS_BARCODE_FAILURE',

    PRINT_CATALOG_REQUEST : 'PRINT_CATALOG_REQUEST',
    PRINT_CATALOG_SUCCESS : 'PRINT_CATALOG_SUCCESS',
    PRINT_CATALOG_FAILURE : 'PRINT_CATALOG_FAILURE',

    ADD_MANUALLY_REQUEST: 'ADD_MANUALLY_REQUEST',
    ADD_MANUALLY_SUCCESS: 'ADD_MANUALLY_SUCCESS',
    ADD_MANUALLY_FAILURE: 'ADD_MANUALLY_FAILURE',

    GET_IMAGE_PRODUCT_REQUEST: 'GET_IMAGE_PRODUCT_REQUEST',
    GET_IMAGE_PRODUCT_SUCCESS: 'GET_IMAGE_PRODUCT_SUCCESS',
    GET_IMAGE_PRODUCT_FAILURE: 'GET_IMAGE_PRODUCT_FAILURE'

}
