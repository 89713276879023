import React, {Component} from 'react'
import {settingAction, alertActions} from '../../redux/actions'
import {connect} from 'react-redux';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import {Divider} from 'primereact/divider';
import Header from '../common/Header'
import { withTranslation } from 'react-i18next';

class DropboxSettingScreen extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			keys: [],
			company: [],
			isFetched : true,
			loading: true
		}
		this.handleChange = this.handleChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}

	componentDidMount() {
		this.props.getKey()
		this.props.getCompany()
	}

	static getDerivedStateFromProps (props, state){

		if(props.isFetched && state.isFetched) {
			// Gerer le GetKeys
			if(props.keys !== undefined && props.keys !== state.keys){
				let keys = props.keys
				let company = [];
				if(keys.length > 0){
					keys.map(e => {
						let obj = {name: e, value: ''};
						company.push(obj);
					})
				}

				return {keys : props.keys, company : company, loading: false}
			}

			// Gerer le GetSettings
			if(props.settings !== undefined && props.settings !== state.company && props.settings.length > 0){

				let company = state.company
				let table = props.settings
				company.forEach(i => {
					i.value = table.filter(k => k.name === i.name)[0] != null ? table.filter(k => k.name === i.name)[0].value : ""
				})
				return {
					company : company,
					loading: false
				}
			}

			//Gerer le retour de l'ajout
			if(props.add){
				return {
					isFetched: false,
					loading: false
				}
			}
		}

		return state;

	}


	handleChange( name, value ) {
		let company  = this.state.company
		let obj = company.filter(x => x.name === name)[0]
		let index = company.indexOf(obj)
		company[index].value= value
		this.setState({company: company});
	}

	onSubmit() {
		let {company} = this.state
		let test = company.filter(x => x.value === "")
		if(test.length === 0){
			this.setState({isFetched: true})
			this.props.addCompany(company)
		} else {
			this.props.warningAlert(this.props.t('toast.emptyInput'))
		}
	}

	render() {

		return (
			<div>
				<Header/>
				<div className="p-d-flex p-jc-center">
					<Panel header={this.props.t('parameter.dropbox')} className="p-grid p-mt-6 p-mx-auto p-dir-col">
						{this.state.company.map(( inp, i ) => {
							return (
								<span className="p-input-icon-right p-col p-grid p-mb-3">
	                            <i className="pi pi-info-circle"/>
	                            <InputText
		                            key={i}
		                            className="p-inputtext-lg p-col-12"
		                            name={inp.name}
		                            value={inp.value}
		                            onChange={e=> this.handleChange(inp.name, e.target.value)}
		                            placeholder={inp.name}
		                            onFocus={() => this.setState({isFetched: false})}
	                            />
                            </span>
							)
						})
						}

						<Divider className="p-mt-3"/>
						<div className="p-d-flex p-flex-column p-jc-center p-ai-end p-pt-4">
							<Button loading={this.state.loading} label={this.props.t('common.valid')} className="p-px-4 validationButton" onClick={this.onSubmit}/>
						</div>
					</Panel>
				</div>
			</div>
		)
	}
}

function mapState( state ) {
	const {keys, settings, isFetched, add} = state.company;

	return {keys, settings, isFetched, add}
}

const actionCreators = {
	getKey: settingAction.getKey,
	getCompany: settingAction.getCompany,
	addCompany: settingAction.addCompany,
	warningAlert: alertActions.warning
};

const connectedApp = (connect(mapState, actionCreators)(withTranslation()(DropboxSettingScreen)));
export {connectedApp as DropboxSettingScreen};
