import React, { Component } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { history } from '../../redux/helpers';

export class SessionDetailsMenu extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            items: [
                {icon: 'icon-visibility icon-2x', command: () => { history.push(`/projects/${this.props.projectId}/session/products/${this.props.productId}/images/${this.props.imageId}/overview`); }},
                {icon: 'icon-Media_Nav_Comment icon-2x', command: () => { history.push(`/projects/${this.props.projectId}/session/products/${this.props.productId}/images/${this.props.imageId}/comments`); }},
                {icon: 'icon-Media_Nav_Metadata icon-2x', command: () => { history.push(`/projects/${this.props.projectId}/session/products/${this.props.productId}/images/${this.props.imageId}/meta`); }},
                // {icon: 'icon-Media_Nav_History icon-2x', command: () => { history.push(`/projects/${this.props.projectId}/session/products/${this.props.productId}/images/${this.props.imageId}/history`); }},
                {icon: 'icon-Link icon-2x', command: () => { history.push(`/projects/${this.props.projectId}/session/products/${this.props.productId}/images/${this.props.imageId}/linked-products`); }}
            ],

        };

    }
    render() {
        return (
            <div className="p-d-flex p-jc-center p-ai-center" style={{height:"50px", backgroundColor: '#252525', width: '100%'}}>
                <TabMenu model={this.state.items}/>
            </div>
        );
    }
}

export class SessionHeader extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            project : JSON.parse(localStorage.getItem('project')),
            items: [
                {icon: 'pi pi-fw pi-chevron-left', command: () => { history.push(`/projects/${this.props.projectId}/session`)}},
                {label: this.props.title, className:"primary-color", disabled : true}

            ],

        };
    }
    render() {
        return (

            <div className="p-d-flex p-jc-start p-ai-end p-mt-2" style={{height:"50px"}}>
                <TabMenu model={this.state.items}/>
            </div>

        );
    }
}
