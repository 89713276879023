import { alertConstants } from '../constants';

export const alertActions = {
    success,
    warning,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message, style:"success" };
}

function warning(message) {
    return { type: alertConstants.WARNING, message, style:"warn" };
}

function error(message) {
    return { type: alertConstants.ERROR, message, style:"error" };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
