import { catalogConstants } from "../constants";

export function catalog(state = {}, action) {
  switch (action.type) {
    case catalogConstants.CATALOG_ADD_REQUEST:
      return Object.assign({}, { isCatalogFetching: true });
    case catalogConstants.CATALOG_ADD_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isCatalogFetched: true });
    case catalogConstants.CATALOG_ADD_FAILURE:
      return {};

    case catalogConstants.GET_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.GET_CATALOG_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isFetched: true ,isGetCatalogFetched:true});
    case catalogConstants.GET_CATALOG_FAILURE:
      return {};

      case catalogConstants.GET_PRODUCT_REQUEST:
        return Object.assign({}, { isProductFetching: true });
      case catalogConstants.GET_PRODUCT_SUCCESS:
        return Object.assign({}, { product: action.product, isProductFetched: true });
      case catalogConstants.GET_PRODUCT_FAILURE:
        return {};

    case catalogConstants.DELETE_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.DELETE_CATALOG_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isFetched: true });
    case catalogConstants.DELETE_CATALOG_FAILURE:
      return {};

    case catalogConstants.SWITCH_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.SWITCH_CATALOG_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isFetched: true });
    case catalogConstants.SWITCH_CATALOG_FAILURE:
      return {};

      case catalogConstants.GET_LINKED_PRODUCTS_REQUEST:
        return Object.assign({}, { isLinkedProductsFetching: true });
      case catalogConstants.GET_LINKED_PRODUCTS_SUCCESS:
        return Object.assign({}, { linkedProducts: action.linkedProducts, isLinkedProductsFetched: true });
      case catalogConstants.GET_LINKED_PRODUCTS_FAILURE:
        return {};

    case catalogConstants.LINK_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.LINK_CATALOG_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isLinked: true });
    case catalogConstants.LINK_CATALOG_FAILURE:
      return {};

      case catalogConstants.FIND_CATALOG_REQUEST:
        return Object.assign({},{ isSearching: true });
      case catalogConstants.FIND_CATALOG_SUCCESS:
        return Object.assign({}, { type: action.catalogs, isSearched: true });
      case catalogConstants.FIND_CATALOG_FAILURE:
        return {};

    case catalogConstants.SEARCH_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.SEARCH_CATALOG_SUCCESS:
      return Object.assign({}, { type: action.catalogs, isSearched: true });
    case catalogConstants.SEARCH_CATALOG_FAILURE:
      return {};

    case catalogConstants.FULL_CATALOG_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.FULL_CATALOG_SUCCESS:
      return Object.assign({}, { catalog: action.catalog, isFetched: true });
    case catalogConstants.FULL_CATALOG_FAILURE:
      return {};

    case catalogConstants.INVENTORY_STATUS_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.INVENTORY_STATUS_SUCCESS:
      return Object.assign({}, { catalog: action.catalog, isFetched: true });
    case catalogConstants.INVENTORY_STATUS_FAILURE:
      return {};

    case catalogConstants.ADD_PRODUCTS_PROJECT_REQUEST:
      return Object.assign({}, { isFetching: true });
    case catalogConstants.ADD_PRODUCTS_PROJECT_SUCCESS:
      return Object.assign({}, { isFetched: true });
    case catalogConstants.ADD_PRODUCTS_PROJECT_FAILURE:
      return {};

    case catalogConstants.PRODUCTS_BARCODE_REQUEST:
      return Object.assign({}, { isBarcodeFetching: true });
    case catalogConstants.PRODUCTS_BARCODE_SUCCESS:
      return Object.assign({}, { isBarcodeFetched: true });
    case catalogConstants.PRODUCTS_BARCODE_FAILURE:
      return {};

    case catalogConstants.PRINT_CATALOG_REQUEST:
      return Object.assign({}, { isPrintFetching: true });
    case catalogConstants.PRINT_CATALOG_SUCCESS:
      return Object.assign({}, { isPrintFetched: true });
    case catalogConstants.PRINT_CATALOG_FAILURE:
      return {};

    case catalogConstants.ADD_MANUALLY_REQUEST:
      return Object.assign({}, {isFetching: true});
    case catalogConstants.ADD_MANUALLY_SUCCESS:
      return Object.assign({}, {catalog: action.catalogs, isFetched: true})
    case catalogConstants.ADD_MANUALLY_FAILURE:
      return {}

    case catalogConstants.GET_IMAGE_PRODUCT_REQUEST:
      return Object.assign({}, {isImgFetching: true});
    case catalogConstants.GET_IMAGE_PRODUCT_SUCCESS:
      return Object.assign({}, {catalog: action.catalogs, isImgFetched: true})
    case catalogConstants.GET_IMAGE_PRODUCT_FAILURE:
      return {}

    default:
      return state;
  }
}
