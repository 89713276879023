export const mediasConstants = {
    MEDIAS_GET_BOARD_REQUEST: 'MEDIAS_GET_BOARD_REQUEST',
    MEDIAS_GET_BOARD_SUCCESS: 'MEDIAS_GET_BOARD_SUCCESS',
    MEDIAS_GET_BOARD_FAILURE: 'MEDIAS_GET_BOARD_FAILURE',
    MEDIAS_DOWNLOADZIP_REQUEST:'MEDIAS_DOWNLOADZIP_REQUEST',
    MEDIAS_DOWNLOADZIP_FAILURE:'MEDIAS_DOWNLOADZIP_FAILURE',
    MEDIAS_DOWNLOADZIP_SUCCESS:'MEDIAS_DOWNLOADZIP_SUCCESS',
    CHANGE_REF_MER_REQUEST: 'CHANGE_REF_MER_REQUEST',
    CHANGE_REF_MER_SUCCESS: 'CHANGE_REF_MER_SUCCESS',
    CHANGE_REF_MER_FAILURE: 'CHANGE_REF_MER_FAILURE'
}
