import React, { Component } from 'react';
import { Card } from 'primereact/card';
import Moment from 'react-moment';
import { ThreeDotsButton } from '../../common/SVGButtons';
import { TieredMenu } from 'primereact/tieredmenu';
import { history } from '../../../redux/helpers';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog'
import ReactDropzone from "react-dropzone";
import { ReactComponent as PDFImg } from '../../../img/pdf.svg';
import { projectActions } from '../../../redux/actions'
import { connect } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button'
import { withTranslation } from 'react-i18next';

class ProjectFileCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showContextMenu: false,
            showReplaceFileDialog: false,
            t: this.props.t
        }
        this.cardClick = this.cardClick.bind(this)
        this.showReplaceFileDialog = this.showReplaceFileDialog.bind(this)
        this.hideReplaceFileDialog = this.hideReplaceFileDialog.bind(this)
        this.replaceFile = this.replaceFile.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
        this.op = React.createRef()
    }

    deleteFile() {
        this.props.deleteClicked(this.props.file.id);
    }

    cardClick() {
        let project = JSON.parse(localStorage.getItem('project'));
        history.push("/projects/" + project.projectId + "/files/" + this.props.file.id);
    }

    downloadFile() {
        this.props.downloadClicked(this.props.file.id);
    }

    replaceFile(acceptedFiles){
        if (acceptedFiles !== null && acceptedFiles.length > 0) {
            this.props.replaceDocument(this.props.projectId, this.props.file.id, 1, acceptedFiles[0])
            this.hideReplaceFileDialog();
            return setTimeout(
                () => window.location.reload(),
                1000
              );
        }
    }

    showReplaceFileDialog() {
        this.setState({ showAddFileDialog: true });
    }

    hideReplaceFileDialog() {
        this.setState({ showAddFileDialog: false });
    }

    render() {

        let background = this.props.file.thumbnail !== null ?
            this.props.file.thumbnail:
        `${process.env.REACT_APP_BASE_URL}/Images/filetypes/${this.props.file.fileType}.svg`;

        return (
            < >
                <Card className="p-d-flex p-flex-column file-card p-p-2 p-card-container">
                    <>
                        <div style={{textAlign: "right"}}>
                            <Button type="button" icon='pi icon-Action_Open icon-2x' className="p-button-text p-p-0" onClick={(e) => this.op.current.toggle(e)}></Button>
                            <OverlayPanel ref={this.op}>
                                <div className="p-d-flex p-flex-column">
                                    <Button className="p-button p-button-text" label={this.state.t('common.replace')} onClick={this.showReplaceFileDialog}></Button>
                                    <Button className="p-button p-button-text" label={this.state.t('common.delete')} onClick={this.deleteFile}></Button>
                                    <Button className="p-button p-button-text" label={this.state.t('common.download')} onClick={this.downloadFile}></Button>
                                </div>
                            </OverlayPanel>
                        </div>
                        <div onClick={() => this.cardClick()} className="p-col-12 p-d-flex p-jc-center p-py-4 p-px-3 p-image-container">
                            <img src={background} />
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-around p-py-2 p-px-3 p-footer-container">
                            <div>
                                <Link to={"/projects/" + this.props.projectId + "/files/" + this.props.file.id} ><p className="p-text-light p-text-truncate">{this.props.file.fileName}</p></Link>
                            </div>
                            <div className="date-font-color">
                                <Moment fromNow ago locale='Fr' >{this.props.file.created}</Moment>
                            </div>
                        </div>
                    </>
                </Card>

                <Dialog position='center' header={this.state.t('projectFile.replaceFile')}
                    visible={this.state.showAddFileDialog}
                    style={{ width: '30vw' }}
                    onHide={() => this.hideReplaceFileDialog()}>
                    <div className="p-d-flex p-jc-center">
                        <ReactDropzone onDrop={acceptedFiles => this.replaceFile(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section className="sf-dropzone">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>
                                            <PDFImg />&nbsp;
                                            {this.state.t('projectFile.uploadFile')}
                                        </p>
                                    </div>
                                </section>
                            )}
                        </ReactDropzone>
                    </div>
                </Dialog>

            </>
        );
    }
}

const mapStateToProps = state => {
    const { project } = state;
    return project;
}

const mapDispatchToProps = {
    replaceDocument: projectActions.replaceDocument,
}

const connectedProject = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectFileCard));
export { connectedProject as ProjectFileCard };
