import {materialConstants} from '../constants';
import {materialService} from '../../services';
import {alertActions} from '.';

export const materialAction = {
    addMaterials,
    getAllMaterials,
    deleteMaterial,
    updateMaterial,
    validateMaterial,
    rejectMaterial,
    draftMaterial,
    deleteAllMaterial,

};

function addMaterials(projectId, materials){
    return dispatch => {
        dispatch(request(materials));
        materialService.materialAdd(projectId, materials)
            .then(
                materials => {
                    dispatch(success(materials))
                    dispatch(alertActions.success('Équipement ajouté avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request(materials) { return { type: materialConstants.MATERIALS_ADD_REQUEST, materials } }
    function success(materials) { return { type: materialConstants.MATERIALS_ADD_SUCCESS, materials } }
    function failure(error) { return { type: materialConstants.MATERIALS_ADD_FAILURE, error } }

};

function getAllMaterials(projectId) {
    return dispatch => {
        dispatch(request());
        materialService.getMaterial(projectId)
            .then(
                material => dispatch(success(material)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: materialConstants.GET_MATERIALS_REQUEST } }
    function success(material) { return { type: materialConstants.GET_MATERIALS_SUCCESS, material } }
    function failure(error) { return { type: materialConstants.GET_MATERIALS_FAILURE, error } }
}

function deleteMaterial (projectId, id){
    return dispatch => {
        dispatch(request(id))

        materialService.deleteMaterial(projectId, id)
        .then(
            materials => {
                dispatch(success(id))
                dispatch(alertActions.success('Équipement supprimé avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: materialConstants.DELETE_MATERIALS_REQUEST, id}}
    function success(id) {return {type: materialConstants.DELETE_MATERIALS_SUCCESS, id}}
    function failure(id, error) {return {type: materialConstants.DELETE_MATERIALS_FAILURE, id, error}}
}

function updateMaterial(projectId, id, material){
    return dispatch => {
        dispatch(request({ material }));
        materialService.updateMaterial(projectId, id, material)
        .then(
            materials => {
                dispatch(success(material));
                dispatch(alertActions.success('Équipement modifié avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(material) {return {type: materialConstants.UPDATE_MATERIALS_REQUEST, material}}
    function success(material) {return {type: materialConstants.UPDATE_MATERIALS_SUCCESS, material}}
    function failure(error) {return {type: materialConstants.UPDATE_MATERIALS_FAILURE, error}}
}

function validateMaterial(projectId, id){
    return dispatch => {
        dispatch(request(id))
        materialService.validateMaterial(projectId, id)
        .then(
            materials => {
                dispatch(success(id))
                dispatch(alertActions.success('Équipement validé avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: materialConstants.VALIDATE_MATERIALS_REQUEST, id}}
    function success(id) {return {type: materialConstants.VALIDATE_MATERIALS_SUCCESS, id}}
    function failure(id, error) {return {type: materialConstants.VALIDATE_MATERIALS_FAILURE, id, error}}
}

function rejectMaterial(projectId, id){
    return dispatch => {
        dispatch(request(id))
        materialService.rejectMaterial(projectId, id)
        .then(
            materials => {
                dispatch(success(id))
                dispatch(alertActions.success('Équipement rejeté avec succès'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: materialConstants.REJECT_MATERIALS_REQUEST, id}}
    function success(id) {return {type: materialConstants.REJECT_MATERIALS_SUCCESS, id}}
    function failure(id, error) {return {type: materialConstants.REJECT_MATERIALS_FAILURE, id, error}}
}

function draftMaterial(projectId, id){
    return dispatch => {
        dispatch(request(id))
        materialService.draftMaterial(projectId, id)
        .then(
            materials => {
                dispatch(success(id))
                dispatch(alertActions.success('Équipement modifiable'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(id) {return {type: materialConstants.DRAFT_MATERIALS_REQUEST, id}}
    function success(id) {return {type: materialConstants.DRAFT_MATERIALS_SUCCESS, id}}
    function failure(id, error) {return {type: materialConstants.DRAFT_MATERIALS_FAILURE, id, error}}
}

function deleteAllMaterial (projectId){
    return dispatch => {
        dispatch(request())

        materialService.deleteAllMaterial(projectId)
            .then(
                materials => {
                    dispatch(success())
                    dispatch(alertActions.success('Tous les équipements ont été supprimés avec succès'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() {return {type: materialConstants.DELETE_ALL_MATERIALS_REQUEST}}
    function success() {return {type: materialConstants.DELETE_ALL_MATERIALS_SUCCESS}}
    function failure(error) {return {type: materialConstants.DELETE_ALL_MATERIALS_FAILURE, error}}
}
