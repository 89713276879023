import React, { Component } from 'react';
import {ReactComponent as DeleteImg} from '../../img/delete.svg' ;
import {ReactComponent as ImportImg} from '../../img/plus.svg' ;
import {ReactComponent as ImportMetaImg} from '../../img/add-file.svg' ;
import {ReactComponent as ExportImg} from '../../img/download.svg' ;
import {ReactComponent as DownloadCloudImg} from '../../img/download-cloud.svg' ;
import {ReactComponent as SettingImg} from '../../img/settings.svg' ;
import {ReactComponent as ThreeDotsImg} from '../../img/threedots.svg' ;
import {ReactComponent as ScanImg} from '../../img/scan.svg' ;
import {ReactComponent as AddFolderImg} from '../../img/folder-plus.svg' ;

export class AddButton extends Component {
    render() {
        return (
            <ImportImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}

export class AddFileButton extends Component {
    render() {
        return (

            <ImportMetaImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}

export class DeleteButton extends Component {
    render() {
        return (

            <DeleteImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}

export class ExportButton extends Component {
    render() {
        return (

            <ExportImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>
        )
    }
}

export class DownloadCloudButton extends Component {
    render() {
        return (
            <div className="p-text-center">
                <DownloadCloudImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>
                <div>{this.props.title}</div>
           </div>
        )
    }
}

export class SettingButton extends Component {
    render() {
        return (

            <SettingImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>
        )
    }
}

export class ThreeDotsButton extends Component {
    render() {
        return (
            <ThreeDotsImg  className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}
export class IconButton extends Component {
    render() {
        return (
            <div onClick={this.props.onClick}>
                <i className={this.props.className} />
           </div>
        )
    }
}

export class ScanButton extends Component {
    render() {
        return (
            <ScanImg style={{cursor: 'pointer'}} className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}

export class AddFolderButton extends Component {
    render() {
        return (
            <AddFolderImg style={{cursor: 'pointer', filter: "invert()"}} className={this.props.className} onClick={this.props.onClick}/>

        )
    }
}

