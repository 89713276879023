import React, { Component } from "react";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import ColorDropDown from "../common/ColorDropDown";
import { Checkbox } from 'primereact/checkbox';
import { withTranslation } from 'react-i18next';

class ImageCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      cardCssClass: "session-image-card",
      media: this.props.media,
      rate:
        this.props.media.metasData.filter((m) => m.name == "Rating").length > 0
          ? this.props.media.metasData.filter((m) => m.name == "Rating")[0]
            .value
          : 0,
      label: this.props.media.metasData.filter((m) => m.name == "Label").length
        ? this.props.media.metasData.filter((m) => m.name == "Label")[0].value
        : "",

    };

    this.cardClicked = this.cardClicked.bind(this);
    this.validationButtonClicked = this.validationButtonClicked.bind(this);
    this.commentButtonClicked = this.commentButtonClicked.bind(this);
    this.setImageMeta = this.setImageMeta.bind(this);
    this.selectionButtonClicked = this.selectionButtonClicked.bind(this);
    this.setCheckedmedia = this.setCheckedmedia.bind(this);
    this.showLinkedPopup = this.showLinkedPopup.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    return {
      selected: props.media.selected,
      cardCssClass: "session-image-card",
      media: props.media,
      rate:
        props.media.metasData.filter((m) => m.name == "Rating").length > 0
          ? props.media.metasData.filter((m) => m.name == "Rating")[0].value
          : 0,
      label: props.media.metasData.filter((m) => m.name == "Label").length
        ? props.media.metasData.filter((m) => m.name == "Label")[0].value
        : "",
    };
  }

  cardClicked() {
    if (this.state.selected) {
      this.setState({ selected: false, cardCssClass: "session-image-card" });
    } else {
      this.setState({
        selected: true,
        cardCssClass: "session-selected-image-card",
      });
    }
    this.props.selectMedia(this.state.media);
  }
  showLinkedPopup(media) {
    this.props.showLinkedPopup(media);
}
  setImageMeta(meta, value) {
    if (value == null) value = 0;
    let newMeta = [];
    let media = this.state.media;
    if (isNaN(value) || value === "") {
      let existingRate = meta.filter((m) => m.name == "Rating");
      if (existingRate.length > 0) newMeta.push(existingRate[0]);
      else newMeta.push({ name: "Rating", value: 0 });
      newMeta.push({ name: "Label", value: value });
      this.setState({ label: value });
    } else {
      let existingLabel = meta.filter((m) => m.name == "Label");
      if (existingLabel.length > 0) newMeta.push(existingLabel[0]);
      else newMeta.push({ name: "Label", value: "" });
      newMeta.push({ name: "Rating", value: value });
      this.setState({ rate: value });
    }

    media.metasData = newMeta;
    this.setState({ media: media });
    this.props.setImageMeta(media);
  }

  selectionButtonClicked(media) {
    this.props.selectionButtonClicked(media);
    this.setState({
      media: {
        ...this.state.media,
        selected: !this.state.selected,
      },
    });
  }

  validationButtonClicked(media) {
    let newStatus = (this.state.media.status !== 4) ? 4 : 3
    this.props.validationButtonClicked(media, newStatus);
    this.setState({
      media: {
        ...media,
        status: newStatus,
      },
    });
  }

  commentButtonClicked(media) {
    this.props.commentButtonClicked(media);
  }

  setCheckedmedia(e) {
    let media = this.state.media
    media.ischecked = e.checked
    this.setState({ media: media })
    this.props.setCheckedmedia(media)
  }

  render() {
    const header = (
      <div className="p-d-flex p-jc-between">
        {this.state.media.title}
        <Checkbox className="custom-checkbox p-as-end"
                  onChange={e => this.setCheckedmedia(e)}
                  checked={this.state.media.ischecked}
        />
      </div>
    );

    const footer = (
      <>
        <div className="p-grid p-align-center p-mb-2">
          <div className="p-col-6">
            <Rating
              value={this.state.rate}
              onChange={(e) =>
                this.setImageMeta(this.state.media.metasData, e.value)
              }
            />
          </div>
          <div className="p-col-6 p-d-flex p-jc-end">
            <ColorDropDown
              style={{ zIndex: "2" }}
              value={this.state.label}
              updateLabel={(e) =>
                this.setImageMeta(this.state.media.metasData, e)
              }
            />
          </div>
        </div>

        <div className="p-grid p-justify-around">
          <Button
            label={this.props.t('common.add')}
            className={`p-col-6 p-my-2 p-button-outlined p-button-sm ${this.state.media.selected ? "card-button-add-active" : "card-button"}`}
            style={{ textDecoration: "none" }}
            onClick={() => this.selectionButtonClicked(this.state.media)}
            tooltip={this.props.t('session.addSelection')}
            tooltipOptions={{ position: "bottom" }}
          />
          <Button
            label={this.props.t('common.valid')}
            className={`p-col-6 p-my-2 p-button-outlined p-button-sm ${this.state.media.status === 4 ? "card-button-val-active" : "card-button"}`}
            style={{ textDecoration: "none" }}
            onClick={() => this.validationButtonClicked(this.state.media)}
            tooltip={this.props.t('common.valid')}
            tooltipOptions={{ position: "bottom" }}
          />
          <Button
            label={this.props.t('session.comment') + ' (' +this.state.media.commentsCount+ ')'}
            className="p-col-6 p-my-2 p-button-outlined p-button-sm card-button"
            style={{ textDecoration: "none" }}
            onClick={() => this.commentButtonClicked(this.state.media)}
            tooltip={this.props.t('session.commentButton')}
            tooltipOptions={{ position: "bottom" }}
          />
          <Button
              label={this.props.t('session.look')}
              className="p-col-6 p-my-2 p-button-outlined p-button-sm card-button"
              style={{ textDecoration: "none" }}
               onClick={() => this.showLinkedPopup(this.state.media)  }
              tooltip={this.props.t('session.createLook')}
              tooltipOptions={{ position: "bottom" }}
          />

        </div>
      </>
    );

    return (
      <div style={this.state.media.ischecked ? { "border": "solid white 0.1em" } : {}}>
        <Card
          footer={footer}
          header={header}
          className={this.state.cardCssClass}
        >
          <div onClick={() => this.cardClicked()}>
            <img
              alt="Card"
              src={`data:image/jpeg;base64,${this.state.media.thumbnail}`}
              style={{ width: "100%" }}
            />{" "}
          </div>
        </Card>
      </div>
    );
  }
}

class MiniImageCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: this.props.media,
      selected: this.props.selected,
      cardCssClass: this.props.selected
        ? "session-mini-selected-image-card"
        : "session-mini-image-card",
      rate:
        this.props.media.metasData.filter((m) => m.name == "Rating").length > 0
          ? this.props.media.metasData.filter((m) => m.name == "Rating")[0]
            .value
          : 0,
      keyPress: false,
    };

    this.cardClicked = this.cardClicked.bind(this);
    this.rateImage = this.rateImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.selected) {
      this.setState({ cardCssClass: "session-mini-image-card" });
    } else {
      this.setState({ cardCssClass: "session-mini-selected-image-card" });
    }
  }

  rateImage(value) {
    if (value == null) value = 0;
    let media = this.state.media;
    let meta = media.metasData.filter((m) => m.name == "Rating");
    if (meta.length > 0) meta[0].value = value;
    else meta.push({ name: "Rating", value: value });
    media.metasData = meta;
    this.setState({ media: media, rate: value });
    this.props.setImageMeta(media);
  }

  cardClicked(e) {
    this.props.selectMedia(this.props.media, e.shiftKey);
    if (this.state.selected) {
      this.setState({
        selected: false,
        cardCssClass: "session-mini-image-card",
        keyPress: false,
      });
    } else {
      this.setState({
        selected: true,
        cardCssClass: "session-mini-selected-image-card",
        keyPress: false,
      });
    }
  }

  render() {
    const header = <span>{this.state.media.title}</span>;
    const footer = (
      <div className="p-d-flex p-jc-center">
        <Rating
          value={this.state.rate}
          onChange={(e) => this.rateImage(e.value)}
        />
      </div>
    );

    return (
      <Card footer={footer} header={header} className={this.state.cardCssClass}>
        <div onClick={(e) => this.cardClicked(e)}>
          <img
            alt="Card"
            src={`data:image/jpeg;base64,${this.state.media.thumbnail}`}
            className="p-my-auto"
          />
        </div>
      </Card>
    );
  }
}

export const ImageCard = withTranslation()(ImageCardComponent)
export const MiniImageCard = withTranslation()(MiniImageCardComponent)
