 
import { commonService } from './common.service';
export const membersService = {
    getMembersProject,
    getMembers
}

function getMembersProject(projectId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/callsheet/members`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}
 
function getMembers() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/members`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}