import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { searchActions } from "../../redux/actions";
import { MultiSelect } from "primereact/multiselect";
class SearchPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      boundFilters: false,
      additionalFilters: [],
      criterias: [],
    };

    this.clearFilters = this.clearFilters.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSearchButtonClicked = this.onSearchButtonClicked.bind(this);

  }

  componentDidMount() {
    if (!this.state.boundFilters)
      this.props.getFilters();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.filters && !state.boundFilters) {

      let filters = props.filters.map(f => {
        return {
          filterName: f.filterName,
          values: f.values.map(v => { return { name: v, value: v } })
        }
      });
      return { additionalFilters: filters, boundFilters: true };
    }
    return state;
  }

  setFilter(filterName, filterValue) {
    let criterias = this.state.criterias;

    criterias[filterName] = filterValue;

    this.setState({ criterias: criterias });
  }
  onSearchButtonClicked = (event) => {
    event.preventDefault()
    this.props.onSearchButtonClicked({
      keyword: this.state.keyword,
      additionalFilters: this.state.criterias,
    });
  }

  clearFilters() {
    this.props.onResetButtonClicked({
      keyword: "",
      additionalFilters: [],
    });
    let searchCriteria = this.state;
    searchCriteria.keyword = "";
    this.props.onResetButtonClicked(searchCriteria);
    this.setState(searchCriteria);
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
     // event.preventDefault()
      this.props.onSearchButtonClicked({
        keyword: this.state.keyword,
        additionalFilters: this.state.criterias,
      });

    }
  }
  render() {
    return (
      <>

        <div  >
          <form onSubmit={this.onSearchButtonClicked}
            onKeyPress={this.handleKeyPress} className="p-d-flex p-dir-col p-jc-between">
            <InputText
              className="p-inputtext-sm p-my-1"
              type="text"
              placeholder="Recherche"
              value={this.state.keyword}
              onChange={(e) => this.setState({ keyword: e.target.value })}
            />
            {this.state.additionalFilters &&
              this.state.additionalFilters.length > 0 &&
              this.state.additionalFilters.map((filter) => {
                return (
                  <MultiSelect
                    placeholder={filter.filterName}
                    key={`filter_${filter.filterName}`}
                    display="chip"
                    optionLabel="name"
                    value={this.state.criterias[filter.filterName]}
                    options={filter.values}
                    onChange={(e) => this.setFilter(filter.filterName, e.value)}
                    filter
                    className=" p-mt-3"
                  />
                );
              })}
            <div className="p-d-flex p-jc-end p-mt-3">
              {/* <Button
              className="p-mr-1 p-button-sm card-button"
              label="Réinitialiser"
              onClick={(e) => this.clearFilters()}
            /> */}
              <Button
                className="p-mr-1 p-button-sm card-button"
                label="Filtrer"
                             />

            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { isFetched, filters } = state.search;
  return { isFetched, filters };
};

const actionCreators = {
  getFilters: searchActions.getFilters,
};

const connectedSearchPanel = connect(
  mapStateToProps,
  actionCreators
)(SearchPanel);
export { connectedSearchPanel as SearchPanel };
