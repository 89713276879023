import { membersService } from '../../services'
import { membersConstants } from '../constants'
import {alertActions} from './'

export const membersAction = {
    getMembersProject,
    getMembers
}

function getMembersProject(projectId){
    return dispatch => {
        dispatch(request());
        membersService.getMembersProject(projectId)
        .then(
            members => dispatch(success(members)),
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request() { return { type: membersConstants.GET_MEMBERS_PROJECT_REQUEST } }
    function success(members) { return { type: membersConstants.GET_MEMBERS_PROJECT_SUCCESS, members } }
    function failure(error) { return { type: membersConstants.GET_MEMBERS_PROJECT_FAILURE, error } }
}

function getMembers(projectId){
    return dispatch => {
        dispatch(request());
        membersService.getMembers()
            .then(
                members => dispatch(success(members)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: membersConstants.GET_MEMBERS_REQUEST } }
    function success(members) { return { type: membersConstants.GET_MEMBERS_SUCCESS, members } }
    function failure(error) { return { type: membersConstants.GET_MEMBERS_FAILURE, error } }
}