import {commonService} from './common.service';

export const assetsService = {
	getImage
};

function getImage( url ) {
	let user = JSON.parse(localStorage.getItem('user'));
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + user.auth.auth_token
		},
	};
	let apiUrl = `${process.env.REACT_APP_BASE_URL}${url}`;
	if (url) {
		return fetch(apiUrl, requestOptions).then(commonService.handleResponseImage.bind(this))
	}
}

 
