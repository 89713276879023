import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "fr",
        debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        saveMissing: true // send not translated keys to endpoint

    });

export default i18n;
