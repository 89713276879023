import {projectConstants} from '../constants';

export function project(state = {}, action) {
   switch (action.type) {
    case projectConstants.SHOOTING_TYPE_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.SHOOTING_TYPE_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isFetched: true, types: action.shootingTypes });
    case projectConstants.SHOOTING_TYPE_FAILURE:
      return {}
    case projectConstants.STEP_ONE_REQUEST:
      return Object.assign({}, { isCreating: true });
    case projectConstants.STEP_ONE_SUCCESS:
      return Object.assign({}, { isCreated: true, project: action.project });
    case projectConstants.STEP_ONE_FAILURE:
      return {}
    case projectConstants.MEMBER_ROLES_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.MEMBER_ROLES_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isFetched: true, memberRoles: action.memberRoles });
    case projectConstants.MEMBER_ROLES_FAILURE:
      return {}
    case projectConstants.MEMBER_ADD_REQUEST:
      return Object.assign({}, { isMemberAddFetching: true });
    case projectConstants.MEMBER_ADD_SUCCESS:
      return Object.assign({}, { isMemberAddFetched: true, project: action.project });
    case projectConstants.MEMBER_ADD_FAILURE:
      return {}
    case projectConstants.GET_PROJECTS_REQUEST:
      return Object.assign({}, { isFetching: true });
    case projectConstants.GET_PROJECTS_SUCCESS:
      return Object.assign({}, { isFetched: true, projects: action.projects });
    case projectConstants.GET_PROJECTS_FAILURE:
      return {}
    case projectConstants.GET_PROJECT_DETAILS_REQUEST:
      return Object.assign({}, { isFetching: true });
    case projectConstants.GET_PROJECT_DETAILS_SUCCESS:
      return Object.assign({}, { isFetched: true, project: action.project });
    case projectConstants.GET_PROJECT_DETAILS_FAILURE:
      return {}
    case projectConstants.UPDATE_PROJECT_REQUEST:
      return Object.assign({}, { isFetching: true });
    case projectConstants.UPDATE_PROJECT_SUCCESS:
      return Object.assign({}, { isFetched: true, project: action.project });
    case projectConstants.UPDATE_PROJECT_FAILURE:
      return {}
    case projectConstants.GET_PROJECT_FILES_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.GET_PROJECT_FILES_SUCCESS:
      return Object.assign({}, { project: { ...state.project, files: action.project }, isFetched: true });
    case projectConstants.GET_PROJECT_FILES_FAILURE:
      return {}
    case projectConstants.GET_FILE_CATEGORIES_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.GET_FILE_CATEGORIES_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, fileCategories: action.project, isFetched: true });
    case projectConstants.GET_FILE_CATEGORIES_FAILURE:
      return {}
    case projectConstants.ADD_DOCUMENT_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.ADD_DOCUMENT_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isSuccessFetched: true });
    case projectConstants.ADD_DOCUMENT_FAILURE:
      return {}
    case projectConstants.DELETE_DOCUMENT_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.DELETE_DOCUMENT_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isDeleteFetched: true });
    case projectConstants.DELETE_DOCUMENT_FAILURE:
      return {}
    case projectConstants.REPLACE_DOCUMENT_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.REPLACE_DOCUMENT_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isFetched: true });
    case projectConstants.REPLACE_DOCUMENT_FAILURE:
      return {}
    case projectConstants.GET_FILE_DETAILS_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.GET_FILE_DETAILS_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, file: action.project, isFetched: true });
    case projectConstants.GET_FILE_DETAILS_FAILURE:
      return {}
    case projectConstants.ADD_FILE_COMMENT_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.ADD_FILE_COMMENT_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, comments: action.comments, isFetched: true });
    case projectConstants.ADD_FILE_COMMENT_FAILURE:
      return {}
    case projectConstants.DELETE_MEMBER_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true });
    case projectConstants.DELETE_MEMBER_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isDeleted: true });
    case projectConstants.DELETE_MEMBER_FAILURE:
      return {}
    case projectConstants.VALIDATE_COMMENT_SUCCESS:
      return Object.assign({}, { project: { ...state.project }, isValidate: true })
    case projectConstants.VALIDATE_COMMENT_REQUEST:
      return Object.assign({}, { project: { ...state.project }, isFetching: true })
    case projectConstants.VALIDATE_COMMENT_FAILURE:
      return {}
    case projectConstants.GET_STATISTIC_REQUEST:
      return Object.assign({}, {isStatsFetching: true})
    case projectConstants.GET_STATISTIC_SUCCESS:
      return Object.assign({}, {stats: action.project, isStatsFetched: true})
    case projectConstants.GET_STATISTIC_FAILURE:
      return {}



      case projectConstants.CREATE_FOLDER_RAW_REQUEST:
        return Object.assign({}, {isRawFetching: true})
      case projectConstants.CREATE_FOLDER_RAW_SUCCESS:
        return Object.assign({}, {project: action.project , isRawFetched: true})
      case projectConstants.CREATE_FOLDER_RAW_FAILURE:
        return {}

        case projectConstants.DELETE_PROJECT_REQUEST:
          return Object.assign({}, {project: { ...state.project }, isDeleteProjectFetching: true})
        case projectConstants.DELETE_PROJECT_SUCCESS:
          return Object.assign({}, {project: { ...state.project }, isDeleteProjectFetched: true})
        case projectConstants.DELETE_PROJECT_FAILURE:
          return {}

    default:
      return state
  }
}
