import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component = null, render: Render = null, ...rest }) {
    const [token] = React.useState(localStorage.getItem('user') || false);
    let user = JSON.parse(localStorage.getItem('user'));
    return (
      <Route
        {...rest}
        render={props =>
          token ?  Render ? (
            Render(props)
          ) : Component ? (
            <Component {...props} />
          ) : 
          null : 
          (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
      />
    );
  }