import React, { Component } from 'react'
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../redux/actions'
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

class InvitationScreen extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
            error: false
        }

        this.submitPassword = this.submitPassword.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }


    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search)
        const email = params.get('email')
        const token = params.get('token')
        if(email !== null && token !== null){
            this.setState({
                email: email,
                token: token
            })
            this.props.confirmEmail(email, token)
        }
    }


    submitPassword = (event) => {
        event.preventDefault()
        const {email, password, confirmPassword} = this.state
        if(password !== confirmPassword) {
            this.props.alertWarning("Les mots de passe ne sont pas identiques")
        }
        else{
            const passwordObj = {
                email: email,
                password: password
            }
            this.props.createPassword(passwordObj)
        }
    }

    handleKeyPress = (event) => {
        const {email, password, confirmPassword} = this.state
        if(event.key === 'Enter'){
            if(password !== confirmPassword) {
                this.props.alertWarning("Les mots de passe ne sont pas identiques")
            }
            else{
                const passwordObj = {
                    email: email,
                    password: password
                }
                this.props.createPassword(passwordObj)
            }
        }
    }

    render () {
        return (
            <div className="p-grid p-d-flex authentication-container p-jc-center p-ai-center">
                <div className="p-col-4 p-sm-12 p-md-6 p-lg-4">
                    <Panel header="Créez votre mot de passe" className="p-pt-4">

                        <form className="p-fluid" onSubmit={this.submitPassword} onKeyPress={this.handleKeyPress}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Password
                                        id="password"
                                        className={this.state.error ? "p-invalid": ''}
                                        value={this.state.password}
                                        onChange={(e) => this.setState({password: e.target.value, error: false})}
                                        toggleMask
                                        required
                                    />
                                    <label htmlFor="username">Mot de passe</label>
                                </span>
                            </div>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Password
                                        feedback={false}
                                        id="confirmPassword"
                                        className={this.state.error ? "p-invalid" : ''}
                                        value={this.state.confirmPassword}
                                        onChange={(e) => this.setState({confirmPassword: e.target.value, error: false})}
                                        toggleMask
                                        required
                                    />
                                    <label htmlFor="password">Confirmer le mot de passe</label>
                                </span>
                            </div>
                            <Button type="submit" className="p-button-rounded" label="Créer le mot de passe" />
                        </form>
                    </Panel>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    alertWarning: alertActions.warning,
    resetPassword: userActions.resetPassword,
    confirmEmail: userActions.confirmEmail,
    createPassword: userActions.createPassword
};

const connectedLoginPage = (connect(mapState, actionCreators)(InvitationScreen));
export { connectedLoginPage as InvitationScreen };
