
import {history} from './../redux/helpers/store';
import {saveAs} from 'file-saver'
export const commonService = {
    handleResponse,logout,handleFileResponse,handleResponseImage
  };
  function handleResponseImage(response) {
    return response.text().then(text => {
        if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
          history.push('/login')
          //location.reload(true);
        }
        if(response.status === 404){
            return null
        }
        return Promise.reject(response.statusText);
      }
        if(response.headers.get('Content-Type') === 'image/jpeg'){
            return response.url
        }
      return text;
    });
  }
function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
          history.push('/login')
          //location.reload(true);
        }
  
        const error = (data && data.Message) || response.statusText;
        return Promise.reject(error);
      }
      return data
    });
  }
  function handleFileResponse(response) {

    return response.blob().then(blob => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                history.push('/login');
            }
        }
        return saveAs(blob, "file.pdf") ;
    })
}
  function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
  }