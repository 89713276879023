import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import ProductTreeview from "./ProductTreeview";
import {ScrollPanel} from "primereact/scrollpanel";
import { withTranslation } from 'react-i18next';

class SessionNav extends Component {
	constructor() {
		super();
		this.state = {
			searchTree: "",
			paths: [],
			products: [],
			newPaths: []
		}
	}

	static getDerivedStateFromProps(props, state){
		if(props.paths && props.selectedPath && props.products){
			return {
				paths: props.paths,
				selectedPath: props.selectedPath,
				products: props.products
			}
		}
		return state
	}

	searchProductTree = (e) => {
		this.setState({searchTree: e.target.value});
		let { paths, selectedPath } = this.state;
		let userInput = e.target.value.toLowerCase();
		let _path = paths.filter((path) => path.storagePath.toLowerCase().includes(userInput) || path.reference.toLowerCase() == userInput);
		 if (_path.length >= 1) {
			this.setState({ newPaths: _path, selectedPath: _path[0].storagePath });
			this.props.selectPath(_path[0].storagePath);
		}
		if (userInput === "" && _path[0].reference.toLowerCase().indexOf(userInput) === 0) {
			this.props.selectPath(selectedPath);
		}
	};

	render() {
		return (
			<div className="secondary-bg-color p-dir-col" style={{ height: "100%" }}>
				<div className="p-col-fixed ">
					<span className="p-input-icon-left" style={{ width: "100%" }}>
						<i className="pi pi-search"/>
						<InputText
							className="p-inputtext-sm p-d-block"
							placeholder={this.props.t('common.search')}
							style={{ width: "100%" }}
							value={this.state.searchTree}
							onChange={this.searchProductTree}/>
					</span>
				</div>
				<ScrollPanel style={{ width: '100%', height: '89vh' }}>
					<ProductTreeview
						paths={this.state.newPaths.length > 0 ? this.state.newPaths : this.state.paths}
						selectPath={(p) => this.props.selectPath(p)}
						selectedPath={this.state.selectedPath}
					/>
				</ScrollPanel>
			</div>
		);
	}
}

export default withTranslation()(SessionNav);
