import { commonService } from './common.service';

export const sessiondetailsService = {
    getImageOverview,
    getImageHistory,
    changeImageHistory,
	  addImageComment,
    validateComment
  }

  function getImageOverview(projectId, productId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}`, requestOptions)
      .then(commonService.handleResponse.bind(this))
  }
  function validateComment(projectId, commentId)
  {
   let user = JSON.parse(localStorage.getItem('user'));
   const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
        };

   return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/comments/${commentId}/validate`, requestOptions)
     .then(commonService.handleResponse.bind(this))
  }
  function addImageComment(projectId, productId, imageId, comment)
   {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
      body: JSON.stringify({

        comment: comment.message,

        pointId: comment.pointId,

      })
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/comment`, requestOptions)
      .then(commonService.handleResponse.bind(this))
   }

   function getImageHistory(projectId, productId, imageId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/versions`, requestOptions)
      .then(commonService.handleResponse.bind(this))
  }

  function changeImageHistory(projectId, productId, imageId, versionId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/session/products/${productId}/images/${imageId}/versions/${versionId}`, requestOptions)
      .then(commonService.handleResponse.bind(this))
  }
