import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment'
import { specialChar } from '../../common/Regex'
import { withTranslation } from 'react-i18next';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: this.props.project,
            types: this.props.types,
            loading: false,
            t: this.props.t
        };
    }

    cancelClicked = () => {
        this.props.cancel(this.props);
    }

    validateClicked = () => {
        this.props.validate(this.state.project);
    }

    render() {
        return (

            <div>
                <div className="p-d-flex p-flex-column p-jc-center p-pt-3">
                    <div className=" p-fluid">
                        <div className="p-field p-col-12">
                            <InputText value={this.state.project.title}
                                       type="text"
                                       style={this.state.errorInput ? { border: '1px solid #f44435' } : {}}
                                       onChange={(e) => {
                                           let value = e.target.value
                                           if ( specialChar.test(value) ) {
                                               this.setState({ errorInput: true })
                                           } else {
                                               let proj = this.state.project;
                                               proj.title = e.target.value.trim();
                                               this.setState({ project: proj, errorInput: false })
                                           }
                                       }}/>
                            {this.state.errorInput ?
                                <small className="p-error">{this.state.t('createProject.specialChar')}</small> : <></>}
                        </div>
                        <div className="p-field p-col-12">
                            <Calendar value={new Date(this.state.project.startShootingDate)}
                                      placeholder={this.state.project.startShootingDate}
                                      onChange={(e) => {
                                          let proj = this.state.project;
                                          proj.startShootingDate = moment(e.target.value).format();
                                          this.setState({ project: proj })
                                      }}/>
                        </div>
                        <div className="p-field p-col-12">
                            <Dropdown value={this.state.project.shootingTypeLabel}
                                      options={this.state.types}
                                      placeholder={this.state.project.shootingTypeLabel}
                                      optionLabel="name"
                                      onChange={(e) => {
                                          console.log('e.target.value', e.target.value)
                                          let proj = this.state.project;
                                          proj.shootingTypeLabel = e.originalEvent.target.ariaLabel;
                                          proj.shootingType = e.target.value;
                                          this.setState({ project: proj })
                                      }}/>
                        </div>
                        <div className="p-field p-col-12">
                            <InputTextarea rows={5} cols={30}
                                           value={this.state.project.description}
                                           onChange={(e) => {
                                               let proj = this.state.project;
                                               proj.description = e.target.value;
                                               this.setState({ project: proj })
                                           }}/>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-d-flex p-jc-end">
                    <div className="p-col p-text-right">
                        <Button className="p-button-rounded p-button-outlined"
                                onClick={() => this.cancelClicked()}>{this.state.t('common.cancel')}</Button>
                        <Button className="p-button-rounded p-ml-3"
                                onClick={() => this.validateClicked()}>{this.state.t('common.valid')}</Button>
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(Project);
