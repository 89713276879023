import { activitiesConstants } from '../constants';

export function activities(state={}, action) {

    switch(action.type){
        case activitiesConstants.GETALL_ACTIVITIES_REQUEST:
            return Object.assign({}, {isFetching: true});
        case activitiesConstants.GETALL_ACTIVITIES_SUCCESS:
            return Object.assign({}, {isFetched: true, types: action.activities});
        case activitiesConstants.GETALL_ACTIVITIES_FAILURE:
        default:
            return state
    }

}