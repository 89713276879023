import React, {Component} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { withTranslation } from 'react-i18next';

class SessionFooter extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			displayedMedias: [],
			selectedMedias: [],
			itemSelected: null,
			item: [
				{name: "A-Z", code: "asc"},
				{name: "Z-A", code: "desc"},
				{name: this.props.t('session.rate'), code: 'rate'}
			],
			t: this.props.t
		}
	}

	static getDerivedStateFromProps( props ) {
		return {
			displayedMediasLength: props.displayedMediasLength,
			selectedMediasLength: props.selectedMediasLength
		}
	}

	onOrderChange = ( e ) => {
		this.setState({
			itemSelected: e.value
		})
		this.props.sortMedias(e.value)
	}

	render() {
		return (
			<div className="p-grid secondary-bg-color p-justify-between p-align-center p-mx-2 p-px-2">
				<Dropdown
					options={this.state.item}
					value={this.state.itemSelected}
					className='p-col-1 memberRoleSelect'
					style={{padding: 0}}
					optionLabel='name'
					optionValue='code'
					onChange={this.onOrderChange}
					placeholder={this.state.t('session.sort')}
				/>
				<p className="p-col p-m-auto p-text-center">
					{this.state.selectedMediasLength > 0 ?
						this.state.selectedMediasLength === 1 ?
							this.state.selectedMediasLength + ' ' + this.state.t('session.selectedImg')
							: this.state.selectedMediasLength + ' ' + this.state.t('session.selectedAllImg')
						: this.state.displayedMediasLength + ' ' + this.state.t('session.result')}
				</p>
				<div className="p-col-3 p-grid p-jc-end p-align-center">
					<Button className="p-button-text p-button-sm"
					        onClick={() => this.props.setSelectAllMedias("selectAll")}
					        label={this.state.t('session.selectAll')}/>
					<Button className="p-button-text p-button-sm"
					        onClick={() => this.props.setSelectAllMedias('deselectAll')}
					        label={this.state.t('session.selectAll')}/>
				</div>
			</div>
		);
	}
}

export default withTranslation()(SessionFooter);
