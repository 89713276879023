import React, { Component } from 'react'
import Header from '../../common/Header'
import { ProjectMenu, ProjectListingsMenu } from '../common/ProjectMenu'
import { DeleteButton, ExportButton, SettingButton } from '../../common/SVGButtons'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Dropdown } from 'primereact/dropdown'
import { connect } from 'react-redux'
import { materialAction, membersAction, projectActions } from '../../../redux/actions'
import moment from 'moment'
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectMaterialsScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectId: null,
            material: [],
            isFetched: false,
            members: [],
            loading: true,
            errors: {},
            t : this.props.t
        }
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.renderSwitch = this.renderSwitch.bind(this)
        this.hideDialog = this.hideDialog.bind(this)
        this.deleteMaterial = this.deleteMaterial.bind(this)
        this.deleteMaterialsList = this.deleteMaterialsList.bind(this)
        this.validateMaterial = this.validateMaterial.bind(this)
        this.rejectMaterial = this.rejectMaterial.bind(this)
        this.draftMaterial = this.draftMaterial.bind(this)
        this.exportCSV = this.exportCSV.bind(this)
    }

    componentDidMount() {
        const projectId = this.props.match.params.projectId
        this.setState({ projectId: projectId, })
        this.props.getMembers()
        this.props.getAllMaterials(projectId)
    }

    static getDerivedStateFromProps(props, state) {

        if (props.material.isFetched) {
            return {
                material: props.material.type,
                isFetched: props.material.isFetched,
                loading: false,
            }
        }
        if (props.members && props.fetched) {
            return { members: props.members }
        }
        if (props.material._isFetched || props.material.fetched || props.material.isDeleted || props.material.isValidate || props.material.isRejected || props.material.isDrafted || props.material.isAllDeleted) {
            props.getAllMaterials(state.projectId)
            return {
                loading: true
            }
        }
    }

    exportCSV() {
        this.dt.exportCSV()
    }

    //Function pour ouvrir la popup ajouter ou editer Place
    openMaterialDialog(newOrdEdit, materialEdit) {
        if (newOrdEdit === 'éditée') {
            materialEdit.collectDate = new Date(materialEdit.collectDate)
            this.setState({
                type: newOrdEdit,
                materialEdit,
                collectDate: materialEdit.collectDate,
                materialDialog: true
            })
        } else {
            this.setState({
                materialDialog: true,
                materialEdit: {},
                type: newOrdEdit,
            })
        }
    }

    //Fermer les dialog
    hideDialog() {
        this.setState({
            submitted: false,
            materialDialog: false,
            materialEdit: {},
            collectDate: null,
            deleteMaterialsDialog: false,
            deleteMaterialDialog: false,
            type: '',
            errors: {}
        })
    }

    //Function pour ajouter ou editer Place - API
    saveMaterial = () => {
        let { projectId, materialEdit, collectDate, type, errors } = this.state
        if (materialEdit.title === undefined || materialEdit.title === null || materialEdit.title === '') {
            errors.name = this.state.t('toast.emptyName')
        }
        else {
            let material = {
                title: materialEdit.title,
                price: parseInt(materialEdit.price),
                origin: materialEdit.origin,
                collectDate: moment(collectDate).format(),
                assignedToId: materialEdit.assignedToId,
                status: materialEdit.status
            }
            if (type === 'éditée') {
                this.props.updateMaterial(projectId, materialEdit.id, material)
                this.props.getAllMaterials(projectId)
            } else {
                this.props.addMaterials(projectId, material)
                this.props.getAllMaterials(projectId)
            }

            this.hideDialog()
        }
    }

    //Horaire du tableau
    collectDateTemplate = rowData => {
        return moment(rowData.collectDate).locale('fr').format('LLL')
    }

    //Prix du tableau
    priceMaterial = rowData => {
        return `${rowData.price}€`
    }

    //bouton du tableau
    actionBodyTemplate(rowData) {
        return (
            <>
                <Button type="button" icon="pi pi-ellipsis-h" className="p-button-text p-button-lg" onClick={(e) => {
                    this.op.toggle(e)
                    this.setState({ rowData: rowData })
                }} />
            </>
        )
    }

    // Ouvrir le dialog qui confirme la suppression
    confirmDeleteMaterial(materialDeleted) {
        this.setState({
            materialDeleted,
            deleteMaterialDialog: true
        })
    }

    // Function pour supprimer UN équipement
    deleteMaterial() {
        let { projectId, materialDeleted } = this.state
        this.props.deleteMaterial(projectId, materialDeleted.id)
        this.setState({
            deleteMaterialDialog: false,
            materialDeleted: null
        })
    }

    // Ouvrir le dialog qui confirme la suppression de la liste
    deleteAllMaterials = () => {
        this.setState({ deleteMaterialsDialog: true })
    }

    // Function pour supprimer TOUS les équipements
    deleteMaterialsList() {
        let { projectId } = this.state
        this.props.deleteAllMaterial(projectId)
        this.setState({
            deleteMaterialsDialog: false
        })
    }

    renderSwitch(obj) {
        switch (obj.status) {
            case 2:
                return <div className="p-d-flex p-flex-column">
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftMaterial(obj.id)} />
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectMaterial(obj.id)} />
                </div>
            case 3:
                return <div className="p-d-flex p-flex-column">
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftMaterial(obj.id)} />
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validateMaterial(obj.id)} />
                </div>
            default:
                return <div className="p-d-flex p-flex-column">
                    <Button label={this.state.t('common.modify')}  className="p-button-text"
                        onClick={() => this.openMaterialDialog('éditée', obj)} />
                    <Button label={this.state.t('common.delete')} className="p-button-text"
                        onClick={() => this.confirmDeleteMaterial(obj)} />
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validateMaterial(obj.id)} />
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectMaterial(obj.id)} />
                </div>
        }
    }

    validateMaterial = (id) => {
        this.props.validateMaterial(this.state.projectId, id)

    }

    rejectMaterial = (id) => {
        this.props.rejectMaterial(this.state.projectId, id)

    }

    draftMaterial = (id) => {
        this.props.draftMaterial(this.state.projectId, id)
    }

    render() {

        const materialDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label={this.state.type === 'éditée' ? this.state.t('common.modify') : this.state.t('common.add')} icon="pi pi-check"
                    className="p-button-text" onClick={this.saveMaterial} />
            </React.Fragment>
        )

        const deleteMaterialDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteMaterial} />
            </React.Fragment>
        )

        const deleteMaterialsDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteMaterialsList} />
            </React.Fragment>
        )

        return (
            <div>
                <Header />
                <ProjectMenu activeItemIndex={2} />
                {this.state.isFetched ? <div className="p-d-flex p-mt-6">
                    <div className="p-col-2">
                        <ProjectListingsMenu />
                    </div>
                    <div className="p-col">
                        <Toast ref={(el) => this.toast = el} />
                        <div className="p-col  p-mr-6">
                            <div className="p-d-flex p-mt-3 p-ai-center">
                                <div className="p-col-3">
                                    <h5>{this.state.t('projectList.material.title')}</h5>
                                </div>
                                <div className="p-col-9 p-d-flex p-jc-end">
                                    <SettingButton className="p-mr-1" />
                                    <DeleteButton className="p-mr-1"
                                        onClick={() => this.deleteAllMaterials()} />
                                    <ExportButton onClick={() => this.exportCSV(this.state.material)} />
                                </div>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-end">
                                <DataTable
                                    style={{ marginTop: '20px' }}
                                    value={this.state.material}
                                    loading={this.state.loading}
                                    emptyMessage={this.state.t('projectList.material.noFind')}
                                    resizableColumns
                                    columnResizeMode="expand"
                                    ref={(el) => { this.dt = el }}
                                    rows={10}
                                >
                                    <Column field="title" header={this.state.t('common.name')} sortable />
                                    <Column body={this.priceMaterial} header={this.state.t('projectList.material.price')} field="price" sortable />
                                    <Column field="origin" header={this.state.t('projectList.material.origin')} sortable />
                                    <Column body={this.collectDateTemplate} header={this.state.t('projectList.place.hourly')} />
                                    <Column field="assignedTo" header={this.state.t('projectList.material.assignedTo')} sortable />
                                    <Column body={this.actionBodyTemplate} style={{ width: '12%' }} />
                                </DataTable>
                                <Button label={this.state.t('common.add')} icon="pi pi-plus" className="p-button-text p-my-1"
                                    onClick={() => this.openMaterialDialog('ajoutée')} />
                            </div>

                            {/* Overlay pour Modifier, Supprimer, Draft ou Edit */}
                            <OverlayPanel ref={(el) => this.op = el}>
                                {this.state.rowData && this.renderSwitch(this.state.rowData)}
                            </OverlayPanel>

                            {/* Popup pour ajouter/modifier un materiel  */}
                            <Dialog visible={this.state.materialDialog}
                                    style={{ width: '30vw', height: '70vh' }}
                                    header={this.state.t('projectList.material.detail')}
                                    modal
                                    className="p-fluid"
                                    footer={materialDialogFooter}
                                    onHide={this.hideDialog}>
                                {this.state.materialEdit &&
                                    <div className="p-fluid p-d-flex  p-flex-column p-jc-between p-ai-center">
                                        <span className="p-float-label p-mt-5 p-field p-col-12">
                                            <InputText id="title"
                                                value={this.state.materialEdit.title}
                                                onChange={(e) => this.setState(prevState => ({
                                                    materialEdit: {
                                                        ...prevState.materialEdit,
                                                        title: e.target.value
                                                    }
                                                }))}
                                                className={this.state.errors.title ? 'p-invalid' : ''}
                                            />
                                            <label htmlhtmlFor="title">{this.state.t('common.name')}</label>
                                            {this.state.errors.title !== '' ? <small id="username2-help"
                                                className="p-error p-d-block">{this.state.errors.title}</small> : <></>}
                                        </span>
                                        <span className="p-float-label p-field p-col-12">
                                            <InputText id="price"
                                                value={this.state.materialEdit.price}
                                                keyfilter="num"
                                                onChange={(e) => this.setState(prevState => ({
                                                    materialEdit: {
                                                        ...prevState.materialEdit,
                                                        price: e.target.value
                                                    }
                                                }))}
                                                className={this.state.errors.price ? 'p-invalid' : ''}
                                            />
                                            <label htmlhtmlFor="price">{this.state.t('projectList.material.price')}</label>
                                            {this.state.errors.price !== '' ? <small id="username2-help"
                                                className="p-error p-d-block">{this.state.errors.price}</small> : <></>}
                                        </span>
                                        <span className="p-float-label p-field p-col-12">
                                            <InputText id="origin"
                                                value={this.state.materialEdit.origin}
                                                onChange={(e) => this.setState(prevState => ({
                                                    materialEdit: {
                                                        ...prevState.materialEdit,
                                                        origin: e.target.value
                                                    }
                                                }))}
                                                className={this.state.errors.origin ? 'p-invalid' : ''}
                                            />
                                            <label htmlhtmlFor="origin">{this.state.t('projectList.material.origin')}</label>
                                            {this.state.errors.origin !== '' ? <small id="username2-help"
                                                className="p-error p-d-block">{this.state.errors.origin}</small> : <></>}
                                        </span>
                                        <span className="p-float-label p-field p-col-12">
                                            <Calendar id="horaire"
                                                stepMinute={15}
                                                minDate={new Date()}
                                                value={this.state.collectDate}
                                                onChange={(e) => this.setState({ collectDate: e.target.value })}
                                                dateFormat="dd/mm/yy"
                                                showTime hourFormat="24" />
                                            <label htmlhtmlFor="horaire">{this.state.t('projectList.place.hourly')}</label>
                                        </span>
                                        <span className="p-float-label p-field p-col-12">
                                            <Dropdown value={this.state.materialEdit.assignedToId}
                                                options={this.state.members}
                                                onChange={(e) => this.setState(prevState => ({
                                                    materialEdit: {
                                                        ...prevState.materialEdit,
                                                        assignedToId: e.target.value
                                                    }
                                                }))}
                                                placeholder={this.state.t('projectList.material.assignedTo')}
                                                optionLabel="email"
                                                optionValue="memberId"
                                                className={this.state.errors.assignedToId ? 'p-invalid' : ''}
                                            />
                                        </span>
                                        {this.state.errors.assignedToId !== '' ? <small id="username2-help"
                                            className="p-error p-d-block">{this.state.errors.assignedToId}</small> : <></>}
                                    </div>
                                }
                            </Dialog>

                            {/* Popup pour supprimer UN équipement */}
                            <Dialog visible={this.state.deleteMaterialDialog}
                                    style={{ width: '450px' }}
                                    header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                                    modal
                                    footer={deleteMaterialDialogFooter}
                                    onHide={this.hideDialog}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle p-mr-3"
                                        style={{ fontSize: '2rem' }} />
                                    {this.state.materialDeleted &&
                                        <span>{this.state.t('projectList.material.deleteMaterial')}</span>}
                                </div>
                            </Dialog>

                            {/* Popup pour supprimer TOUS les équipements */}
                            <Dialog visible={this.state.deleteMaterialsDialog}
                                style={{ width: '450px' }}
                                header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                                modal
                                footer={deleteMaterialsDialogFooter}
                                onHide={this.hideDialog}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle p-mr-3"
                                        style={{ fontSize: '2rem' }} />
                                    <span>{this.state.t('projectList.material.deleteAll')}</span>
                                </div>
                            </Dialog>

                        </div>
                    </div>
                </div> :
                    <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ height: '60vh' }}>
                        <ProgressSpinner className="p-progress-spinner" />
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { material, project } = state
    const { members, fetched } = state.members
    return { material, project, members, fetched }
}

const actionMaterials = {
    getProject: projectActions.getProject,
    addMaterials: materialAction.addMaterials,
    getAllMaterials: materialAction.getAllMaterials,
    deleteMaterial: materialAction.deleteMaterial,
    updateMaterial: materialAction.updateMaterial,
    validateMaterial: materialAction.validateMaterial,
    rejectMaterial: materialAction.rejectMaterial,
    draftMaterial: materialAction.draftMaterial,
    deleteAllMaterial: materialAction.deleteAllMaterial,
    getMembers: membersAction.getMembers,
}

const connectedMaterialListing = connect(mapStateToProps, actionMaterials)(withTranslation()(ProjectMaterialsScreen))
export { connectedMaterialListing as ProjectMaterialsScreen }
