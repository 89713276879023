import { commonService } from './common.service';
export const tasksService = {
    getTasks,
    addTasks,
    updateTask,
    changeStatusTask,
    commentsTask,
    addChecklist,
    validateChecklist
}

function getTasks (projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token },

    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

 

function addTasks(projectId, task){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(task)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function updateTask(projectId, taskId, task){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(task)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks/${taskId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function changeStatusTask(projectId, taskId, status){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks/${taskId}/status/${status}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function commentsTask(projectId, taskId, comment){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(comment)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks/${taskId}/comments`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function addChecklist(projectId, taskId, checklist){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(checklist)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks/${taskId}/checklist`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function validateChecklist(projectId, taskId, itemId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/tasks/${taskId}/checklist/${itemId}/validate`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
