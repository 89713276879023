import React, { Component } from 'react';
import { Dialog } from "primereact/dialog";
import { Carousel } from "primereact/carousel";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LazyImage } from "../../common/LazyImage";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { alertActions, catalogAction, productsActions } from "../../../redux/actions";
import { CustomAutoComplete } from "../../common/CustomAutoComplete";
import { withTranslation } from 'react-i18next';

class NewLookDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			searchProduct: '',
			displayedMedias: [],
			mainProduct: {},
			selectedMedias: [],
			product: [],
			displayedProducts: [],
		}
		this.linkingProduct = this.linkingProduct.bind(this)
		this.changeCarousel = this.changeCarousel.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		if (props.isProductLinkedFetched && props.product.products !== state.product) {
			return {
				product: props.product.products,
				mainProduct: props.product.mainProduct,
			};
		}

		if (props.displayedMedias && props.selectedMedias && props.displayedProducts && props.displayedMedias
			&& props.projectId && state.selectedMedias.length === 0) {
			return {
				displayedMedias: props.displayedMedias,
				selectedMedias: props.selectedMedias,
				displayedProducts: props.displayedProducts,
				projectId: props.projectId,
			}
		}

		if (props.productOpened && props.mainProduct && state.product.length === 0 && !props.isProductLinkedFetched) {
			return {
				product: props.productOpened,
				mainProduct: props.mainProduct,
			}
		}

		if (state.searchProduct === "") {
			return { displayedProducts: [] }
		}

		return state
	}

	productTemplate(prod) {
		return (
			<img
				src={`data:image/jpeg;base64,${prod.thumbnail}`}
				alt={prod.title}
				style={{ objectFit: "cover", width: "auto", maxHeight: "350px", textAlign: "center" }}
			/>
		);
	}

	lookTemplate(rowData) {
		return (
			<ToggleButton
				checked={rowData.isVisible}
				onChange={(e) => { this.props.linkedChange(e.value, rowData, "visible"); }}
				onIcon="icon-visibility icon-3x"
				offIcon="icon-visiblity_off icon-3x"
				className="visibility-button"
				onLabel=""
				offLabel=""
			/>
		);
	}

	deleteProductList(product) {
		return (
			<Button
				icon="icon-Close"
				className="visibility-button"
				onClick={() => this.deleteProductFromList(product)}
			/>
		);
	}

	deleteProductFromList(product) {
		let list = this.state.product;
		let index = list.findIndex((prod) => prod.id === product.id);
		if (index !== -1) {
			list.splice(index, 1);
			this.setState({ product: list });
		}
	}
	setSelectedProduct(value) {
		let selectedProducts = this.state.product;
		let check = selectedProducts.some((prod) => value.id === prod.id);
		if (!check) {
			value.isVisible = true;
			selectedProducts.push(value);
			this.setState({ product: selectedProducts });
		} else {
			this.props.alertWarning(this.props.t('toast.productOn'));
		}
		this.setState({ selectedProducts: selectedProducts, inputSearch: '', searchProduct: '' })
	}
	changeCarousel(index) {
		let { displayedMedias } = this.state;
		let newSelectMedia = [];

		if (index >= 0 && displayedMedias[index].id !== this.state.selectedMedias[0].id) {
			newSelectMedia.push(displayedMedias[index]);
			this.setState({
				selectedMedias: newSelectMedia,
				searchProduct: '',
				displayedProducts: []
			}, () => this.props.getProductLinked(this.state.projectId, newSelectMedia[0].productId, newSelectMedia[0].id));
		}
	}

	linkingProduct() {
		const { selectedMedias } = this.state;
		let table = [];
		if (this.state.mainProduct.isVisible) {
			table.push(this.state.mainProduct.id);
		}
		this.state.product.map((prod) => {
			if (prod.isVisible && prod.id !== this.state.mainProduct.id)
				table.push(prod.id);
		});

		this.props.linkProduct(this.state.projectId, selectedMedias[0].productId, selectedMedias[0].id, table);
	}

	imageTemplate(rowData) {
		if (rowData.properties && rowData.properties.length > 0) {
			return (
				<div data-tip data-for={`tooltip-${rowData.reference}`}>
					<LazyImage
						key={rowData.id}
						src={rowData.thumbnail}
						alt={rowData.product}
						className="product-image"
					/>
					<ReactTooltip id={`tooltip-${rowData.reference}`} place="right" type="dark" effect="float">
						{rowData.properties.map(item => <p>{item.name} : {item.value}</p>)}
					</ReactTooltip>
				</div>
			);
		} else {
			return (<LazyImage
				key={rowData.id}
				src={rowData.thumbnail}
				alt={rowData.product}
				className="product-image"
			/>)
		}
	}

	onHide = () => {
		this.setState({
			searchProduct: '',
			displayedMedias: [],
			mainProduct: {},
			selectedMedias: [],
			product: [],
			displayedProducts: []
		})

		this.props.onHide()
	}

	render() {
		let footerLinkPopup = (
			<>
				<Divider className="p-mb-4" />
				<Button
					label={this.props.t('common.cancel')}
					className="cancelButton"
					onClick={() => this.onHide()}
				/>
				<Button
					label={this.props.t('common.valid')}
					className="validationButton"
					onClick={this.linkingProduct}
					disabled={this.props.isProductLinkedFecthing}
				/>
			</>
		);

		return (
			<Dialog
				modal={false}
				visible={this.props.visible}
				closable={true}
				header={this.props.t('session.createLook')}
				footer={footerLinkPopup}
				onHide={() => this.onHide()}
				style={{ width: "90vw", maxWidth: "1923px" }}
			>
				<div className="p-grid">
					<div className="p-col-6">
						<Carousel
							value={this.state.displayedMedias}
							itemTemplate={this.productTemplate}
							page={this.state.displayedMedias.indexOf(this.state.selectedMedias[0])}
							onPageChange={(event) => this.changeCarousel(event.page)}
							onPage={(event) => console.log("test")}
							className="select-carousel"
						/>
					</div>
					<div className="p-col-6">
						<div className="p-d-flex">
							{!this.props.isProductLinkedFecthing && <Panel
								className="p-my-3 rounded-content panel-sm"
								style={{ width: "100%", maxWidth: "none" }}
							>
								<div className="p-d-flex p-ai-center">
									<div className="p-col-1">
										<LazyImage
											key={this.state.mainProduct.id}
											src={this.state.mainProduct.thumbnail}
											alt={this.state.mainProduct.product}
											className="product-image"
											style={{ height: "50px", width: "auto" }}
										/>
									</div>

									<div className="p-col-3 ">
										{this.state.mainProduct.reference}
									</div>
									<div className="p-col-3 ">
										{this.state.mainProduct.product}
									</div>
									<div className="p-col-1 p-jc-end">
										{this.lookTemplate(this.state.mainProduct)}
									</div>
								</div>
							</Panel>}
						</div>
						<div className="p-input-icon-left" style={{ width: "100%", maxWidth: "none" }}
						>
							<i className="pi pi-search" />
							<CustomAutoComplete
								placeholder={this.props.t('session.dialog.search')}
								value={this.state.searchProduct}
								onChange={(e) => this.setState({ searchProduct: e })}
								onSelect={(e) => {
									this.setSelectedProduct(e)
								}}
								style={{ width: "100%", maxWidth: "none" }}
							/>

						</div>

						<DataTable
							value={this.state.product.filter(p => p.id != this.state.mainProduct.id)}
							rows={3}
							paginator
							className="p-mt-3"
							loading={this.props.isProductLinkedFecthing}
						>
							<Column header="Image" body={this.imageTemplate} />
							<Column field="product" header={this.props.t('common.name')} sortable />
							<Column field="reference" header={this.props.t('catalog.columns.reference')} sortable />
							<Column header={this.props.t('catalog.columns.look')} body={(rowData) => this.lookTemplate(rowData)} />
							<Column
								body={(rowData) => this.deleteProductList(rowData)}
								headerStyle={{ width: "8em", textAlign: "center" }}
								bodyStyle={{ textAlign: "center", overflow: "visible" }}
							/>
						</DataTable>
					</div>
				</div>
			</Dialog>
		);
	}
}

function mapState(state) {
	const { isProductLinkedFetched, product, isProductLinkedFecthing } = state.products
	const { isSearched, type } = state.catalog
	return { isSearched, type, isProductLinkedFetched, product, isProductLinkedFecthing }
}

const actionSession = {
	getProductLinked: productsActions.getProductLinked,
	linkProduct: productsActions.linkProduct,
	alertSuccess: alertActions.success,
	alertWarning: alertActions.warning,
	findCatalog: catalogAction.findCatalog,
}

const connectedSessionScreen = connect(mapState, actionSession)(withTranslation()(NewLookDialog))
export { connectedSessionScreen as NewLookDialog };
