import React, {Component} from 'react';
import Header from '../common/Header'
import {connect} from 'react-redux';
import {userActions, alertActions} from '../../redux/actions'
import {ScrollPanel} from 'primereact/scrollpanel'
import {Panel} from 'primereact/panel'
import {ProgressSpinner} from 'primereact/progressspinner'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {InputText} from 'primereact/inputtext';
import Dropzone from "react-dropzone";
import {DownloadCloudButton} from "../common/SVGButtons";
import {emailRegex} from '../common/Regex'
import { withTranslation } from 'react-i18next';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            isFetched: false,
            showEditProfileDialog: false,
            uploadDocument: [],
            errors: {},
        }
    }

    componentDidMount = () => {
        this.props.getUser()
    }

    static getDerivedStateFromProps(props, state) {
            if (props.user.isFetched) {
            return {
                user: props.user.user,
                isFetched: props.user.isFetched
            }
        }
        return state
    }

    showEditProfileDialog = () => {
        let userEdit = {...this.state.user}
        this.setState({
            showEditProfileDialog: true,
            userEdit: userEdit
        })
    }

    onHide = () => {
        this.setState({
            showEditProfileDialog: false
        })
    }

    isHeroEqual(object1, object2) {
        return object1.name !== object2.name || object1.email !== object2.email;
    }


    validateClicked = () => {
        let {userEdit, uploadDocument, user} = this.state
        let newUser = []
        if (this.isHeroEqual(user, userEdit)) {
            if (emailRegex.test(userEdit.email)) {
                newUser = [userEdit]
                if (uploadDocument.length > 0) {
                    newUser.push(uploadDocument)
                }
                this.props.updateUser(newUser)
                this.onHide()
            } else {
                this.props.alertWarning(this.props.t('toast.invalidEmail'))
            }
        } else {
            this.props.alertWarning(this.props.t('toast.noModify'))
        }

    }

    onDrop = (file) => {
        let errors = this.state.errors
        if (file.length > 0) {
            errors['uploadDocument'] = "";
            let doc = [];
            let reader = new FileReader();
            reader.onload = (file => {
                return () => {
                    // let data = reader.result;
                    doc.push({name: file.name, document: file});
                    this.setState({uploadDocument: doc})
                }
            })(file[0]);
            reader.readAsDataURL(file[0]);
        } else {
            errors['uploadDocument'] = this.props.t('common.pdfAllowed')
        }
        this.setState({errors: errors})
    };

    render() {
        const {t} = this.props;

        const editProfileFooter = (<div>
                <Button className="p-button-rounded p-button-outlined" onClick={() => this.onHide()}>{t('common.cancel')}</Button>
                <Button className="p-button-rounded p-ml-3" onClick={() => this.validateClicked()}>{t('common.valid')}</Button>
            </div>
        )

        return (
            <div>
                <Header/>
                <ScrollPanel className="sf-scrollpanel">
                    {this.state.isFetched ?
                        <div className="p-col" style={{width: '50%'}}>
                            <div className="p-d-flex p-ai-center p-mt-6 p-mb-3 p-jc-between">
                                <h5>{t('parameter.myProfile')}</h5>

                                <Button className="p-button-rounded" label={t('common.modify')}
                                        onClick={() => this.showEditProfileDialog()}/>
                            </div>
                            <Panel className="p-my-3 rounded-content">
                                <div className="p-d-flex">
                                    <div className="col-3">{t('parameter.name')}</div>
                                    <div className="col-7 p-offset-2">{this.state.user.name}</div>
                                </div>
                            </Panel>
                            <Panel className="p-my-3 rounded-content">
                                <div className="p-d-flex">
                                    <div className="col-3">{t('parameter.email')}</div>
                                    <div className="col-7 p-offset-2">{this.state.user.email}</div>
                                </div>
                            </Panel>
                            <Panel className="p-my-3 rounded-content">
                                <div className="p-d-flex">
                                    <div className="col-3">{t('parameter.profilePicture')}</div>
                                    <div className="col-7 p-offset-2">
                                        <img src={this.state.user.profilePicture ? 'data:image/png;base64,' + this.state.user.profilePicture : '/Symbol.svg'}
                                             alt={t('parameter.alt') + this.state.user.name}
                                             style={{width: "100%", height: '100%'}}
                                        />
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        :
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "60vh"}}>
                            <ProgressSpinner className="p-progress-spinner"/>
                        </div>
                    }

                    <Dialog position='center' header={t('parameter.updateProfile')}
                            visible={this.state.showEditProfileDialog}
                            style={{width: '30vw'}}
                            onHide={() => this.onHide()}
                            footer={editProfileFooter}
                    >
                        <div className="p-d-flex p-flex-column p-jc-center p-pt-3">
                            {this.state.userEdit &&
                            <div className=" p-fluid">
                                <div className="p-field p-col-12">
                                    <InputText value={this.state.userEdit.name} type="text"
                                               placeholder={t('common.name')}
                                               onChange={(e) => {
                                                   let user = this.state.userEdit;
                                                   user.name = e.target.value;
                                                   this.setState({userEdit: user})
                                               }}/>
                                </div>
                                <div className="p-field p-col-12">
                                    <InputText value={this.state.userEdit.email} type="text"
                                               placeholder={t('common.email')}
                                               onChange={(e) => {
                                                   let user = this.state.userEdit;
                                                   user.email = e.target.value;
                                                   this.setState({userEdit: user})
                                               }}/>
                                </div>
                                <div className="p-d-flex p-flex-column p-jc-center p-ai-center dropzone-file">
                                    <Dropzone onDrop={this.onDrop} multiple={false} accept="image/jpeg, image/png"
                                              required>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <DownloadCloudButton title={t('parameter.dragDropPicture')}/>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {this.state.uploadDocument.length > 0 &&
                                    <ul className="list-unstyled">
                                        <li>
                                            {this.state.uploadDocument[0].name}
                                        </li>
                                    </ul>
                                    }
                                </div>

                            </div>
                            }
                        </div>
                    </Dialog>
                </ScrollPanel>
            </div>
        );
    }
}

function mapState(state) {
    const {user} = state;
    return {user};
}

const actionProfile = {
    getUser: userActions.getUser,
    updateUser: userActions.updateUserProfile,
    alertWarning: alertActions.warning,
};

const connectedProfile = connect(mapState, actionProfile)(withTranslation()(UserProfile));
export {connectedProfile as UserProfile};
