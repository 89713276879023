import React, { Component } from 'react';
import ProjectCard from '../card/ProjectCard';
import { productsActions, projectActions } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { history } from '../../../redux/helpers';
import './ProjectListing.scss'
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectListing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			projects: [],
			isFetched: false,
			t: this.props.t
		}
		this.deleteProject = this.deleteProject.bind(this)
	}

	componentDidMount() {
		this.props.getProjects();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.isDeleteProjectFetched) {
			this.props.getProjects();
			this.setState({ loading: true });
		}
	}
	static getDerivedStateFromProps(props, state) {
		if (props.isFetched && props.projects) {
		 	localStorage.setItem('projects',JSON.stringify(props.projects))
			return {
				projects: props.projects,
				isFetched: props.isFetched,
				loading: false
			}
		}
		return state;
	}

	onButtonClick = () => {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user.definedStorageSettings) {
			history.push('/new-project')
		} else {
			history.push('/dropbox-setting')
		}

	}
	deleteProject(id) {
		this.props.deleteProject(id);
	}

	render() {
		return (
			<>
				<Button icon="pi pi-plus p-p-1" className="addCampaignButton"
					tooltip={this.state.t('dashboardScreen.newProject')}
					tooltipOptions={{ position: 'top' }}
					onClick={() => history.push('/new-project')} />
				{!this.state.loading ?
					this.state.projects && this.state.projects.length > 0 ?

						<div className="p-d-flex p-flex-wrap p-jc-start"	>
							{
								this.state.projects.map((project, index) => {
									return <>
										<div key={index} className="p-mx-md-auto p-mx-sm-auto p-mx-xl-3 p-mx-lg-3  p-pt-4">
											<ProjectCard project={project}
												deleteProject={(id) => this.deleteProject(id)} />
										</div>
									</>
								})

							}

						</div> :
						<div className="p-col-12 p-md-4 p-lg-2 p-pt-6">
							<div className="p-d-flex p-flex-column p-jc-center p-ai-center new-project-container"
								onClick={this.onButtonClick}>
								<input type="button" className="new-project-button" style={{ borderRadius: "50px" }}
									value={this.state.t('dashboardScreen.newProject')} />
							</div>
						</div>
					:
					<div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ height: "60vh" }}>
						<ProgressSpinner className="p-progress-spinner" />
					</div>
				}
			</>
		);
	}
}


function mapState(state) {
	const { authentication } = state;
	const { projects, isFetched, isDeleteProjectFetched } = state.project;
	const { user } = authentication;

	return { projects, isFetched, isDeleteProjectFetched };
}

const actionCreators = {
	getProjects: projectActions.getAllProjects,
	deleteProject: projectActions.deleteProject,
	images: productsActions.getReference,
}

const connectedApp = connect(mapState, actionCreators)(withTranslation()(ProjectListing));
export { connectedApp as ProjectListing };
