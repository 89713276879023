import { settingConstants } from '../constants';

export function company(state = {}, action){
    switch(action.type){
        case settingConstants.ADD_COMPANY_REQUEST:
            return Object.assign({}, {isFetching: true});
        case settingConstants.ADD_COMPANY_SUCCESS:
            return Object.assign({}, {add: true, isFetched: true});
        case settingConstants.ADD_COMPANY_FAILURE:
            return {}

        case settingConstants.GET_COMPANY_REQUEST:
            return Object.assign({}, {isFetching: true});
        case settingConstants.GET_COMPANY_SUCCESS:
            return Object.assign({}, {settings: action.company, isFetched : true});
        case settingConstants.GET_COMPANY_FAILURE:
            return {}

        case settingConstants.GET_KEY_REQUEST:
            return Object.assign({}, {isFetching: true});
        case settingConstants.GET_KEY_SUCCESS:
            return Object.assign({}, {keys: action.key, isFetched : true});
        case settingConstants.GET_KEY_FAILURE:
            return {}

        case settingConstants.GET_NOTIFICATION_SETTING_REQUEST:
            return Object.assign({}, {isFetching: true});
        case settingConstants.GET_NOTIFICATION_SETTING_SUCCESS:
            return Object.assign({}, {settings: action.settings, isSettingFetched : true});
        case settingConstants.GET_NOTIFICATION_SETTING_FAILURE:
            return {}

        case settingConstants.GET_NOTIFICATION_TYPE_REQUEST:
            return Object.assign({}, {isFetching: true});
        case settingConstants.GET_NOTIFICATION_TYPE_SUCCESS:
            return Object.assign({}, {types: action.types, isTypeFetched : true});
        case settingConstants.GET_NOTIFICATION_TYPE_FAILURE:
            return {}

        case settingConstants.ADD_NOTIFICATION_SETTING_REQUEST:
            return Object.assign({}, {isFetchingAdd: true});
        case settingConstants.ADD_NOTIFICATION_SETTING_SUCCESS:
            return Object.assign({}, {settings: action.settings, isFetchingAdd : false});
        case settingConstants.ADD_NOTIFICATION_SETTING_FAILURE:
            return Object.assign({}, {isFetchingAdd: false});


        default:
            return state
    }
}
