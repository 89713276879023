import { commonService } from './common.service';
export const userService = {
    login,
    logout,
    socialLogin,
    register,
    forgetPassword,
    resetPassword,
    changePassword,
    updateProfile,
    getUser,
    confirmEmail,
    createPassword
};

function login(username, password) {
    let email = username
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/login`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}
 
function socialLogin(provider, accessToken) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({provider, accessToken})
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/social`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function forgetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(email)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/forgot-password`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function resetPassword(password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            "password": password
        })
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/reset-password`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function changePassword(user) {
    let obj = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + obj.auth.auth_token
        },
        body: JSON.stringify({
            "currentPassword": user.oldPassword,
            "newPassword": user.newPassword
        })
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/reset-password`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
  // let hub=JSON.stringify(localStorage.getItem('hub'));
     
  //   hub.stop();
   // localStorage.removeItem('hub');

}

function getUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/profile`, requestOptions).then(commonService.handleResponse.bind(this));
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(user)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/register`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}


 

function updateProfile(field) {
    let user = JSON.parse(localStorage.getItem('user'));

    let formdata = new FormData();
    if(field[1]){
        formdata.append("file", field[1][0].document, field[1][0].name);
    }
    formdata.append("member",JSON.stringify(field[0]));

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: formdata
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/profile`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(usr => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user["profile"] = usr
            localStorage.setItem('user', JSON.stringify(user));
            return usr;
        });
}

function confirmEmail(email, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/confirmEmail?email=${email}&token=${token}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function createPassword(password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(password)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/authentication/create-password`, requestOptions)
        .then(commonService.handleResponse.bind(this))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}
