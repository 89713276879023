import { commonService } from './common.service';

export const projectService = {
  shootingTypes,
  submitStepOne,
  uploadfile,
  memberRoles,
  memberAdd,
  getAllProjects,
  getProject,
  updateProject,
  getProjectFiles,
  getFileCategories,
  addDocument,
  deleteDocument,
  replaceDocument,
  getFile,
  downloadFile,
  addComment,
  deleteMember,
  validateComment,
  getProjectStatistics,
  createFolderRaw,
  deleteProject
}
function deleteProject(projectId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    },
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function createFolderRaw(projectId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    },
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/addRaw`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}
function shootingTypes() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/GetShootingTypes`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function memberRoles() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/Roles/GetRoles`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function memberAdd(members) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    },
    body: JSON.stringify(members)
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/InviteMembers`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}


function uploadfile(projectCreated, project) {
  let user = JSON.parse(localStorage.getItem('user'));
  let formdata = new FormData();
  formdata.append("file", project.uploadDocument[0].document, project.uploadDocument[0].name);
  const requestOptionsFile = {
    method: 'POST',
    body: formdata,
    headers: {
      'Authorization': 'Bearer ' + user.auth.auth_token,
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectCreated.projectId}/files/add-to-project/1`, requestOptionsFile)
    .then(commonService.handleResponse.bind(this))
}

function submitStepOne(project) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(project),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getAllProjects() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getProject(projectId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function updateProject(project) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(project),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${project.projectId}`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getProjectFiles(projectId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getFileCategories() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/files/file-categories`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function addDocument(projectId, document, categoryId) {
  let user = JSON.parse(localStorage.getItem('user'));
  let formdata = new FormData();
  formdata.append("file", document);
  const requestOptionsFile = {
    method: 'POST',
    body: formdata,
    headers: {
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/add-to-project/${categoryId}`, requestOptionsFile)
    .then(commonService.handleResponse.bind(this))
}

function deleteDocument(projectId, documentId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptionsFile = {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/${documentId}`, requestOptionsFile)
    .then(commonService.handleResponse.bind(this))
}

function replaceDocument(projectId, documentId, categoryId, document) {
  let user = JSON.parse(localStorage.getItem('user'));
  let formdata = new FormData();
  formdata.append("file", document);
  const requestOptionsFile = {
    method: 'POST',
    body: formdata,
    headers: {
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/${categoryId}/replace/${documentId}`, requestOptionsFile)
    .then(commonService.handleResponse.bind(this))
}

function getFile(projectId, fileId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/${fileId}`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function downloadFile(projectId, fileId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/${fileId}/download`, requestOptions)
    .then(commonService.handleFileResponse.bind(this))
}

function addComment(projectId, fileId, comment, pointId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
    body: JSON.stringify({
      "comment": comment,
      "pointId": pointId
    })
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/${fileId}/comment`, requestOptions)
    .then(commonService.handleResponse.bind(this))

}
function validateComment(projectId, commentId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/files/comments/${commentId}/validate`, requestOptions)
    .then(commonService.handleResponse.bind(this))

}

function deleteMember(projectId, memberId) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptionsFile = {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/members/${memberId}`, requestOptionsFile)
    .then(commonService.handleResponse.bind(this))
}

function getProjectStatistics() {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    }
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/statistics`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}