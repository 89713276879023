export const settingConstants = {
    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

    ADD_COMPANY_REQUEST: 'ADD_COMPANY_REQUEST',
    ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
    ADD_COMPANY_FAILURE: 'ADD_COMPANY_FAILURE',

    GET_KEY_REQUEST: 'GET_KEY_REQUEST',
    GET_KEY_SUCCESS: 'GET_KEY_SUCCESS',
    GET_KEY_FAILURE: 'GET_KEY_FAILURE',

    GET_NOTIFICATION_SETTING_REQUEST: 'GET_NOTIFICATION_SETTING_REQUEST',
    GET_NOTIFICATION_SETTING_SUCCESS: 'GET_NOTIFICATION_SETTING_SUCCESS',
    GET_NOTIFICATION_SETTING_FAILURE: 'GET_NOTIFICATION_SETTING_FAILURE',

    GET_NOTIFICATION_TYPE_REQUEST: 'GET_NOTIFICATION_TYPE_REQUEST',
    GET_NOTIFICATION_TYPE_SUCCESS: 'GET_NOTIFICATION_TYPE_SUCCESS',
    GET_NOTIFICATION_TYPE_FAILURE: 'GET_NOTIFICATION_TYPE_FAILURE',

    ADD_NOTIFICATION_SETTING_REQUEST: 'ADD_NOTIFICATION_SETTING_REQUEST',
    ADD_NOTIFICATION_SETTING_SUCCESS: 'ADD_NOTIFICATION_SETTING_SUCCESS',
    ADD_NOTIFICATION_SETTING_FAILURE: 'ADD_NOTIFICATION_SETTING_FAILURE'
}