export const callsheetConstants = {
    CALLSHEET_ADD_REQUEST: 'CALLSHEET_ADD_REQUEST',
    CALLSHEET_ADD_SUCCESS: 'CALLSHEET_ADD_SUCCESS',
    CALLSHEET_ADD_FAILURE: 'CALLSHEET_ADD_FAILURE',

    GET_CALLSHEET_REQUEST: 'GET_CALLSHEET_REQUEST',
    GET_CALLSHEET_SUCCESS: 'GET_CALLSHEET_SUCCESS',
    GET_CALLSHEET_FAILURE: 'GET_CALLSHEET_FAILURE',

    DELETE_CALLSHEET_REQUEST: 'DELETE_CALLSHEET_REQUEST',
    DELETE_CALLSHEET_SUCCESS: 'DELETE_CALLSHEET_SUCCESS',
    DELETE_CALLSHEET_FAILURE: 'DELETE_CALLSHEET_FAILURE',

    UPDATE_CALLSHEET_REQUEST: 'UPDATE_CALLSHEET_REQUEST',
    UPDATE_CALLSHEET_SUCCESS: 'UPDATE_CALLSHEET_SUCCESS',
    UPDATE_CALLSHEET_FAILURE: 'UPDATE_CALLSHEET_FAILURE',

    DOWNLOAD_CALLSHEET_REQUEST : 'DOWNLOAD_CALLSHEET_REQUEST',
    DOWNLOAD_CALLSHEET_SUCCESS : 'DOWNLOAD_CALLSHEET_SUCCESS',
    DOWNLOAD_CALLSHEET_FAILURE : 'DOWNLOAD_CALLSHEET_FAILURE'
}