import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import PrivateRoute from './components/common/PrivateRoute';
import './index.css';
import { App } from './App';
import { LoginScreen } from './components/authentication/LoginScreen'
import { RegisterScreen } from './components/authentication/RegisterScreen'
import { InvitationScreen } from './components/authentication/InvitationScreen'
import { ForgotPassword } from './components/authentication/ForgotPassword'
import { DropboxSettingScreen } from './components/dropbox/DropboxSettingScreen'
import Dashboard from './components/dashboard/DashboardScreen'
import { CreateProjectScreen } from './components/project/createProject/createProjectScreen'
import { ProjectDetailsScreen } from './components/project/projectDetails/ProjectDetailsScreen'
import { ProjectFilesScreen } from './components/project/projectFiles/projectFilesScreen'
import { ProjectCallsheetScreen } from './components/project/projectCallsheet/ProjectCallsheetScreen'
import { ProjectContributorsScreen } from './components/project/projectLists/ProjectContributorsScreen'
import { ProjectPlacesScreen } from './components/project/projectLists/ProjectPlacesScreen'
import { ProjectMaterialsScreen } from './components/project/projectLists/ProjectMaterialsScreen'
import { ProjectProductsScreen } from './components/project/projectLists/ProjectProductsScreen'
import { FileDetailsScreen } from './components/files/FileDetailsScreen'
import { SessionScreen } from './components/session/SessionScreen'
import { SessionOverview } from './components/session-details/SessionOverview'
import { SessionComments } from './components/session/SessionComments'
import { SessionMeta } from './components/session-details/SessionMeta'
import { SessionHistory } from './components/session-details/SessionHistory'
import { SessionLinkedProducts } from './components/session-details/SessionLinkedProducts'
import { SearchScreen } from "./components/search/searchScreen";
import { ProjectTasks } from './components/task/Tasks'
import { ProjectRetouch } from './components/task/Retouch'
import { CatalogScreen } from './components/catalog/CatalogScreen'
import { store, history } from './redux/helpers/store';
import reportWebVitals from './reportWebVitals';
import {UserProfile} from './components/profile/UserProfile'
import * as serviceWorker from './serviceWorker';
import './i18n'; 
import { NotificationScreen } from './components/notification/NotificationScreen'
import { NotificationHistory } from './components/common/NotificationHistory';
import {Notifications} from'./components/common/Notifications'
const routing = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute
          exact path="/"
          component={(props) => (
            <App><Dashboard /></App>
          )}
        />
        <PrivateRoute
          exact path="/catalog"
          render={(props) => <App><CatalogScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/my-account"
          render={(props) => <App><UserProfile {...props} /></App>}
        />
        <PrivateRoute
          exact path="/notification-settings"
          render={(props) => <App><NotificationScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/notifications"
          render={(props) => <App><Notifications {...props} /></App>}
        /> 
        <PrivateRoute
          exact path="/notification-history"
          render={(props) => <App><NotificationHistory {...props} /></App>}
        />
        <PrivateRoute
          exact path="/new-project"
          render={(props) => <App><CreateProjectScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId"
          render={(props) => <App><ProjectDetailsScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/files"
          render={(props) => <App><ProjectFilesScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/files/:fileId"
          render={(props) => <App><FileDetailsScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/lists/contributors"
          render={(props) => <App><ProjectContributorsScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/lists/places"
          render={(props) => <App><ProjectPlacesScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/lists/products"
          render={(props) => <App><ProjectProductsScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/lists/materials"
          render={(props) => <App><ProjectMaterialsScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/callsheet"
          render={(props) => <App><ProjectCallsheetScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session"
          render={(props) => <App><SessionScreen {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/tasks"
          render={(props) => <App><ProjectTasks {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/retouch"
          render={(props) => <App><ProjectRetouch {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session/products/:productId/images/:imageId/overview"
          render={(props) => <App><SessionOverview {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session/products/:productId/images/:imageId/comments"
          render={(props) => <App><SessionComments {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session/products/:productId/images/:imageId/meta"
          render={(props) => <App><SessionMeta {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session/products/:productId/images/:imageId/history"
          render={(props) => <App><SessionHistory {...props} /></App>}
        />
        <PrivateRoute
          exact path="/projects/:projectId/session/products/:productId/images/:imageId/linked-products"
          render={(props) => <App><SessionLinkedProducts {...props} /></App>}
        />

        <Route
          path="/login"
          render={() => <App><LoginScreen /></App>}
        />
               <Route
          path="/signup"
          render={() => <App><RegisterScreen /></App>}
        />
        <Route
          path="/create-password"
          render={(props) => <App><InvitationScreen {...props} /></App>}
        />
        <Route
          path='/dropbox-setting'
          render={() => <App><DropboxSettingScreen /></App>}
        />
        <Route
          path='/forgot-password'
          render={() => <App><ForgotPassword /></App>}
        />

        <Route
          path="/search/:search"
          render={(props) => <App><SearchScreen {...props} /></App>}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>

)

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div></div>}>
            {routing}
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
reportWebVitals();
