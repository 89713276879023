import { commonService } from './common.service';
export const settingService = {
    addCompany,
    getCompany,
    getKey,
    getNotificationSettings,
    getNotificationType,
    addNotificationSetting
}

function addCompany(setting){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.auth.auth_token},
        body: JSON.stringify(setting)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/company`, requestOptions)
    .then(commonService.handleResponse.bind(this))
    .then(company => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      user.definedStorageSettings = true
      localStorage.setItem('user', JSON.stringify(user));
      return company;
    });
}

 

function getCompany(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/company`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getKey(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        email: "hehacet424@mail3tech.com",
        password: "just-fordemo10",
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/keys`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}

function getNotificationSettings() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/notification-settings`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getNotificationType() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token},
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/notification-types`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function addNotificationSetting( setting ) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(setting)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/settings/notification-settings`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
