import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../redux/actions';
import { history } from '../../redux/helpers/store'
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class LoginScreen extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            email: "",
            password: "",
            hidden: true,
            errors: {
              email: '',
              password: '',
            },
            loading: false
          };
        this.props.logout();
    }

    static getDerivedStateFromProps (props, state){
        if(props.user.isLoging){
            return {loading: props.user.isLoging}
        }
        if(props.user.isFailed){
            return {loading: false}
        }
    }

    submitLogin = (event) => {
        event.preventDefault()
        this.props.login(this.state.email, this.state.password);
      }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault()
            this.props.login(this.state.email, this.state.password);
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div className="p-grid p-d-flex authentication-container p-jc-center p-ai-center">
                <div className="p-col-4 p-sm-12 p-md-6 p-lg-4">
                    <Panel header={t('authentication.common.login')} className="p-pt-4">

                        <p className="p-d-flex p-jc-center p-mb-6">{t('authentication.loginPage.question')}

                            <Link className="p-ml-1 link" to="/signup"> {t('authentication.common.register')}</Link>
                        </p>
                        <form className="p-fluid" onSubmit={this.submitLogin} onKeyPress={this.handleKeyPress}>
                            <div className="p-field">
                            <span className="p-float-label">
                                <InputText keyfilter="email" id="username" value={this.state.email}
                                           onChange={(e) => this.setState({ email: e.target.value })}/>
                                <label htmlFor="username">{t('common.email')}</label>
                            </span>
                            </div>
                            <div className="p-field">
                            <span className="p-float-label">
                                <Password feedback={false} id="password" value={this.state.password}
                                          onChange={(e) => this.setState({ password: e.target.value })}/>
                                <label htmlFor="password">{t('common.password')}</label>
                            </span>
                                <div className="p-d-flex p-jc-end">
                                    <Link className="p-mt-3 link" to="/forgot-password">{t('authentication.common.forgot')}</Link>
                                </div>
                            </div>
                            <Button loading={this.state.loading} type="submit" className="p-button-rounded" label={t('authentication.loginPage.bouton')}/>
                        </form>
                    </Panel>
                </div>
            </div>

        );
    }
}


function mapState(state) {
    const { authentication, user } = state;
    return { authentication, user };
  }

  const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
  };

const connectedLoginPage = (connect(mapState, actionCreators)(withTranslation()(LoginScreen)));
export { connectedLoginPage as LoginScreen };
