import React, {Component} from "react";
import Header from "../../common/Header";
import {ProjectListingsMenu, ProjectMenu} from "../common/ProjectMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
  alertActions,
  catalogAction,
  productsActions,
} from "../../../redux/actions";
import {connect} from "react-redux";
import {InputSwitch} from "primereact/inputswitch";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {DownloadCloudButton} from "../../common/SVGButtons";
import Dropzone from "react-dropzone";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { MultiSelect } from "primereact/multiselect";
import { Menubar } from "primereact/menubar";
import { Menu } from 'primereact/menu';
import ReactTooltip from 'react-tooltip';
import AddProduct from "./AddProduct";
import {LazyImage} from "../../common/LazyImage";
import {LinkedProductDialog} from "./LinkedProductDialog";
import {isTablet} from "react-device-detect";
import CustomBarcode from "../../catalog/CustomBarcode";
import {ReactComponent as LookIcon} from "../../../img/Action_Look.svg";
import {
  allQty,
  allQtyMaj,
  barCode,
  qtyPurchased,
  remainsDeliver,
} from "../../common/ConstanteCommon";
import {DialogImg} from "./DialogImg";
import {Paginator} from "primereact/paginator";
import {withTranslation} from "react-i18next";

class ProjectProductsScreen extends Component {
  constructor(props) {
    super(props);
     this.state = {
      t: this.props.t,
      catalog: [],
      displayedProducts: [],
      errors: {},
      isFetched: false,
      projectId: null,
      project:null,
      selectedDate: null,
      showCatalogDialog: false,
      showCatalogMetaDataDialog: false,
      addProductDialog: false,
      openDialogAssociated: false,
      openDialogCatalogDeleteAll: false,
      productId: null,
      loadingNewFile: false,
      loadingPrint: false,
      selectedProducts: [],
      columns: [
        // { header: 'Look', field: 'hasLook' },
        {header: this.props.t("projectList.shootingList.product")},
        {header: this.props.t("projectList.shootingList.barCode")},
        {
          field: "hasImages",
          header: this.props.t("projectList.shootingList.shoot"),
        },
        {
          field: "imagesCount",
          header: this.props.t("projectList.shootingList.image"),
        },
        {
          field: "availabilityDate",
          header: this.props.t("projectList.shootingList.availabilityDate"),
        },
        {
          field: "inventory",
          header: this.props.t("projectList.shootingList.inventory"),
        },
        {
          field: "properties",
          header: this.props.t("projectList.shootingList.quantity"),
        },
        {
          field: "commentInProject",
          header: this.props.t("projectList.shootingList.comment"),
        },
      ],
      boundColumns: false,
      selectedColumns: [],
      keyWord: "",
      referencingProductId: null,
      isCatalogFetching: false,
      loadingBarCode: false,
      openDialogImg: false,
      dialogImgPrd: {},
      currentPage: 1,
      perPage: 10,
      totalCount: 0,
      totalQueryCount: 0,
      sortField: null,
      sortOrder: 0,
      orderBy: 0,
      loadingTable: false,
      shootedProductsCount: 0,
    };
    this.ref = React.createRef();
    this.imageTemplate = this.imageTemplate.bind(this);
    this.shootedProductTemplate = this.shootedProductTemplate.bind(this);
    this.setShootedProduct = this.setShootedProduct.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.onHide = this.onHide.bind(this);
    this.deleteAllProducts = this.deleteAllProducts.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onSubmitFile = this.onSubmitFile.bind(this);
    this.showCatalogDialog = this.showCatalogDialog.bind(this);
    this.showCatalogMetaDataDialog = this.showCatalogMetaDataDialog.bind(this);
    this.submitDeleteAllProducts = this.submitDeleteAllProducts.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.addProductToCatalog = this.addProductToCatalog.bind(this);
    this.showAddProduct = this.showAddProduct.bind(this);
    this.printListShoot = this.printListShoot.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.barcodeTemplate = this.barcodeTemplate.bind(this);
    this.inventoryHistoryTemplate = this.inventoryHistoryTemplate.bind(this);
    this.qtyTemplate = this.qtyTemplate.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.searchKeyWord = this.searchKeyWord.bind(this);
    this.onLinkedProductDialogHide = this.onLinkedProductDialogHide.bind(this);
    this.updateProductLook = this.updateProductLook.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.projectId) {
      const projectId = this.props.match.params.projectId;
      let dtoShoot = {currentPage: 1, perPage: 10};
      this.searchProducts(projectId, dtoShoot);
      this.setState({
        projectId: projectId,
        displayedProducts: [],
      });
    }
  }

  handlePageChange(e) {
    let dtoShoot = {
      currentPage: e.page + 1,
      perPage: 10,
      keyWord: this.state.keyWord,
      orderBy: this.state.orderBy,
      orderType: this.state.sortOrder,
    };
    this.searchProducts(this.state.projectId, dtoShoot);
    this.setState({
      currentPage: e.page + 1,
      selectedProducts: [],
    });
  }
  searchKeyWord() {
    let dtoShoot = {currentPage: 1, perPage: 10, keyWord: this.state.keyWord};
    this.searchProducts(this.state.projectId, dtoShoot);
    this.setState({
      isFetched: false,
      catalog: [],
      currentPage: 1,
      displayedProducts: [],
    });
  }
  searchProducts(projectId, dtoShoot) {
    this.setState({loadingTable: true});
    this.props.getProducts(projectId, dtoShoot);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.state !== undefined) {
      if (
        props.location.state.project !== undefined &&
        props.location.state.project.projectId !== state.projectId
      ) {
        let dtoShoot = {currentPage: 1, perPage: 10};
        props.getProducts(props.location.state.project.projectId, dtoShoot);
        return {
          projectId: props.location.state.project.projectId,
          displayedProducts: [],
          loadingTable:true,
          project:props.location.state.project
        };
      }
    }
    if (props.catalog.isFetched && props.catalog.type) {
      let columns = state.columns;
      let displayedColumns =
        state.selectedColumns.length > 0
          ? state.selectedColumns
          : state.columns;

      if (!state.boundColumns) {
        if (props.catalog.type.result && props.catalog.type.result.length > 0) {
          let newColumns = [];
          let prd = props.catalog.type.result.filter(
            item => item.properties?.length > 0
          )[0];
          if (prd !== null && prd !== undefined) {
            newColumns = prd.properties?.map(k => {
              return {
                field: k.name,
                header: k.name[0].toUpperCase() + k.name.slice(1),
                body: item => {
                  let tab = item.properties?.filter(p => p.name === k.name);
                  if (tab !== undefined && tab.length > 0)
                    return <>{tab[0].value}</>;
                  else return <></>;
                },
              };
            });
            // newColumns.filter((col) => {
            //   return (col.field.indexOf(allQty) > 0 && col.field.indexOf(qtyPurchased) > 0 && col.field.indexOf(remainsDeliver) > 0)
            // })
            columns = columns.concat(newColumns);
            displayedColumns = displayedColumns.filter(col => {
              return (
                col.field != "reference" &&
                col.field != "inventory" &&
                col.field != "commentInProject"
                //  &&  !col.field.includes(allQty)  && !col.field.includes(qtyPurchased)   && !col.field.includes(remainsDeliver)
              );
            });
          }
        }
      }
      if (props.catalog.type && props.catalog.type.result.length > 0) {
        return {
          catalog: props.catalog.type.result,
          displayedProducts: props.catalog.type.result,
          columns: columns,
          selectedColumns: displayedColumns,
          isFetched: props.catalog.isFetched,
          loadingNewFile: false,
          boundColumns: true,
          loadingPrint: false,
          totalCount: props.catalog.type.totalCount,
          currentPage: props.catalog.type.currentPage,
          totalQueryCount: props.catalog.type.totalQueryCount,
          loadingTable: false,
          shootedProductsCount: props.catalog.type.shootedProductsCount,
        };
      } else {
        return {
          catalog: [],
          displayedProducts: [],
          selectedColumns: displayedColumns,
          boundColumns: true,
          columns: columns,
          loadingTable: false,
          isFetched: props.catalog.isFetched,
          currentPage: props.catalog.type.currentPage,
          totalCount: props.catalog.type.totalCount,
          totalQueryCount: props.catalog.type.totalQueryCount,
          shootedProductsCount: props.catalog.type.shootedProductsCount,
        };
      }
    }

    if (props.catalog.isCatalogFetched) {
      return {
        loadingNewFile: false,
      };
    }

    if (props.catalog.isCatalogFetching) {
      return {
        loadingNewFile: true,
      };
    }

    if (props.catalog.isPrintFetching) {
      return {
        loadingPrint: true,
      };
    }

    if (props.catalog.isPrintFetched) {
      return {
        loadingPrint: false,
      };
    }

    if (props.catalog.isBarcodeFetching) {
      return {
        loadingBarCode: true,
      };
    }

    if (props.catalog.isBarcodeFetched) {
      return {
        loadingBarCode: false,
      };
    }

    return state;
  }
  deleteAllProducts() {
    if (this.state.selectedProducts && this.state.selectedProducts.length > 0)
      this.setState({openDialogCatalogDeleteAll: true});
    else this.props.alertWarning(this.state.t("toast.noSelect"));
  }

  submitDeleteAllProducts() {
    let {projectId} = this.state;
    this.props.deleteCatalog(
      projectId,
      this.state.selectedProducts.map(p => p.id)
    );
    this.setState({openDialogCatalogDeleteAll: false});
    setTimeout(() => {
      this.props.getProducts(projectId);
    }, 1000);
  }

  showCatalogDialog() {
    this.setState({showCatalogDialog: true});
  }

  showCatalogMetaDataDialog() {
    this.setState({showCatalogMetaDataDialog: true});
  }

  setShootedProduct(rowData) {
    let {projectId, catalog} = this.state;
    let product = catalog.filter(p => p.id === rowData.id)[0];
    const index = catalog.indexOf(product);
    let newshootedCount = product.hasImages
      ? this.state.shootedProductsCount - 1
      : this.state.shootedProductsCount + 1;
    product.hasImages = !product.hasImages;
    this.setState(({catalog}) => {
      catalog: [
        ...catalog.slice(0, index),
        product,
        ...catalog.slice(index + 1),
      ];
    });

    this.setState({shootedProductsCount: newshootedCount});
    this.props.setShootedProduct(projectId, rowData.id);
  }

  exportCSV() {
    if (this.dt !== undefined) this.dt.exportCSV();
    else
      this.props.alertWarning(
        this.state.t("projectList.shootingList.exportEmptyList")
      );
  }

  onLinkedProductDialogHide(productId = 0, countLinkedProducts = 0) {
    if (countLinkedProducts > 0) {
      this.updateProductLook(productId, true);
    } else {
      if (productId > 0 && countLinkedProducts == 0) {
        this.updateProductLook(productId, false);
      }
    }
    this.setState({openDialogAssociated: false, referencingProductId: null});
  }
  onHide() {
    this.setState({
      showCatalogDialog: false,
      showCatalogMetaDataDialog: false,
      openDialogCatalogDeleteAll: false,
      uploadDocument: null,
      isCatalogFetched: false,
      globalFilter: "",
      addProductDialog: false,
      referencingProductId: null,
      openDialogImg: false,
      dialogImgPrd: {},
    });
  }
  updateProductLook(productId, hasLook) {
    let products = this.state.catalog;
    let product = products.filter(p => p.id === productId)[0];
    const index = products.indexOf(product);
    product.hasLook = hasLook;
    this.setState(({catalog}) => {
      catalog: [
        ...catalog.slice(0, index),
        product,
        ...catalog.slice(index + 1),
      ];
    });
  }
  /**
   * Data table templating
   */
  onDateChange(e) {
    this.dt.filter(e.value, "date", "custom");
    this.setState({selectedDate: e.value});
  }

  barcodeTemplate(data) {
    return <CustomBarcode value={data.barcode} key={data.id} />;
  }
  dateTemplate(rowData) {
    return (
      <>
        <span>{rowData.availabilityDate}</span>
      </>
    );
  }

  onDrop = file => {
    let errors = this.state.errors;
    if (file.length > 0) {
      errors["uploadDocument"] = "";
      let doc = [];
      let reader = new FileReader();
      reader.onload = (file => {
        return () => {
          doc.push({name: file.name, document: file});
          this.setState({
            uploadDocument: doc,
          });
        };
      })(file[0]);
      reader.readAsDataURL(file[0]);
    } else {
      errors["uploadDocument"] = this.state.t("common.csvAllowed");
    }
    this.setState({errors: errors});
  };

  imageTemplate(rowData) {
    return (
      <div
        className="p-grid p-dir-col"
        data-tip={
          rowData.properties && rowData.properties.length > 0 ? true : false
        }
        data-for={
          rowData.properties && rowData.properties.length > 0
            ? `tooltip-${rowData.reference}`
            : ""
        }
      >
        <LazyImage
          key={rowData.id}
          src={rowData.thumbnail}
          alt={rowData.product}
          className="product-image p-col"
          style={{
            maxHeight: "125px",
            height: "auto",
            maxWidth: "100px",
            width: "auto",
            objectFit: "contain",
            boxShadow: "none",
          }}
        />
        <p className="p-col" style={{maxHeight: "55px"}}>
          {rowData.product}
        </p>
        {rowData.properties && rowData.properties.length > 0 ? (
          <ReactTooltip
            id={`tooltip-${rowData.reference}`}
            place="right"
            type="dark"
            effect="float"
          >
            {rowData.properties.map(item => (
              <p>
                {item.name} : {item.value}
              </p>
            ))}
          </ReactTooltip>
        ) : (
          <></>
        )}
      </div>
    );
  }

  inventoryHistoryTemplate(data) {
    if (data.inventoryStatus) {
      return (
        <div data-tip data-for={`tooltip-${data.id}`}>
          <div> {data.inventoryStatus.status} </div>
          <ReactTooltip
            id={`tooltip-${data.id}`}
            place="right"
            type="dark"
            effect="float"
          >
            {data.inventoryStatus.productDescription != null ? (
              <p>
                {this.state.t("projectList.shootingList.description") +
                  " " +
                  data.inventoryStatus.productDescription}
              </p>
            ) : (
              <p>{this.state.t("projectList.shootingList.descriptionNC")}</p>
            )}
          </ReactTooltip>
        </div>
      );
    } else {
      return <></>;
    }
  }

  onSubmitFile = () => {
    let {projectId, uploadDocument} = this.state;
    this.props.addCatalog(projectId, uploadDocument);
    this.onHide();
  };

  selectedProducts(id) {
    this.setState({
      selectedProducts: [...id],
    });
  }

  shootedProductTemplate(rowData) {
    return (
      <InputSwitch
        checked={rowData.hasImages}
        onChange={() => this.setShootedProduct(rowData)}
      />
    );
  }

  actionBodyTemplate(obj) {
    return (
      <Button
        type="button"
        icon="pi icon-Action_AssocierRef icon-4x"
        className="p-button-text"
        style={{color: "white"}}
        onClick={() => this.openAssociatedProducts(obj)}
      />
    );
  }

  openAssociatedProducts(id) {
    this.setState({
      referencingProductId: id,
      openDialogAssociated: true,
    });
  }

  setSelectedProducts(selectedProducts) {
    this.setState({selectedProducts: selectedProducts});
  }

  onColumnToggle(event) {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.state.columns.filter(col =>
      selectedColumns.some(sCol => sCol.header === col.header)
    );
    this.setState({selectedColumns: orderedSelectedColumns});
  }

  generateBarcodeButtonClicked() {
    this.props.generateBarCodes(this.state.projectId);
  }

  printListShoot(projectId) {
    this.props.printShootList(projectId);
  }

  addProductToCatalog(newProduct) {
    this.props.addOneProduct(this.state.projectId, newProduct);
  }

  showAddProduct() {
    this.setState({addProductDialog: true});
  }

  actionMenu = [
    {
      icon: "icon-Nav_Menu icon-2x",
      items: [
        {
          icon: "svgIcon-Barcode",
          label: this.props.t("projectList.shootingList.generateBC"),
          command: () => {
            this.generateBarcodeButtonClicked();
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
                loading={this.state.loadingBarCode}
              />
            );
          },
        },
        {
          icon: "icon-Action_ icon-3x",
          label: this.props.t("catalog.dialog.productAdd"),
          command: () => {
            this.showAddProduct();
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
              />
            );
          },
        },
        {
          icon: "icon-Action_NewFile icon-3x",
          label: this.props.t("catalog.importList"),
          command: () => {
            this.showCatalogDialog();
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
                loading={this.state.loadingNewFile}
              />
            );
          },
        },
        {
          icon: "icon-Session_Delete icon-3x",
          label: this.props.t("projectList.shootingList.deleteProduct"),
          command: () => {
            this.deleteAllProducts();
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
              />
            );
          },
        },
        {
          icon: "icon-Action_Download icon-3x",
          label: this.props.t("projectList.shootingList.exportCSV"),
          command: () => {
            this.exportCSV();
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
              />
            );
          },
        },
        {
          icon: "icon-FileBar_Print icon-2x icon-printer",
          label: this.props.t("projectList.shootingList.downloadBoard"),
          command: () => {
            this.printListShoot(this.state.projectId);
          },
          template: (item, options) => {
            return (
              <Button
                icon={item.icon}
                className={options.className + " p-button-text session-button"}
                onClick={item.command}
                label={item.label}
                loading={this.state.loadingPrint}
              />
            );
          },
        },
      ],
    },
  ];

  qtyTemplate(data) {
    var otherColsTodisplay = data.properties?.filter(
      col => col.name.includes(remainsDeliver) || col.name.includes(allQty)
    );
    if (otherColsTodisplay?.length > 0) {
      return otherColsTodisplay.map(prop => {
        return (
          <p>
            {prop.name.charAt(0).toUpperCase() + prop.name.slice(1)} :{" "}
            {prop.value}
          </p>
        );
      });
    } else {
      return (
        <div>
          <p>{this.state.t("catalog.quantity.buy")}</p>
          <p>{this.state.t("catalog.quantity.integrated")}</p>
          <p>{this.state.t("catalog.quantity.all")}</p>
        </div>
      );
    }
  }
  sortTable = e => {
    let dtoShoot = {
      currentPage: 1,
      perPage: 10,
      keyWord: this.state.keyWord,
      orderBy: this.state.orderBy,
      orderType: e.sortOrder,
    };
    if (e.sortField === "properties") dtoShoot.orderBy = 1;
    if (e.sortField === "availabilityDate") dtoShoot.orderBy = 2;
    if (e.sortField === "inventory") dtoShoot.orderBy = 9;
    if (e.sortField === "imagesCount") dtoShoot.orderBy = 6;
    if (e.sortField === "couleur") dtoShoot.orderBy = 5;
    if (e.sortField === "hasImages") dtoShoot.orderBy = 7;

    this.searchProducts(this.state.projectId, dtoShoot);
    this.setState({
      currentPage: 1,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
      orderBy: dtoShoot.orderBy,
    });
  };

  lookTemplate = product => {
    return (
      <div className="p-grid p-col-align-center">
        <Button
          type="button"
          icon="pi icon-Action_AssocierRef icon-4x"
          className="p-button-text p-col p-px-0 p-mx-0"
          style={{color: "white"}}
          onClick={() => this.openAssociatedProducts(product.id)}
        />
        {product.hasLook ? (
          <>
            <LookIcon
              className="p-col-fixed p-my-auto"
              style={{width: "35px", height: "35px", padding: 0}}
              data-tip="Appartient à un Look"
              data-for="logoLook"
            />
            <ReactTooltip
              id="logoLook"
              effect="solid"
              type="light"
              place="bottom"
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  linkedProductClose = (product, linkedProducts) => {
    let {displayedProducts} = this.state;
    let indexProd = displayedProducts.findIndex(prod => prod.id === product.id);
    displayedProducts[indexProd].linkedProducts = linkedProducts;
    if (linkedProducts.length > 0) {
      displayedProducts[indexProd].hasLook = true;
    } else {
      displayedProducts[indexProd].hasLook = false;
    }
    this.setState({displayedProducts});
  };

  render() {
    const importProductFooter = (
      <>
        <Button
          label={this.state.t("common.cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.onHide}
        />
        <Button
          label={this.state.t("common.add")}
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.onSubmitFile}
        />
      </>
    );

    const deleteAllProductsFooter = (
      <>
        <Button
          label={this.state.t("common.no")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.onHide}
        />
        <Button
          label={this.state.t("common.yes")}
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.submitDeleteAllProducts}
        />
      </>
    );

    return (
      <div>
        <Header projectId={this.state.projectId}  projectName={this.state.project?.title}/>
        <ProjectMenu activeItemIndex={2} />

        <div className="p-grid p-mt-6">
          <div className={isTablet ? "p-col-12" : "p-col-2"}>
            <ProjectListingsMenu />
          </div>
          <div className="p-col p-mr-6">
            {this.state.isFetched ? (
              this.state.totalCount > 0 ? (
                <>
                  <Toast ref={el => (this.toast = el)} />
                  <div
                    className="p-grid p-justify-center p-align-start"
                    style={{alignItems: "center"}}
                  >
                    <MultiSelect
                      className="p-col"
                      value={this.state.selectedColumns}
                      options={this.state.columns}
                      optionLabel="header"
                      fixedPlaceholder={true}
                      placeholder={
                        this.state.selectedColumns.length +
                        " " +
                        this.state.t("projectList.shootingList.selectedColumns")
                      }
                      onChange={e => this.onColumnToggle(e)}
                      style={{width: "20em"}}
                    />
                    <div className="p-col">
                      <h5 className="p-my-0">{this.state.t('projectList.shootingList.products')}</h5>
                    </div>
                    <div className="p-col">
                      <ProgressBar value={this.state.totalCount > 0 ? ((this.state.shootedProductsCount * 100) / this.state.totalCount) : 0} />
                    </div>
                    <div className="p-col">
                      {this.state.shootedProductsCount}/
                      <b>{this.state.totalCount} </b>
                    </div>
                    <div className="p-col">
                      <form onSubmit={this.searchKeyWord}
                        className="p-d-flex p-dir-col p-jc-between">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            type="search"
                            className="p-inputtext-sm p-my-1"
                            value={this.state.keyWord}
                            onChange={e =>
                              this.setState({keyWord: e.target.value})
                            }
                            placeholder={this.state.t("common.search")}
                          />
                        </span>
                      </form>
                    </div>

                    <div className="p-col">
                      <Menu model={this.actionMenu} style={{width: '260px'}} popup ref={el => this.menu = el} id="popup_menu"  />
                      <Button icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup/>
                    </div>
                  </div>

                  <div className="p-col datatable-responsive-demo">
                    <DataTable
                      ref={el => {
                        this.dt = el;
                      }}
                      value={this.state.displayedProducts}
                      selection={this.state.selectedProducts}
                      selectionMode="checkbox"
                      onSelectionChange={e => this.setSelectedProducts(e.value)}
                      emptyMessage={this.state.t("catalog.emptyTable")}
                      removableSort
                      scrollable
                      frozenWidth="600px"
                      scrollDirection="horizontal"
                      onSort={e => this.sortTable(e)}
                      sortField={this.state.sortField}
                      sortOrder={this.state.sortOrder}
                      loading={this.state.loadingTable}
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{width: "3rem", height: "49px"}}
                        frozen
                        alignFrozen="left"
                        style={{
                          height: "222px",
                          flexGrow: 1,
                          flexBasis: "3rem",
                        }}
                      />
                      {/*Actions*/}
                      <Column
                        body={data => this.lookTemplate(data)}
                        header="Actions"
                        excludeGlobalFilter
                        frozen
                        headerStyle={{height: "49px", width: "100px"}}
                        style={{
                          height: "222px",
                          flexGrow: 1,
                          flexBasis: "100px",
                        }}
                      />
                      {this.state.selectedColumns &&
                        this.state.selectedColumns.map((col, i) => {
                          if (
                            col.header ===
                            this.state.t("projectList.shootingList.product")
                          )
                            return (
                              <Column
                                body={data => this.imageTemplate(data)}
                                key={`${col.field}-${i}`}
                                field={col.field}
                                header={col.header}
                                frozen
                                headerStyle={{height: "49px"}}
                                style={{height: "230px", flexGrow: 1}}
                              />
                            );
                          // Shooté
                          else if (col.field === "hasImages")
                            return (
                              <Column
                                body={this.shootedProductTemplate}
                                header={col.header}
                                field="hasImages"
                                sortable
                                key={`${col.field}-${i}`}
                                excludeGlobalFilter
                                headerStyle={{height: "49px", width: "130px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "130px",
                                }}
                              />
                            );
                          // Date de sortie
                          else if (col.field === "availabilityDate")
                            return (
                              <Column
                                body={this.dateTemplate}
                                field="availabilityDate"
                                header={col.header}
                                sortable
                                key={`${col.field}-${i}`}
                                excludeGlobalFilter
                                headerStyle={{height: "49px", width: "200px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "200px",
                                }}
                              />
                            );
                          // BarCode
                          else if (col.header.indexOf(barCode) !== -1) {
                            return (
                              <Column
                                body={data => this.barcodeTemplate(data)}
                                key={`${col.field}-${i}`}
                                field={col.field}
                                header={col.header}
                                frozen
                                headerStyle={{height: "49px", width: "200px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "200px",
                                }}
                                className="p-mx-2"
                              />
                            );
                          }
                          // Quantité
                          else if (col.header.indexOf(allQtyMaj) !== -1) {
                            return (
                              <Column
                                body={data => this.qtyTemplate(data)}
                                key={`${col.field}-${i}`}
                                field={col.field}
                                header={col.header}
                                sortable
                                headerStyle={{height: "49px", width: "200px"}}
                                style={{
                                  height: "222px",
                                  flexGrow: 1,
                                  flexBasis: "200px",
                                }}
                              />
                            );
                          }
                          // Inventaire
                          else if (col.field === "inventoryStatus") {
                            return (
                              <Column
                                body={data =>
                                  this.inventoryHistoryTemplate(data)
                                }
                                key={`${col.field}-${i}`}
                                field={col.field}
                                sortable
                                header={col.header}
                                headerStyle={{height: "49px", width: "100px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "100px",
                                }}
                              />
                            );
                          }
                          //Nombre d'image
                          else if (col.field === "imagesCount") {
                            return (
                              <Column
                                field={col.field}
                                header={col.header}
                                body={data => (
                                  <p
                                    onClick={() =>
                                      this.setState({
                                        openDialogImg: true,
                                        dialogImgPrd: data,
                                      })
                                    }
                                    style={{cursor: "pointer"}}
                                  >
                                    {data.imagesCount}
                                  </p>
                                )}
                                sortable
                                key={`${col.field}-${i}`}
                                excludeGlobalFilter
                                headerStyle={{height: "49px", width: "160px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "260px",
                                }}
                              />
                            );
                          } else
                            return (
                              <Column
                                field={col.field}
                                header={col.header}
                                body={col.body}
                                sortable
                                key={`${col.field}-${i}`}
                                excludeGlobalFilter
                                headerStyle={{height: "49px", width: "160px"}}
                                style={{
                                  height: "230px",
                                  flexGrow: 1,
                                  flexBasis: "260px",
                                }}
                              />
                            );
                        })}
                    </DataTable>
                  </div>
                  <div>
                    <Paginator
                      totalRecords={this.state.totalQueryCount}
                      currentPage={this.state.currentPage}
                      rows={this.state.perPage}
                      first={(this.state.currentPage - 1) * this.state.perPage}
                      onPageChange={e => this.handlePageChange(e)}
                    />
                  </div>
                </>
              ) : (
                <div className="image-placeholder p-d-flex p-my-6 p-dir-col p-jc-center p-ai-center">
                  {this.state.t("projectList.shootingList.emptyProduct")}
                  <Button
                    className="p-button-rounded p-mt-6"
                    onClick={() => this.showCatalogDialog()}
                    label={this.state.t(
                      "projectList.shootingList.importShootingList"
                    )}
                    loading={this.state.loadingNewFile}
                  />
                </div>
              )
            ) : (
              <div
                className="p-d-flex p-flex-column p-jc-center p-ai-center"
                style={{height: "60vh"}}
              >
                <ProgressSpinner className="p-progress-spinner" />
              </div>
            )}
          </div>
        </div>
        <Dialog
          position="center"
          header={this.state.t("catalog.importCatalog")}
          visible={this.state.showCatalogDialog}
          onHide={this.onHide}
          style={isTablet ? {width: "60vw"} : {width: "30vw"}}
          footer={importProductFooter}
        >
          <div className="p-d-flex p-flex-column p-jc-center p-ai-center dropzone-file">
            <Dropzone
              onDrop={this.onDrop}
              multiple={false}
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DownloadCloudButton
                      title={this.state.t("common.dragDrop")}
                    />
                    <span>{this.state.t("catalog.importFormat")}</span>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="error">{this.state.errors.uploadDocument}</div>
            {this.state.uploadDocument && (
              <div className="p-mt-5">{this.state.uploadDocument[0].name}</div>
            )}
          </div>
        </Dialog>

        {/* Popup pour associer des références  */}
        <LinkedProductDialog
          open={this.state.openDialogAssociated}
          hide={(productId, count) =>
            this.onLinkedProductDialogHide(productId, count)
          }
          productId={this.state.referencingProductId}
          projectId={this.state.projectId}
          changeState={(p, l) => this.linkedProductClose(p, l)}
        />

        {/* Popup pour supprimer les produits */}
        <Dialog
          visible={this.state.openDialogCatalogDeleteAll}
          style={{width: "720px"}}
          header={this.state.t("dashboardScreen.projectCard.deleteQuestion")}
          modal
          className="p-fluid"
          footer={deleteAllProductsFooter}
          onHide={this.onHide}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{fontSize: "2rem"}}
            />
            <span>
              {this.state.t("projectList.shootingList.deleteProductQ")}
            </span>
          </div>
        </Dialog>

        {/* Popup pour ajouter un produit manuellement */}
        <AddProduct
          openDialog={this.state.addProductDialog}
          hideDialog={() => this.setState({addProductDialog: false})}
          addProduct={newProduct => this.addProductToCatalog(newProduct)}
          catalog={this.state.catalog}
        />

        {/* Popup pour Afficher toutes les images */}
        <DialogImg
          open={this.state.openDialogImg}
          dialogImgPrd={this.state.dialogImgPrd}
          onHide={this.onHide}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {catalog, uploadFile} = state;
  return {catalog, uploadFile};
};

const actionCreators = {
  getProducts: catalogAction.getProducts,
  addCatalog: catalogAction.addCatalog,
  deleteCatalog: catalogAction.deleteCatalog,
  setShootedProduct: catalogAction.setShootedProduct,
  alertWarning: alertActions.warning,
  alertSuccess: alertActions.success,
  generateBarCodes: productsActions.generateBarCodes,
  printShootList: catalogAction.printShootList,
  addProduct: catalogAction.addProductsToProject,
  addOneProduct: catalogAction.addManuallyProduct,
};

const connectedShootingListing = connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(ProjectProductsScreen));
export {connectedShootingListing as ProjectProductsScreen};
