import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {connect} from "react-redux";
import {catalogAction} from "../../../redux/actions";
import {ScrollPanel} from 'primereact/scrollpanel';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {ProgressSpinner} from 'primereact/progressspinner';
import { withTranslation } from 'react-i18next';

class DialogImg extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			open: false,
			dialogImgPrd: {},
			images: {},
			openViewer: false,
			indexImg: null,
			isImgFetched: false,
			activeIndex: 0,
			t: this.props.t
		}

		this.mapObejct = this.mapObejct.bind(this)
		this.onHide = this.onHide.bind(this)
		this.openViewer = this.openViewer.bind(this)
	}

	componentDidUpdate( prevProps, prevState ) {
		if (this.state.open && !this.state.isImgFetched && this.state.dialogImgPrd.id !== prevState.dialogImgPrd.id) {
			this.props.showImg(this.state.dialogImgPrd.id)
		}
	}

	componentDidMount() {
		if (this.state.open) {
			this.props.showImg(this.state.dialogImgPrd.id)
		}
	}

	static getDerivedStateFromProps( props, state ) {
		if (props.open && props.dialogImgPrd) {
			if (props.dialogImgPrd !== state.dialogImgPrd && !props.isImgFetching) {
				return {
					open: props.open,
					dialogImgPrd: props.dialogImgPrd
				}
			}

			if (props.isImgFetched) {
				console.log("props.catalog", props.catalog)
				return {
					images: props.catalog,
					isImgFetched: props.isImgFetched
				}
			}
		}

		return state
	}

	onHide = () => {
		this.setState({
			open: false,
			dialogImgPrd: {},
			images: {},
			openViewer: false,
			indexImg: null,
			isImgFetched: false,
			activeIndex: 0
		})
		this.props.onHide()
	}

	openViewer = index => {
		this.setState({openViewer: true, indexImg: index})
	}

	mapObejct = obj => {
		let objLenght = Object.keys(obj).length
		if (objLenght > 0) {
			for (const [key, array] of Object.entries(obj)) {
				return (
					<div>
						{this.state.openViewer ?
							(<div className="p-grid p-dir-col">
								<i className='pi pi-chevron-left p-col'
								   onClick={() => this.setState({openViewer: false})}
								   style={{cursor: 'pointer'}}>

								</i>
								<img
									src={process.env.REACT_APP_BASE_URL + array[this.state.indexImg].publicUrl}
									className="p-col"
									alt='Image fullscreen'
								/>
							</div>)
							:
							<Accordion
								activeIndex={this.state.activeIndex}
								onTabChange={( e ) => this.setState({activeIndex: e.index})}
							>
								<AccordionTab header={<h5>{key}</h5> }>
									<ScrollPanel style={{width: '100%', height: '340px'}}>
										<div className="p-d-flex p-ai-center p-grid">
										{array.map(( img, i ) => {
											return <img
												key={i}
												src={`data:image/jpeg;base64,${img.thumbnail}`}
												alt={array.mediaId}
												className="p-col-2"
												style={{
													width: '150px',
													height: 'auto',
													objectFit: 'contain',
													// cursor: 'pointer'
												}}
												// onClick={() => this.openViewer(i)}
											/>
										})}
										</div>
									</ScrollPanel>
								</AccordionTab>
							</Accordion>
						}
					</div>
				)
			}
		} else {
			return (<p>{this.state.t('projectList.shootingList.noPhotos')}</p>)
		}

	}

	render() {
		return (
			<Dialog
				visible={this.state.open}
				modal
				onHide={() => this.onHide()}
				header={this.state.dialogImgPrd.product}
				draggable={false}
				resizable={false}
				style={{width: '750px'}}
			>
				<>
					{this.state.isImgFetched ?
						this.mapObejct(this.state.images)
						:
						<ProgressSpinner className="p-progress-spinner"/>
					}
				</>
			</Dialog>
		);
	}
}

const mapStateToProps = ( state ) => {
	const {isImgFetched, catalog, isImgFetching} = state.catalog;
	return {isImgFetched, catalog, isImgFetching};
};

const actionCatalog = {
	showImg: catalogAction.showImg,
};

const connectedImageDialog = connect(mapStateToProps, actionCatalog)(withTranslation()(DialogImg));
export {connectedImageDialog as DialogImg};
