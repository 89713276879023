import { callsheetConstants } from '../constants'

export function callsheet(state = {}, action){
    switch(action.type) {
        case callsheetConstants.CALLSHEET_ADD_REQUEST:
            return Object.assign({}, { isFetching: true });
        case callsheetConstants.CALLSHEET_ADD_SUCCESS: 
            return Object.assign({}, { type: action.callsheet, _isFetched: true });
        case callsheetConstants.CALLSHEET_ADD_FAILURE:
            return {}
        
        case callsheetConstants.GET_CALLSHEET_REQUEST:
            return Object.assign({}, { isFetching: true });
        case callsheetConstants.GET_CALLSHEET_SUCCESS:
            return Object.assign({}, { type: action.callsheet, isFetched: true});
        case callsheetConstants.GET_CALLSHEET_FAILURE:
            return {}
        
        case callsheetConstants.UPDATE_CALLSHEET_REQUEST:
            return Object.assign({}, {isFetching: true});
        case callsheetConstants.UPDATE_CALLSHEET_SUCCESS: 
            return Object.assign({}, {type: action.callsheet, is_Fetched: true});
        case callsheetConstants.UPDATE_CALLSHEET_FAILURE:
            return {}
        
        case callsheetConstants.DELETE_CALLSHEET_REQUEST: 
            return Object.assign({}, {isDeleting: true});
        case callsheetConstants.DELETE_CALLSHEET_SUCCESS:
            return Object.assign({}, {type: action.callsheet, isDeleted: true});
        case callsheetConstants.DELETE_CALLSHEET_FAILURE:
            return {}

        case callsheetConstants.DOWNLOAD_CALLSHEET_REQUEST:
            return Object.assign({}, { isCallsheetFetching: true });
        case callsheetConstants.DOWNLOAD_CALLSHEET_SUCCESS:
            return Object.assign({}, { project: { ...state.project }, file: action.project, isCallsheetFetched: true });
        case callsheetConstants.DOWNLOAD_CALLSHEET_FAILURE:
            return {}

        default:
            return state
    }
}