import React, { Component } from "react";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";
import { ListBox } from "primereact/listbox";
import Moment from "react-moment";
import "moment/locale/fr";
import { connect } from "react-redux";
import { history } from "../../redux/helpers";
import { hubActions } from "../../redux/actions/notifications.actions";
import { projectActions } from "../../redux/actions/project.actions";
import { NotificationContext } from "../../DependencyInjection/NotificationContext";

class Notifications extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      inputText: "",
      ReceivedData: [],
      isNotifReceived: false,
      notificationId: null,
      showMore: false,
      project: null,
      projects: [],
      notificationTarget: null,
    };
    this.notificationButtonClicked = this.notificationButtonClicked.bind(this);
    this.showNotificationsHistory = this.showNotificationsHistory.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.redirectToNotificationTarget =
      this.redirectToNotificationTarget.bind(this);
  }

  componentDidMount() {
    let hubConnection = this.context._currentValue;
    if (hubConnection !== null && hubConnection !== undefined) {
      hubConnection.onclose(function () {
        console.log("Connection disconnected");
        connection.stop();
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.notifications.isNotifPushed) {
      return { isNotifReceived: true };
    }

    if (
      props.notifications.isFetched &&
      props.notifications.type.result.length > 0
    ) {
      return {
        ReceivedData: props.notifications.type.result,
        isNotifReceived: false,
      };
    }
    if (props.notifications.markAsReadIsFetched) {
      let notifications = state.ReceivedData;
      if (state.notificationId !== null) {
        let notification = notifications.filter(
          (f) => f.id == state.notificationId
        )[0];
        if (!notification.isRead) {
          notification.isRead = true;
          const index = notifications.indexOf(notification);
          return {
            notifications: [
              ...notifications.slice(0, index),
              notification,
              ...notifications.slice(index + 1),
            ],
          };
        }
      }
    }
    return state;
  }
  notificationButtonClicked() {
    if (JSON.parse(localStorage.getItem("notifications")) != null) {
      localStorage.removeItem("notifications");
    }
    let dto = { limit: 8 };
    this.props.getNotificationHistory(dto);
  }

  markAsRead(notificationId) {
    this.setState({ notificationId: notificationId });
    let notifications = this.state.ReceivedData;
    let notification = notifications.filter((n) => n.id == notificationId)[0];
    if (!notification.isRead) this.props.markAsRead(notificationId);
    let storedProject = JSON.parse(localStorage.getItem("project"));
    if (notification.projectId > 0) {
      if (
        storedProject == null ||
        parseInt(storedProject.projectId) !== parseInt(notification.projectId)
      ) {
        let projects = JSON.parse(localStorage.getItem("projects"));
        if (projects.length > 0) {
          let notificationProject = projects.filter(
            (n) => n.projectId == notification.projectId
          )[0];
          localStorage.setItem("project", JSON.stringify(notificationProject));
        }
      }
    }
    this.redirectToNotificationTarget(notification);
  }
  redirectToNotificationTarget(notification) {
    switch (notification.objectType) {
      case "Catalog": {
        history.push(`/catalog`);
        break;
      }
      case "Image": {
        let state = {
          productId: notification.productId,
          projectId: notification.projectId,
          imageId: notification.objectId,
          project:JSON.parse(localStorage.getItem("project")),
        };
        history.push(
          `/projects/${notification.projectId}/session/products/${notification.productId}/images/${notification.objectId}/comments`,
          state
        );
         break;
      }
      case "Sync":
      case "Export":
      case "ExportMerch": {
        let state = {
          project:JSON.parse(localStorage.getItem("project")),
        };
        history.push(`/projects/${notification.projectId}/session`,state);

        break;
      }
      case "ListShoot": {
        let state = {
          project:JSON.parse(localStorage.getItem("project")),
        };
        history.push(`/projects/${notification.projectId}/lists/products`,state);

        break;
      }
      case "File": {
        let state = {
          project:JSON.parse(localStorage.getItem("project")),
        };
        history.push(
          `/projects/${notification.projectId}/files/${notification.objectId}`,state
        );
        break;
      }
      default:
        break;
    }
  }
  hideNotificationsHistory() {
    this.setState({ showMore: false });
  }
  showNotificationsHistory() {
    history.push("/notification-history");
  }

  render() {
    const itemTemplate = (option) => {
      let iconLetter = option.message.charAt(0);
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return (
        <>
          <div className="p-grid" style={{ cursor: "pointer" }}>
            <div className="p-col-2">
              <span
                className="custom-marker"
                style={
                  iconLetter !== " "
                    ? { backgroundColor: `#${randomColor}` }
                    : { backgroundColor: "##5c5c5c" }
                }
              >
                {iconLetter}
              </span>
            </div>
            <div className="p-col-9">
              <div> {option.message}</div>
              <div
                style={
                  !option.isRead ? { color: "#90caf9" } : { color: "#adadad" }
                }
              >
                {" "}
                <span> Il y a </span>
                <Moment fromNow ago locale="Fr">
                  {option.created}
                </Moment>
              </div>
            </div>
            <div className="p-col">
              {!option.isRead ? (
                <Badge severity="info" className="p-badge-info"></Badge>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    };
    return (
      <>
          <Button
            icon="pi pi-bell"
             onClick={(e) => {
               this.op.toggle(e);
            //   this.notificationButtonClicked();
             }}
            style={{ fontSize: "2rem" }}
            aria-haspopup
            aria-controls="overlay_panel"
            className="p-button-text p-overlay-badge"
          >
            {this.state.isNotifReceived ? (
              <Badge
                severity="danger"
                style={{
                  top: "unset",
                  right: "unset",
                }}
              ></Badge>
            ) : (
              <></>
            )}
          </Button>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            style={{ width: "450px" }}

            dismissable
             onShow={()=>this.notificationButtonClicked()}
          >
            <ListBox
              value={this.state.ReceivedData[0]}
              options={this.state.ReceivedData}
              dataKey="id"
              optionValue="id"
              className="p-listbox-list-wrapper"
              itemTemplate={itemTemplate}
              onChange={(e) => this.markAsRead(e.value)}
            />
            <div className="p-d-flex p-justify-center">
              <Button
                label="Voir tout"
                className="p-button-link p-button-sm  "
                style={{ color: "#90caf9" }}
                onClick={() => this.showNotificationsHistory()}
              />
            </div>
          </OverlayPanel>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { notifications, project } = state;
  return { notifications, project };
}

const actionCreator = {
  getNotificationHistory: hubActions.getNotificationHistory,
  markAsRead: hubActions.markAsRead,
  getProject: projectActions.getProject,
  notificationPushed: hubActions.pushNotification,
  //  getProjects: projectActions.getAllProjects
};
const connectedNotifications = connect(
  mapStateToProps,
  actionCreator
)(Notifications);
export { connectedNotifications as Notifications };
