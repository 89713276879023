import React, {Component} from 'react'
import {Button} from 'primereact/button'
import {Rating} from 'primereact/rating'
import ColorDropDown from '../common/ColorDropDown'
import { withTranslation } from 'react-i18next';

class ImageOverviewHeader extends Component {

	constructor( props ) {
		super(props)
		this.state = {
			media: this.props.media,
			rate:
				this.props.media.metasData.filter(( m ) => m.name == 'Rating').length > 0
					? this.props.media.metasData.filter(( m ) => m.name == 'Rating')[0].value
					: 0,
			label: this.props.media.metasData.filter(( m ) => m.name == 'Label').length
				? this.props.media.metasData.filter(( m ) => m.name == 'Label')[0].value
				: '',
			t: this.props.t
		}

		this.validationButtonClicked = this.validationButtonClicked.bind(this)
		this.commentButtonClicked = this.commentButtonClicked.bind(this)

	}

	static getDerivedStateFromProps( props, state ) {
		if (props.media !== state.media) {
			return {
				media: props.media,
				rate:
					props.media.metasData.filter(( m ) => m.name == 'Rating').length > 0
						? props.media.metasData.filter(( m ) => m.name == 'Rating')[0].value
						: 0,
				label: props.media.metasData.filter(( m ) => m.name == 'Label').length
					? props.media.metasData.filter(( m ) => m.name == 'Label')[0].value
					: '',
			}
		}
		return state
	}

	selectionButtonClicked( media ) {
		this.props.selectionButtonClicked(media)
	}

    showLinkedPopup(media) {
        this.props.showLinkedPopup(media);
    }

	validationButtonClicked( media ) {
		let selectedMedia = this.state.media
		selectedMedia.status = 4
		this.setState({media: selectedMedia})
		this.props.validationButtonClicked(media)
	}

	commentButtonClicked( media ) {
		this.props.commentButtonClicked(media)
	}

	setImageMeta( meta, value ) {
		if (value == null) value = 0
		let newMeta = []
		let media = this.state.media
		if (isNaN(value)) {
			let existingRate = meta.filter(( m ) => m.name == 'Rating')
			if (existingRate.length > 0) newMeta.push(existingRate[0])
			else newMeta.push({name: 'Rating', value: 0})
			newMeta.push({name: 'Label', value: value})
			this.setState({label: value})
		} else {
			let existingLabel = meta.filter(( m ) => m.name == 'Label')
			if (existingLabel.length > 0) newMeta.push(existingLabel[0])
			else newMeta.push({name: 'Label', value: ''})
			newMeta.push({name: 'Rating', value: value})
			this.setState({rate: value})
		}

		media.metasData = newMeta
		this.setState({media: media})
		this.props.setImageMeta(media)
	}

	render() {
		return (
			<div className="p-d-flex">
				<div className="p-col-fixed p-d-flex p-jc-center">{this.state.media.title}</div>

				{/*<div className="p-col-fixed">*/}
				{/*    <i className="icon-Flag_1Empty"></i>*/}
				{/*</div>*/}

				<div className="p-col p-d-flex p-jc-center">
					<Button
						label={this.state.t('common.add')}
						className={` p-mr-3 p-button-outlined p-button-sm ${this.state.media.selected ? 'card-button-add-active' : 'card-button'}`}
						onClick={() => this.selectionButtonClicked(this.state.media)}
						style={{textDecoration: "none"}}
						tooltip={this.state.t('session.addSelection')}
						tooltipOptions={{position: "bottom"}}
					/>

					<Button
						label={this.state.t('common.valid')}
						className={`p-mr-3 p-button-outlined p-button-sm ${this.state.media.status === 4 ? 'card-button-val-active' : 'card-button'}`}
						onClick={() => this.validationButtonClicked(this.state.media)}
						style={{textDecoration: "none"}}
						tooltip={this.state.t('common.valid')}
						tooltipOptions={{position: "bottom"}}
					/>

					<Button
						label={this.state.t('session.commentButton')}
						className="p-button-outlined p-button-sm card-button p-mr-3"
						onClick={() => this.commentButtonClicked(this.state.media)}
						style={{textDecoration: "none"}}
						tooltip={this.state.t('session.commentButton')}
						tooltipOptions={{position: "bottom"}}
					/>

					<Button
						label={this.state.t('session.look')}
						className="p-mr-3 p-button-outlined p-button-sm card-button"
						style={{textDecoration: "none"}}
						onClick={() => this.showLinkedPopup(this.state.media)}
						tooltip={this.state.t('session.createLook')}
						tooltipOptions={{position: "bottom"}}
					/>
				</div>
				<div className="p-d-flex p-jc-between p-ai-center" style={{width: '170px'}}>
					<Rating
						value={this.state.rate}

						onChange={( e ) => this.setImageMeta(this.state.media.metasData, e.value)}/>
					<ColorDropDown
						style={{zIndex: '2'}}
						value={this.state.label}
						updateLabel={( e ) => this.setImageMeta(this.state.media.metasData, e)}/>
					<span>
                        {this.state.media.status == 4 ? (
	                        <i className="icon-Done icon-2x"></i>
                        ) : (
	                        <></>
                        )}
                    </span>
				</div>

			</div>
		)
	}
}

export default withTranslation()(ImageOverviewHeader)
