import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import { withTranslation } from 'react-i18next';

class CommentDialog extends Component {
	constructor() {
		super();
		this.state = {
			commentProject: ''
		}

	}

	static getDerivedStateFromProps( props, state ) {
		if (props.selectedMediasLength) {
			return {
				selectedMediasLength: props.selectedMediasLength
			}
		}
		return state
	}

	render() {
        const {t} = this.props;

		return (
			<Dialog
				modal={false}
				visible={this.props.showCommentProjectPopup}
				closable={false}
				header={
					this.state.selectedMediasLength === 1 ? this.props.t('session.dialog.launchRetouch') : this.props.t('session.dialog.launchRetouchs')
				}
				onHide={() => {
					this.setState({commentProject: ''})
					this.props.hideCommentProjectPopup()
				}}
			>
				<div className="p-fluid">
					<div className="p-field">
                <span className="p-float-label">
                  <InputTextarea
	                  placeholder={this.props.t('session.dialog.addComment')}
	                  cols={50}
	                  autoResize
	                  className="p-mb-2"
	                  onChange={( event ) =>
		                  this.setState({
			                  commentProject: event.target.value,
		                  })
	                  }
                  />
                </span>
					</div>

					<div className="p-d-flex p-flex-row p-jc-end">
						<Button
							className="p-button-rounded cancel-button"
							style={{width: "20%"}}
							label={this.props.t('common.cancel')}
							onClick={() => {
								this.setState({commentProject: ''})
								this.props.hideCommentProjectPopup()
							}}
						/>
						<Button
							className="p-button-rounded p-ml-3 dialog-button"
							style={{width: "20%"}}
							label={this.props.t('session.dialog.post')}
							onClick={() => {
								this.props.handleCommentProject(this.state.commentProject);
								this.props.hideCommentProjectPopup();
							}}
						/>
					</div>
				</div>
			</Dialog>
		);
	}
}

export default withTranslation()(CommentDialog);
