export const materialConstants = {
    MATERIALS_ADD_REQUEST: 'MATERIALS_ADD_REQUEST',
    MATERIALS_ADD_SUCCESS: 'MATERIALS_ADD_SUCCESS',
    MATERIALS_ADD_FAILURE: 'MATERIALS_ADD_FAILURE',

    GET_MATERIALS_REQUEST: 'GET_MATERIALS_REQUEST',
    GET_MATERIALS_SUCCESS: 'GET_MATERIALS_SUCCESS',
    GET_MATERIALS_FAILURE: 'GET_MATERIALS_FAILURE',

    DELETE_MATERIALS_REQUEST: 'DELETE_MATERIALS_REQUEST',
    DELETE_MATERIALS_SUCCESS: 'DELETE_MATERIALS_SUCCESS',
    DELETE_MATERIALS_FAILURE: 'DELETE_MATERIALS_FAILURE',

    UPDATE_MATERIALS_REQUEST: 'UPDATE_MATERIALS_REQUEST',
    UPDATE_MATERIALS_SUCCESS: 'UPDATE_MATERIALS_SUCCESS',
    UPDATE_MATERIALS_FAILURE: 'UPDATE_MATERIALS_FAILURE',

    VALIDATE_MATERIALS_REQUEST: 'VALIDATE_MATERIALS_REQUEST',
    VALIDATE_MATERIALS_SUCCESS: 'VALIDATE_MATERIALS_SUCCESS',
    VALIDATE_MATERIALS_FAILURE: 'VALIDATE_MATERIALS_FAILURE',

    REJECT_MATERIALS_REQUEST: 'REJECT_MATERIALS_REQUEST',
    REJECT_MATERIALS_SUCCESS: 'REJECT_MATERIALS_SUCCESS',
    REJECT_MATERIALS_FAILURE: 'REJECT_MATERIALS_FAILURE',

    DRAFT_MATERIALS_REQUEST: 'DRAFT_MATERIALS_REQUEST',
    DRAFT_MATERIALS_SUCCESS: 'DRAFT_MATERIALS_SUCCESS',
    DRAFT_MATERIALS_FAILURE: 'DRAFT_MATERIALS_FAILURE',

    DELETE_ALL_MATERIALS_REQUEST: 'DELETE_ALL_MATERIALS_REQUEST',
    DELETE_ALL_MATERIALS_SUCCESS: 'DELETE_ALL_MATERIALS_SUCCESS',
    DELETE_ALL_MATERIALS_FAILURE: 'DELETE_ALL_MATERIALS_FAILURE',

}
