import { alertActions } from ".";
import { hubService } from "../../services";
import { hubConstants } from "../constants";
import { store } from "../helpers/store";
export const hubActions = {
  getNotificationHistory,
  markAsRead,
  pushNotification,
};
function pushNotification() {

    store.dispatch({ type: hubConstants.NOTIFICATIONS_PUSHED});
  
}
function getNotificationHistory(dto) {
  return (dispatch) => {
    dispatch(request());
    hubService.getNotificationHistory(dto).then(
      (notifications) => dispatch(success(notifications)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: hubConstants.GET_NOTIFICATIONS_HISTORY_REQUEST };
  }
  function success(notifications) {
    return {
      type: hubConstants.GET_NOTIFICATIONS_HISTORY_SUCCESS,
      notifications,
    };
  }
  function failure(error) {
    return { type: hubConstants.GET_NOTIFICATIONS_HISTORY_FAILURE, error };
  }
}

function markAsRead(notificationId) {
  return (dispatch) => {
    dispatch(request());
    hubService.markAsRead(notificationId).then(
      (notifications) => dispatch(success(notifications)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: hubConstants.MARK_AS_READ_REQUEST };
  }
  function success(notifications) {
    return { type: hubConstants.MARK_AS_READ_SUCCESS, notifications };
  }
  function failure(error) {
    return { type: hubConstants.MARK_AS_READ_FAILURE, error };
  }
}
