import React, { Component } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { assetsService } from '../../services'
import { ProgressSpinner } from "primereact/progressspinner";

class LazyImage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			imageAsBase64: '',
			src: '',
			load: true
		}
	
	}

	componentDidMount() {
		
		if (this.props.src) {
			assetsService.getImage(this.props.src).then(data => {
				if (data === null)
					this.setState({ src: this.props.src, imageAsBase64: '/Symbol.svg', load: false })
				else
					this.setState({ src: this.props.src, imageAsBase64: data, load: false })
			})
		}
	}

	// static getDerivedStateFromProps( props, state ) {
	// 	if (props.src && props.src !== undefined && state.src !== props.src) {
	// 		let imageAsBase64 = '';
	// 		let load = props.loader
	// 		assetsService.getImage(props.src).then(data => {
	// 			if (data === null) {
	// 				imageAsBase64 = '/Symbol.svg'
	// 				load = !load
	// 			} else {
	// 				imageAsBase64 = data
	// 				load = !load
	// 			}
	// 		})
	// 		return {src: props.src, imageAsBase64: imageAsBase64, load: load}
	// 	}
	// 	return state
	// }


	render() {
		return (
			<>
			
					<LazyLoadImage
						key={this.props.imageKey}
						src={this.state.imageAsBase64}
						alt={this.props.alt}
						className={this.props.className}
						style={
							this.state.imageAsBase64 === '/Symbol.svg' ?
								Object.assign({}, this.props.style, {
									padding: "10%"
								})
								:
								this.props.style
						}
						onClick={this.props.onClick}
					/>
				
			</> 
		) 
	}

}
export {LazyImage}
