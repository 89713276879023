import { alertConstants } from '../constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message, 
        style:"success"
      };
      case alertConstants.WARNING:
        return {
          type: 'alert-warning',
          message: action.message, 
          style:"warn"
        };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message, 
        style:"error"
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}
