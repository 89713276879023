import React, { Component } from "react";
import { Menubar } from "primereact/menubar";
import { ReactComponent as Logo } from "../../img/full-logo.svg";
import { ReactComponent as LogoMini } from "../../img/mini-logo.svg";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { history } from "../../redux/helpers";
import { withTranslation } from "react-i18next";
import { Notifications } from "./Notifications";
import { Menu } from 'primereact/menu';

class Header extends Component {
  constructor(props) {
    super(props);
    let project = JSON.parse(localStorage.getItem("project"));
    this.state = {
      projectName: project !== null ? project.title : "",
      projectId: project?.projectId,
      searchBar: false,
      search: "",
    };

    const { t } = this.props;

    if (project != null) {
      this.projectMenu = [
        {
          label: t("header.projectMenu.brief"),
          command: () => {
            history.push(`/projects/${this.state.projectId}`);
          },
        },
        {
          label: t("header.projectMenu.session"),
          command: () => {
            history.push(`/projects/${this.state.projectId}/session`);
          },
        },
        {
          label: t("header.projectMenu.task"),
          command: () => {
            history.push(`/projects/${project.projectId}/tasks`);
          },
        },
        {
          /*
                label: t('header.projectMenu.message'),
                command:()=>{ history.push(`/projects/${project.projectId}/chat`); }
            */
        },
      ];
    } else {
      this.projectMenu = [];
    }

        this.rightMenu = [
            {
                label: t('header.rightMenu.dashboard'),
                command: () => {
                    history.push(`/`);
                }
            },
            {
                label: t('header.rightMenu.account'),
                command: () => {
                    history.push(`/my-account`);
                }
            },
            {
                label: t('header.rightMenu.notification'),
                command: () => {
                     history.push(`/notification-settings`);
                              }
            },
            {
                label: t('header.rightMenu.dropbox'),
                command: () => {
                    history.push(`/dropbox-setting`);
                }
            },
            {
                label: t('header.rightMenu.logout'),
                command: () => {
                    localStorage.removeItem('user');
                    history.push(`/login`);
                }
            }
        ];
    }


  componentWillReceiveProps(nextProps) {
    if (nextProps.projectName) {
      this.setState({
        projectName: nextProps.projectName,
      });
      sessionStorage.setItem("projectName", nextProps.projectName);
    }
    if (nextProps.projectId) {
      this.setState({
        projectId: nextProps.projectId,
      });
    }
  }

  searchButton = () => {
    this.setState({ searchBar: true });
  };

  sendSearch = (e) => {
    e.preventDefault();
    history.push(`/search/${this.state.search}`);
  };

  render() {
    const { t } = this.props;
    let path = window.location.pathname;
    return (
      <div className="p-grid p-d-flex">
        <div className="p-col-4 p-d-flex p-jc-start p-ai-center p-as-center">
          <Link to="/">
            {path === "/" ||
            path.includes("/search/") ||
            this.state.projectName === "" ? (
              <Logo />
            ) : (
              <span className="p-d-flex p-jc-start p-ai-center">
                <LogoMini />{" "}
                <p className="p-ml-3">
                  {this.state.projectName
                    ? this.state.projectName
                    : sessionStorage.getItem("projectName")}
                </p>
              </span>
            )}
          </Link>
          <Button
            icon="pi pi-angle-left"
            style={{ color: "white", backgroundColor: "#252525" }}
            className="p-button-sm p-py-1 p-ml-4 p-mr-3"
            onClick={() => window.history.back()}
          />
          <Button
            icon="pi pi-angle-right"
            style={{ color: "white", backgroundColor: "#252525" }}
            className="p-button-sm p-py-1"
            onClick={() => window.history.forward()}
          />
        </div>
        {path !== "/" && !path.includes('/search/') && path !== '/catalog' ?
            <div className="p-col-4 p-d-flex p-jc-center">
                <Menubar model={this.projectMenu} className="p-jc-center header-center-menu"/>
            </div>
            :
            <span className="p-col-4 p-d-flex p-jc-center"></span>}


        <div className="p-col-4 p-d-flex p-jc-end">
          {!this.state.searchBar ? (
            <Button
              className="p-as-center"
              icon="pi pi-search"
              style={{ color: "white", backgroundColor: "#252525" }}
              onClick={this.searchButton}
            />
          ) : (
            <form
              className="p-as-center p-input-icon-left"
              onSubmit={this.sendSearch}
            >
              <i className="pi pi-search" />
              <InputText
                value={this.state.search}
                onChange={(e) => this.setState({ search: e.target.value })}
                placeholder={t("common.search")}
                type="search"
                onBlur={() => this.setState({ searchBar: false })}
              />
            </form>
          )}

            <div className="header-right-menu p-d-flex p-jc-end">
                <Button icon='pi pi-fw pi-plus' className="p-button-text" onClick={() => {
                    history.push('/new-project')
                }}/>
                {/*<Button icon='pi pi-fw pi-bell' className="p-button-text" onClick={() => {*/}
                {/*    return <Notifications/>*/}
                {/*}}/>*/}
                <Notifications/>
                <Menu model={this.rightMenu} popup ref={el => this.menu = el} id="popup_menu"/>
                <Button icon="pi pi-user" className="p-button-text" onClick={(event) => this.menu.toggle(event)}
                        aria-controls="popup_menu" aria-haspopup/>
            </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
