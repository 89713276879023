import React, {Component} from 'react'
import Header from '../../common/Header'
import {ProjectListingsMenu, ProjectMenu} from '../common/ProjectMenu'
import { alertActions, contributorAction } from '../../../redux/actions'
import {connect} from 'react-redux'
import {authentication} from '../../../redux/reducers/authentication.reducer'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {OverlayPanel} from 'primereact/overlaypanel'
import {InputText} from 'primereact/inputtext'
import {Dialog} from 'primereact/dialog'
import {Toast} from 'primereact/toast'
import {Calendar} from 'primereact/calendar'
import {TabPanel, TabView} from 'primereact/tabview'
import {InputTextarea} from 'primereact/inputtextarea';
import {ScrollPanel} from 'primereact/scrollpanel';
import Dropzone from 'react-dropzone'
import moment from 'moment'
import 'moment/locale/fr'
import { DownloadCloudButton} from '../../common/SVGButtons'
import { Menubar } from "primereact/menubar";
import './ProjectContributorsScreen.scss'
import {addLocale} from 'primereact/api'
import {ProgressSpinner} from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectContributorsScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contributor: [{
                agence: null,
                companyId: 0,
                email: null,
                id: 0,
                name: null,
                phone: null,
                role: null,
                status: 0,
                type: null
            }],
            isFetched: true,
            projectId: JSON.parse(sessionStorage.getItem("ProjectId")),
            contributorDialog: false,
            deleteContributorsDialog: false,
            deleteContributorDialog: false,
            selectedContributor: null,
            openSelectedDialog: false,
            importContributorsDialog: false,
            uploadDocument: [],
            documentContributors: [],
            errors: {},
            emailBody: '',
            activeIndex: 0,
            loading: true,
            searchContributors: '',
            t: this.props.t
        }

        this.importContributors = this.importContributors.bind(this)
        this.deleteAllContributors = this.deleteAllContributors.bind(this)
        this.exportCSV = this.exportCSV.bind(this)
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.onHide = this.onHide.bind(this)
        this.deleteContributor = this.deleteContributor.bind(this)
        this.validateContributor = this.validateContributor.bind(this)
        this.rejectContributor = this.rejectContributor.bind(this)
        this.draftContributor = this.draftContributor.bind(this)
        this.deleteContributorsList = this.deleteContributorsList.bind(this)
    }

    componentDidMount() {
        if (this.props.match.params.projectId) {
            const projectId = this.props.match.params.projectId
            this.setState({
                projectId: projectId
            })
            this.props.getAllContributors(projectId)
        }
    }

    static getDerivedStateFromProps (props, state){
        if(props.contributors.isFetched && props.contributors.type !== state.contributors ){
            return {
                contributors: props.contributors.type,
                isFetched: props.contributors.isFetched,
                loading: false
            }
        }

        if(props.contributors.isFetching){
            return {
                isFetched: false,
                loading: true
            }
        }

        if(props.contributors.isAddFetched ||
            props.contributors.isUpdateFetched ||
            props.contributors.isDeleted ||
            props.contributors.isValidate ||
            props.contributors.isRejected ||
            props.contributors.isDrafted ||
            props.contributors.isAllDeleted ||
            props.contributors.isImported){
            props.getAllContributors(state.projectId)
            return {loading: true}
        }
    }

    importContributors() {
        this.setState({importContributorsDialog: true})
    }

    deleteAllContributors() {
        this.setState({deleteContributorsDialog: true})
    }

    deleteContributorsList() {
        let {projectId} = this.state
        this.props.deleteAllContributor(projectId)
        this.onHide()
    }

    exportCSV() {
        this.dt.exportCSV()
    }

    dateOnShoot(rowData) {
        return `${moment(rowData.startDate).locale('fr').format('LLL')} au ${moment(rowData.endDate).locale('fr').format('LLL')}`
    }

    actionBodyTemplate(rowData) {
        return (
            <>
                <Button type="button" icon='pi' className="p-button-text p-button-lg" onClick={(e) => {
                    this.op.toggle(e)
                    this.setState({rowData: rowData})
                }}>
                    ...
                </Button>
            </>
        )
    }

    renderSwitch(obj) {
        switch (obj.status) {
            case 2 :
                return <div className='p-d-flex p-flex-column p-m-0'>
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftContributor(obj.id)}/>
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectContributor(obj.id)}/>
                </div>
            case 3 :
                return <div className='p-d-flex p-flex-column p-m-0'>
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftContributor(obj.id)}/>
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validateContributor(obj.id)}/>
                </div>
            default:
                return <div className='p-d-flex p-flex-column p-m-0'>
                    <Button label={this.state.t('common.modify')}  className="p-button-text"
                            onClick={() => this.openContributorDialog('éditée', obj)}/>
                    <Button label={this.state.t('common.delete')} className="p-button-text"
                            onClick={() => this.confirmDeleteContributor(obj)}/>
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validateContributor(obj.id)}/>
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectContributor(obj.id)}/>
                </div>
        }
    }

    openSelectedContributorDialog(contributor) {
        this.setState({
            openSelectedDialog: true,
            contributorSelected: contributor
        })
    }

    openContributorDialog(newOrdEdit, contributorEdit) {
        if (newOrdEdit === 'éditée') {
            contributorEdit.startDate = new Date(contributorEdit.startDate)
            contributorEdit.endDate = new Date(contributorEdit.endDate)
            this.setState({
                type: newOrdEdit,
                contributorEdit,
                startDate: contributorEdit.startDate,
                endDate: contributorEdit.endDate,
                contributorDialog: true
            })
        } else {
            this.setState({
                contributorDialog: true,
                contributorEdit: {},
                type: newOrdEdit,
            })
        }
    }

    onHide = () => {
        this.setState({
            contributorDialog: false,
            contributorEdit: null,
            type: null,
            deleteContributorDialog: false,
            contributorDelete: null,
            deleteContributorsDialog: false,
            startDate: null,
            endDate: null,
            selectedContributor: null,
            openSelectedDialog: false,
            importContributorsDialog: false,
            uploadDocumentHebergement: null,
            uploadDocumentTransport: null,
            uploadDocument: [],
            errors: {},
            emailBody: '',
            activeIndex: 0,
            documentContributors: [],
        })
    }

    saveContributor = () => {
        let {projectId, contributorEdit, type, startDate, endDate, errors} = this.state
        if (contributorEdit.email === undefined || contributorEdit.email === null || contributorEdit.email === '') {
            errors.email = this.state.t('toast.emptyEmail');
        }
        else {
            let contributor = {
                name: contributorEdit.name,
                agence: contributorEdit.agence,
                role: contributorEdit.role,
                phone: contributorEdit.phone,
                email: contributorEdit.email,
                startDate: moment(startDate).format(),
                endDate: moment(endDate).format(),
                status: contributorEdit.status
            }
            if (type === 'éditée') {
                this.props.updateContributor(projectId, contributorEdit.id, contributor)

            } else {
                this.props.addContributors(projectId, contributor)
            }

            this.onHide()
        }
    }

    confirmDeleteContributor(contributorDelete) {
        this.setState({
            contributorDelete,
            deleteContributorDialog: true
        })
    }

    // Function pour supprimer UN contributor
    deleteContributor() {
        let {projectId, contributorDelete} = this.state
        this.props.deleteContributor(projectId, contributorDelete.id)
        this.onHide()
    }

    validateContributor = (id) => {
        this.props.validateContributor(this.state.projectId, id)
    }

    rejectContributor = (id) => {
        this.props.rejectContributor(this.state.projectId, id)
    }

    draftContributor = (id) => {
        this.props.draftContributor(this.state.projectId, id)
    }

    onDrop = (file) => {
        let doc = this.state.uploadDocument
        let errors = this.state.errors
        if (file.length > 0) {
            errors['uploadDocument'] = ''

            let reader = new FileReader()
            reader.onload = (file => {
                return () => {
                    // let data = reader.result;
                    doc.push({name: file.name, document: file});
                    this.setState({uploadDocument: doc})
                }
            })(file[0])
            reader.readAsDataURL(file[0])
        } else {
            errors['uploadDocument'] = this.state.t('common.pdfAllowed')
        }
        this.setState({errors: errors})
    }

    onDropContributors = (file) => {
        let {documentContributors} = this.state
        let errors = this.state.errors
        if (file.length > 0) {
            errors['documentContributors'] = ''

            let reader = new FileReader()
            reader.onload = (file => {
                return () => {
                    documentContributors.push({name: file.name, document: file})
                }
            })(file[0])
            reader.readAsDataURL(file[0])
        } else {
            errors['documentContributors'] = this.state.t('common.pdfAllowed')
        }
        this.setState({errors: errors})
    }

    sendAttachFiles = () => {
        let {projectId, contributorSelected, uploadDocument, activeIndex, emailBody} = this.state
        if (activeIndex === 0) {
            let text = this.state.t('projectList.contributor.hello') + ' ' + this.state.contributorSelected && this.state.contributorSelected.name + '. ' + this.state.t('projectList.contributor.trainTicket')
            this.setState({
                activeIndex: 1,
                emailBody: text
            })
        } else {
            let addFiles = {
                message: emailBody,
                files: uploadDocument
            }

            this.props.attachFilesContributors(projectId, contributorSelected.id, addFiles)
            this.onHide()
        }

    }

    importContributorFile = () => {
        let {projectId, documentContributors} = this.state
        this.props.importContributor(projectId, documentContributors)
        this.onHide()
    }

    fr = addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        monthNamesShort: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
        today: 'Aujourd\'hui',
        clear: 'Nettoyer',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    })

    actionMenu = [
        {
            icon: "icon-Nav_Menu icon-2x",
            items: [
                {
                    icon: "icon-Action_ icon-3x",
                    label: this.props.t('projectList.contributor.add'),
                    command: () => {
                        this.importContributors();
                    },
                    template: (item, options) => {
                        return (
                            /* custom element */
                            <Button
                                icon={item.icon}
                                className={options.className + " p-button-text session-button"}
                                onClick={item.command}
                                label={item.label}
                            />
                        );
                    },
                },
                {
                    icon: "icon-Session_Delete icon-3x",
                    label: this.props.t('projectList.contributor.delete'),
                    command: () => {
                        this.deleteAllContributors()
                    },
                    template: (item, options) => {
                        return (
                            /* custom element */
                            <Button
                                icon={item.icon}
                                className={options.className + " p-button-text session-button"}
                                onClick={item.command}
                                label={item.label}
                            />
                        );
                    },
                },
                {
                    icon: "icon-Action_Download icon-3x",
                    label: this.props.t('projectList.shootingList.exportCSV'),
                    command: () => {
                        this.exportCSV()
                    },
                    template: (item, options) => {
                        return (
                            /* custom element */
                            <Button
                                icon={item.icon}
                                className={options.className + " p-button-text session-button"}
                                onClick={item.command}
                                label={item.label}
                            />
                        );
                    },
                },
            ],
        },
    ];

    render() {

        const deleteContributorDialogFooter = (
            <>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text" onClick={this.onHide}/>
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteContributor}/>
            </>
        )

        const deleteContributorsDialogFooter = (
            <>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text" onClick={this.onHide}/>
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteContributorsList}/>
            </>
        )

        const selectedContributorDialogFooter = (
            <>
                <Button label={this.state.t('common.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.onHide}/>
                <Button label={this.state.t('projectList.contributor.sendEmail')} icon="pi pi-check" className="p-button-text" onClick={this.sendAttachFiles}/>
            </>
        )

        const importContributor = (
            <>
                <Button label={this.state.t('common.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.onHide}/>
                <Button label={this.state.t('common.import')} icon="pi pi-check" className="p-button-text" onClick={this.importContributorFile}/>
            </>
        )

        const addOrEditFooter = (
            <div className="p-d-flex p-flex-row p-jc-end p-ai-end">
                <Button type="button"
                        label={this.state.t('common.cancel')}
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={this.onHide}
                        style={{width: "20%"}}
                />
                <Button type="submit"
                        label={this.state.type === 'éditée' ? this.state.t('common.modify') : this.state.t('common.add')}
                        icon="pi pi-check" className="p-button-text"
                        style={{width: "20%"}}
                        onClick={(e) => this.saveContributor(e)}
                />
            </div>
        )

        return (
            <div>
                <Header/>
                <ProjectMenu activeItemIndex={2}/>
                {this.state.isFetched ? <div className="p-d-flex p-mt-6">
                        <div className="p-col-2">
                            <ProjectListingsMenu/>
                        </div>
                        <div className="p-col  p-mr-6">
                            <Toast ref={(el) => this.toast = el}/>
                            <div className="p-grid p-align-center">
                                <div className="p-col-11">
                                    <h5>{this.state.t('projectList.contributor.title')}</h5>
                                </div>
                                <div className="p-col-1">
                                    <Menubar model={this.actionMenu}  className="action-button"/>
                                </div>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-end">
                                <DataTable ref={(el) => {this.dt = el}}
                                           style={{marginTop: '20px'}}
                                           value={this.state.contributors}
                                           paginator rows={10}
                                           loading={this.state.loading}
                                           columnResizeMode='expand'
                                           globalFilter={this.state.searchContributors}
                                           resizableColumns
                                           selection={this.state.selectedContributor}
                                           onSelectionChange={e => this.setState({selectedContributor: e.value})}
                                           selectionMode="single"
                                           dataKey="id"
                                           onRowSelect={e => this.openSelectedContributorDialog(e.data)}
                                           emptyMessage={this.state.t('projectList.contributor.noFind')}>
                                    <Column field="name" header={this.state.t('common.name')} sortable filterMatchMode="contains"/>
                                    <Column field="agence" header={this.state.t('projectList.contributor.agency')}  sortable filterMatchMode="contains"/>
                                    <Column field="role" header={this.state.t('common.role')} sortable filterMatchMode="contains"/>
                                    <Column field="phone" header={this.state.t('projectList.contributor.tel')} filterMatchMode="contains"/>
                                    <Column field="email" header={this.state.t('common.email')} filterMatchMode="contains"/>
                                    <Column body={this.dateOnShoot} header={this.state.t('projectList.contributor.dateOnShoot')}/>
                                    <Column body={this.actionBodyTemplate} style={{width: '12%'}}/>
                                </DataTable>
                                <Button label={this.state.t('common.add')} icon="pi pi-plus" className="p-button-text p-my-1"
                                        onClick={() => this.openContributorDialog('ajoutée')}/>

                                {/* Overlay pour Modifier, Supprimer, Draft ou Edit */}
                                <OverlayPanel className="contributors-panel" ref={(el) => this.op = el}>
                                    {this.state.rowData && this.renderSwitch(this.state.rowData)}
                                </OverlayPanel>

                                {/* Popup pour ajouter/modifier un contributor  */}

                                <Dialog visible={this.state.contributorDialog}
                                        style={{width: '30vw', height: '70vh', minWidth: '550px', minHeight: '660px', maxHeight: '880px', maxWidth: '790px'}}
                                        header={this.state.t('projectList.contributor.detail')}
                                        modal
                                        className="p-fluid"
                                        footer={addOrEditFooter}
                                        onHide={this.onHide}>
                                    {this.state.contributorEdit &&
                                    <ScrollPanel style={{height: "90%"}}>
                                    <span className='p-float-label p-mt-5 p-field p-col-12'>
                                        <InputText id='name'
                                                   value={this.state.contributorEdit.name}
                                                   onChange={(e) => this.setState(prevState => ({
                                                       contributorEdit: {
                                                           ...prevState.contributorEdit,
                                                           name: e.target.value
                                                       }
                                                   }))}
                                                   required
                                                   className={this.state.errors.name ? 'p-invalid' : ''}
                                        />
                                        <label htmlhtmlFor='name'>{this.state.t('common.name')}</label>
                                        {this.state.errors.name !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.name}</small> : <></>}
                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <InputText id='agence'
                                                     value={this.state.contributorEdit.agence}
                                                     onChange={(e) => this.setState(prevState => ({
                                                         contributorEdit: {
                                                             ...prevState.contributorEdit,
                                                             agence: e.target.value
                                                         }
                                                     }))}
                                                     required
                                                     className={this.state.errors.agence ? 'p-invalid' : ''}
                                          />
                                          <label htmlhtmlFor='agence'>{this.state.t('projectList.contributor.agency')}</label>
                                            {this.state.errors.agence !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.agence}</small> : <></>}

                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <InputText id='role'
                                                     value={this.state.contributorEdit.role}
                                                     onChange={(e) => this.setState(prevState => ({
                                                         contributorEdit: {
                                                             ...prevState.contributorEdit,
                                                             role: e.target.value
                                                         }
                                                     }))}
                                                     required
                                                     className={this.state.errors.role ? 'p-invalid' : ''}
                                          />
                                          <label htmlhtmlFor='role'>{this.state.t('common.role')}</label>
                                            {this.state.errors.role !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.role}</small> : <></>}

                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <InputText id='phone'
                                                     value={this.state.contributorEdit.phone}
                                                     keyfilter="num"
                                                     onChange={(e) => this.setState(prevState => ({
                                                         contributorEdit: {
                                                             ...prevState.contributorEdit,
                                                             phone: e.target.value
                                                         }
                                                     }))}
                                                     required
                                                     className={this.state.errors.phone ? 'p-invalid' : ''}
                                          />
                                          <label htmlhtmlFor='phone'>{this.state.t('projectList.contributor.phone')}</label>
                                            {this.state.errors.phone !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.phone}</small> : <></>}

                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <InputText id='email'
                                                     value={this.state.contributorEdit.email}
                                                     keyfilter="email"
                                                     onChange={(e) => this.setState(prevState => ({
                                                         contributorEdit: {
                                                             ...prevState.contributorEdit,
                                                             email: e.target.value
                                                         }
                                                     }))}
                                                     required
                                                     className={this.state.errors.email ? 'p-invalid' : ''}
                                          />
                                          <label htmlhtmlFor='email'>{this.state.t('common.email')} </label>
                                            {this.state.errors.email !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.email}</small> : <></>}

                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <Calendar id='startDate'
                                                    value={this.state.startDate}
                                                    onChange={(e) => this.setState({startDate: e.target.value})}
                                                    dateFormat="dd/mm/yy"
                                                    showTime hourFormat="24"
                                              //stepMinute={15}
                                                    placeholder={this.state.t('projectList.contributor.startDate')}
                                                    locale={this.fr}
                                                    minDate={new Date()}
                                                    required
                                          />
                                            {/* <label htmlhtmlFor='startDate'>Date du début</label> */}
                                    </span>
                                        <span className='p-float-label p-field p-col-12'>
                                          <Calendar id='endDate'
                                                    value={this.state.endDate}
                                                    onChange={(e) => this.setState({endDate: e.target.value})}
                                                    dateFormat="dd/mm/yy"
                                                    showTime
                                                    hourFormat="24"
                                              //stepMinute={15}
                                                    placeholder={this.state.t('projectList.contributor.endDate')}
                                                    minDate={this.state.startDate}
                                                    locale={this.fr}
                                                    required
                                          />
                                            {/* <label htmlhtmlFor='endDate'>Date du fin</label> */}
                                    </span>
                                    </ScrollPanel>
                                    }
                                </Dialog>

                                <Dialog visible={this.state.deleteContributorDialog}
                                        style={{width: '450px'}}
                                        header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                                        modal
                                        footer={deleteContributorDialogFooter}
                                        onHide={this.onHide}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3"
                                           style={{fontSize: '2rem'}}/>
                                        {this.state.contributorDelete &&
                                        <span>{this.state.t('projectList.contributor.deleteContributor')}</span>}
                                    </div>
                                </Dialog>

                                <Dialog visible={this.state.deleteContributorsDialog}
                                        style={{width: '450px'}}
                                        header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                                        modal
                                        footer={deleteContributorsDialogFooter}
                                        onHide={this.onHide}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3"
                                           style={{fontSize: '2rem'}}/>
                                        <span>{this.state.t('projectList.contributor.deleteContributors')}</span>
                                    </div>
                                </Dialog>

                                <Dialog visible={this.state.openSelectedDialog}
                                        style={{width: '40vw'}}
                                        header={this.state.contributorSelected && this.state.contributorSelected.name}
                                        modal
                                        footer={selectedContributorDialogFooter}
                                        onHide={this.onHide}>
                                    <TabView headerStyle={{marginRight: '10px'}}
                                             activeIndex={this.state.activeIndex}
                                             onTabChange={(e) => {
                                                 this.setState({activeIndex: e.index})
                                             }}>
                                        <TabPanel header={this.state.t('projectList.contributor.information')} leftIcon="pi pi-info-circle">
                                            <div className="p-my-3">
                                                <p><span className="light">{this.state.t('projectList.contributor.phone')}  : </span>{this.state.contributorSelected && this.state.contributorSelected.role}</p>
                                                <p><span className="light">{this.state.t('projectList.contributor.email')} : </span>{this.state.contributorSelected && this.state.contributorSelected.email}</p>
                                                <p><span className="light">{this.state.t('projectList.contributor.phone')} : </span>{this.state.contributorSelected && this.state.contributorSelected.phone}</p>
                                            </div>
                                            <div className="p-my-6 p-grid">
                                                <div className="p-mr-2 p-col">
                                                    <h5>{this.state.t('projectList.contributor.transport')}</h5>
                                                    <div className="p-p-4">
                                                        <Dropzone onDrop={this.onDrop} multiple={false}
                                                                  accept=".pdf">
                                                            {({getRootProps, getInputProps}) => (
                                                                <div {...getRootProps({className: 'dropzone'})} style={{width: '100%', height: '100%'}}>
                                                                    <form>
                                                                        <DownloadCloudButton/>
                                                                        <p className="p-text-light">{this.state.t('projectList.contributor.dropFiles')}</p>
                                                                        <input {...getInputProps()} />
                                                                        <Button label={this.state.t('common.add')}
                                                                                className="p-d-block p-mx-auto p-button-sm"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                }}/>
                                                                    </form>
                                                                    {this.state.uploadDocument.length > 0 ?
                                                                        <aside className="p-mt-3">
                                                                            <h5 className='p-mb-0'>{this.state.t('projectList.contributor.file')}</h5>
                                                                            <span>{this.state.uploadDocument[0].name}</span>
                                                                        </aside>
                                                                        :
                                                                        <></>}
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                                <div className="p-ml-2 p-col">
                                                    <h5>{this.state.t('projectList.contributor.accommodation')}</h5>
                                                    <div className='p-p-4 '>
                                                        <Dropzone onDrop={this.onDrop} multiple={false}
                                                                  accept=".pdf">
                                                            {({getRootProps, getInputProps}) => (
                                                                <div {...getRootProps({className: 'dropzone'})} style={{width: '100%', height: '100%'}}>
                                                                    <form className="pdc_dropZone_container">
                                                                        <DownloadCloudButton/>
                                                                        <p className="pdc_dropZone_text">{this.state.t('projectList.contributor.dropFiles')}</p>
                                                                        <Button label={this.state.t('common.add')}
                                                                                className="p-d-block p-mx-auto p-button-sm"
                                                                                onClick={(e) => {e.preventDefault()}}/>
                                                                        <input {...getInputProps()} />
                                                                    </form>
                                                                    {this.state.uploadDocument.length > 0 && this.state.uploadDocument[1] ?
                                                                        <aside className="p-mt-3">
                                                                            <h5 className='p-mb-0'>{this.state.t('projectList.contributor.file')}</h5>
                                                                            <span>{this.state.uploadDocument[1].name}</span>
                                                                        </aside>
                                                                        :
                                                                        <></>}
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel header={this.state.t('projectList.contributor.preview')} leftIcon="pi pi-eye" className="p-grid">
                                            <InputTextarea className="p-my-6 p-col" rows={5} value={this.state.emailBody}
                                                           style={{width: '100%'}}
                                                           onChange={(e) => this.setState({emailBody: e.target.value})}
                                                           autoResize/>
                                        </TabPanel>
                                    </TabView>
                                </Dialog>

                                <Dialog
                                    visible={this.state.importContributorsDialog}
                                    style={{width: '40vw', height: '40vh'}}
                                    header={this.state.t('projectList.contributor.importContributor')}
                                    modal
                                    footer={importContributor}
                                    onHide={this.onHide}
                                >
                                    <Dropzone onDrop={this.onDropContributors} multiple={false}
                                              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({className: 'dropzone'})} style={{width: '100%', height: '100%'}}>
                                                <form
                                                    className="p-d-flex p-flex-column p-jc-center p-ai-center p-py-5">
                                                    <DownloadCloudButton/>
                                                    <p>{this.state.t('projectList.contributor.dragFile')}</p>
                                                    <Button label={this.state.t('common.add')} className="p-d-block p-mx-auto p-button-sm"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                            }}/>
                                                    <input {...getInputProps()} />
                                                </form>
                                                {this.state.documentContributors.length > 0 && this.state.documentContributors[0] ?
                                                    <aside className="p-mt-3">
                                                        <h5 className='p-mb-0'>{this.state.t('projectList.contributor.file')}</h5>
                                                        <span>{this.state.documentContributors[0].name}</span>
                                                    </aside>
                                                    :
                                                    <></>}
                                            </div>
                                        )}
                                    </Dropzone>
                                </Dialog>

                            </div>
                        </div>
                    </div> :
                    <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "60vh"}}>
                        <ProgressSpinner className="p-progress-spinner"/>
                    </div>}
            </div>
        )
    }
}

function mapState(state) {
    const {contributors} = state
    const {user} = authentication
    return {user, contributors}
}

const actionCreators = {
    addContributors: contributorAction.addContributors,
    getAllContributors: contributorAction.getAllContributors,
    deleteContributor: contributorAction.deleteContributor,
    updateContributor: contributorAction.updateContributor,
    validateContributor: contributorAction.validateContributor,
    rejectContributor: contributorAction.rejectContributor,
    draftContributor: contributorAction.draftContributor,
    deleteAllContributor: contributorAction.deleteAllContributor,
    importContributor: contributorAction.importContributor,
    attachFilesContributors: contributorAction.attachFilesContributors,
    successWarning: alertActions.success
}

const connectedApp = connect(mapState, actionCreators)(withTranslation()(ProjectContributorsScreen))
export {connectedApp as ProjectContributorsScreen}
