import React, {Component} from 'react'
import Header from '../../common/Header'
import {ProjectListingsMenu, ProjectMenu} from '../common/ProjectMenu'
import {DeleteButton, ExportButton, SettingButton} from '../../common/SVGButtons'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {Toast} from 'primereact/toast'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import {OverlayPanel} from 'primereact/overlaypanel'
import {addLocale} from 'primereact/api';
import {connect} from 'react-redux'
import {authentication} from '../../../redux/reducers/authentication.reducer'
import {placeAction} from '../../../redux/actions'
import './ProjectPlacesScreen.scss'
import moment from 'moment'
import 'moment/locale/fr'
import {ProgressSpinner} from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectPlacesScreen extends Component {
    constructor (props) {
        super(props)
        this.state = {
            place: [],
            projectId: null,
            placeDialog: false,
            placeEdit: {},
            loading: true,
            errors : {},
            t: this.props.t
        }
        this.deleteAllPlaces = this.deleteAllPlaces.bind(this)
        this.exportCSV = this.exportCSV.bind(this)
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.confirmDeletePlace = this.confirmDeletePlace.bind(this)
        this.hideDialog = this.hideDialog.bind(this)
        this.deletePlace = this.deletePlace.bind(this)
        this.deleteSelectedPlaces = this.deleteSelectedPlaces.bind(this)
        this.validatePlace = this.validatePlace.bind(this)
        this.rejectPlace = this.rejectPlace.bind(this)
        this.draftPlace = this.draftPlace.bind(this)
        this.renderSwitch = this.renderSwitch.bind(this)
    }

    componentDidMount () {
        const projectId = this.props.match.params.projectId
        this.setState({ projectId: projectId })
        this.props.getAllPlace(projectId)
        setTimeout(() => {
            this.setState({loading : false})
        }, 5000);
    }

    static getDerivedStateFromProps (props, state){
        if(props.place.isFetched && props.place.type !== state.place ){
            return {
                place: props.place.type,
                isFetched: props.place.isFetched,
                loading: false
            }
        }

        if(props.place._isFetched ||
            props.place.fetched ||
            props.place.isDeleted ||
            props.place.isValidate ||
            props.place.isRejected ||
            props.place.isDrafted ||
            props.place.isAllDeleted){
            props.getAllPlace(state.projectId)
            return {loading: true}
        }
    }

    deleteAllPlaces (id) {
        this.setState({
            deletePlacesDialog: true,
            categorieId: id
        })
    }

    exportCSV () {
        console.log(
            'this.dt', this.dt
        )
        this.dt.exportCSV()
    }

    imageBodyTemplate (rowData) {
        return <img src={rowData.icon} alt={rowData.weather} className='weather-icon'/>
    }

    reservationBodyTemplate = (rowData) => {
        return moment(rowData.reservationDate).locale('fr').format('LLL')
    }

    hideDialog () {
        this.setState({
            submitted: false,
            placeDialog: false,
            placeEdit: {},
            reservationDate: null,
            deletePlacesDialog: false,
            deletePlaceDialog: false,
            categorieId: null,
            type: ''
        })
        setTimeout(() => console.log('hideDialog State', this.state))
    }

    //---------------------------------------------------------------------------------------------------------

    //Function pour ouvrir la popup ajouter ou editer Place
    openPlaceDialog (newOrdEdit, categorieId, placeEdit) {
        if (newOrdEdit === 'éditée') {
            console.log('placeEdit', placeEdit)
            placeEdit.reservationDate = new Date(placeEdit.reservationDate)
            this.setState({
                type: newOrdEdit,
                placeEdit,
                reservationDate: placeEdit.reservationDate,
                placeDialog: true
            })
        } else {
            this.setState({
                placeDialog: true,
                placeEdit: {
                    placeCategoryId: categorieId
                },
                type: newOrdEdit,
            })
        }
    }

    //---------------------------------------------------------------------------------------------------------

    confirmDeletePlace (placeDelete) {
        console.log('comfirmDeletePlace', placeDelete)
        this.setState({
            placeDelete,
            deletePlaceDialog: true
        })
    }

    deletePlace () {
        let { projectId, placeDelete } = this.state
        this.props.deletePlace(projectId, placeDelete.id)
        this.setState({
            deletePlaceDialog: false,
            placeDelete: null
        })

    }

    deleteSelectedPlaces () {
        let { projectId, categorieId } = this.state
        this.props.deleteAllPlace(projectId, categorieId)
        this.setState({
            categorieId: null,
            deletePlacesDialog: false
        })
    }

    actionBodyTemplate (rowData) {
        return (
            <>
                <Button type="button" icon='pi pi-ellipsis-h' className="p-button-text p-button-lg" onClick={(e) => {
                    this.op.toggle(e)
                    this.setState({ rowData: rowData })
                }}/>
            </>
        )
    }

    //---------------------------------------------------------------------------------------------------------

    //Function pour ajouter ou editer Place - API
    savePlace = () => {
        let { projectId, placeEdit, reservationDate, type, errors } = this.state
        console.log('placeEdit', placeEdit)
        if (placeEdit.name === undefined || placeEdit.name === null || placeEdit.name === '') {
            errors.name = 'Le nom est requis';
        }
        // if (placeEdit.address === undefined || placeEdit.address === null || placeEdit.address === '') {
        //     errors.address = "L'adresse est requise";
        // }
        // if (placeEdit.contactEmail === undefined || placeEdit.contactEmail === null || placeEdit.contactEmail === '') {
        //     errors.email = 'L\'email est requis';
        // }
        // if (placeEdit.contactPhone === undefined || placeEdit.contactPhone === null || placeEdit.contactPhone === '') {
        //     errors.phone = 'Le numéro de téléphone est requis';
        // }
        else {
            let place = {
                name: placeEdit.name,
                address: placeEdit.address,
                contactEmail: placeEdit.contactEmail,
                contactPhone: placeEdit.contactPhone,
                reservationDate: moment(reservationDate).format(),
                placeCategoryId: placeEdit.placeCategoryId,
                status: placeEdit.status
            }
            console.log('place', place)
            if (type === 'éditée') {
                this.props.updatePlace(projectId, placeEdit.id, place)

            } else {
                this.props.addPlaces(projectId, place)
            }
            this.hideDialog()
        }

    }
    //---------------------------------------------------------------------------------------------------------

    validatePlace = (id) => {
        console.log('id', id)
        this.props.validatePlace(this.state.projectId, id)
    }

    rejectPlace = (id) => {
        this.props.rejectPlace(this.state.projectId, id)
    }

    draftPlace = (id) => {
        this.props.draftPlace(this.state.projectId, id)
    }

    renderSwitch (obj) {
        switch (obj.status) {
            case 2 :
                return <div className='p-d-flex p-flex-column'>
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftPlace(obj.id)}/>
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectPlace(obj.id)}/>
                </div>
            case 3 :
                return <div className='p-d-flex p-flex-column'>
                    <Button label={this.state.t('projectList.contributor.draft')} className="p-button-text" onClick={() => this.draftPlace(obj.id)}/>
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validatePlace(obj.id)}/>
                </div>
            default:
                return <div className='p-d-flex p-flex-column'>
                    <Button label={this.state.t('common.modify')}  className="p-button-text"
                            onClick={() => this.openPlaceDialog('éditée', obj.placeCategoryId, obj)}/>
                    <Button label={this.state.t('common.delete')} className="p-button-text" onClick={() => this.confirmDeletePlace(obj)}/>
                    <Button label={this.state.t('common.valid')} className="p-button-text" onClick={() => this.validatePlace(obj.id)}/>
                    <Button label={this.state.t('projectList.contributor.reject')} className="p-button-text" onClick={() => this.rejectPlace(obj.id)}/>
                </div>
        }
    }

    fr = addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        monthNamesShort: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
        today: 'Aujourd\'hui',
        clear: 'Nettoyer',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    })

    render () {

        const placeDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.cancel')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label={this.state.type === 'éditée' ? this.state.t('common.modify') : this.state.t('common.add')} icon="pi pi-check"
                        className="p-button-text" onClick={this.savePlace}/>
            </React.Fragment>
        )

        const deletePlaceDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deletePlace}/>
            </React.Fragment>
        )

        const deletePlacesDialogFooter = (
            <React.Fragment>
                <Button label={this.state.t('common.no')} icon="pi pi-times" className="p-button-text"
                        onClick={this.hideDialog}/>
                <Button label={this.state.t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedPlaces}/>
            </React.Fragment>
        )

        return (
            <div>
                <Header/>
                <ProjectMenu activeItemIndex={2}/>
                {this.state.isFetched ? <div className='p-d-flex p-mt-6'>
                    <div className='p-col-2'>
                        <ProjectListingsMenu/>
                    </div>
                    <div className='p-col'>
                        <Toast ref={(el) => this.toast = el}/>
                        {this.state.isFetched && this.state.place.map((place) => {
                            return (
                                <>
                                    <div className='p-col-10'>
                                        <div className='p-d-flex p-mt-3 p-ai-center'>
                                            <div className='p-col-3'>
                                                <h5>{place.title}</h5>
                                            </div>
                                            <div className='p-col-9 p-d-flex p-jc-end'>
                                                <SettingButton className='p-mr-1'/>
                                                <DeleteButton className='p-mr-1'
                                                              onClick={() => this.deleteAllPlaces(place.id)}/>
                                                <ExportButton onClick={() => this.exportCSV(place.places)}/>

                                            </div>
                                        </div>
                                        <div className='p-d-flex p-flex-column p-ai-end'>
                                            <DataTable
                                                style={{ marginTop: '20px' }}
                                                value={place.places}
                                                loading={!(this.state.isFetched)}
                                                emptyMessage={`Aucun ${place.title.toLowerCase()} trouvé`}
                                                resizableColumns
                                                columnResizeMode='expand'
                                                ref={(el) => { this.dt = el }}
                                                rows={10}
                                            >
                                                <Column field='name' header={this.state.t('common.name')} sortable style={{ width: '14%' }}/>
                                                <Column field='address' header={this.state.t('projectList.place.adress')} style={{ width: '20%' }} sortable/>
                                                <Column field='contactEmail' header={this.state.t('common.email')}  style={{ width: '20%' }} sortable/>
                                                <Column field='contactPhone' header={this.state.t('projectList.contributor.phone')} />
                                                <Column body={this.reservationBodyTemplate} field="reservationDate" header={this.state.t('projectList.place.reservation')} sortable/>
                                                <Column body={this.imageBodyTemplate} style={{ width: '8%' }} header={this.state.t('projectList.place.weather')}/>
                                                <Column body={this.actionBodyTemplate} style={{ width: '12%' }}/>
                                            </DataTable>
                                            <Button label={this.state.t('common.add')}  icon="pi pi-plus" className="p-button-text p-my-1"
                                                    onClick={() => this.openPlaceDialog('ajoutée', place.id)}/>
                                        </div>

                                        {/* Overlay pour Modifier, Supprimer, Draft ou Edit */}
                                        <OverlayPanel ref={(el) => this.op = el}>
                                            {this.state.rowData ? console.log('rowData', this.state.rowData) : <></>}
                                            {this.state.rowData && this.renderSwitch(this.state.rowData)}
                                        </OverlayPanel>

                                        {/* Popup pour ajouter/modifier une place */}
                                        <Dialog visible={this.state.placeDialog}
                                                style={{ width: '30vw', height: '70vh' }}
                                                header={this.state.t('projectList.place.placeDetails')}
                                                modal
                                                className="p-fluid"
                                                footer={placeDialogFooter}
                                                onHide={this.hideDialog}>
                                            <div className='p-fluid p-d-flex  p-flex-column p-jc-between p-ai-center'>
                                                <span className='p-float-label p-mt-5 p-field p-col-12'>
                                                  <InputText id='name'
                                                             value={this.state.placeEdit.name}
                                                             onChange={( e ) => this.setState(prevState => ({
	                                                             placeEdit: {
		                                                             ...prevState.placeEdit,
		                                                             name: e.target.value
	                                                             }
                                                             }))}
                                                             className={this.state.errors.name ? 'p-invalid' : ''}
                                                  />
                                                  <label htmlhtmlFor='name'>{this.state.t('common.name')}</label>
	                                                {this.state.errors.name !== '' ? <small id="username2-help" className="p-error p-d-block">{this.state.errors.name}</small> : <></>}
                                                </span>
												<span className='p-float-label p-field p-col-12'>
                                                  <InputText id='address'
                                                             value={this.state.placeEdit.address}
                                                             onChange={( e ) => this.setState(prevState => ({
	                                                             placeEdit: {
		                                                             ...prevState.placeEdit,
		                                                             address: e.target.value
	                                                             }
                                                             }))}
                                                             className={this.state.errors.address ? 'p-invalid' : ''}
                                                  />
                                                  <label htmlhtmlFor='address'>{this.state.t('projectList.place.adress')}</label>
													{this.state.errors.address !== '' ? <small id="username2-help"
													                                           className="p-error p-d-block">{this.state.errors.address}</small> : <></>}
                                                </span>
												<span className='p-float-label p-field p-col-12'>
                                                  <InputText id='email'
                                                             keyfilter='email'
                                                             value={this.state.placeEdit.contactEmail}
                                                             onChange={( e ) => this.setState(prevState => ({
	                                                             placeEdit: {
		                                                             ...prevState.placeEdit,
		                                                             contactEmail: e.target.value
	                                                             }
                                                             }))}
                                                             className={this.state.errors.email ? 'p-invalid' : ''}
                                                  />
                                                  <label htmlhtmlFor='email'>{this.state.t('common.email')}</label>
													{this.state.errors.email !== '' ? <small id="username2-help"
													                                         className="p-error p-d-block">{this.state.errors.email}</small> : <></>}

                                                </span>
												<span className='p-float-label p-field p-col-12'>
                                                  <InputText id='phone' keyfilter='num'
                                                             value={this.state.placeEdit.contactPhone}
                                                             onChange={( e ) => this.setState(prevState => ({
	                                                             placeEdit: {
		                                                             ...prevState.placeEdit,
		                                                             contactPhone: e.target.value
	                                                             }
                                                             }))}
                                                             className={this.state.errors.phone ? 'p-invalid' : ''}
                                                  />
                                                  <label htmlhtmlFor='phone'>{this.state.t('projectList.contributor.phone')}</label>
													{this.state.errors.phone !== '' ? <small id="username2-help"
													                                         className="p-error p-d-block">{this.state.errors.phone}</small> : <></>}
                                                </span>
												<span className='p-float-label p-field p-col-12'>
                                                    <Calendar id='horaire'
                                                              locale={this.fr}
                                                              stepMinute={15}
                                                              minDate={new Date()}
                                                              value={this.state.reservationDate}
                                                              onChange={( e ) => this.setState({reservationDate: e.target.value})}
                                                              dateFormat="dd/mm/yy"
                                                              showTime hourFormat="24"/>
                                                    <label htmlhtmlFor='horaire'>{this.state.t('projectList.place.hourly')}</label>
                                                </span>
											</div>
										</Dialog>

										{/* Popup pour supprimer une place */}
										<Dialog visible={this.state.deletePlaceDialog} style={{width: '450px'}}
										        header={this.state.t('dashboardScreen.projectCard.deleteQuestion')} modal
										        footer={deletePlaceDialogFooter}
										        onHide={this.hideDialog}>
											<div className="confirmation-content">
												<i className="pi pi-exclamation-triangle p-mr-3"
												   style={{fontSize: '2rem'}}/>
												{this.state.placeDelete &&
												<span>{this.state.t('projectList.place.deletePlace')}</span>}
											</div>
										</Dialog>

										{/* Popup pour supprimer toutes les listes places */}
										<Dialog visible={this.state.deletePlacesDialog} style={{width: '450px'}}
										        header={this.state.t('dashboardScreen.projectCard.deleteQuestion')}
                                                modal
										        footer={deletePlacesDialogFooter}
										        onHide={this.hideDialog}>
											<div className="confirmation-content">
												<i className="pi pi-exclamation-triangle p-mr-3"
												   style={{fontSize: '2rem'}}/>
												<span>{this.state.t('projectList.place.deleteAll')}</span>
											</div>
										</Dialog>
									</div>
								</>)
							})}
						</div>
					</div> :
					<div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{height: "60vh"}}>
						<ProgressSpinner className="p-progress-spinner"/>
					</div>}
			</div>
		)
	}
}

const mapStateToProps = ( state ) => {
	const {place} = state
	const {user} = authentication
	return {user, place}
}

const actionPlaces = {
	addPlaces: placeAction.addPlaces,
	getAllPlace: placeAction.getAllPlaces,
	deletePlace: placeAction.deletePlace,
	updatePlace: placeAction.updatePlace,
	validatePlace: placeAction.validatePlace,
	rejectPlace: placeAction.rejectPlace,
	draftPlace: placeAction.draftPlace,
	deleteAllPlace: placeAction.deleteAllPlace
}

const connectedPlaceListing = connect(mapStateToProps, actionPlaces)(withTranslation()(ProjectPlacesScreen))
export {connectedPlaceListing as ProjectPlacesScreen}
