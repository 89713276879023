import React, { Component } from 'react'
import { SessionDetailsMenu, SessionHeader } from './SessionDetailsMenu'
import { connect } from 'react-redux'
import { sessiondetailsActions } from '../../redux/actions'
import { ProgressSpinner } from 'primereact/progressspinner'
import PrismaZoom from 'react-prismazoom'
import './SessionOverview.scss'

class SessionOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {
            image: null,
            imageId: '',
            productId: 0,
            project: JSON.parse(localStorage.getItem('project')),
            isLoading: false,
            zoom: 1
        }
        this.prismaZoom = React.createRef();
    }

    componentDidMount() {
        if (this.props.match.params.productId && this.props.match.params.imageId) {
            this.setState({ imageId: this.props.match.params.imageId, productId: this.props.match.params.productId })
            this.props.getImageOverview(this.state.project.projectId, this.props.match.params.productId, this.props.match.params.imageId)
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isFetching && !nextProps.isFetched) {
            this.setState({ image: null, isLoading: true })
        }
        if (nextProps.image && nextProps.image !== this.state.image && nextProps.isFetched && !nextProps.isFetching) {
            this.setState({ isLoading: false, image: nextProps.image })
        }
    }
    
    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onClickOnZoomOut = event => {
        console.log("Prisma Zoom : ", this.prismaZoom)
        this.prismaZoom.current.zoomOut(1)
    }

    onClickOnZoomIn = event => {
        this.prismaZoom.current.zoomIn(1)
    }

    render() {
     
        return (
            <div className="p-grid p-dir-col p-d-flex p-ai-center" style={{ overflow: "hidden" }}>
                <PrismaZoom topBoundary={120} onZoomChange={this.onZoomChange} ref={this.prismaZoom}>
                    <div className="p-dir-col" style={{ height: 'calc(100vh - 80px)' }}>
                        {this.state.image && this.state.image.url &&
                            <SessionHeader projectId={this.state.project.projectId} title={this.state.image.productName} />
                        }
                        <div className="p-d-flex p-jc-center">
                            {this.state.isLoading && !this.state.image ?
                                <div className="p-d-flex p-justify-center p-ai-center p-my-6" style={{ maxHeight: 'calc(100vh - 110px)' }}>
                                    <ProgressSpinner strokeWidth="3" />

                                </div>
                                :
                                <>
                                    {this.state.image && this.state.image.url && <img src={process.env.REACT_APP_BASE_URL + this.state.image.url}
                                        style={{ objectFit: 'cover', maxHeight: 'calc(100vh - 110px)', height: 'auto', maxWidth: '100%' }}
                                        alt={this.state.image.productName} />}
                                </>
                            }
                        </div>
                    </div>
                </PrismaZoom>
                <div className="p-d-flex p-ai-center p-px-3 p-py-2 zoom-panel">
                    <span className="zoom-minus" onClick={this.onClickOnZoomOut}></span>
                    <span className="zoompercent">{`${parseInt((this.state.zoom * 100))}%`}</span>
                    <span className="zoom-plus" onClick={this.onClickOnZoomIn}></span>
                </div>
                <SessionDetailsMenu projectId={this.state.project.projectId} productId={this.state.productId}
                    imageId={this.state.imageId} />

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { image, isFetched, isFetching } = state.sessiondetails
    return { image, isFetched, isFetching }
}

const mapDispatchToProps = {
    getImageOverview: sessiondetailsActions.getImageOverview
}

const connectedSessionOverview = connect(mapStateToProps, mapDispatchToProps)(SessionOverview)
export { connectedSessionOverview as SessionOverview }
