import { commonService } from './common.service';


export const catalogService = {
    getProducts,
    getProduct,
    addCatalog,
    deleteCatalog,
    setShootedProduct,
    linkProduct,
    searchProduct,
    getFullCatalog,
    setInventoryStatus,
    addProductsToProject,
    generateBarCodes,
    printShootList,
    addManuallyProduct,
    findProduct,
    showImg,
    getLinkedproducts
};
function getLinkedproducts(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
     };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/${productId}/link`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
function getProducts(projectId, dtoShoot) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(dtoShoot),
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getProduct(productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
     };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/catalog/products/${productId}/search`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
function addCatalog(projectId, catalog, bool = true) {
    let user = JSON.parse(localStorage.getItem('user'));
    let formdata = new FormData();
    formdata.append("file", catalog[0].document, catalog[0].name);
    console.log('formdata', formdata)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token

        },
        body: formdata
    };
    let url = projectId === 0 ?
        `${process.env.REACT_APP_BASE_URL}/api/catalog/import/${bool}` :
        `${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/import/${bool}`
    return fetch(url, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function deleteCatalog(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(productId)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setShootedProduct(projectId, productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/${productId}/shoot`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}


function linkProduct(projectId, productId, selectedProdduct) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(selectedProdduct)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/${productId}/link`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
function findProduct(q, p, projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/find?q=${q}&p=${p}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function searchProduct(q, p, projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/search?q=${q}&p=${p}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function addProductsToProject(projectId, productsRefs) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.auth.auth_token },
        body: JSON.stringify(productsRefs)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function getFullCatalog(currentPage, perPage, search) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/catalog?currentPage=${currentPage}&perPage=${perPage}&keyword=${search}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function setInventoryStatus(status, selectedProducts) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: JSON.stringify(selectedProducts)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/catalog/products/status/${status}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function generateBarCodes(productsRefs) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },

        body: JSON.stringify(productsRefs)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/catalog/products/barcodes`, requestOptions)
        .then(commonService.handleFileResponse.bind(this))
}

function printShootList(projectId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    }
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/products/listshoot`, requestOptions)
        .then(commonService.handleFileResponse.bind(this))
}

function addManuallyProduct(projectId, product) {
    let user = JSON.parse(localStorage.getItem('user'));
    let formdata = new FormData();
    if (product[1]) {
        formdata.append("file", product[1][0].document, product[1][0].name);
        formdata.append('products', [JSON.stringify(product[0])])
    } else {
        formdata.append('products', JSON.stringify(product[0]))
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.auth.auth_token
        },
        body: formdata
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${projectId}/catalog/Add`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}

function showImg(productId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        }
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/catalog/products/${productId}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
