import { projectConstants } from '../constants'
import { projectService } from '../../services'
import { alertActions } from './'

export const projectActions = {
    shootingTypes,
    stepOne,
    getMemberRoles,
    memberAdd,
    getAllProjects,
    getProject,
    updateProject,
    getProjectFiles,
    getFileCategories,
    addDocument,
    deleteDocument,
    replaceDocument,
    getFile,
    downloadFile,
    addComment,
    deleteMember,
    validateComment,
    getProjectStatistics,
    createFolderRaw,
    deleteProject
}
 
function deleteProject(projectId) {
    return dispatch => {
        dispatch(request())
        projectService.deleteProject(projectId)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
            
    }
    function request() { return { type: projectConstants.DELETE_PROJECT_REQUEST } }

    function success(response) { return { type: projectConstants.DELETE_PROJECT_SUCCESS, project: response } }

    function failure(error) { return { type: projectConstants.DELETE_PROJECT_FAILURE, error } }
}

function createFolderRaw(projectId) {
    return dispatch => {
        dispatch(request())
        projectService.createFolderRaw(projectId)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
            
    }

    function request() { return { type: projectConstants.CREATE_FOLDER_RAW_REQUEST } }

    function success(response) { return { type: projectConstants.CREATE_FOLDER_RAW_SUCCESS, project: response } }

    function failure(error) { return { type: projectConstants.CREATE_FOLDER_RAW_FAILURE, error } }
}

function shootingTypes() {
    return dispatch => {
        dispatch(request())
        projectService.shootingTypes()
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.SHOOTING_TYPE_REQUEST } }

    function success(shootingTypes) { return { type: projectConstants.SHOOTING_TYPE_SUCCESS, shootingTypes } }

    function failure(error) { return { type: projectConstants.SHOOTING_TYPE_FAILURE, error } }
}

function stepOne(project) {
    return dispatch => {
        dispatch(request())
        projectService.submitStepOne(project)
            .then(
                projectCreated => {
                    dispatch(success(projectCreated))
                    if (project.uploadDocument.length > 0) {
                        projectService.uploadfile(projectCreated, project)
                            .then(
                                data => dispatch(successFileUpload(data)),
                                error => {
                                    dispatch(uploadFailure(error.toString()))
                                    dispatch(alertActions.error(error.toString()))
                                }
                            )
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.STEP_ONE_REQUEST } }

    function success(project) { return { type: projectConstants.STEP_ONE_SUCCESS, project } }

    function successFileUpload(fileUpload) { return { type: projectConstants.FILE_UPLOAD_SUCCESS, fileUpload } }

    function failure(error) { return { type: projectConstants.STEP_ONE_FAILURE, error } }

    function uploadFailure(error) { return { type: projectConstants.FILE_UPLOAD_FAILURE, error } }
}

function getMemberRoles() {
    return dispatch => {
        dispatch(request())
        projectService.memberRoles()
            .then(
                memberRoles => dispatch(success(memberRoles)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.MEMBER_ROLES_REQUEST } }

    function success(memberRoles) { return { type: projectConstants.MEMBER_ROLES_SUCCESS, memberRoles } }

    function failure(error) { return { type: projectConstants.MEMBER_ROLES_FAILURE, error } }
}

function memberAdd(members) {
    return dispatch => {
        dispatch(request(members))
        projectService.memberAdd(members)
            .then(
                members => dispatch(success(members)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(members) { return { type: projectConstants.MEMBER_ADD_REQUEST, members } }

    function success(members) { return { type: projectConstants.MEMBER_ADD_SUCCESS, members } }

    function failure(error) { return { type: projectConstants.MEMBER_ADD_FAILURE, error } }
}

function getAllProjects() {
    return dispatch => {
        dispatch(request())
        projectService.getAllProjects()
            .then(
                projects => dispatch(success(projects)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_PROJECTS_REQUEST } }

    function success(projects) { return { type: projectConstants.GET_PROJECTS_SUCCESS, projects } }

    function failure(error) { return { type: projectConstants.GET_PROJECTS_FAILURE, error } }
}

function getProject(projectId) {
    return dispatch => {
        dispatch(request())
        projectService.getProject(projectId)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_PROJECT_DETAILS_REQUEST } }

    function success(project) { return { type: projectConstants.GET_PROJECT_DETAILS_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.GET_PROJECT_DETAILS_FAILURE, error } }
}

function updateProject(project) {
    return dispatch => {
        dispatch(request())
        projectService.updateProject(project)
            .then(
                projectUpdated => dispatch(success(projectUpdated)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.STEP_ONE_REQUEST } }

    function success(project) { return { type: projectConstants.STEP_ONE_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.STEP_ONE_FAILURE, error } }
}

function getProjectFiles(projectId) {
    return dispatch => {
        dispatch(request())
        projectService.getProjectFiles(projectId)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_PROJECT_FILES_REQUEST } }

    function success(project) { return { type: projectConstants.GET_PROJECT_FILES_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.GET_PROJECT_FILES_FAILURE, error } }
}

function getFileCategories() {
    return dispatch => {
        dispatch(request())
        projectService.getFileCategories()
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_FILE_CATEGORIES_REQUEST } }

    function success(project) { return { type: projectConstants.GET_FILE_CATEGORIES_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.GET_FILE_CATEGORIES_FAILURE, error } }
}

function addDocument(projectId, document, categoryId) {
    return dispatch => {
        dispatch(request())
        projectService.addDocument(projectId, document, categoryId)
            .then(
                projectUpdated => {
                    dispatch(success(projectUpdated))
                    dispatch(alertActions.success('Fichier ajouté avec succès'))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.ADD_DOCUMENT_REQUEST } }

    function success(project) { return { type: projectConstants.ADD_DOCUMENT_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.ADD_DOCUMENT_FAILURE, error } }
}

function deleteDocument(projectId, documentId) {
    return dispatch => {
        dispatch(request())
        projectService.deleteDocument(projectId, documentId)
            .then(
                projectUpdated => {
                    dispatch(success(projectUpdated))
                    dispatch(alertActions.success('Fichier supprimé avec succès'))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.DELETE_DOCUMENT_REQUEST } }

    function success(project) { return { type: projectConstants.DELETE_DOCUMENT_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.DELETE_DOCUMENT_FAILURE, error } }
}

function replaceDocument(projectId, documentId, categoryId, document) {
    return dispatch => {
        dispatch(request())
        projectService.replaceDocument(projectId, documentId, categoryId, document)
            .then(
                projectUpdated => dispatch(success(projectUpdated)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.REPLACE_DOCUMENT_REQUEST } }

    function success(project) { return { type: projectConstants.REPLACE_DOCUMENT_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.REPLACE_DOCUMENT_FAILURE, error } }
}

function getFile(projectId, fileId) {
    return dispatch => {
        dispatch(request())
        projectService.getFile(projectId, fileId)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_FILE_DETAILS_REQUEST } }

    function success(project) { return { type: projectConstants.GET_FILE_DETAILS_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.GET_FILE_DETAILS_FAILURE, error } }
}

function downloadFile(projectId, fileId) {
    return dispatch => {
        dispatch(request())
        projectService.downloadFile(projectId, fileId)
            .then(
                project => {
                    dispatch(success(project))
                    dispatch(alertActions.success('Fichier téléchargé avec succès'))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.DOWNLOAD_FILE_REQUEST } }

    function success(project) { return { type: projectConstants.DOWNLOAD_FILE_SUCCESS, project } }

    function failure(error) { return { type: projectConstants.DOWNLOAD_FILE_FAILURE, error } }
}

function addComment(projectId, fileId, message, position) {
    return dispatch => {
        dispatch(request())
        projectService.addComment(projectId, fileId, message, position)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.ADD_FILE_COMMENT_REQUEST } }

    function success(comments) { return { type: projectConstants.ADD_FILE_COMMENT_SUCCESS, comments } }

    function failure(error) { return { type: projectConstants.ADD_FILE_COMMENT_FAILURE, error } }
}
function validateComment(projectId, commentId) {
    return dispatch => {
        dispatch(request())
        projectService.validateComment(projectId, commentId)
            .then(
                project => {
                    dispatch(success(project))
                    dispatch(alertActions.success('Le commentaire a été validé'));
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.VALIDATE_COMMENT_REQUEST } }

    function success(comments) { return { type: projectConstants.VALIDATE_COMMENT_SUCCESS, comments } }

    function failure(error) { return { type: projectConstants.VALIDATE_COMMENT_FAILURE, error } }
}
function deleteMember(projectId, memberId) {
    return dispatch => {
        dispatch(request())
        projectService.deleteMember(projectId, memberId)
            .then(
                member => dispatch(success(member)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.DELETE_MEMBER_REQUEST } }

    function success(member) { return { type: projectConstants.DELETE_MEMBER_SUCCESS, member } }

    function failure(error) { return { type: projectConstants.DELETE_MEMBER_FAILURE, error } }
}

function getProjectStatistics() {
    return dispatch => {
        dispatch(request())
        projectService.getProjectStatistics()
            .then(
                stats => dispatch(success(stats)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request() { return { type: projectConstants.GET_STATISTIC_REQUEST } }
    function success(project) { return { type: projectConstants.GET_STATISTIC_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.GET_STATISTIC_FAILURE, error } }
}