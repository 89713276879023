import React, { Component } from 'react';
import {Dropdown} from 'primereact/dropdown';
class ColorDropDown extends Component {
    constructor(props)
    {
        super(props);
        let  colors = [
            { color: '', code: '', hxd: '' },
            { color: 'Rouge', code: 'red', hxd: '#ff2905' },
            { color: 'Orange', code: 'orange', hxd: '#f58f17' },
            { color: 'Jaune', code: 'yellow', hxd: '#f5c200' },
            { color: 'Vert', code: 'green', hxd: '#23ae2b' },
            { color: 'Bleu', code: 'blue', hxd: '#1c7bf5' },
            { color: 'Magenta', code: 'pink', hxd: '#ff1493' },
            { color: 'Violet', code: 'purple', hxd: '#a455cc' }
        ] ;
        let selectedColor = colors.filter(c => c.code.toLowerCase() === this.props.value.toLowerCase())[0] ;
        this.state={
           colors : colors,
    selectedColor : selectedColor};
    this.switchLabel = this.switchLabel.bind(this);

    }

    componentDidUpdate (prevProps, prevState) {
        if(prevProps.value !== this.props.value){
            let selectedColor = this.state.colors.filter(c => c.code.toLowerCase() === this.props.value.toLowerCase())[0] ;

            this.setState({selectedColor: selectedColor})
        }
    }

    switchLabel(value)
    {
        this.setState({selectedColor : value});
        this.props.updateLabel(value) ;
    }
    render() {
        let label = (option) => {
            if (option.code === '') {
                return <div className='label-color' style={{ height: '7px' }}/>
            } else { return <div className='label-color' style={{ height: '7px' , background: option.hxd }}/> }
        }
        let selectedLabel = (option) => {
            if (option) {
                if (option.code === '') {
                    return <div className='label-color'
                                style={{ height: '7px' }}/>
                } else { return <div className='label-color' style={{height: '7px' , background: option.hxd }}/>}
            }
        }

        return (
            <div>
                <Dropdown
                    value={this.state.selectedColor}
                    options={this.state.colors}
                    optionLabel="code"
                    className='color-dropdown'
                    itemTemplate={label}
                    valueTemplate={selectedLabel}
                    onChange={(e) => this.switchLabel(e.value.code.toLowerCase())}
                    scrollHeight="none"
                    panelClassName="color-panel"

                />
            </div>
        );
    }
}

export default ColorDropDown;
