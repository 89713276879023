import React, {Component} from "react";
import {Checkbox} from "primereact/checkbox";
import {Button} from "antd";
import {Divider} from "primereact/divider";
import { withTranslation } from 'react-i18next';

class ImageFilter extends Component {
	constructor( props ) {
		super(props);
		this.state = {
			colors: [
				{color: this.props.t('session.color.black'), code: "black", hxd: "#181818"},
				{color: this.props.t('session.color.red'), code: "red", hxd: "#ff2905"},
				{color: this.props.t('session.color.orange'), code: "orange", hxd: "#f58f17"},
				{color: this.props.t('session.color.yellow'), code: "yellow", hxd: "#f5c200"},
				{color: this.props.t('session.color.green'), code: "green", hxd: "#23ae2b"},
				{color: this.props.t('session.color.blue'), code: "blue", hxd: "#1c7bf5"},
				{color: this.props.t('session.color.pink'), code: "pink", hxd: "#ff1493"},
				{color: this.props.t('session.color.purple'), code: "purple", hxd: "#a455cc"},
			],
			filters: this.props.filters,
			loading: true
		};

		this.setFilter = this.setFilter.bind(this)
		this.checkValue = this.checkValue.bind(this)
		this.resetFilters = this.resetFilters.bind(this)
	}

	componentDidMount() {
		if (JSON.parse(sessionStorage.getItem("filters"))) {
			const json = sessionStorage.getItem('filters')
			const filters = JSON.parse(json)
			this.setState(() => ({filters}))
		}
	}

	setFilter( type, value ) {
		let filters = this.state.filters;
		if (filters[type].includes(value)) {
			let index = filters[type].indexOf(value);
			filters[type].splice(index, 1);
		} else
			filters[type].push(value);

		this.setState({filters: filters})
	}

	checkValue( type, value ) {
		return this.state.filters[type].includes(value)
	}

	resetFilters() {
		let filters = {
			rating: [],
			labels: [],
			status: [],
			selected: []
		}
		this.setState({filters: filters}, function () {
			this.applyFilters(filters)
		})

	}

	applyFilters() {
		this.props.applyFilters(this.state.filters)
	}

	render() {
		const {t} = this.props;
		return (
			<>
				<h5>{t('session.filter.title')}</h5>
				<div className="p-d-flex secondary-bg-color p-px-4 p-py-3">
					{/* Rating */}
					<div>
						<Divider align="left" className="p-mt-3">
							<h5 className="secondary-color">{t('session.filter.note')}</h5>
						</Divider>

						<div className="p-grid p-mb-6 p-mt-2">
							<div className="p-formgroup-inline">
								<div className="p-field-checkbox">
									<Checkbox inputId="rating0" onChange={e => this.setFilter('rating', '0')}
									          checked={this.checkValue('rating', '0')}/>
									<label htmlFor="rating0">
										<i className="pi pi-star-o"></i>
									</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="rating1" onChange={e => this.setFilter('rating', '1')}
									          checked={this.checkValue('rating', '1')}/>
									<label htmlFor="rating1">
										<i className="pi pi-star"></i>
									</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="rating2" onChange={e => this.setFilter('rating', '2')}
									          checked={this.checkValue('rating', '2')}/>
									<label htmlFor="rating2">
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
									</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="rating3" onChange={e => this.setFilter('rating', '3')}
									          checked={this.checkValue('rating', '3')}/>
									<label htmlFor="rating3">
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
									</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="rating4" onChange={e => this.setFilter('rating', '4')}
									          checked={this.checkValue('rating', '4')}/>
									<label htmlFor="rating4">
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
									</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="rating5" onChange={e => this.setFilter('rating', '5')}
									          checked={this.checkValue('rating', '5')}/>
									<label htmlFor="rating5">
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
										<i className="pi pi-star"></i>
									</label>
								</div>
							</div>
						</div>
						<Divider align="left">
							<h5 className="secondary-color">{t('session.filter.tag')}</h5>
						</Divider>
						{/* Colors */}

						<div className="p-grid p-mb-6 p-mt-2">
							<div className="p-formgroup-inline">
								{this.state.colors &&
								this.state.colors.map(( option ) => {
									return (
										<div className="p-field-checkbox">
											<Checkbox inputId={`rating_${option.code}`}
											          onChange={e => this.setFilter('labels', option.code)}
											          checked={this.checkValue('labels', option.code)}/>
											<label htmlFor={`rating_${option.code}`}>
												<div
													className="label-color"
													style={{
														background: option.hxd,
														border: '1px solid white',
													}}
												/>
											</label>
										</div>
									)
								})}
							</div>
						</div>
						{/* Statut */}
						<Divider align="left">
							<h5 className="secondary-color">{t('session.filter.state')}</h5>
						</Divider>
						{/*status in back
					        Draft = 0,
					        Commented=1,
					        InProgress=2,
					        WaitingForValidation=3,
					        Valid = 4,
					        Rejected = 5,
					        */}
						<div className="p-grid p-mb-6 p-mt-2">
							<div className="p-formgroup-inline">
								<div className="p-field-checkbox">
									<Checkbox inputId="statusall" onChange={e => this.setFilter('status', 0)}
									          checked={this.checkValue('status', 0)}/>
									<label htmlFor="statusall">{t('session.filter.all')}</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="statusselected" onChange={e => this.setFilter('selected', true)}
									          checked={this.checkValue('selected', true)}/>
									<label htmlFor="statusselected">{t('session.filter.selected')}</label>
								</div>

								<div className="p-field-checkbox">
									<Checkbox inputId="statusvalid" onChange={e => this.setFilter('status', 4)}
									          checked={this.checkValue('status', 4)}/>
									<label htmlFor="statusvalid">{t('session.filter.valid')}</label>
								</div>
								<div className="p-field-checkbox">
									<Checkbox inputId="statusretouches" onChange={e => this.setFilter('status', 1)}
									          checked={this.checkValue('status', 1)}/>
									<label htmlFor="statusretouches">{t('session.filter.retouch')}</label>
								</div>
							</div>
						</div>

						<div className="p-d-flex p-jc-between">
							<Button className="p-button p-button-sm"
							        onClick={() => this.applyFilters()}>{t('session.filter.apply')}</Button>
							<Button className="p-button p-button-outlined p-button-sm card-button"
							        onClick={() => this.resetFilters()}>{t('session.filter.reset')}</Button>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default withTranslation()(ImageFilter)
