
import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "../common/Header";
import SessionBreadcrumb from "./SessionBreadcrumb";
import {history} from "../../redux/helpers";
import {
  alertActions,
  catalogAction,
  mediasActions,
  productsActions,
} from "../../redux/actions";
import {ProgressSpinner} from "primereact/progressspinner";
import {NewLookDialog} from "./session-dialog/NewLookDialog";
import {UploadRetouchDialog} from "./session-dialog/UploadRetouchDialog";
import CommentDialog from "./session-dialog/CommentDialog";
import ChangeMainProductDialog from "./session-dialog/ChangeMainProductDialog";
import SessionNav from "./SessionNav";
import {ImageContainer} from "./ImageContainer";
import SessionMenu from "./SessionMenu";
import {Divider} from "primereact/divider";
import ImageFilter from "./ImageFilter";
import {withTranslation} from "react-i18next";
import {isTablet} from 'react-device-detect'
import { Button } from 'primereact/button';

class SessionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: JSON.parse(localStorage.getItem("project")),
      products: [],
      medias: [],
      displayedMedias: [],
      selectedPath: "",
      selectedMedias: [],
      boardMedias: [],
      selectedProduct: {},
      paths: [],
      filters: {rating: [], labels: [], status: [], selected: []},
      showCommentProjectPopup: false,
      showZipPopup: false,
      commentProject: "",
      searchTree: "",
      errors: {},
      uploadDocument: [],
      isFetched: false,
      loading: false,
      showLinkPopup: false,
      searchProduct: "",
      isVisible: [],
      isLinked: [],
      product: [],
      displayedProducts: [],
      mainProduct: {},
      isOverviewMode: false,
      showchangeRefPopup: false,
      newRef: null,
      disabled: false,
      isDownloadFetching: false,
      exportImageRequest: false,
      exportMerchRequest: false,
      isSynchronizing: false,
      isFolderFetched: true,
	  menuNav: !isTablet,
      t: this.props.t,
    };

    this.selectPath = this.selectPath.bind(this);
    this.commentButtonClicked = this.commentButtonClicked.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.getBoard = this.getBoard.bind(this);
    this.showCommentProjectPopup = this.showCommentProjectPopup.bind(this);
    this.showchangeRefPopup = this.showchangeRefPopup.bind(this);
    this.handleCommentProject = this.handleCommentProject.bind(this);
    this.getMediaBoard = this.getMediaBoard.bind(this);
    this.validateMedias = this.validateMedias.bind(this);
    this.UpdateSelectMedias = this.UpdateSelectMedias.bind(this);
    this.setKeyDown = this.setKeyDown.bind(this);
    this.linkedChange = this.linkedChange.bind(this);
    this.changeRefMer = this.changeRefMer.bind(this);
  }

  componentDidMount() {
    if (this.state.project) {
      // this.setState({products:[],medias:[],paths:[],displayedMedias:[],})
      this.props.getFolder(this.state.project.projectId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const jsonPath = sessionStorage.getItem("selected");
    const selected = JSON.parse(jsonPath);

    if (this.props.isFolderFetched && prevState.isFolderFetched) {
      let selectedPath = selected
        ? selected
        : this.props.folder[0]
        ? this.props.folder[0].storagePath
        : "null";
      this.props.getImages(this.state.project.projectId, selectedPath);
      this.setState({
        selectedPath: selectedPath,
        paths: this.props.folder,
        isFolderFetched: false,
      });
    }
  }
  static updateDisplayedMedias(state) {
    state = {...state, displayedMedias: [], boardMedias: []};
    var filters = state.filters;
    var displayedMedias = state.products;

    if (filters.selected.length > 0) {
      displayedMedias = displayedMedias.filter(m => m.selected == true);
    }

    if (filters.rating.length > 0)
      displayedMedias = displayedMedias.filter(
        m =>
          m.metasData.filter(meta => meta.name == "Rating").length > 0 &&
          filters.rating.includes(
            m.metasData.filter(meta => meta.name == "Rating")[0].value
          )
      );

    if (filters.labels.length > 0)
      displayedMedias = displayedMedias.filter(
        m =>
          m.metasData.filter(meta => meta.name == "Label").length > 0 &&
          filters.labels.includes(
            m.metasData.filter(meta => meta.name == "Label")[0].value
          )
      );

    if (filters.status.length > 0) {
      if (filters.status == 0) {
        displayedMedias = displayedMedias;
      } else {
        displayedMedias = displayedMedias.filter(m =>
          filters.status.includes(m.status)
        );
      }
    }
    state = {...state, displayedMedias: displayedMedias};
    return state;
  }

  static getDerivedStateFromProps(props, state) {
    const jsonFilter = sessionStorage.getItem("filters");
    const filters = JSON.parse(jsonFilter);

    if (props.isFetched) {
      if (props.products && props.products != state.products) {
        let selectedProduct =
          props.products.length > 0 ? props.products[0] : null;
        let products = props.products;
        let newState = {
          products: products,
          selectedProduct:
            selectedProduct !== null ? selectedProduct.product : null,
          isFetched: props.isFetched,
          filters: filters ? filters : state.filters,
        };
        return SessionScreen.updateDisplayedMedias(newState);
      }
    }
    if (props.location.state !== undefined) {
      if (
        props.location.state.project !== undefined &&
        props.location.state.project.projectId !== state.project.projectId
      ) {
        props.getFolder(props.location.state.project.projectId);
        //sessionStorage.removeItem("expandedRefs");
        return {
          project: props.location.state.project,
          displayedMedias: [],
          products: [],
          medias: [],
          paths: [],
          selectPath:"",
          isFolderFetched:true
          
        };
      }
    }

    if (
      props.isProductLinkedFetched &&
      props.product.products !== state.product
    ) {
      return {
        product: props.product.products,
        mainProduct: props.product.mainProduct,
      };
    }

    if (props.media.isDownloadFetching) {
      return {isDownloadFetching: props.media.isDownloadFetching};
    }

    if (props.exportImageRequest) {
      return {exportImageRequest: props.exportImageRequest};
    }

    if (
      props.exportImageSuccess ||
      (!props.exportImageRequest && state.exportImageRequest)
    ) {
      return {exportImageRequest: false};
    }

    if (props.exportMerchRequest) {
      return {exportMerchRequest: props.exportMerchRequest};
    }

    if (
      props.exportMerchSuccess ||
      (!props.exportMerchRequest && state.exportMerchRequest)
    ) {
      return {exportMerchRequest: false};
    }

    if (props.isSynchronizing) {
      return {isSynchronizing: props.isSynchronizing};
    }

    if (
      props.isSynchronized ||
      (!props.isSynchronizing && state.isSynchronizing)
    ) {
      if (props.isSynchronized) {
        let project = state.project;
        project.needToBeSynchronized = false;
        localStorage.setItem("project", JSON.stringify(project));
        return {
          isSynchronizing: false,
          project: project,
        };
      }
      return {isSynchronizing: false};
    }

    return state;
  }

  ///Events
  getMediaBoard(medias) {
    let boardMedias = [];
    medias.forEach(media => {
      if (boardMedias.filter(i => i.id == media.productId).length > 0) {
        let mediaIndex = boardMedias
          .filter(i => i.id == media.productId)[0]
          .externalIds.indexOf(media.id);
        if (mediaIndex > -1)
          boardMedias
            .filter(i => i.id == media.productId)[0]
            .externalIds.splice(mediaIndex, 1);
        else
          boardMedias
            .filter(i => i.id == media.productId)[0]
            .externalIds.push(media.id);
      } else {
        boardMedias.push({id: media.productId, externalIds: [media.id]});
      }
    });
    return boardMedias;
  }

  validateMedia(media, newStatus) {
    this.props.validateImg(
      this.state.project.projectId,
      media.productId,
      media.id
    );
    media.status = newStatus;
    let index = this.state.displayedMedias.findIndex(m => m.id === media.id);
    if (index !== -1) {
      this.setState({
        displayedMedias: [
          ...this.state.displayedMedias.slice(0, index),
          media,
          ...this.state.displayedMedias.slice(index + 1),
        ],
      });
    }
  }

  addToSelection(media) {
    let mediaByProducts = [];
    mediaByProducts.push({id: media.productId, externalIds: [media.id]});
    this.props.selectedImagesBool(
      this.state.project.projectId,
      mediaByProducts,
      !media.selected
    );
    media.selected = !media.selected;
  }

  UpdateSelectMedias(isSelected) {
    let mediaBoard = this.getMediaBoard(this.state.selectedMedias);
    let displayedMedias = this.state.displayedMedias;
    if (mediaBoard && mediaBoard.length > 0) {
      this.props.selectedImagesBool(
        this.state.project.projectId,
        mediaBoard,
        isSelected
      );
      let externalids = mediaBoard.flatMap(m => m.externalIds);
      let updateDisplayed = displayedMedias.map(m => {
        if (externalids.indexOf(m.id) !== -1) {
          m.selected = isSelected;
        }
        return m;
      });
      this.setState({displayedMedias: updateDisplayed});
    }
  }

  updateCheckedMedias = media => {
    let medias = this.state.selectedMedias;
    let index = medias.findIndex(m => m.id == media.id);
    if (index == -1 && media.ischecked) {
      medias.push(media);
    } else if (index !== -1 && !media.ischecked) {
      medias.splice(index, 1);
    }
    this.setState({selectedMedias: medias, isOverviewMode: false});
  };

  validateMedias() {
    let selectedMedias = this.state.selectedMedias;
    let medias = this.getMediaBoard(selectedMedias);
    let displayedMedias = this.state.displayedMedias;
    if (medias && medias.length > 0) {
      this.props.validateImages(this.state.project.projectId, medias);
      let externalids = medias.flatMap(m => m.externalIds);
      let updateDisplayed = displayedMedias.map(m => {
        if (externalids.indexOf(m.id) !== -1) {
          m.status = 4;
        }
        return m;
      });
      this.setState({displayedMedias: updateDisplayed});
    }
  }

  selectPath(path) {
    this.setState({isOverviewMode: false});
    const json = JSON.stringify(path);
    sessionStorage.setItem("selected", json);
    this.state.selectedMedias.map((media, i) => {
      let index = this.state.displayedMedias.indexOf(
        this.state.selectedMedias[i]
      );
      this.state.displayedMedias[index].ischecked = false;
    });
    this.setState({selectedPath: path, selectedMedias: []}, () =>
      this.setState(SessionScreen.updateDisplayedMedias(this.state))
    );
    this.props.getImages(this.state.project.projectId, path);
  }

  applyFilters(filters) {
    const jsonFilters = JSON.stringify(filters);
    sessionStorage.setItem("filters", jsonFilters);
    this.setState({filters: filters}, () =>
      this.setState(SessionScreen.updateDisplayedMedias(this.state))
    );
  }

  setKeyDown(key) {
    let selectedMedias = this.state.selectedMedias;
    let displayedMedias = this.state.displayedMedias;
    let index = displayedMedias.indexOf(selectedMedias[0]);

    if (selectedMedias.length === 1) {
      if (key.code === "ArrowRight" && index < displayedMedias.length - 1) {
        let newMedia = [displayedMedias[index + 1]];
        this.setState({selectedMedias: newMedia});
      } else if (key.code === "ArrowLeft" && index > 0) {
        let newMedia = [displayedMedias[index - 1]];
        this.setState({selectedMedias: newMedia});
      }
    }
  }

  commentButtonClicked(media) {
    history.push(
      `/projects/${this.state.project.projectId}/session/products/${media.productId}/images/${media.id}/comments`,
      this.state.displayedMedias
    );
  }
  UnCheckMedias(medias) {
    medias.map(m => (m.ischecked = false));
    this.setState({selectedMedias: []});
  }
  getBoard() {
    let mediaBoard = this.getMediaBoard(this.state.selectedMedias);
    if (mediaBoard && mediaBoard.length > 0) {
      this.props.getBoard(this.state.project.projectId, mediaBoard);
    }
  }
  showchangeRefPopup() {
    this.setState({showchangeRefPopup: true});
  }

  showCommentProjectPopup() {
    this.setState({showCommentProjectPopup: true});
  }

  handleCommentProject(comment) {
    let projectId = this.state.project.projectId;
    this.props.setCommentProject(projectId, comment);
  }

  DownloadMedias() {
    let mediaBoard = this.getMediaBoard(this.state.displayedMedias);
    if (mediaBoard && mediaBoard.length > 0) {
      this.props.DownloadMedias(this.state.project.projectId, mediaBoard);
    }
  }

  showLinkedPopup(media) {
    let {project, selectedMedias} = this.state;
    if (selectedMedias.length == 0) {
      selectedMedias.push(media);
    }
    this.props.getProductLinked(
      project.projectId,
      selectedMedias[0].productId,
      selectedMedias[0].id
    );
    this.setState({showLinkPopup: true});
  }

  showZipPopup = () => {
    this.setState({showZipPopup: true});
  };

  linkedChange(value, rowData, type) {
    let product = [...this.state.product];
    let index = product.findIndex(prod => prod.id === rowData.id);
    if (index === -1) {
      let mainProduct = this.state.mainProduct;
      mainProduct.isVisible = value;
      this.setState({mainProduct: mainProduct});
    } else {
      if (product[index].id == this.state.mainProduct.id) {
        this.state.mainProduct.isVisible = value;
        this.setState({mainProduct: this.state.mainProduct});
      }
      product[index].isVisible = value;
      this.setState({product: product});
    }
  }

  changeRefMer(newRef) {
    let mediasbyproduct = this.getMediaBoard(this.state.selectedMedias);
    if (newRef == null || newRef == undefined) {
      this.props.alertWarning(this.state.t("toast.noSelect"));
    } else {
      this.props.changeRefMer(
        this.state.project.projectId,
        mediasbyproduct,
        newRef.id,
        newRef.reference
      );
      this.setState({showchangeRefPopup: false});
    }
  }

  setSelectAllMedias = p => {
    let {displayedMedias, selectedMedias} = this.state;
    displayedMedias.map(media => {
      if (p === "selectAll") {
        if (!selectedMedias.find(el => el.id === media.id)) {
          media.ischecked = true;
          selectedMedias.push(media);
        }
      } else {
        media.ischecked = false;
        selectedMedias = [];
      }
    });
    this.setState({displayedMedias, selectedMedias});
  };

  exportValideImage = () => {
    const {project} = this.state;
    this.props.exportValideImage(project.projectId);
  };

  exportMerch = () => {
    const {project} = this.state;
    this.props.exportMerch(project.projectId);
  };

  syncButtonClicked = () => {
    this.props.synchronizeSession(this.state.project.projectId);
  };

  render() {
    return (
      <div>
        <Header
          projectName={this.state.project.title}
          projectId={this.state.project.projectId}
        />
        {this.state.isFetched ? (<>
          {isTablet ? <Button
                value={this.state.menuNav}
                icon="pi pi-sitemap"
                onClick={() => this.setState({menuNav : !this.state.menuNav})}
            /> : <></> }
          <div className="p-grid" style={{ height: "90vh" }}>
            <div className={isTablet ? !this.state.menuNav ? "p-sr-only" : "p-col-2 p-lg-2 p-md-3 fadeinleft animation-duration-1000" : "p-col-2 p-xl-2 p-lg-2 p-md-3 p-pt-0"}>
              <SessionNav
                paths={this.state.paths}
                selectedPath={this.state.selectedPath}
                selectPath={p => this.selectPath(p)}
                products={this.state.products}
              />
            </div>
            <div className={`p-col p-xl-10 p-lg-10 p-md-${this.state.menuNav ? 9 : 12} p-mx-xl-0 p-mx-lg-0 p-mx-md-0 p-mt-1`}>
              <div className="p-grid secondary-bg-color">
                <div className="p-col-9 p-px-2">
                  {this.state.selectedPath !== "null" &&
                  this.state.selectedPath !== undefined ? (
                    <SessionBreadcrumb
                      path={this.state.selectedPath}
                      selectPath={p => this.selectPath(p)}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="p-grid"
                onKeyDown={e => this.setKeyDown(e)}
                tabIndex="0"
                style={{outline: "none"}}
              >
                <ImageContainer
                  projectId={this.state.project.projectId}
                  displayedMedias={this.state.displayedMedias}
                  selectedMediasSession={this.state.selectedMedias}
                  isOverviewMode={this.state.isOverviewMode}
                  commentButtonClicked={m => this.commentButtonClicked(m)}
                  showLinkedPopup={m => this.showLinkedPopup(m)}
                  isSynchronizing={this.state.isSynchronizing}
                  addToSelection={m => this.addToSelection(m)}
                  validateMedia={(m, s) => this.validateMedia(m, s)}
                  updateCheckedMedias={m => this.updateCheckedMedias(m)}
                  setSelectAllMedias={p => this.setSelectAllMedias(p)}
                  selectedMediasLength={this.state.selectedMedias.length}
                  filters={this.state.filters}
                  syncButtonClicked={() => this.syncButtonClicked()}
                  paths={this.state.paths}
                  loading={this.state.isFetched}
                />
                <div className="p-col-3 p-xl-3 p-lg-3 p-md-12 p-px-xl-3 p-px-lg-3 p-px-md-1 p-mr-xl-0 p-mr-lg-0 p-mr-md-2">
                  <SessionMenu
                    medias={this.state.selectedMedias}
                    UnCheckMedias={m => this.UnCheckMedias(m)}
                    UpdateSelectMedias={m => this.UpdateSelectMedias(m)}
                    validateMedias={() => this.validateMedias()}
                    isDownloadFetching={this.state.isDownloadFetching}
                    isSynchronizing={this.state.isSynchronizing}
                    exportImageRequest={this.state.exportImageRequest}
                    exportMerchRequest={this.state.exportMerchRequest}
                    getBoard={() => this.getBoard()}
                    showchangeRefPopup={() => this.showchangeRefPopup()}
                    showCommentProjectPopup={() =>
                      this.showCommentProjectPopup()
                    }
                    exportValideImage={() => this.exportValideImage()}
                    exportMerch={() => this.exportMerch()}
                    showLinkedPopup={() => this.showLinkedPopup()}
                    showZipPopup={() => this.showZipPopup()}
                    syncButtonClicked={() => this.syncButtonClicked()}
                    needToBeSynchronized={
                      this.state.project.needToBeSynchronized
                    }
                  />
                  <Divider className="p-my-3" />
                  <ImageFilter
                    filters={this.state.filters}
                    applyFilters={f => this.applyFilters(f)}
                  />
                </div>
              </div>
            </div>
          </div>
		</>
        ) : (
          <div
            className="p-d-flex p-flex-column p-jc-center p-ai-center"
            style={{height: "90vh"}}
          >
            <ProgressSpinner className="p-progress-spinner" />
          </div>
        )}
        {/* Create  Component  Comment */}
        <CommentDialog
          selectedMediasLength={this.state.selectedMedias.length}
          showCommentProjectPopup={this.state.showCommentProjectPopup}
          hideCommentProjectPopup={() =>
            this.setState({showCommentProjectPopup: false})
          }
          handleCommentProject={e => this.handleCommentProject(e)}
        />
        {/*  Create  Component  Upload Retouche */}
        <UploadRetouchDialog
          showZipPopup={this.state.showZipPopup}
          onHide={() => this.setState({showZipPopup: false})}
          projectId={this.state.project.projectId}
        />
        {/* Create  Component  Look */}
        <NewLookDialog
          selectedMedias={this.state.selectedMedias}
          displayedMedias={this.state.displayedMedias}
          displayedProducts={this.state.displayedProducts}
          mainProduct={this.state.mainProduct}
          productOpened={this.state.product}
          linkedChange={(e, f, g) => this.linkedChange(e, f, g)}
          visible={this.state.showLinkPopup}
          onHide={() => {
            this.state.displayedMedias.map(m => (m.ischecked = false));
            this.setState({
              showLinkPopup: false,
              product: [],
              selectedMedias: [],
            });
          }}
          projectId={this.state.project.projectId}
        />
        {/* Change la référence mère */}
        <ChangeMainProductDialog
          productfolders={this.state.productfolders}
          showchangeRefPopup={this.state.showchangeRefPopup}
          onHide={() => this.setState({showchangeRefPopup: false})}
          changeRefMer={e => this.changeRefMer(e)}
          projectId={this.state.project.projectId}
        />
      </div>
    );
  }
}

function mapState(state) {
  const {catalog, media} = state;
  const {
    products,
    isFetched,
    zip,
    isProductLinkedFetched,
    product,
    linkProductIsFetched,
    isSynchronizing,
    isSynchronized,
    isRateOrLabelFetching,
    isImageSelectedFetching,
    folder,
    isFolderFetched,
    exportImageRequest,
    exportImageSuccess,
    exportMerchRequest,
    exportMerchSuccess,
  } = state.products;

  return {
    products,
    isFetched,
    zip,
    catalog,
    isProductLinkedFetched,
    product,
    linkProductIsFetched,
    isSynchronizing,
    isRateOrLabelFetching,
    isImageSelectedFetching,
    media,
    folder,
    isFolderFetched,
    isSynchronized,
    exportImageRequest,
    exportImageSuccess,
    exportMerchRequest,
    exportMerchSuccess,
  };
}

const actionCreators = {
  getImages: productsActions.getImages,
  setCommentProject: productsActions.setCommentProject,
  getBoard: mediasActions.getBoard,
  alertWarning: alertActions.warning,
  validateImages: productsActions.validateImages,
  DownloadMedias: mediasActions.DownloadMedias,
  selectedImagesBool: productsActions.selectedImagesProductBool,
  linkProduct: productsActions.linkProduct,
  getProductLinked: productsActions.getProductLinked,
  changeRefMer: mediasActions.changeRefMer,
  getFolder: productsActions.getFolder,
  validateImg: productsActions.validateImg,
  exportValideImage: productsActions.exportValideImage,
  exportMerch: productsActions.exportMerch,
  synchronizeSession: productsActions.synchronizeSession,
};

const connectedSessionScreen = connect(
  mapState,
  actionCreators
)(withTranslation()(SessionScreen));
export {connectedSessionScreen as SessionScreen};
