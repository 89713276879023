import { mediasConstants } from '../constants';

export function media(state = {}, action) {
    switch (action.type) {
        case mediasConstants.MEDIAS_GET_BOARD_REQUEST:
            return Object.assign({}, { isDownloadFetching: true });
        case mediasConstants.MEDIAS_GET_BOARD_SUCCESS:
            return Object.assign({}, { type: action.board, isDownloadFetched: true });
        case mediasConstants.MEDIAS_GET_BOARD_FAILURE:
            return {}

        case mediasConstants.CHANGE_REF_MER_REQUEST:
            return Object.assign({}, { isFetching: true });
        case mediasConstants.CHANGE_REF_MER_SUCCESS:
            return Object.assign({}, { type: action.board, isFetchedMoving: true });
        case mediasConstants.CHANGE_REF_MER_REQUEST:
            return {}
        default:
            return state
    }
}

