 
import { commonService } from './common.service';
export const mediasService = {
    getBoard,DownloadMedias,changeRefMer
}
function changeRefMer (projectId,mediasbyproducts,newref)
{
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.auth.auth_token
        },

        body: JSON.stringify(mediasbyproducts)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/medias/ChangeRef/${newref}`, requestOptions)
        .then(commonService.handleResponse.bind(this))
}
function DownloadMedias(projectId, imageIds) {
  let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.auth_token
    },
        body: JSON.stringify(imageIds)
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/medias/export`, requestOptions)
    .then(commonService.handleResponse.bind(this))
}
function getBoard(projectId, imageIds) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.auth.auth_token
      },
      
      body: JSON.stringify(imageIds)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/projects/${projectId}/medias/download`, requestOptions)
      .then(commonService.handleFileResponse.bind(this))
  }

 
