import {searchConstants} from '../constants';
export function search(state = {}, action) {
    switch (action.type) {
      case searchConstants.GET_FILTERS_REQUEST:
        return Object.assign({}, { isFetching: true });
      case searchConstants.GET_FILTERS_SUCCESS:
        return Object.assign({}, { filters: action.filters, isFetched: true });
      case searchConstants.GET_FILTERS_FAILURE:
        return Object.assign({}, { isFetched: true });
    
        case searchConstants.ADVANCED_SEARCH_REQUEST:
        return Object.assign({}, { isFetching: true });
      case searchConstants.ADVANCED_SEARCH_SUCCESS:
        return Object.assign({}, { catalog: action.searchResult, isFetched: true });
      case searchConstants.ADVANCED_SEARCH_FAILURE:
        return Object.assign({}, { isFetched: true }); 
        
        default:
        return state
    }
  }
  