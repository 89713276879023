import React, { Component } from 'react'
import Header from '../../common/Header'
import { ProjectMenu } from '../common/ProjectMenu'
import { history } from '../../../redux/helpers'
import { alertActions } from "../../../redux/actions";
import { projectActions } from '../../../redux/actions/project.actions'
import { connect } from 'react-redux'
import { Panel } from 'primereact/panel'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Member } from '../member/Member'
import Project from './Project'
import { ScrollPanel } from 'primereact/scrollpanel'
import moment from 'moment'
import { ProgressSpinner } from 'primereact/progressspinner'
import { withTranslation } from 'react-i18next';

class ProjectDetailsScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            project: {
                projectId: null,
                title: null,
                description: null,
                shootingType: null,
                shootingTypeLabel: null,
                startShootingDate: null,
                thumbUrl: null,
                members: [],
            },
            types: [],
            memberRoles: [],
            isFetched: false,
            showMembersDialog: false,
            showEditProjectDialog: false,
            loading: true,
            t: this.props.t
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.project && Object.keys(props.project).length > 0 && props.isFetched && props.project.projectId !== state.project.projectId) {
            sessionStorage.setItem('Project', props.project.title)
            sessionStorage.setItem('ProjectId', props.project.projectId)
            return { project: props.project, isFetched: props.isFetched };

        } else if (props.types && Object.keys(props.types).length > 0 && props.isFetched) {
            return { types: props.types }
        }
        if (props.memberRoles && Object.keys(props.memberRoles).length > 0) {
            return { memberRoles: props.memberRoles };
        }
        if (props.isRawFetched) {
            if (props.project.members == null && state.project.members !== null) {
                props.project.members = state.project.members;
            }
            return { project: props.project }

        }
    }

    componentDidMount() {
        if (this.props.match.params.projectId) {
            this.props.shootingTypes();
             this.props.getProject(this.props.match.params.projectId) }
    }


    showMembersDialog() {
        this.setState({
            showMembersDialog: true
        })
    }

    hideMembersDialog(dialogState) {
        this.setState({
            showMembersDialog: false
        })
    }

    showEditProjectDialog() {
        let state = {
            showEditProjectDialog: true
        }

        this.setState(state)
    }

    hideEditProjectDialog() {
        let state = {
            showEditProjectDialog: false
        }

        this.setState(state)
    }

    updateProject(project) {
        this.props.updateProject(project)
        this.hideEditProjectDialog()
    }

    createFolderRaw() {
        this.props.createFolderRaw(this.state.project.projectId)
    }

    updateProjectMembers(members) {
        const requestData = {
            projectId: this.state.project.projectId,
            members: members
        }
        this.props.addMember(requestData)
        this.setState(prevState => {
            return {
                project: {
                    ...prevState.project,
                    members: members
                }
            }
        })
        this.hideMembersDialog()

    }

    deleteMember(memberId) {
        this.props.deleteMember(this.state.project.projectId, memberId)
    }

    isStringNullOrWhiteSpace(value) {
        return value === undefined
            || value === null
            || typeof value !== 'string'
            || value.match(/^ *$/) !== null;
    }
    render() {
        return (
            <div>
                <Header projectName={this.state.project.title} projectId={this.state.project.projectId} />
                <ScrollPanel className="sf-scrollpanel">
                    <ProjectMenu projectId={this.state.project.projectId} activeItemIndex={0} />
                    {this.state.isFetched ?
                        <div className="p-grid p-d-flex">
                            <div className="p-col p-d-flex p-flex-column">

                                <div className="p-d-flex p-ai-center p-mt-6 p-mb-3 p-jc-between">
                                    <h5>{this.state.t('projectDetails.title')}</h5>

                                    <Button className="p-button-rounded" label={this.state.t('common.modify')}
                                        onClick={() => this.showEditProjectDialog()} />
                                </div>
                                <Panel className="p-my-3 rounded-content">
                                    <div className="p-d-flex">
                                        <div className="col-3">{this.state.t('projectDetails.projectName')}</div>
                                        <div className="col-7 p-offset-2">{this.state.project.title}</div>
                                    </div>
                                </Panel>
                                <Panel className="p-my-3 rounded-content">
                                    <div className="p-d-flex">
                                        <div className="col-3">{this.state.t('projectDetails.projectDate')}</div>
                                        <div className="col-7 p-offset-2">{moment(this.state.project.startShootingDate).format('DD/MM/YYYY')}</div>
                                    </div>
                                </Panel>
                                <Panel className="p-my-3 rounded-content">
                                    <div className="p-d-flex">
                                        <div className="col-3">{this.state.t('projectDetails.projectType')}</div>
                                        <div className="col-7 p-offset-2">{this.state.project.shootingTypeLabel}</div>
                                    </div>
                                </Panel>
                                <Panel className="p-my-3 rounded-content">
                                    <div className="p-d-flex">
                                        <div className="col-3 p-mr-5">{this.state.t('projectDetails.projectDescrib')}</div>
                                        <div className="col-7 p-offset-1">{this.state.project.description}</div>
                                    </div>
                                </Panel>
                                {!this.isStringNullOrWhiteSpace(this.state.project.rawMediasUrl) ?
                                    <Panel className="p-my-3 rounded-content">
                                        <div className="p-d-flex">
                                            <div className="p-offset-4"><a href={`${this.state.project.rawMediasUrl}`}
                                                target="_blank"
                                                style={{ "font-weight": 600 }}>{this.state.t('projectDetails.linkRaw')}</a></div>

                                        </div>
                                    </Panel>
                                    :
                                    <>
                                        <Button className="p-button-rounded p-as-center" label={this.state.t('projectDetails.createRaw')}
                                            onClick={() => this.createFolderRaw()} />

                                    </>}
                            </div>
                            <div className="p-col">
                                <div className="p-d-flex p-ai-center p-mt-6 p-mb-3 p-jc-between">
                                    <h5>{this.state.t('projectDetails.members')}</h5>
                                    <Button className="p-button-rounded" label={this.state.t('common.modify')}
                                        onClick={() => this.showMembersDialog()} />
                                </div>
                                {this.state.project.members && this.state.project.members.map((value, index) => {
                                    return <Panel className="p-my-3 p-py-auto rounded-content">
                                        <div className="p-grid p-align-center">
                                            <div className="p-col p-grid p-align-center">
                                                <span className="p-badge p-badge-md p-badge-warning">
                                                    {value.name ? value.name.charAt(0).toUpperCase() : value.email.charAt(0).toUpperCase()}
                                                </span>&nbsp;
                                                {value.name}
                                            </div>
                                            <div className="p-col">{value.email}</div>
                                            <div className="p-col">{value.role}</div>
                                        </div>
                                    </Panel>
                                })}
                            </div>
                        </div> :
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ height: "60vh" }}>
                            <ProgressSpinner className="p-progress-spinner" />
                        </div>}

                    {/* Popup pour ajouter des membres */}
                    <Member
                        visible={this.state.showMembersDialog}
                        cancel={(s) => this.hideMembersDialog(s)}
                        validate={(e) => this.updateProjectMembers(e)}
                        members={JSON.parse(JSON.stringify(this.state.project.members))}
                        projectId={this.state.project.projectId}
                        delete={(e) => this.deleteMember(e)}
                    />

                    <Dialog position='center' header="METTRE À JOUR LE PROJET"
                        visible={this.state.showEditProjectDialog}
                        style={{ width: '30vw' }}
                        onHide={() => this.hideEditProjectDialog()}>
                        <Project
                            cancel={() => this.hideEditProjectDialog()}
                            validate={(e) => this.updateProject(e)}
                            project={this.state.project}
                            types={this.state.types}
                        />
                    </Dialog>
                </ScrollPanel>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { project, isFetched, types, isRawFetched } = state.project

    return { project, isFetched, types, isRawFetched }
}

const mapDispatchToProps = {
    getProject: projectActions.getProject,
    shootingTypes: projectActions.shootingTypes,
    addMember: projectActions.memberAdd,
    updateProject: projectActions.updateProject,
    deleteMember: projectActions.deleteMember,
    createFolderRaw: projectActions.createFolderRaw
}

const connectedProject = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectDetailsScreen))
export { connectedProject as ProjectDetailsScreen }
