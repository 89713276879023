import React, { Component } from 'react'
import { alertActions, projectActions } from '../../../redux/actions'
import { connect } from 'react-redux'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';
import { history } from '../../../redux/helpers'
import './createProjectScreen.scss'
import InputEmail from '../../common/InputEmail'
import { withTranslation } from 'react-i18next';

class CreateProjectMember extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectId: "",
            project: {},
            members: [
                { email: '', role: '' },
                { email: '', role: '' },
                { email: '', role: '' },
                { email: '', role: '' },
            ],
            role: [
                { label: this.props.t('createProject.role.photographer'), value: 'photographer' },
                { label: this.props.t('createProject.role.retoucher'), value: 'retoucher' },
                { label: this.props.t('createProject.role.validator'), value: 'validator' }
            ],
            loading: false,
            inputError: false,
            t: this.props.t
        }

    }

    componentDidMount = () => {
        if ( this.props.project.project.projectId ) {
            this.setState({
                projectId: this.props.project.project.projectId,
                project: this.props.project
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ( props.project.isMemberAddFetching ) {
            return { loading: true }
        }
        if ( props.project.isMemberAddFetched ) {
            history.push('/')
        }
    }

    handleMemberEmailChange = (idx) => (evt) => {
        const newMembers = this.state.members.map((member, sidx) => {
            if ( idx !== sidx ) return member
            return { ...member, name: member.name, email: evt, role: member.role }
        })
        this.setState({ members: newMembers })
    }

    handleMemberNameChange = (idx) => (evt) => {
        const newMembers = this.state.members.map((member, sidx) => {
            if ( idx !== sidx ) return member
            return { ...member, name: evt.target.value, email: member.email, role: member.role }
        })
        this.setState({ members: newMembers })
    }

    handleMemberRoleChange = (idx) => (evt) => {
        const newMembers = this.state.members.map((member, sidx) => {
            if ( idx !== sidx ) return member
            return { ...member, name: member.name, role: evt.target.value, email: member.email }
        })

        this.setState({ members: newMembers })
    }

    handleMemberSubmit = (e) => {
        e.preventDefault()
        let memberDataEmpty = this.state.members.filter(field => field.email === '')
        if ( memberDataEmpty.length === 4 ) {
            this.props.alertWarning(this.state.t('createProject.warning.empty'))
        } else if ( this.state.inputError ) {
            this.props.alertWarning(this.state.t('createProject.warning.error'))
        } else {
            let memberData = this.state.members.filter(field => field.email !== '')
            const requestData = {
                projectId: this.state.projectId,
                members: memberData
            }
            this.props.memberAdd(requestData)
        }
    }

    render() {

        return (
            <div className="p-d-flex p-flex-column p-ai-center p-p-6">
                <hr className="step-bar"/>
                <div className="p-mt-6 p-p-6 p-d-flex p-flex-column p-jc-around stepTwoContainer">
                    <h4 className="p-text-center">{this.state.t('createProject.memberTitle')}</h4>
                    {this.state.members.map((member, i) => (
                        <div key={i} className="p-grid p-m-0 p-p-0">
                            <InputText
                                className="p-col-4"
                                placeholder={this.state.t('createProject.addName')}
                                value={member.name}
                                onChange={this.handleMemberNameChange(i)}
                                style={{ minHeight: '52px' }}/>
                            {/*<InputText className="p-col p-p-2 p-mx-2" placeholder="Ajouter un email" value={member.email}*/}
                            {/*           onChange={this.handleMemberEmailChange(i)}/>*/}
                            <InputEmail
                                value={member.email}
                                className="p-col-4 p-mx-2"
                                placeholder={this.state.t('createProject.addEmail')}
                                onChange={this.handleMemberEmailChange(i)}
                                isError={bool => this.setState({ inputError: bool })}
                                style={{ minHeight: '52px' }}
                            />
                            <Dropdown
                                className="memberRoleSelect p-col"
                                placeholder={this.state.t('common.role')}
                                options={this.state.role}
                                value={member.role}
                                onChange={this.handleMemberRoleChange(i)}
                            />
                        </div>
                    ))}
                    <div className="p-d-flex p-flex-row p-jc-end p-pt-6 p-pl-6 p-pr-6 p-pb-2 footer">
                        <Button label={this.state.t('createProject.later')} onClick={() => history.push(`/`)}/>
                        <Button loading={this.state.loading} label={this.state.t('common.send')} className="p-ml-3"
                                onClick={this.handleMemberSubmit}/>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const projects = state.projects
    return projects
}

const actionCreators = {
    getMemberRoles: projectActions.getMemberRoles,
    memberAdd: projectActions.memberAdd,
    getProjects: projectActions.getAllProjects,
    alertWarning: alertActions.warning,
}

const connectedProject = (connect(mapState, actionCreators)(withTranslation()(CreateProjectMember)))
export { connectedProject as CreateProjectMember }
